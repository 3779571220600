import { Modal, ModalProps } from 'antd';
import { A1CCustomizeGoalModalContainer } from '../../container/A1CCustomizeGoalModalContainer/A1CCustomizeGoalModalContainer';
import { BgGoal } from '../../../../uc-api-sdk';

export interface A1CCustomizeGoalModalComponentProps extends ModalProps {
  patientId: string;
  initialValues?: BgGoal;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const A1CCustomizeGoalModalComponent = ({
  patientId,
  initialValues,
  title = "Customize this patient's A1c goal",
  open,
  onSubmit,
  onCancel,
  ...props
}: A1CCustomizeGoalModalComponentProps) => {
  const handleSubmit = () => {
    onSubmit?.();
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <A1CCustomizeGoalModalContainer
        patientId={patientId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
};
