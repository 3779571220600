import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { SelectType } from '../../../Input/types';
import { MedicationInstructionEnumComponent } from '../../../../enumComponent/MedicationInstructionEnumComponent/MedicationInstructionEnumComponent';
import { MedicationInstructionEnum } from '../../../../uc-api-sdk';

export interface MedicationInstructionSelectComponentProps
  extends SelectType<MedicationInstructionEnum> {}

export const MedicationInstructionSelectComponent = ({
  value,
  onChange,
  allowClear = true
}: MedicationInstructionSelectComponentProps) => (
  <EnumSelectComponent
    value={value}
    onChange={onChange}
    options={MedicationInstructionEnum}
    optionRenderer={MedicationInstructionEnumComponent}
    allowClear={allowClear}
  />
);
