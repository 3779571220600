import { map } from 'lodash';
import { MED_BG_ICON, MED_BP_ICON } from '../../../assets/commonIconInfo';
import { Icon } from '../../../icons/Icon';

export interface MedicationTypeIconComponentProps {
  types: string[];
}

export const MedicationTypeIconComponent = ({
  types,
}: MedicationTypeIconComponentProps) => {
  const getIcon = (type: string) => {
    switch (type) {
      case 'BP':
        return (
          <Icon
            key={type}
            info={MED_BP_ICON}
          />
        );
      case 'BG':
        return (
          <Icon
            key={type}
            info={MED_BG_ICON}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex ai-c jc-c medication-types">
      {
        map(types, getIcon)
      }
    </div>
  );
};
