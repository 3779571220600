import { Button, Card } from 'antd';
import dayjs from 'dayjs';
import { useBoolean } from 'usehooks-ts';
import { ReactNode, useState } from 'react';
import { Title } from '../../../../uiComponent/Title/Title';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { CalendarDrawerComponent } from '../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import './OnboardingCardComponent.scss';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { StartNowVisitButtonContainer } from '../../../visit/container/StartNowVisitButtonContainer/StartNowVisitButtonContainer';
import { WorklistDrawerComponent } from '../../../worklist/component/WorklistDrawerComponent/WorklistDrawerComponent';
import { StartNowCreateVisitFormContainerProps } from '../../../visit/container/StartNowCreateVisitFormContainer/StartNowCreateVisitFormContainer';
import { OnboardingVisitStatusComponent } from '../OnboardingVisitStatusComponent/OnboardingVisitStatusComponent';
import { useCreateVisitForm } from '../../../visit/hook/useCreateVisitForm';

export interface OnboardingCardComponentProps {
  title: string;
  description: ReactNode;
  suggestedTime?: number;
  patientInfo: PatientInfo;
  clinicEvent: ClinicEventsService;
  visitType: VisitTypeEnum;
  onSchedule?: () => void;
  onReschedule?: () => void;
  onCloseCalendar?: () => void;
  showButtons?: boolean;
  mixpanelEvent: MixpanelEvents;
  onSubmitStartNow?: (clinicEventId: string) => void;
}

export const OnboardingCardComponent = ({
  title,
  description,
  suggestedTime,
  patientInfo,
  clinicEvent,
  visitType,
  onSchedule,
  onReschedule,
  onCloseCalendar,
  showButtons = true,
  mixpanelEvent,
  onSubmitStartNow,
}: OnboardingCardComponentProps) => {
  const { getVisitDuration } = useCreateVisitForm();
  const { send: sendMixpanel } = useMixpanelContext();
  const {
    id,
    startTime,
    calendarEventId,
    visitParticipantsId,
  } = clinicEvent;
  const {
    value: isReschedule,
    setTrue: startReschedule,
    setFalse: cancelReschedule,
  } = useBoolean();
  const {
    isOpen: isCalendarOpen,
    open: openCalendar,
    close: closeCalendar,
  } = useOpen();
  const {
    isOpen: isWorklistOpen,
    open: openWorklist,
    close: closeWorklist,
  } = useOpen();
  const [
    worklistClinicEventId,
    setWorklistClinicEventId,
  ] = useState<string | undefined>();
  const suggestedVisitTime = suggestedTime || getVisitDuration(visitType);

  useUpdateListener('GO_TO_CHECK_IN', closeCalendar);

  const handleOnReschedule = () => {
    startReschedule();
    openCalendar();
    onReschedule?.();
    sendMixpanel({ event: mixpanelEvent });
  };

  const handleOnSchedule = () => {
    cancelReschedule();
    openCalendar();
    onSchedule?.();
    sendMixpanel({ event: mixpanelEvent });
  };

  const handleCloseCalendar = () => {
    cancelReschedule();
    closeCalendar();
    patientInfo.refetch(['enrollmentInfoRefetch']);
    onCloseCalendar?.();
  };

  const handleSubmitStartNow: StartNowCreateVisitFormContainerProps['onCheckedIn'] = (
    clinicEventId: string,
  ) => {
    if (clinicEventId) {
      onSubmitStartNow?.(clinicEventId);
      setWorklistClinicEventId(clinicEventId);
      openWorklist();
    }
  };

  const renderButtons = () => (
    <div>
      {
        id
          ? (
            <OnboardingVisitStatusComponent
              clinicEvent={clinicEvent}
              onEdit={handleOnReschedule}
            />
          ) : (
            <div className="flex jc-cb">
              <StartNowVisitButtonContainer
                patientId={patientInfo.id}
                visitType={visitType}
                startNowOverlapInterval={suggestedVisitTime}
                onCheckedIn={handleSubmitStartNow}
              />
              <Button
                type="link"
                onClick={handleOnSchedule}
                className="primary-link"
              >
                Make a schedule
              </Button>
            </div>
          )
      }
    </div>
  );

  return (
    <>
      <Card className="onboarding-card-component" hoverable>
        <div className="flex jc-sb gap5">
          <div className="w100">
            <div className="flex jc-sb ai-c">
              <Title>{title}</Title>
              <div className="flex fd-c ai-e">
                {showButtons && renderButtons()}
              </div>
            </div>
            <Title.SubTitle>
              Suggested time:
              {' '}
              {suggestedVisitTime}
              {' '}
              mins
            </Title.SubTitle>
            <Title.SubTitle>
              {description}
            </Title.SubTitle>
          </div>

        </div>
      </Card>
      <WorklistDrawerComponent
        patientId={patientInfo.id}
        isOpen={isWorklistOpen}
        onClose={closeWorklist}
        clinicEventId={worklistClinicEventId || ''}
      />
      <CalendarDrawerComponent
        isOpen={isCalendarOpen}
        onClose={handleCloseCalendar}
        visitInitialValue={!isReschedule ? {
          dateTime: dayjs(),
          patientId: patientInfo.id,
          visitType,
          assignees: patientInfo?.assigneesService?.getAssignees() ?? [],
        } : undefined}
        calendarInitialValue={isReschedule ? {
          date: startTime.toISOString(),
          eventId: calendarEventId,
          participants: visitParticipantsId,
        } : undefined}
      />
    </>
  );
};
