import { MedicationReminderDeclineReasonEnum } from '../../uc-api-sdk';

export const getDeclineReasonText = (value?: MedicationReminderDeclineReasonEnum | null) => {
  switch (value) {
    case MedicationReminderDeclineReasonEnum.FEELS_REMINDERS_ARE_UNNECESSARY:
      return 'Feels reminders are unnecessary';
    case MedicationReminderDeclineReasonEnum.FINDS_REMINDERS_DISTURBING_OR_INCONVENIENT:
      return 'Finds reminders disturbing or inconvenient';
    case MedicationReminderDeclineReasonEnum.PREFERS_MANUAL_TRACKING_OR_SELF_MANAGEMENT:
      return 'Prefers manual tracking or self-management';
    case MedicationReminderDeclineReasonEnum.ALREADY_HAS_OTHER_REMINDER_SYSTEM_IN_PLACE:
      return 'Already has other reminder systems in place';
    case MedicationReminderDeclineReasonEnum
      .PATIENT_DOES_NOT_HAVE_ANY_PRESCRIBED_MEDICATIONS_NO_NEED:
      return 'Patient does not have any prescribed medications so don`\'t need reminder';
    default:
      return null;
  }
};

export interface MedicationReminderDeclineReasonEnumProps {
  value: MedicationReminderDeclineReasonEnum | null;
}

export const MedicationReminderDeclineReasonEnumComponent = ({
  value,
}: MedicationReminderDeclineReasonEnumProps) => (<span>{getDeclineReasonText(value)}</span>);
