import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TimeSelectComponent } from '../../../visit/component/CreateVisitFormComponent/TimeSelectComponent';
import { useMedicationReminderForm } from '../../hook/useMedicationFormReminder';

export interface MedicationReminderTimeSelectFormItemComponentProps {
  label?: string;
  fieldName?: number;
  onChange?: (value?: number) => void;
  disabled?: boolean;
}

export const MedicationReminderTimeSelectFormItemComponent = ({
  label = 'Reminder',
  fieldName,
  onChange,
  disabled,
}: MedicationReminderTimeSelectFormItemComponentProps) => {
  const medicationReminderForm = useMedicationReminderForm();
  return (
    <FormItem
      className="mb0"
      name={medicationReminderForm.makeListNamePath('timeSelect', fieldName)}
      label={label}
      required
    >
      <TimeSelectComponent
        minValue={0}
        maxValue={24}
        onChange={onChange}
        disabled={disabled}
      />
    </FormItem>
  );
};
