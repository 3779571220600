import { AppFunctions } from '../uc-api-sdk';

export class AppFunctionsService {
  private appFunctions?: AppFunctions;

  constructor(appFunctions?: AppFunctions) {
    this.appFunctions = appFunctions;
  }

  public getAppFunctionsInfo(): AppFunctions {
    return this.appFunctions ?? {};
  }
}
