import { MedicationComplianceEnum } from '../../uc-api-sdk';

export const getMedicationReviewText = (value?: MedicationComplianceEnum | null) => {
  switch (value) {
    case MedicationComplianceEnum.MEDICATION_REVIEW_COMPLETED:
      return 'Medication review completed';
    case MedicationComplianceEnum.MEDICATION_REVIEW_NOT_COMPLETED:
      return 'Medication review not completed';
    case MedicationComplianceEnum.NO_MEDICATION_PRESCRIBED:
      return 'No medication prescribed';
    default:
      return null;
  }
};

export interface MedicationListReviewEnumComponentProps {
  value?: MedicationComplianceEnum | null;
}

export const MedicationListReviewEnumComponent = ({
  value,
}: MedicationListReviewEnumComponentProps) => (<span>{getMedicationReviewText(value)}</span>);
