import { map } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { IN_CLINIC_VISIT_ICON } from '../../../../assets/commonIconInfo';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { Icon } from '../../../../icons/Icon';
import { Height, VitalEnumType } from '../../../../uc-api-sdk';
import { BMIComponent } from '../../../../uiComponent/BMIComponent/BMIComponent';
import { HSMeasurementService } from '../../helper/HSMeasurementService';
import { LogbookCellComponent } from '../LogbookCellComponent/LogbookCellComponent';
import { LogbookComponentProps } from '../LogbookComponent/LogbookComponent';
import { LogbookModalComponent } from '../LogbookComponent/LogbookModalComponent';
import { LogbookInfoComponent } from '../LogbookInfoComponent/LogbookInfoComponent';
import { LogbookSingleInfoComponent } from '../LogbookInfoComponent/LogbookSingleInfoComponent';
import { MeasurementTagsIconsComponent } from '../MeasurementTagsIconsComponent/MeasurementTagsIconsComponent';
import './HSTableCellComponent.scss';
import { HSTextComponent } from './HSTextComponent';

export interface HSTableCellComponentProps {
  data: HSMeasurementService[];
  height?: Height;
}

export const HSTableCellComponent: FC<HSTableCellComponentProps> = ({
  data,
  height
}) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean();

  const children = useMemo(() => map(data, (v, i) => (
    <LogbookCellComponent
      className={v.isInClinic ? 'inClinic' : ''}
      key={i}
      text={(
        <HSTextComponent
          value={v.getCellValue.weightInLbs}
          inClinic={v.isInClinic}
        />
      )}
      color={v.getColor}
      hasNote={!!v.userNote}
      topRightIcon={
        v.isInClinic && (
          <Icon src={IN_CLINIC_VISIT_ICON.src} alt={IN_CLINIC_VISIT_ICON.alt} size={16} hexColor="#fff" />
        )
      }
    />
  )), [data]);

  const renderTab = useCallback<LogbookComponentProps<HSMeasurementService>['renderTab']>((dataValue, isSelected, onSelect) => (
    <LogbookCellComponent
      color={dataValue.getColor}
      text={(
        <HSTextComponent
          value={dataValue.getCellValue.weightInLbs}
          inClinic={dataValue.isInClinic}
        />
      )}
      hasNote={!!dataValue.userNote}
      onClick={onSelect}
      reverseColor={!isSelected}
      topRightIcon={
        dataValue.isInClinic && (
          <Icon src={IN_CLINIC_VISIT_ICON.src} alt={IN_CLINIC_VISIT_ICON.alt} size={16} hexColor="#fff" />
        )
      }
      bottomLeftIcon={(
        <MeasurementTagsIconsComponent
          hadMedicine={dataValue.hadMedicine}
          color="#FFF"
        />
      )}
    />
  ), []);

  const renderLogbookInfo = useCallback<LogbookComponentProps<HSMeasurementService>['renderLogbookInfo']>((dataValue) => (
    <>
      <div className="text-gray-scale-2 mb20">{dataValue.isInClinic ? '(Care Team Entries & EHR)' : '(Patient-uploaded Measurement)'}</div>
      <BMIComponent
        weight={dataValue.weight}
        height={height}
        label={<strong className="text-gray">BMI:</strong>}
        showIndication={false}
      />
      <LogbookInfoComponent
        vitalType={VitalEnumType.HS}
        patientNote=""
        hadActivity={false}
        hadInsulin={false}
        hadMedicine={false}
        moodTags={[]}
      >
        <LogbookSingleInfoComponent
          title="Patient Notes (Measurement)"
          content={dataValue.userNote}
          emptyMessage="No Comment"
        />
      </LogbookInfoComponent>
    </>
  ), []);

  const renderTitle = useCallback<LogbookComponentProps<HSMeasurementService>['renderTitle']>((dataValue) => (
    <>
      {TimezoneService.getTimeOfDayText(dataValue.timeOfDay)}
    </>
  ), []);

  return (
    <div className="HSTableCellComponent">
      <LogbookModalComponent<HSMeasurementService>
        open={open}
        onCancel={closeModal}
        logbookProps={{
          data,
          renderLogbookInfo,
          renderTitle,
          renderTab,
        }}
      />
      <LogbookCellComponent.Group onClick={openModal}>
        {children}
      </LogbookCellComponent.Group>
    </div>
  );
};
