import { Timeline } from 'antd';
import { map, orderBy } from 'lodash';
import {
  HistoryInfo,
  HistoryInfo1,
  HistoryInfo2,
  HistoryItemComponent,
} from './HistoryItemComponent';
import './HistoryListComponent.scss';

export interface HistoryListComponentProps<T extends HistoryInfo> {
  notes: T[] | null | undefined;
}

export const HistoryListComponent = <T extends HistoryInfo>({
  notes,
}: HistoryListComponentProps<T>) => {
  const getDate = (v: T) => (v as HistoryInfo1).createdAt || (v as HistoryInfo2).date;

  return (
    <Timeline className="history-list-component">
      {map(orderBy(notes, getDate, 'desc'), (note, index) => (
        <HistoryItemComponent
          key={index}
          note={note}
          isLatest={index === 0}
        />
      ))}
    </Timeline>
  );
};
