/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum LanguageType {
  English = 'EL',
  Cantonese = 'CT',
  Mandarin = 'MN',
  Spanish = 'SP',
  Vietnamese = 'VN',
  Tagalog = 'TA',
  Taishanese = 'YUZ',
  Amharic = 'AH',
  Akan = 'AK',
  Albanian = 'AL',
  Armenian = 'AM',
  Annamese = 'AN',
  Arabic = 'AR',
  Assamese = 'AS',
  Azerbaijani = 'AZ',
  Batak = 'BA',
  Bulgarian = 'BB',
  Bangla = 'BD',
  Bugis = 'BG',
  Bengali = 'BI',
  Banjarese = 'BJ',
  Bauan = 'BN',
  Bhutanese = 'BT',
  Burmese = 'BU',
  Boyanese = 'BY',
  Croatian = 'CA',
  Chekiang = 'CK',
  Chaldean = 'CL',
  Cambodian = 'CM',
  Creole = 'CR',
  Chawan = 'CW',
  Czech = 'CZ',
  Danish = 'DA',
  Dusun = 'DS',
  Dutch = 'DU',
  Dayak = 'DY',
  Farsi = 'FA',
  Finnish = 'FI',
  Flemish = 'FL',
  Foochow = 'FO',
  French = 'FR',
  Gujarati = 'GE',
  Ghanaian = 'GH',
  German = 'GM',
  Goanese = 'GO',
  Greek = 'GR',
  Gurkhali = 'GU',
  Hunan = 'HA',
  Hebrew = 'HB',
  Hakka = 'HC',
  Hindi = 'HD',
  Hubei = 'HE',
  Henghua = 'HH',
  Hockchia = 'HI',
  Hokkien = 'HK',
  Hainanese = 'HN',
  Hindustani = 'HT',
  Hungarian = 'HU',
  Iban = 'IB',
  Icelandic = 'IC',
  Indonesian = 'ID',
  Ilongo = 'IG',
  Ilocano = 'IH',
  Irish = 'IR',
  Italian = 'IT',
  Javanese = 'JA',
  Jaffnese = 'JF',
  Japanese = 'JP',
  Kannada = 'KA',
  Khasi = 'KB',
  Kikuyu = 'KE',
  Khmer = 'KH',
  Kiangsi = 'KI',
  Khek = 'KK',
  Kelabit = 'KL',
  Kashmiri = 'KM',
  Karen = 'KN',
  Konkani = 'KO',
  Korean = 'KR',
  Kwongsai = 'KS',
  Kayan = 'KY',
  Kadazan = 'KZ',
  Lao = 'LA',
  Lithuanian = 'LH',
  Luichew = 'LU',
  Maltese = 'MA',
  Malabari = 'MB',
  Myanmar = 'MC',
  Mandingo = 'MD',
  Minangkabau = 'ME',
  Montenegrin = 'MF',
  Malagasy = 'MG',
  Manchu = 'MH',
  Mongolian = 'MI',
  Maldivian = 'ML',
  Malayalam = 'MM',
  Maori = 'MO',
  Marathi = 'MR',
  Multani = 'MT',
  Malay = 'MY',
  Melanau = 'MZ',
  Nepali = 'NE',
  Norwegian = 'NO',
  Nepalese = 'NP',
  Newari = 'NW',
  Oriya = 'OR',
  Persian = 'PE',
  Filipino = 'PH',
  Pekinese = 'PI',
  Punjabi = 'PJ',
  Polish = 'PL',
  Pathan = 'PN',
  Portuguese = 'PO',
  Pampangan = 'PP',
  Pushtu = 'PU',
  Rakhine = 'RK',
  Romanian = 'RO',
  Russian = 'RU',
  Shan = 'SA',
  Scottish = 'SB',
  Szechuan = 'SC',
  Sindhi = 'SD',
  Serbian = 'SE',
  SwissGerman = 'SG',
  Shanghainese = 'SH',
  Sinhalese = 'SI',
  Slovak = 'SL',
  Shansi = 'SN',
  Sundanese = 'SS',
  Shantung = 'ST',
  Slavic = 'SV',
  Swedish = 'SW',
  Teochew = 'TC',
  Telugu = 'TE',
  Thai = 'TH',
  Tibetan = 'TI',
  Tamil = 'TM',
  Tongan = 'TO',
  Turkish = 'TU',
  Taiwanese = 'TW',
  Unknown = 'UN',
  Urdu = 'UR',
  Visayan = 'VS',
  Wenchow = 'WC',
  Welsh = 'WE',
  Others = 'XX',
  Yiddish = 'YI',
}
