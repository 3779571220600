import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';
import { MedicationStatusEnum } from '../../uc-api-sdk';

export interface MedicationStatusEnumComponentProps {
  value?: MedicationStatusEnum | '';
}

export const MedicationStatusEnumComponent = ({
  value,
}: MedicationStatusEnumComponentProps) => {
  const medStatus = value === MedicationStatusEnum.NOT_SURE
    ? 'Uncertain'
    : value;
  return (
    <BasicEnumComponent
      value={medStatus}
    />
  );
};
