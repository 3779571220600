import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { createPatientColumns } from '../../../patient/component/PatientsTableComponent/columns';
import { PatientsTableComponent } from '../../../patient/component/PatientsTableComponent/PatientsTableComponent';
import { PatientTableProps } from '../../type';

export interface WatchedPatientsTableComponentProps extends PatientTableProps {
}

export const WatchedPatientsTableComponent = ({
  totalSize,
  filters,
  sorters,
  ...props
}: WatchedPatientsTableComponentProps) => {
  const columns = useTableColumns(
    createPatientColumns,
    ['name', 'age', 'gender', 'clinic', 'provider', 'controlLevel', 'addedTime', 'note', 'action'],
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters,
  });

  return (
    <PatientsTableComponent
      totalSize={totalSize}
      columns={columnsWithFilters}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
