import { Table } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { map } from 'lodash';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { createAlertSummaryColumns } from '../../table/createAlertSummaryColumns';
import { useMedicalAlertGetPatientAlertSummary } from '../../../../uc-api-sdk';
import { LastDaysRangeSelectComponent } from '../../../../uiComponent/LastDaysRangeSelectComponent/LastDaysRangeSelectComponent';

export interface MedicalAlertSummaryTableContainerProps {
  patientId: string | undefined;
}

const defaultRange = 7; // days

export const MedicalAlertSummaryTableContainer = ({
  patientId,
}: MedicalAlertSummaryTableContainerProps) => {
  const [
    range,
    setRange
  ] = useState<number | undefined>(defaultRange);
  const alertSummaryInfo = useMedicalAlertGetPatientAlertSummary({
    params: {
      memberId: patientId || '',
      triggeredOnTsEnd: dayjs().endOf('day').valueOf(),
      triggeredOnTsStart: (
        typeof range === 'number'
          ? dayjs().subtract(range, 'days').startOf('day').valueOf()
          : 1 // represent no value
      ),
    },
    options: {
      sendOnMount: !!patientId,
    }
  });

  const availableAlertDisplayNames = useMemo(() => (
    map(alertSummaryInfo?.data?.data, 'alertDisplayName')
      .filter(v => !!v) as string[]
  ), [alertSummaryInfo?.data?.data]);

  const columns = useTableColumns(
    createAlertSummaryColumns({
      availableAlertDisplayNames,
    }),
    ['alertDisplayName', 'eventType', 'priority', 'alertCategory', 'totalCount', 'lastTriggeredOnTs'],
    [availableAlertDisplayNames]
  );

  return (
    <>
      <div className="flex ai-c">
        <TextComponent>
          Medical Alert Trigger Summary of
        </TextComponent>
        <LastDaysRangeSelectComponent
          defaultValue={7}
          onChange={setRange}
          className="ant-select-underline-selection text-gray-scale-2"
          dropdownMatchSelectWidth={false}
        />
      </div>
      <Table
        columns={columns}
        dataSource={alertSummaryInfo.data?.data || []}
        loading={alertSummaryInfo.isLoading}
        pagination={false}
        size="middle"
        scroll={{ y: 300, x: 'max-content' }}
      />
    </>
  );
};
