import { Card } from 'antd';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { CardTitleComponent } from '../../../../uiComponent/CardTypographyComponent/CardTitleComponent/CardTitleComponent';
import { LastUpdateComponent } from '../../../../uiComponent/LastUpdateComponent/LastUpdateComponent';

export interface TranscribeVisitCardComponentProps {
  title?: string;
  children: ReactNode;
  updatedAt?: string | null;
  cardBordered?: boolean;
  cardClassName?: string;
}

export const TranscribeVisitCardComponent = ({
  title,
  updatedAt,
  children,
  cardClassName = '',
  cardBordered = true
}: TranscribeVisitCardComponentProps) => (
  <div className="flex fd-c ai-e mt20 mb40">
    {
      updatedAt
      && (
        <LastUpdateComponent
          updatedAt={updatedAt}
          attentionDayThreshold={[-30, 0]}
        />
      )
    }
    <Card
      className={classNames({
        'w100 py12': true,
        [cardClassName]: !!cardClassName,
      })}
      bordered={cardBordered}
    >
      {
        title
        && (
          <CardTitleComponent size="default">
            <span>
              {title}
            </span>
          </CardTitleComponent>
        )
      }
      {children}
    </Card>
  </div>
);
