import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Insurance } from '../../features/Insurance/hook/insuranceApis';
import { ConsentService } from '../../services/ConsentService';
import { ControlLevelService } from '../../services/ControlLevelService';
import { EnrolledProgramService } from '../../services/EnrolledProgramService';
import { EnrollmentService } from '../../services/EnrollmentService';
import { FollowUpVisitWindowService } from '../../services/FollowUpVisitService';
import { MedicationManagementService } from '../../services/MedicationManagementService';
import { PatientAssigneesService } from '../../services/PatientAssigneesService';
import { PatientBillableTimeService } from '../../services/PatientBillableTimeService';
import { PatientDeviceService } from '../../services/PatientDeviceService';
import { PatientOutstandingService } from '../../services/PatientOutstandingService';
import { PatientService } from '../../services/PatientService';
import {
  EnrollmentRequest,
  HealthCondition,
  LastMeasurementResponse,
  Patient,
  PatientAssignees,
  PatientComplexity,
  PatientConsent,
  PatientEnrolledProgram,
  PhoneReportInfo,
  UcMedicationManagement
} from '../../uc-api-sdk';
import { AppFunctionsService } from '../../services/AppFunctionsService';

export interface PatientInfoRefetchObj {
  patientRefetch: () => Promise<unknown>;
  patientComplexityRefetch: () => Promise<unknown>;
  enrolledProgramRefetch: () => Promise<unknown>;
  patientInsuranceRefetch: () => Promise<unknown>;
  assigneesRefetch: () => Promise<unknown>;
  followUpVisitWindowRefetch: () => Promise<unknown>;
  enrollmentInfoRefetch: () => Promise<unknown>;
  healthConditionsRefetch: () => Promise<unknown>;
  consentRefetch: () => Promise<unknown>;
  medicationManagementRefetch: () => Promise<unknown>;
  hiatusStatusRefetch: () => Promise<unknown>;
  devicesRefetch: () => Promise<unknown>;
  billableTimeRefetch: () => Promise<unknown>;
  outstandingItemCountRefetch: () => Promise<unknown>;
  appVersionRefetch: () => Promise<unknown>;
  lastMeasurementDateRefetch: () => Promise<unknown>;
  controlLevelRefetch: () => Promise<unknown>;
  bpBaselineRefetch: () => Promise<unknown>;
  appFunctionsRefetch: () => Promise<unknown>;
}

export interface PatientInfo {
  id: string;
  patientInfo?: Patient | null;
  patientComplexity?: PatientComplexity | null;
  patientInsurance?: Insurance | null;
  patientHealthCondition?: HealthCondition | null;
  patientMedicationManagement?: UcMedicationManagement | null;
  patientInfoService?: PatientService;
  enrolledProgram?: PatientEnrolledProgram | null;
  enrolledProgramService: EnrolledProgramService;
  assignees?: PatientAssignees | null;
  assigneesService?: PatientAssigneesService;
  followUpVisitWindowService?: FollowUpVisitWindowService;
  enrollmentInfo?: EnrollmentRequest | null;
  enrollmentService: EnrollmentService;
  consentInfo?: PatientConsent | null;
  consentService: ConsentService;
  medicationManagementService?: MedicationManagementService;
  devicesService?: PatientDeviceService;
  billableTimeService?: PatientBillableTimeService;
  outstandingService?: PatientOutstandingService;
  patientAppVersion?: PhoneReportInfo;
  lastMeasurementDate?: LastMeasurementResponse[];
  controlLevelService?: ControlLevelService;
  appFunctionsService?: AppFunctionsService;

  refetch: (
    keys?: (keyof PatientInfoRefetchObj)[],
    excludedKeys?: (keyof PatientInfoRefetchObj)[], // refetch all except the excluded keys
  ) => void;
  isLoadingObj: {
    patientInfoLoading: boolean;
    patientComplexityLoading: boolean;
    patientInsuranceLoading: boolean;
    enrolledProgramLoading: boolean;
    assigneesLoading: boolean;
    followUpVisitWindowLoading: boolean;
    insuranceLoading: boolean;
    enrollmentInfoLoading: boolean;
    healthConditionsLoading: boolean;
    consentLoading: boolean;
    medicationManagementLoading: boolean;
    controlLevelLoading: boolean;
    bpBaselineLoading: boolean;
    appFunctionsLoading: boolean;
  }
  isLoading?: boolean;
}

interface Value {
  info?: PatientInfo;
  setPatient: (info: PatientInfo) => void;
}

export const PatientContext = React.createContext<Value>({
  setPatient: () => {
    console.error('Patient context does not exist yet!');
  },
});

export interface PatientProviderProps {
  info?: PatientInfo;
  children: React.ReactNode;
}

export const PatientProvider: FC<PatientProviderProps> = ({
  info,
  children,
}) => {
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>(info);

  useEffect(() => {
    setPatientInfo(info);
  }, [info, setPatientInfo]);

  const contextValue = useMemo<Value>(() => ({
    info: patientInfo ? {
      ...patientInfo,
      patientInfoService: patientInfo?.patientInfo
        ? new PatientService({
          patientInfo: patientInfo.patientInfo,
          insurance: patientInfo.patientInsurance,
          patientDiagnoses: patientInfo.patientInfo.diagnoses,
          healthCondition: patientInfo.patientHealthCondition,
          complexity: patientInfo.patientComplexity,
        })
        : undefined,
      assigneesService: patientInfo?.assignees
        ? new PatientAssigneesService(patientInfo.assignees)
        : undefined,
    } : undefined,
    setPatient: setPatientInfo,
  }), [
    patientInfo,
    setPatientInfo,
  ]);

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatientContext = () => {
  const patientContextValue = useContext<Value>(PatientContext);
  return patientContextValue;
};
