import { Button, Form, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { MpTrackingHelperProps } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { ComplianceAlert } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { OverlayComponent } from '../../../../uiComponent/overlayComponent/OverlayComponent';
import { useAlertForm } from '../../hook/useAlertForm';
import { EmptyAlertComponent } from '../EmptyAlertComponent/EmptyAlertComponent';
import { AlertCheckboxGroupComponent } from './AlertCheckboxGroupComponent';
import { AlertSnoozeButtonComponent } from './AlertSnoozeButtonComponent';
import { AlertSnoozeListComponent } from './AlertSnoozeListComponent';

export interface AlertFormSubmitValue {
  comment: string;
  isCommentOnly: true;
  alerts: ComplianceAlert[];
}

export interface AlertFormComponentProps {
  data?: ComplianceAlert[];
  snoozed?: ComplianceAlert[];
  onSubmit?: (value: AlertFormSubmitValue) => void;
  type: 'compliance' | 'medical';
  complianceLoading?: boolean;
  onComplianceAlertStart?: () => void;
  onComplianceAlertEnd?: (value?: MpTrackingHelperProps['properties']) => void;
}

export const AlertFormComponent = ({
  data,
  snoozed,
  onSubmit,
  type,
  complianceLoading,
  onComplianceAlertStart,
  onComplianceAlertEnd
}: AlertFormComponentProps) => {
  const hook = useAlertForm();
  const { value: isLoading, setTrue, setFalse } = useBoolean();
  const [shouldUnselectedAll, setShouldUnselectedAll] = useState(false);

  useEffect(() => {
    onComplianceAlertStart?.();
  }, []);

  const handleSubmit = async (v: AlertFormSubmitValue) => {
    try {
      const submit = hook.handleSubmitAndReset(onSubmit);
      setTrue();
      await submit?.(v);
      setFalse();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  // reset form, make selected alerts 0, to hide the comment
  useEffect(() => {
    if (data && hook.form.getFieldValue('alerts') && (hook.form.getFieldValue('alerts') !== data)) {
      hook.handleReset();
      setShouldUnselectedAll(true);
    }
  }, [data]);

  return (
    <OverlayComponent
      overlay={<Spin size="large" />}
      showOverlay={complianceLoading}
    >
      <Form
        form={hook.form}
        onFinish={handleSubmit}
        className="h100"
      >
        <FixedComponent>
          <FixedComponent.Child>
            <FormItem info={hook.getInfo('alerts')}>
              {
              data && data.length !== 0
                ? (
                  <AlertCheckboxGroupComponent
                    data={data}
                    shouldUnselectedAll={shouldUnselectedAll}
                  />
                )
                : (snoozed && snoozed.length === 0 && (<EmptyAlertComponent type="compliance" />))
            }
            </FormItem>
            {type === 'compliance' && snoozed && snoozed.length > 0 && (<AlertSnoozeListComponent data={snoozed} />)}
          </FixedComponent.Child>
          <FixedComponent.Child isFixed>
            <FormItem
              noStyle
              shouldUpdate={hook.shouldUpdate(['alerts'])}
            >
              {({ getFieldValue }) => {
                const v = getFieldValue('alerts');
                return v && v.length > 0 && (
                <>
                  <FormItem
                    shouldUpdate={hook.shouldUpdate(['isCommentOnly'])}
                  >
                    {({ getFieldValue }) => (
                      <FormItem
                        info={hook.getInfo('comment')}
                        rules={hook.getRequiredRules('comment')}
                        required
                      >
                        <TextArea placeholder={hook.getPlaceholderText(getFieldValue)} rows={3} />
                      </FormItem>
                    )}
                  </FormItem>
                  <div className="flex jc-sb mt20">
                    <FormItem valuePropName="checked" info={hook.getInfo('isCommentOnly')}>
                      <Checkbox>
                        Add a comment only
                      </Checkbox>
                    </FormItem>
                    <div className="flex jc-sb">
                      {type === 'compliance' && (
                      <AlertSnoozeButtonComponent
                        type="default"
                        title="Snooze"
                        data={v}
                        onComplianceAlertEnd={onComplianceAlertEnd}
                      />
                      )}
                      <FormItem
                        noStyle
                        shouldUpdate={hook.shouldUpdate(['isCommentOnly'])}
                      >
                        {({ getFieldValue }) => (
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            className="b5"
                          >
                            {hook.getSubmitButtonText(getFieldValue)}
                          </Button>
                        ) }
                      </FormItem>
                    </div>
                  </div>
                </>
                );
              } }
            </FormItem>
          </FixedComponent.Child>
        </FixedComponent>
      </Form>
    </OverlayComponent>
  );
};
