import { get, join, map } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { getGenderLongDescription } from '../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { getProgramCategoryEnumShortText } from '../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import { age } from '../../features/patient/component/PatientsTableComponent/PatientAgeComponent';
import { EnrolledProgramService } from '../../services/EnrolledProgramService';
import { PatientResult, ProgramCategoryEnum, VitalEnumType } from '../../uc-api-sdk';
import { AccountHelper } from '../account';
import { EmptyString } from '../../uiComponent/EmptyComponent/EmptyComponent';
import { getVitalEnumShortText } from '../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { billableStatisticBillable, billableStatisticDays, billableStatisticTime } from '../../features/billing/component/BillableStatisticComponent/BillableStatisticComponent';
import TimezoneService from '../timezone/timezoneService';
import { USA_DATE } from '../../constants/timeFormat';
import { ColumnType } from '../../hooks/table/useTableColumns/useTableColumns';
import { DataIndexBGDeviceLastUsed, DataIndexBPDeviceLastUsed } from '../../features/providerView/component/ProviderViewTablesComponent/columns';

const getPrograms = (patient?: PatientResult) => {
  if (patient?.enrolledProgram?.programs?.length) {
    const ep = new EnrolledProgramService(patient?.enrolledProgram);
    return join(map(ep.getPrograms(), (v: ProgramCategoryEnum) => getProgramCategoryEnumShortText(v)), ',');
  }
  return EmptyString;
};

const vital = (patient?: PatientResult) => {
  if (patient?.enrolledProgram?.vitals?.length) {
    const ep = new EnrolledProgramService(patient?.enrolledProgram ?? undefined);
    return join(map(ep.getVitals(), (v: VitalEnumType) => getVitalEnumShortText(v)), ',');
  }
  return EmptyString;
};

const getRPMDays = (
  patient?: PatientResult
) => billableStatisticDays(patient?.monthlyBillableStatistic ?? undefined);

const getBillableTime = (
  patient?: PatientResult
) => billableStatisticTime(patient?.monthlyBillableStatistic ?? undefined);

const getBillingEligibility = (
  patient?: PatientResult
) => (billableStatisticBillable(patient?.monthlyBillableStatistic ?? undefined) ? 'Yes' : 'No');

const getClinic = (isProvider: boolean, patient?: PatientResult) => {
  const n = patient?.clinic?.nickName || patient?.clinic?.alias;
  return (isProvider ? patient?.clinic?.businessName : n) ?? EmptyString;
};

const getProvider = (
  patient?: PatientResult
) => AccountHelper.getFullName(patient?.profile?.doctorUser) ?? EmptyString;

const getAssignedRDHC = (
  patient?: PatientResult
) => AccountHelper.getFullName(patient?.assignee?.assignedToRDUser) ?? EmptyString;

const getAssignedCA = (
  patient?: PatientResult
) => AccountHelper.getFullName(patient?.assignee?.assignedToCAUser) ?? EmptyString;

const getEnrollmentDate = (
  patient?: PatientResult
) => (patient?.enrolledProgram?.enrollmentDate
  ? TimezoneService.calcDateTimeDayjs(patient?.enrolledProgram?.enrollmentDate, undefined)
    .format(USA_DATE) : EmptyString);

const getAddedDate = (
  patient?: PatientResult
) => (patient?.watchInfo?.addedTime
  ? TimezoneService.calcDateTimeDayjs(patient?.watchInfo?.addedTime, undefined)
    .format(USA_DATE) : EmptyString);

const getRegistrationDate = (
  patient?: PatientResult
) => (patient?.createdAt
  ? TimezoneService.calcDateTimeDayjs(patient?.createdAt, undefined)
    .format(USA_DATE) : EmptyString);

const getDischargedDate = (
  patient?: PatientResult
) => (patient?.dischargedAt
  ? TimezoneService.calcDateTimeDayjs(patient?.dischargedAt, undefined)
    .format(USA_DATE) : EmptyString);

const getDeviceLastUsedDate = (
  key: string,
  patient?: PatientResult,
) => (
  get(patient, key)
    ? (
      TimezoneService.calcDateTimeDayjs(
        get(patient, key),
        patient?.clinic?.timezone ?? undefined
      ).format(USA_DATE)
    ) : EmptyString
);

const getStatus = (patient?: PatientResult) => {
  const pro = new EnrolledProgramService(patient?.enrolledProgram ?? undefined);
  return (
    pro.getPrograms().length > 0 ? 'Enrolled' : 'Unenrolled'
  );
};

const getTitles = (columns: ColumnsType<PatientResult> | undefined) => {
  const titles: React.ReactNode[] = map(columns, (v: ColumnType<PatientResult> | undefined) => {
    if (typeof v?.title === 'string') {
      return v?.title ?? '';
    }
    return get(get(v?.title, 'props'), 'children');
  });
  return titles;
};

const getContent = (
  patients: PatientResult[],
  column: ColumnsType<PatientResult> | undefined,
  isProvider: boolean
) => {
  const titles = getTitles(column);
  let csvString = `${join(titles, ',')}\n`; // CSV header

  patients.forEach(patient => {
    const columns: string[] = [];
    map(titles, (title: string) => {
      const each = () => {
        switch (title) {
          case 'Patient':
            return AccountHelper.getFullName(patient.profile) ?? EmptyString;
          case 'Age':
            return age(patient.profile?.birthday ?? undefined);
          case 'Sex at birth':
            return getGenderLongDescription(patient.profile?.gender ?? undefined);
          case 'Program':
            return getPrograms(patient);
          case 'Vitals':
            return vital(patient);
          case 'RPM Days':
            return getRPMDays(patient);
          case 'Billable time':
            return getBillableTime(patient);
          case 'Billing Eligibility':
            return getBillingEligibility(patient);
          case 'Clinic':
            return getClinic(isProvider, patient);
          case 'Provider':
            return getProvider(patient);
          case 'Assigned RD/HC':
            return getAssignedRDHC(patient);
          case 'Assigned CA':
            return getAssignedCA(patient);
          case 'Enrollment Date':
            return getEnrollmentDate(patient);
          case 'MRN':
            return patient.medicalRecordId ?? EmptyString;
          case 'Status':
            return getStatus(patient);
          case 'Added Time':
            return getAddedDate(patient);
          case 'Note':
            return patient.watchInfo?.watchNote ?? EmptyString;
          case 'Registration Date':
            return getRegistrationDate(patient);
          case 'Discharged Date':
            return getDischargedDate(patient);
          case 'Insurance Provider':
            return patient.profile?.insuranceProvider ?? EmptyString;
          case 'BP Device':
            return patient.bpDeviceInfo?.recentUsedDeviceMacId ?? EmptyString;
          case 'BP Last Used Date':
            return getDeviceLastUsedDate(DataIndexBPDeviceLastUsed, patient);
          case 'BG Device':
            return patient.bgDeviceInfo?.recentUsedDeviceMacId ?? EmptyString;
          case 'BG Last Used Date':
            return getDeviceLastUsedDate(DataIndexBGDeviceLastUsed, patient);
          default:
            return EmptyString;
        }
      };
      // Common Format and MIME Type for Comma-Separated Values (CSV) Files
      // Eg. to have values foo and bar,baz, you do foo,"bar,baz"
      columns.push(`"${each()}"` || EmptyString);
    });
    csvString += `${join(columns)}\n`;
  });
  return csvString;
};

export const convertToCSV = (
  patients: PatientResult[],
  column: ColumnsType<PatientResult> | undefined,
  isProvider: boolean,
) => getContent(patients, column, isProvider);
