import { WorklistDiseaseHistoryComponent } from '../WorklistDiseaseHistoryComponent/WorklistDiseaseHistoryComponent';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';

export interface SimplifiedWorklistDiseaseHistoryComponentProps {
  patientId: string;
  itemNumber?: number;
}

export const SimplifiedWorklistDiseaseHistoryComponent = ({
  patientId,
  itemNumber = -1
}: SimplifiedWorklistDiseaseHistoryComponentProps) => (
  <div className="simplified-worklist-section">
    <WorklistSectionHeaderComponent
      number={itemNumber}
      title="Disease History Review"
    />

    <WorklistDiseaseHistoryComponent
      patientId={patientId}
    />
  </div>
);
