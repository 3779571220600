import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem, { FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthPeriodPicker } from './PriorAuthPeriodPicker';

export interface PriorAuthAuthPeriodFormItemComponentProps extends FormItemProps {
  fieldName?: number;
}

export const PriorAuthAuthPeriodFormItemComponent = ({
  fieldName,
  ...formItemProps
}: PriorAuthAuthPeriodFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();

  return (
    <FormItem
      noStyle
      shouldUpdate={(
        insuranceForm.shouldUpdateFromList(['priorAuthCPTCodeCategory', 'priorAuthSubmissionDate'])
      )}
    >
      {
        ({ getFieldValue }) => {
          const cptCodeCategory = insuranceForm.getObjectValue('priorAuthCPTCodeCategory', getFieldValue, fieldName);
          const submissionDate = insuranceForm.getObjectValue('priorAuthSubmissionDate', getFieldValue, fieldName);
          return (
            <FormItem
              name={insuranceForm.makeListNamePath('priorAuthAuthPeriod', fieldName)}
              label={insuranceForm.getLabel('priorAuthAuthPeriod')}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemProps}
            >
              <PriorAuthPeriodPicker
                cptCodeCategory={cptCodeCategory}
                submissionDate={submissionDate}
              />
            </FormItem>
          );
        }
      }
    </FormItem>
  );
};
