import { Form } from 'antd';
import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { DiseaseHistorySubmitValue, useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { DiseaseHistoryFormListComponent } from '../DiseaseHistoryFormListComponent/DiseaseHistoryFormListComponent';
import { FormType } from '../../../Input/types';
import { LeaveNoteFormItemComponent } from '../../../../uiComponent/LeaveNoteFormItemComponent/LeaveNoteFormItemComponent';
import { useICDCodeRecommendationContext } from '../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';

export interface DiseaseHistoryFormComponentProps extends FormType<DiseaseHistorySubmitValue> {
  onSubmit?: (values: DiseaseHistorySubmitValue) => void;
  disabled?: boolean;
}

export const DiseaseHistoryFormComponent = ({
  initialValues,
  onSubmit,
  isLoading,
  disabled,
  onValuesChange,
  formButtons,
}: DiseaseHistoryFormComponentProps) => {
  const diseaseHistoryForm = useDiseaseHistoryForm();
  const {
    recommendedDiseaseHistory,
    processRecommendedDiseaseHistory,
  } = useICDCodeRecommendationContext(['recommendedDiseaseHistory']);

  const handleSubmit = (values: DiseaseHistorySubmitValue) => {
    onSubmit?.(values);
  };

  useEffect(() => {
    if (recommendedDiseaseHistory === undefined) {
      return;
    }
    const currentDiseaseHistory = (
      diseaseHistoryForm.form.getFieldValue('diseaseHistoryList')
      || initialValues?.diseaseHistoryList
    );
    const newDiseaseHistory = processRecommendedDiseaseHistory(
      currentDiseaseHistory,
      recommendedDiseaseHistory,
    );
    if (!isEqual(currentDiseaseHistory, newDiseaseHistory)) {
      diseaseHistoryForm.form.setFieldsValue({
        diseaseHistoryList: newDiseaseHistory,
      });
      onValuesChange?.({
        diseaseHistoryList: newDiseaseHistory
      }, {
        ...diseaseHistoryForm.form.getFieldsValue(true),
        diseaseHistoryList: newDiseaseHistory
      });
    }
  }, [recommendedDiseaseHistory]);

  return (
    <Form
      layout="vertical"
      form={diseaseHistoryForm.form}
      onFinish={diseaseHistoryForm.handleSubmit(handleSubmit)}
      disabled={diseaseHistoryForm.formDisabled || disabled || isLoading}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <DiseaseHistoryFormListComponent />
      <LeaveNoteFormItemComponent
        className="my10"
        name={diseaseHistoryForm.getName('diseaseHistoryNote')}
      />
      {formButtons}
    </Form>
  );
};
