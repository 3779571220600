import { useEffect, useState } from 'react';
import { MedicationNameSelectComponent, MedicationNameSelectComponentProps } from '../../../selectComponents/MedicationNameSelectComponent/MedicationNameSelectComponent';
import {
  useMedicationSearchDictionary,
  Sort$Direction,
  MedicationDictionary
} from '../../../uc-api-sdk';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { useScrollPagination } from '../../../hooks/useScrollPagination/useScrollPagination';

export interface MedicationNameContainerProps {
  value?: MedicationNameSelectComponentProps['value'];
  onChange?: MedicationNameSelectComponentProps['onChange'];
}
export const MedicationNameContainer = ({
  value,
  onChange,
}: MedicationNameContainerProps) => {
  const [
    searchValue,
    setSearchValue,
  ] = useState<string>(value?.displayName || '');
  const medicationList = useMedicationSearchDictionary({});
  const makeParams = (searchValue: string, page = 1) => ({
    request: {
      filter: searchValue,
      pageInfo: {
        page,
        size: 50,
        sort: [
          {
            direction: Sort$Direction.ASC,
            property: 'ingredientsTTY'
          },
          {
            direction: Sort$Direction.ASC,
            property: 'brandName',
          },
          {
            direction: Sort$Direction.ASC,
            property: 'displayName',
          }
        ],
        pagination: true,
      },
    },
  });

  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: medicationList,
    fetchParams: (page = 1) => makeParams(searchValue || '', page)
  });

  const handleSearch = useDebounce((searchValue: string) => {
    setSearchValue(searchValue);
    if (!searchValue) return;
    medicationList.send({
      params: makeParams(searchValue, 1),
    });
  });

  useEffect(() => {
    if (value?.displayName) {
      handleSearch(value.displayName);
      setSearchValue(value.displayName);
    }
  }, [value?.displayName]);

  return (
    <MedicationNameSelectComponent
      options={allData as MedicationDictionary[]}
      value={value}
      onSearch={handleSearch}
      onChange={onChange}
      onPopupScroll={handleOnScroll}
      loading={medicationList.isLoading}
    />
  );
};
