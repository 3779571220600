import { FormProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import {
  filter,
  isNil,
  reduce,
  uniq
} from 'lodash';
import moment, { Moment } from 'moment';
import { FieldData } from 'rc-field-form/es/interface';
import { useCallback, useMemo } from 'react';
import { PatientInfo } from '../../../contexts/PatientInfoContext/PatientInfoContext';
import { TimeService } from '../../../helpers/time/timeService';
import { ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { FollowUpVisitWindowService } from '../../../services/FollowUpVisitService';
import { GetFieldValue } from '../../../types/form';
import {
  CalendarSourceEnum,
  ClinicEvent,
  EnrolledProgramStatusEnum,
  VisitParticipant,
  VisitParticipantRoleEnum,
  VisitTypeEnum
} from '../../../uc-api-sdk';
import { SubmitValue } from '../component/CreateVisitFormComponent/CreateVisitFormComponent';
import { TimeSelectComponentProps } from '../component/CreateVisitFormComponent/TimeSelectComponent';
import { EventTypeRadioGroupComponentProps } from '../component/EventTypeRadioGroupComponent/EventTypeRadioGroupComponent';
import { VisitTypeSelectCalendarValue } from '../component/VisitTypeSelectComponent/VisitTypeSelectCalendarComponent';
import { ParticipantsEnum, ParticipantsFormEnum } from '../type/participants';
import { DEFAULT_VISIT_DURATION_MINUTES } from './useCreateVisitHelper';
import { useLog } from '../../../hooks/useLog/useLog';

export interface UseCreateVisitFormArg {
  onVisitDateChange?: (value: Moment) => void;
  onVisitTimeChange?: (start: Moment, end: Moment) => void;
  onParticipantsChange?: (participants: string[]) => void;
  editVisit?: ClinicEvent; // visit is selected for edit
}

export const useCreateVisitForm = ({
  onVisitDateChange,
  onVisitTimeChange,
  onParticipantsChange,
  editVisit,
}: UseCreateVisitFormArg = {}) => {
  const { log } = useLog('visit');
  const factory = useFormHookFactory({
    eventType: {
      name: 'eventType',
      label: 'Event Type',
    },
    eventTitle: {
      name: 'eventTitle',
      label: 'Event Title',
    },
    otherEventParticipants: {
      name: 'otherEventParticipants',
      label: 'Participants',
    },
    patient: {
      name: 'patient',
      label: 'Patient',
    },
    visitType: {
      name: 'visitType',
      label: 'Visit Type',
    },
    visitMethod: {
      name: 'visitMethod',
      label: 'Visit Method',
    },
    location: {
      name: 'location',
      label: 'location',
    },
    participants: {
      name: 'participants',
      label: 'Participants',
    },
    rdhc: {
      name: ParticipantsFormEnum.RdHc,
      label: '',
    },
    ca: {
      name: ParticipantsFormEnum.CA,
      label: '',
    },
    md: {
      name: ParticipantsFormEnum.MD,
      label: '',
    },
    ma: {
      name: ParticipantsFormEnum.MA,
      label: '',
    },
    visitDate: {
      name: 'visitDate',
      label: 'Visit Date',
    },
    startTime: {
      name: 'startTime',
      label: 'Start time',
    },
    endTime: {
      name: 'endTime',
      label: 'End time',
    },
    duration: {
      name: 'duration',
      label: '',
    },
    description: {
      name: 'description',
      label: 'Description',
    },
  });

  const shouldShowPatientInfoCard: ShouldShow = useCallback((getFieldValue) => (
    !isNil(getFieldValue(factory.getName('patient')))
  ), []);

  const shouldShowLocation: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('visitMethod')) === 'inClinic'
  ), []);

  const shouldShowFollowUpVisitWindow: ShouldShow = useCallback((getFieldValue) => {
    const visitDate = factory.getValue('visitDate', getFieldValue);
    const visitType = factory.getValue('visitType', getFieldValue)?.value;
    const patientId = factory.getValue('patient', getFieldValue);
    return (
      visitDate
      && patientId
      && visitType === VisitTypeEnum.POST_MD_FOLLOW_UP
    );
  }, []);

  const getStartTime = useCallback((getFieldValue: GetFieldValue) => {
    log('getStartTime');
    const visitDate: Moment = getFieldValue(factory.getName('visitDate'));
    const startTime = getFieldValue(factory.getName('startTime'));
    return visitDate && startTime
      ? dayjs(visitDate.toDate()).startOf('day').add(startTime, 'minutes')
      : undefined;
  }, []);

  const getVisitDuration = (visitType?: VisitTypeEnum, duration?: number) => {
    log('getVisitDuration', visitType, duration);
    switch (visitType) {
      case VisitTypeEnum.INITIAL: return 20;
      case VisitTypeEnum.TECH_ONBOARDING: return 20;
      case VisitTypeEnum.POST_MD_FOLLOW_UP: return 10;
      case VisitTypeEnum.ADDITIONAL: return duration || 20;
      case VisitTypeEnum.TECH: return 20;
      case VisitTypeEnum.FOLLOW_UP: return 30;
      case VisitTypeEnum.INIT: return 30;
      default: return duration || DEFAULT_VISIT_DURATION_MINUTES;
    }
  };

  const calcEndTimeBasedVisitType = useCallback((
    startTime: Dayjs | Moment,
    visitType?: VisitTypeEnum,
  ) => {
    const d = factory.getValue('duration', factory.form.getFieldValue);
    const duration = getVisitDuration(visitType, d);
    return moment(startTime.toDate()).add(duration, 'minute');
  }, []);

  const calcEndTime = (startTime: Dayjs, duration: number = DEFAULT_VISIT_DURATION_MINUTES) => {
    log('calcEndTime', startTime, duration);
    return startTime.clone().add(duration, 'minutes');
  };

  const getEndTime = useCallback((getFieldValue: GetFieldValue) => {
    log('getEndTime');
    const visitType = getFieldValue(factory.getName('visitType'))?.value;
    const startTime = getStartTime(getFieldValue);
    const endTimeInMinutes = getFieldValue(factory.getName('endTime'));
    const startTimeInMinutes = getFieldValue(factory.getName('startTime'));
    if (startTime && visitType === VisitTypeEnum.INIT) {
      return startTime.clone().startOf('day').add(endTimeInMinutes, 'minutes');
    }
    if (startTime && visitType === VisitTypeEnum.ADDITIONAL) {
      const d = factory.getValue('duration', getFieldValue);
      const duration = getVisitDuration(visitType, d);
      return calcEndTime(startTime, duration);
    }
    const duration = getVisitDuration(visitType, endTimeInMinutes - startTimeInMinutes);
    return startTime ? calcEndTime(startTime, duration) : undefined;
  }, []);

  const getParticipants = useCallback((getFieldValue: GetFieldValue) => {
    log('getParticipants');
    const participants: string[] = getFieldValue(factory.getName('participants')) || [];
    const rdhc = participants.includes(ParticipantsEnum.RdHc) ? getFieldValue(factory.getName('rdhc')) : undefined;
    const ca = participants.includes(ParticipantsEnum.CA) ? getFieldValue(factory.getName('ca')) : undefined;
    const md = participants.includes(ParticipantsEnum.MD) ? getFieldValue(factory.getName('md')) : undefined;
    const ma = participants.includes(ParticipantsEnum.MA) ? getFieldValue(factory.getName('ma')) : undefined;
    const res = filter([rdhc, ca, md, ma], (v) => v !== undefined);
    return res;
  }, []);

  const shouldShowParticipantSelect = useCallback((
    (role: ParticipantsEnum, getFieldValue: GetFieldValue) => {
      const participants: ParticipantsEnum[] = getFieldValue(factory.getName('participants')) || [];
      switch (role) {
        case ParticipantsEnum.RdHc:
          return participants.includes(ParticipantsEnum.RdHc);
        case ParticipantsEnum.CA:
          return participants.includes(ParticipantsEnum.CA);
        case ParticipantsEnum.MD:
          return participants.includes(ParticipantsEnum.MD);
        case ParticipantsEnum.MA:
          return participants.includes(ParticipantsEnum.MA);
        default: return false;
      }
    }), []);

  const getParticipantsBasedOnVisitType = (visitType?: VisitTypeEnum): ParticipantsEnum[] => {
    switch (visitType) {
      case VisitTypeEnum.INITIAL:
      case VisitTypeEnum.TECH_ONBOARDING:
      case VisitTypeEnum.TECH:
      case VisitTypeEnum.INIT:
        return [ParticipantsEnum.CA];
      case VisitTypeEnum.POST_MD_FOLLOW_UP:
        return [ParticipantsEnum.CA, ParticipantsEnum.MD];
      case VisitTypeEnum.ADDITIONAL:
        return [ParticipantsEnum.RdHc];
      default: return [];
    }
  };

  const onValuesChange = useCallback<Exclude<FormProps['onValuesChange'], undefined>>((changedValues, allValues) => {
    log('onValuesChange', changedValues, allValues);
    // visitDate
    const visitDate = factory.getName('visitDate');
    if (visitDate in changedValues) {
      onVisitDateChange?.(changedValues[visitDate]);
    }

    // startTime
    const startTime = factory.getName('startTime');
    const visitType = factory.getName('visitType');
    const eventType = factory.getName('eventType');
    const endTime = factory.getName('endTime');
    if ((startTime in changedValues && visitDate in allValues)
      || (visitType in changedValues && startTime in allValues && visitDate in allValues)
    ) {
      const sTime = moment(allValues[visitDate]).startOf('day').add(allValues[startTime], 'minutes');
      const eTime = allValues[eventType] === CalendarSourceEnum.UC_VISIT
        ? calcEndTimeBasedVisitType(sTime, allValues[visitType]?.value)
        : moment(dayjs(sTime.toDate()).add(15, 'minutes').toISOString());
      onVisitTimeChange?.(sTime, eTime);
    }

    // endTime
    if (endTime in changedValues) {
      const sTime = moment(allValues[visitDate]).startOf('day').add(allValues[startTime], 'minutes');
      const eTime = moment(allValues[visitDate]).startOf('day').add(allValues[endTime], 'minutes');
      onVisitTimeChange?.(sTime, eTime);
    }

    // VisitType
    if (visitType in changedValues) {
      const newVisitType = changedValues[visitType]?.value || changedValues[visitType];
      // only update participants when visitType is changed
      const roles = getParticipantsBasedOnVisitType(newVisitType);

      factory.form.setFieldsValue({
        [factory.getName('participants')]: roles,
      });
    }

    // participants change
    const rdhc = factory.getName('rdhc');
    const ca = factory.getName('ca');
    const md = factory.getName('md');
    if (
      rdhc in changedValues
      || ca in changedValues
      || md in changedValues
    ) {
      const allParticipants: string[] = [];
      if (allValues[rdhc]) {
        allParticipants.push(allValues[rdhc]);
      }
      if (allValues[ca]) {
        allParticipants.push(allValues[ca]);
      }
      if (allValues[md]) {
        allParticipants.push(allValues[md]);
      }

      onParticipantsChange?.(allParticipants);
    }

    // other event participants change
    const otherEventParticipants = factory.getName('otherEventParticipants');
    if (otherEventParticipants in changedValues) {
      onParticipantsChange?.(changedValues[otherEventParticipants]);
    }
  }, [
    factory.form,
    onParticipantsChange,
    onVisitDateChange,
    onVisitTimeChange,
    editVisit,
  ]);

  const getFieldValue = (
    fieldChanged: FieldData[],
    field: Parameters<typeof factory.getName>[0],
  ) => {
    for (let i = 0; i < fieldChanged.length; i += 1) {
      // @ts-ignore because the antd type is incorrect
      for (let j = 0; j < fieldChanged[i].name?.length; j += 1) {
        // @ts-ignore because the antd type is incorrect
        if (fieldChanged[i].name[j] === field) {
          return fieldChanged[i].value;
        }
      }
    }
    return undefined;
  };

  const onFieldsChange = useCallback<Exclude<FormProps['onFieldsChange'], undefined>>((fieldChanged) => {
    log('onFieldsChange', fieldChanged);
    if (getFieldValue(fieldChanged, 'visitType') || getFieldValue(fieldChanged, 'participants')) {
      const participants = getParticipants(factory.form.getFieldValue);
      onParticipantsChange?.(participants);
    }
  }, [
    onParticipantsChange,
    getFieldValue,
  ]);

  const onStartTimeChange = useCallback<Exclude<TimeSelectComponentProps['onChange'], undefined>>((v) => {
    log('onStartTimeChange', v);
    if (!v) return;
    const visitType: VisitTypeEnum | undefined = factory.form.getFieldValue(factory.getName('visitType'))?.value;
    const eventType: CalendarSourceEnum = factory.form.getFieldValue(factory.getName('eventType'));
    if (eventType === CalendarSourceEnum.OTHER) {
      factory.form.setFieldValue(factory.getName('endTime'), v + 15);
    } else {
      const d = factory.form.getFieldValue(factory.getName('duration'));
      const duration = getVisitDuration(visitType, d);
      factory.form.setFieldValue(factory.getName('endTime'), v + duration);
    }
  }, [factory.form]);

  const getRecommendedJumpDate = useCallback((
    startTime?: Dayjs | number,
  ) => {
    if (!startTime) {
      return undefined;
    }
    const from = startTime ? dayjs(startTime) : undefined;
    // windowService.from + [75, 112/202] days from last checked-out F/U or Initial
    // 1/ date to auto-jump from last checked-out F/U or Initial + 90 days
    const date = from?.add(15, 'days');
    const day = date?.day();
    let dateMoment = dayjs(date?.toDate());
    // if day is a saturday or sunday make the visit for last friday
    if (day === 0) {
      dateMoment = dateMoment.add(-2, 'days');
    } else if (day === 6) {
      dateMoment = dateMoment.add(-1, 'days');
    }
    // 2/ don't auto-jump to past date, and not including current date
    const isPastDate = (dayjs()?.diff(dateMoment, 'days') || 0) > 0;
    if (isPastDate) {
      return undefined;
    }
    return dateMoment;
  }, []);

  const onEndTimeChange = useCallback<Exclude<TimeSelectComponentProps['onChange'], undefined>>((v) => {
    log('onEndTimeChange', v);
    if (!v) return;
    const visitType: VisitTypeEnum | undefined = factory.form.getFieldValue(factory.getName('visitType'))?.value;
    const eventType: CalendarSourceEnum = factory.form.getFieldValue(factory.getName('eventType'));
    const startTime = factory.form.getFieldValue(factory.getName('startTime'));
    if (startTime > v) {
      if (eventType === CalendarSourceEnum.OTHER) {
        factory.form.setFieldValue(factory.getName('startTime'), v - 15);
        factory.form.setFieldValue(factory.getName('duration'), 15);
      } else {
        const duration = getVisitDuration(visitType);
        factory.form.setFieldValue(factory.getName('duration'), duration);
        factory.form.setFieldValue(factory.getName('startTime'), v - duration);
      }
    }
    if (visitType === VisitTypeEnum.ADDITIONAL) {
      factory.form.setFieldValue(factory.getName('duration'), v - startTime);
    }
  }, []);

  const onVisitTypeChange = useCallback((windowService?: FollowUpVisitWindowService) => (
    (v: VisitTypeSelectCalendarValue | undefined) => {
      if (!v) return;
      const participants = getParticipants(factory.form.getFieldValue);

      const startTime = factory.form.getFieldValue('startTime');
      const duration = getVisitDuration(v.value);
      factory.form.setFieldValue(factory.getName('duration'), duration);
      if (v.value !== VisitTypeEnum.INIT) {
        factory.form.setFieldValue(factory.getName('endTime'), startTime + duration);
      }
      if (v.value === VisitTypeEnum.FOLLOW_UP && v.shouldJump && windowService) {
        const dateMoment = getRecommendedJumpDate(windowService?.from);
        if (!dateMoment) return;
        const jumpedDate = moment(dateMoment?.toDate());
        factory.form.setFieldValue(factory.getName('visitDate'), jumpedDate);
        onVisitDateChange?.(jumpedDate);
      }

      onParticipantsChange?.(participants);
    }
  ), [
    factory.form,
    onVisitDateChange,
  ]);

  const isParticipantsDisabled = useCallback((getFieldValue: GetFieldValue) => (
    !factory.hasValue('patient', getFieldValue) || !factory.hasValue('visitType', getFieldValue)
  ), [factory.hasValue]);

  const isVisit = useCallback((getFieldValue: GetFieldValue) => (
    factory.getValue('eventType', getFieldValue) === CalendarSourceEnum.UC_VISIT
  ), [factory.getValue]);

  const isOtherEvent = useCallback((getFieldValue: GetFieldValue) => (
    factory.getValue('eventType', getFieldValue) === CalendarSourceEnum.OTHER
  ), [factory.getValue]);

  const getPatientRequiredOption = useCallback((getFieldValue: GetFieldValue) => (
    isOtherEvent(getFieldValue) ? [] : factory.getRequiredRules('patient')
  ), [isOtherEvent, factory.getRequiredRules]);

  const isEndTimeDisabled = useCallback((getFieldValue: GetFieldValue) => (
    isVisit(getFieldValue)
    && factory.getValue('visitType', getFieldValue)?.value !== VisitTypeEnum.ADDITIONAL
  ), [factory.getValue, getFieldValue]);

  const getEndTimeDurationOption = useCallback((getFieldValue: GetFieldValue) => {
    const startTime = getStartTime(factory.form.getFieldValue);
    if (
      isVisit(getFieldValue)
      && factory.getValue('visitType', getFieldValue)?.value === VisitTypeEnum.ADDITIONAL
      && startTime
    ) {
      const startTimeInMinutes = startTime.diff(dayjs(startTime).startOf('day'), 'minutes');
      return [startTimeInMinutes + 20, startTimeInMinutes + 40];
    }
    return undefined;
  }, [factory.getValue, getFieldValue]);

  const onEventTypeChange = useCallback<Exclude<EventTypeRadioGroupComponentProps['onChange'], undefined>>((v) => {
    log('onEventTypeChange', v);
    const startTime = getStartTime(factory.form.getFieldValue);
    const allParticipants = getParticipants(factory.form.getFieldValue);
    const otherParticipantsField = factory.getName('otherEventParticipants');
    if (v === CalendarSourceEnum.OTHER) {
      if (startTime) {
        const endTime = calcEndTime(startTime, 15);
        const sTime = moment(startTime.toDate());
        const eTime = moment(endTime.toDate());
        const endTimeInMinutes = TimeService.getTotalMins(endTime);
        factory.form.setFieldsValue({
          [factory.getName('endTime')]: endTimeInMinutes,
          [factory.getName('visitType')]: undefined,
          [otherParticipantsField]: uniq(allParticipants)
        });
        onVisitTimeChange?.(sTime, eTime);
      }
    } else {
      const endTime = getEndTime(factory.form.getFieldValue);
      if (startTime && endTime) {
        const sTime = moment(startTime.toDate());
        const eTime = moment(endTime.toDate());
        const endTimeInMinutes = TimeService.getTotalMins(endTime);
        factory.form.setFieldValue(factory.getName('endTime'), endTimeInMinutes);
        onVisitTimeChange?.(sTime, eTime);
      }
    }
  }, [factory.form]);

  const parseParticipants = useCallback((
    value: Partial<SubmitValue>
  ) => (
    reduce(value.participants, (res, curr) => {
      switch (curr) {
        case ParticipantsEnum.RdHc:
          if (value.rdhc) {
            res.push({
              participantId: value.rdhc,
              role: VisitParticipantRoleEnum.RD_HC,
            });
          }
          break;
        case ParticipantsEnum.CA:
          if (value.ca) {
            res.push({
              participantId: value.ca,
              role: VisitParticipantRoleEnum.CA,
            });
          }
          break;
        case ParticipantsEnum.MA:
          res.push({
            participantId: value.ma,
            role: VisitParticipantRoleEnum.MA,
          });
          break;
        case ParticipantsEnum.MD:
          if (value.md) {
            res.push({
              participantId: value.md,
              role: VisitParticipantRoleEnum.MD,
            });
          }
          break;
        default: break;
      }
      return res;
    }, [] as VisitParticipant[])
  ), []);

  const getEventTitle = useCallback((info?: PatientInfo) => {
    if (info
      && !info?.isLoadingObj.enrolledProgramLoading
      && !info?.isLoadingObj.patientInfoLoading) {
      const clinic = info?.patientInfoService?.getClinic()?.orgNumber ?? undefined;
      const enrollment = (
        info?.enrolledProgramService?.getStatus() === EnrolledProgramStatusEnum.ENROLLED
          ? 'Enrolled'
          : 'Unenrolled'
      );
      const enrollText = `[${enrollment}]`;
      let name;
      if (info?.patientInfoService?.getFullName()) {
        name = info?.patientInfoService?.getFullName();
      }
      const title = [clinic ?? '', enrollText, name ?? ''].join(' ');
      return title;
    }
    return undefined;
  }, []);

  const response = useMemo(() => ({
    ...factory,
    getParticipantsBasedOnVisitType,
    onFieldsChange,
    onValuesChange,
    shouldShowFollowUpVisitWindow,
    shouldShowPatientInfoCard,
    shouldShowParticipantSelect,
    shouldShowLocation,
    onStartTimeChange,
    onEndTimeChange,
    onVisitTypeChange,
    getStartTime,
    getEndTime,
    isParticipantsDisabled,
    getParticipants,
    calcEndTime,
    getVisitDuration,
    isVisit,
    isOtherEvent,
    getPatientRequiredOption,
    onEventTypeChange,
    parseParticipants,
    getEventTitle,
    getRecommendedJumpDate,
    isEndTimeDisabled,
    getEndTimeDurationOption,
    calcEndTimeBasedVisitType,
  }), [
    factory,
    onFieldsChange,
    onValuesChange,
    shouldShowFollowUpVisitWindow,
    shouldShowPatientInfoCard,
    shouldShowParticipantSelect,
    shouldShowLocation,
    onStartTimeChange,
    onEndTimeChange,
    onVisitTypeChange,
    getStartTime,
    getEndTime,
    isParticipantsDisabled,
    getParticipants,
    isVisit,
    isOtherEvent,
    getPatientRequiredOption,
    onEventTypeChange,
    parseParticipants,
    getEventTitle,
    getRecommendedJumpDate,
    isEndTimeDisabled,
    getEndTimeDurationOption,
    calcEndTimeBasedVisitType,
  ]);

  return response;
};
