import dayjs, { Dayjs } from 'dayjs';
import { find, isEqual, uniqueId } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { TimeService } from '../../../helpers/time/timeService';
import { Assignee } from '../../../services/PatientAssigneesService';
import { CalendarSourceEnum, ClinicEvent, RoleTypeEnum } from '../../../uc-api-sdk';
import { CalendarEvent } from '../../calendar/component/BigCalendarComponent/BigCalendarComponent';
import { NEW_EVENT_ID } from '../../calendar/constant';
import { CreateVisitValue } from '../../calendar/context/CreateVisitContext/CreateVisitContext';
import { CalendarColorService } from '../../calendar/helper/CalendarColorService/CalendarColorService';
import { VisitInitialValue } from '../../calendar/hook/useCalendarHelper';
import { ParticipantsEnum } from '../type/participants';
import { useCreateVisitForm, UseCreateVisitFormArg } from './useCreateVisitForm';
import { useCreateVisitInitialValues } from './useCreateVisitInitialValues';
import { useLog } from '../../../hooks/useLog/useLog';

export const DEFAULT_VISIT_DURATION_MINUTES = 20;

export interface NewVisit {
  start: Date;
  end: Date;
}

export interface UseCreateVisitHelperArg {
  onEmployeeIdChange?: (ids: string[]) => void;
  onDateChange?: (date: Dayjs) => void;
}

export const useCreateVisitHelper = ({
  onEmployeeIdChange,
  onDateChange,
}: UseCreateVisitHelperArg = {}) => {
  const { userId = '' } = useLoggedInUserFromContext();
  const { log } = useLog('visit');
  const {
    getInitialValues,
    getParticipantsInitialValue,
  } = useCreateVisitInitialValues();
  const [selectedEvent, setSelectedEvent] = useState<CreateVisitValue['selectedEvent'] | undefined>();

  const onVisitDateChange = useCallback((visitDate: Moment) => {
    log('onVisitDateChange', visitDate);
    const calculateTime = (oldDateTime: Dayjs, newDate: Moment | undefined) => {
      const minuteDiff = oldDateTime.diff(oldDateTime.clone().startOf('day'), 'minute');
      return dayjs(newDate?.toDate()).startOf('day').add(minuteDiff, 'minute').toDate();
    };

    if (!visitDate) return;
    onDateChange?.(dayjs(visitDate.toDate()));
    setSelectedEvent((v) => {
      if (v) {
        return {
          ...v,
          start: calculateTime(dayjs(v.start), visitDate),
          end: calculateTime(dayjs(v.end), visitDate),
        };
      }
      return undefined;
    });
  }, [setSelectedEvent, onDateChange]);

  const onVisitTimeChange = useCallback<Exclude<UseCreateVisitFormArg['onVisitTimeChange'], undefined>>((startTime, endTime) => {
    log('onVisitTimeChange', startTime, endTime);
    setSelectedEvent((v) => {
      const dateTime = dayjs(startTime?.toDate());
      if (v) {
        return {
          ...v,
          start: dateTime.clone().toDate(),
          end: dayjs(endTime.toDate()).toDate(),
        };
      }
      return undefined;
    });
  }, [setSelectedEvent]);

  const onParticipantsChange = useCallback<Exclude<UseCreateVisitFormArg['onParticipantsChange'], undefined>>((v) => {
    log('onParticipantsChange', v);
    onEmployeeIdChange?.(v);
  }, [onEmployeeIdChange]);

  const createVisitForm = useCreateVisitForm({
    onVisitDateChange,
    onVisitTimeChange,
    onParticipantsChange,
    editVisit: selectedEvent?.info,
  });

  const setInitialValue = (event: CalendarEvent<ClinicEvent>, isEdit: boolean) => {
    log('setInitialValue', event, isEdit);
    if (isEdit) {
      createVisitForm.form.setFieldsValue(getInitialValues(event.info ?? {}));
    } else {
      const visitType = createVisitForm.form.getFieldValue(createVisitForm.getName('visitType'))?.value;
      const end = createVisitForm.calcEndTimeBasedVisitType(
        moment(event.start),
        visitType
      );
      createVisitForm.form.setFieldsValue({
        [createVisitForm.getName('eventType')]:
          createVisitForm.getValue('eventType', createVisitForm.form.getFieldValue) as CalendarSourceEnum | undefined
          || CalendarSourceEnum.UC_VISIT,
        [createVisitForm.getName('otherEventParticipants')]:
          createVisitForm.getValue('otherEventParticipants', createVisitForm.form.getFieldValue)
          || [userId],
        [createVisitForm.getName('visitDate')]: moment(event.start).startOf('day'),
        [createVisitForm.getName('startTime')]: TimeService.getTotalMins(moment(event.start)),
        [createVisitForm.getName('endTime')]: TimeService.getTotalMins(end),
      });
    }
  };

  const isFieldsTouched = useCallback(() => {
    log('isFieldsTouched');
    const allValues = createVisitForm.form.getFieldsValue();
    const participants = getParticipantsInitialValue(selectedEvent?.info?.visitParticipants || []);
    return (
      allValues[createVisitForm.getName('patient')] !== selectedEvent?.info?.patientId
      || allValues[createVisitForm.getName('visitType')] !== selectedEvent?.info?.visitType
      || allValues[createVisitForm.getName('visitMethod')] !== selectedEvent?.info?.visitMethod
      || allValues[createVisitForm.getName('startTime')] !== TimeService.getTotalMins(moment(selectedEvent?.start))
      || allValues[createVisitForm.getName('endTime')] !== TimeService.getTotalMins(moment(selectedEvent?.end))
      || allValues[createVisitForm.getName('description')] !== selectedEvent?.info?.description
      || !isEqual(allValues[createVisitForm.getName('participants')], participants.participants)
      || allValues[createVisitForm.getName('rdhc')] !== participants.rdhc
      || allValues[createVisitForm.getName('ca')] !== participants.ca
      || allValues[createVisitForm.getName('md')] !== participants.md
      || !moment(allValues[createVisitForm.getName('visitDate')])
        .startOf('day')
        .isSame(moment(selectedEvent?.start).startOf('day'))
    );
  }, [createVisitForm.form]);

  const handleSelectNew = useCallback((v: NewVisit) => {
    log('handleSelectNew', v);
    setSelectedEvent((event) => {
      setInitialValue(v, false);
      const d = createVisitForm.getValue('duration', createVisitForm.form.getFieldValue);
      const visitType = createVisitForm.getValue(
        'visitType',
        createVisitForm.form.getFieldValue
      )?.value || event?.info?.visitType || undefined;
      const duration = createVisitForm.getVisitDuration(visitType, d);

      if (event && event.isEdit) {
        let { end } = v;
        if (event.info?.visitType) {
          end = moment(v.start).add(duration, 'minute').toDate();
        } else {
          end = dayjs(v.start).add(duration, 'm').toDate();
        }
        return {
          ...event,
          colors: CalendarColorService.getColor('gray'),
          start: v.start,
          end,
        };
      }
      return {
        id: NEW_EVENT_ID,
        uId: uniqueId(),
        start: v.start,
        end: dayjs(v.start).add(duration, 'm').toDate(),
        colors: CalendarColorService.getColor('gray'),
        showPopover: true,
        showPopoverOnMount: true,
        isEdit: true,
      };
    });
  }, [setSelectedEvent, createVisitForm.form]);

  const getAssigneesBasedOnParticipants = (
    participants: ParticipantsEnum[],
    assignees: Assignee[],
  ) => {
    log('getAssigneesBasedOnParticipants', participants, assignees);
    const rd = find(assignees, (a) => a.role === RoleTypeEnum.RD);
    const ca = find(assignees, (a) => a.role === RoleTypeEnum.CA);
    const res: {
      rd?: string;
      ca?: string;
    } = {};
    const emIds: string[] = [];
    if (participants.includes(ParticipantsEnum.RdHc) && rd) {
      res.rd = rd.id;
      emIds.push(rd.id);
    }
    if (participants.includes(ParticipantsEnum.RdHc) && ca) {
      res.ca = ca.id;
      emIds.push(ca.id);
    }
    return { employees: emIds, assignees: res };
  };

  const handleSelectNewWithInitialValues = useCallback((v: VisitInitialValue) => {
    log('handleSelectNewWithInitialValues', v);
    const startTime = v.dateTime.clone();
    const endTime = createVisitForm.calcEndTime(
      v.dateTime,
      createVisitForm.getVisitDuration(v.visitType),
    );
    const initValues = getInitialValues({
      patientId: v.patientId,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      visitType: v.visitType,
    });
    initValues.participants = createVisitForm.getParticipantsBasedOnVisitType(v.visitType);
    const { employees, assignees } = getAssigneesBasedOnParticipants(
      initValues.participants as ParticipantsEnum[],
      v.assignees,
    );
    initValues.ca = assignees.ca;
    initValues.rdhc = assignees.rd;
    createVisitForm.form.setFieldsValue(initValues);
    onEmployeeIdChange?.(employees);
    setSelectedEvent({
      id: NEW_EVENT_ID,
      uId: uniqueId(),
      start: startTime.toDate(),
      end: endTime.toDate(),
      colors: CalendarColorService.getColor('gray'),
      showPopover: true,
      showPopoverOnMount: v.showFormOnMount,
      isEdit: true,
      disabledFields: ['patient'],
    });
  }, [setSelectedEvent, createVisitForm.form]);

  const handleSelectExisting = useCallback((v: CalendarEvent<ClinicEvent>) => {
    log('handleSelectExisting', v);
    if (selectedEvent === undefined) {
      setSelectedEvent({
        ...v,
        showPopover: true,
        showPopoverOnMount: true,
      });
    }
  }, [selectedEvent]);

  const setTempEventAsSelectedEvent = useCallback<CreateVisitValue['setTempEventAsSelectedEvent']>((v) => {
    log('setTempEventAsSelectedEvent', v);
    if (v) {
      setSelectedEvent({
        ...selectedEvent,
        isEdit: true,
      });
      setInitialValue({ ...selectedEvent }, true);
    } else {
      setSelectedEvent(undefined);
    }
  }, [
    selectedEvent,
    setSelectedEvent,
  ]);

  const deselectEvent = useCallback(() => {
    log('deselectEvent');
    setSelectedEvent(undefined);
  }, [setSelectedEvent]);

  const removeNewEvent = useCallback(async () => {
    log('removeNewEvent');
    setSelectedEvent(undefined);
    createVisitForm.form.resetFields();
  }, [setSelectedEvent, createVisitForm.form]);

  const visitInfo = useMemo<CreateVisitValue>(() => ({
    selectedEvent,
    form: createVisitForm,
    setTempEventAsSelectedEvent,
    removeNewEvent,
    isFieldsTouched,
  }), [
    selectedEvent,
    createVisitForm,
    setTempEventAsSelectedEvent,
    removeNewEvent,
    isFieldsTouched,
  ]);

  return {
    selectedEvent,
    deselectEvent,
    handleSelectNew,
    handleSelectExisting,
    visitInfo,
    removeNewEvent,
    visitForm: createVisitForm,
    handleSelectNewWithInitialValues,
  };
};
