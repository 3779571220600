import {
  Checkbox, Col, DatePicker, FormProps, Row
} from 'antd';
import { Moment } from 'moment';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { NumberInputWithSuffixComponent } from '../../../../uiComponent/NumberInputWithSuffixComponent/NumberInputWithSuffixComponent';
import { useBaselineForm } from '../../../patient/hook/useBaselineForm';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface BpBaselineFormItemComponentProps {
  enrollmentDate?: string;
  form: FormProps['form'];
}

export interface BpBaselineFormComponentValues {
  bpBaselineDate: Moment;
  systolic_blood_pressure: number;
  diastolic_blood_pressure: number;
  pulse?: number;
  skipBpBaseline: boolean;
}

export const BpBaselineFormItemComponent = ({
  enrollmentDate,
  form
}: BpBaselineFormItemComponentProps) => {
  const hook = useBaselineForm({ form });

  return (
    <div>
      <div className="mb20">
        {`Transcribe the most recent blood pressure measurement from EHR,
        ensuring it was recorded within 30 days of enrollment.
        [Patient was enrolled on: ${enrollmentDate}]`}
      </div>
      <FormItem
        noStyle
        shouldUpdate={hook.shouldUpdate(['skipBpBaseline'])}
      >
        {
          ({ getFieldValue }) => {
            const disabled = hook.getValue('skipBpBaseline', getFieldValue);
            return (
              <Row gutter={20}>
                <Col span={6}>
                  <FormItem
                    info={hook.getInfo('bpBaselineDate')}
                    required={!disabled}
                  >
                    <DatePicker
                      format={USA_DATE}
                      className="w100"
                      disabled={disabled}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem
                    info={hook.getInfo('systolic_blood_pressure')}
                    required={!disabled}
                  >
                    <NumberInputWithSuffixComponent unit="mmHg" showUnit disabled={disabled} min={1} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem
                    info={hook.getInfo('diastolic_blood_pressure')}
                    required={!disabled}
                  >
                    <NumberInputWithSuffixComponent unit="mmHg" showUnit disabled={disabled} min={1} />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem
                    info={hook.getInfo('pulse')}
                  >
                    <NumberInputWithSuffixComponent unit="bpm" showUnit disabled={disabled} min={1} />
                  </FormItem>
                </Col>
              </Row>
            );
          }
        }
      </FormItem>
      <FormItem
        name={hook.getName('skipBpBaseline')}
        valuePropName="checked"
      >
        <Checkbox>
          {hook.getLabel('skipBpBaseline')}
        </Checkbox>
      </FormItem>
    </div>
  );
};
