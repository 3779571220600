import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { Title } from '../../../../uiComponent/Title/Title';
import { EnrollmentAssignedCareTeamSelectContainer } from '../../container/EnrollmentAssignedCareTeamSelectContainer/EnrollmentAssignedCareTeamSelectContainer';
import { InitialAssessmentCardContainer } from '../../container/InitialAssessmentCardContainer/InitialAssessmentCardContainer';
import { TechOnboardingCardContainer } from '../../container/TechOnboardingCardContainer/TechOnboardingCardContainer';
import { ConsentFormMissingInfoComponent } from '../ConsentFormMissingInfoComponent/ConsentFormMissingInfoComponent';
import { EnrollmentWrapperComponent } from '../EnrollmentWrapperComponent/EnrollmentWrapperComponent';

import './OnboardingComponent.scss';

export interface OnboardingComponentProps {
  patientInfo: PatientInfo;
  onClose?: () => void;
}

export const OnboardingComponent = ({
  patientInfo,
  onClose,
}: OnboardingComponentProps) => {
  useUpdateListener('WORKLIST_CLOSED', () => patientInfo.refetch(['enrollmentInfoRefetch']));

  return (
    <EnrollmentWrapperComponent
      onClose={onClose}
    >
      {
        !patientInfo.consentService.hasConsent()
          ? (
            <ConsentFormMissingInfoComponent
              description="You need to enroll patient and collect consent before onboarding"
            />
          ) : (
            <>
              <div className="onboarding__assignees">
                <div className="mb20 bdr-r">
                  <Title noMargin>
                    Assign Care Team Members
                  </Title>
                  <Title.SubTitle>
                    Assign a RD/HC and a CM/CA to the enrolled patient.
                  </Title.SubTitle>
                </div>
                <EnrollmentAssignedCareTeamSelectContainer
                  patientInfo={patientInfo}
                />
              </div>
              <TechOnboardingCardContainer
                patientInfo={patientInfo}
              />
              <InitialAssessmentCardContainer
                patientInfo={patientInfo}
              />
            </>
          )
      }
    </EnrollmentWrapperComponent>
  );
};
