import { GetFieldValue } from '../../types/form';
import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { MedicationReminderEnum } from '../../uc-api-sdk';

export const useMedicationContainerForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    medicationReview: {
      name: 'medicationCompliance',
      label: 'Medication Review'
    },
    note: {
      name: 'note',
      label: 'Note',
    },
    medicationReminder: {
      name: 'medicationReminder',
      label: 'Medication Reminder Service',
    },
    medicationReminderDeclineReason: {
      name: 'medicationReminderDeclineReason',
      label: 'Decline Reason',
    },
    compliance: {
      name: 'reconciliationCompliance',
      label: 'Medication Compliance',
      required: true,
    },
    complianceOther: {
      name: 'reconciliationComplianceOther',
      label: '',
    },
    otherInfo: {
      name: 'otherInfo',
      label: 'Other Medication Information',
    },
    notes: {
      name: 'extraNote',
      label: 'Notes',
    },
  }, options);

  const checkIfOtherCompliance = (getFieldValue: GetFieldValue) => (
    factory.getValue('compliance', getFieldValue) === 'Other'
  );

  const checkIfDeclinedReminder = (getFieldValue: GetFieldValue) => (
    factory.getValue('medicationReminder', getFieldValue) === MedicationReminderEnum.PATIENT_DECLINED_MEDICATION_REMINDER
  );

  return {
    ...factory,
    checkIfOtherCompliance,
    checkIfDeclinedReminder,
  };
};
