import { filter, map, reverse } from 'lodash';
import { PriorityEnumComponent } from '../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { Nullable, PriorityLevelEnum } from '../../../uc-api-sdk';
import { ColumnType } from '../../../types/table';

// -- PRIORITY
type ExtendsPriority = { priority?: Nullable<string> }; // string as PriorityLevelEnum
const validPriorityLevels = filter(PriorityLevelEnum, (v) => v !== PriorityLevelEnum.level);
const priorityLevelIndex = {
  [PriorityLevelEnum.HIGH]: 3,
  [PriorityLevelEnum.MEDIUM]: 2,
  [PriorityLevelEnum.LOW]: 1,
  [PriorityLevelEnum.level]: 0, // ignore
};

export const getAlertPriorityFilters = <T extends ExtendsPriority>(): ColumnType<T> => ({
  filters: map(reverse(validPriorityLevels), (value) => ({
    text: (<PriorityEnumComponent value={value} type="filter" />),
    value,
  })),
  onFilter: (
    value,
    record
  ) => record?.priority === value,
});

export const getAlertPrioritySorter = <T extends ExtendsPriority>(): ColumnType<T> => ({
  sorter: {
    compare: (a, b) => {
      const aIndex = priorityLevelIndex[a.priority as PriorityLevelEnum];
      const bIndex = priorityLevelIndex[b.priority as PriorityLevelEnum];
      return aIndex - bIndex;
    }
  }
});
