import { Form } from 'antd';
import { CancelSubmitWithPopconfirmComponent, CancelSubmitWithPopconfirmComponentProps } from '../../../../uiComponent/CancelSubmitWithPopconfirmComponent/CancelSubmitWithPopconfirmComponent';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthConfirmStatusContentComponent } from '../PriorAuthLayout/PriorAuthConfirmStatusContentComponent';

export interface InsuranceSubmitCancelFormItemComponentProps
  extends CancelSubmitWithPopconfirmComponentProps {
  fieldName?: number,
  isLoading?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const InsuranceSubmitCancelFormItemComponent = ({
  fieldName,
  isLoading,
  onCancel,
  onSubmit,
  submitText = 'Save',
  popConfirmProps,
  ...props
}: InsuranceSubmitCancelFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  return (
    <FormItem
      noStyle
      shouldUpdate={insuranceForm.shouldUpdateFromList(['priorAuthStatus', 'priorAuthActiveCase'], fieldName)}
    >
      {
        ({ getFieldValue }) => {
          const priorAuthStatus = insuranceForm.getObjectValue('priorAuthStatus', getFieldValue, fieldName);
          const shouldConfirm = insuranceForm.shouldConfirmSubmit(getFieldValue, fieldName);
          return (
            <CancelSubmitWithPopconfirmComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onCancel={onCancel}
              onSubmit={onSubmit}
              beforeConfirm={insuranceForm.form.validateFields}
              submitText={submitText}
              submitDisabled={isLoading}
              showConfirm={shouldConfirm}
              title={(
                <PriorAuthConfirmStatusContentComponent
                  status={priorAuthStatus}
                />
              )}
              popConfirmProps={{
                icon: null,
                placement: 'topRight',
                okText: 'Yes, submit',
                ...popConfirmProps,
              }}
            />
          );
        }
      }
    </FormItem>
  );
};
