import { map } from 'lodash';
import { useCallback } from 'react';
import { Form } from 'antd';
import { InsuranceEligibilityStatusEnumComponent } from '../../../../../enumComponent/EligibilityStatusEnumComponent/EligibilityStatusEnumComponent';
import { useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import { EnumSelectComponent } from '../../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { InsuranceEligibilityStatusComponent } from '../../InsuranceCardComponent/InsuranceCardLayoutComponent';
import { EligibilityStatusEnum } from '../../../../../uc-api-sdk';

export interface InsuranceEligibilityStatusFormItemComponentProps {
  fieldName: number;
  span?: number;
}

export const InsuranceEligibilityStatusFormItemComponent = ({
  fieldName,
  span,
}: InsuranceEligibilityStatusFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  const optionRenderer = useCallback((option: { value: EligibilityStatusEnum }) => (
    <InsuranceEligibilityStatusEnumComponent value={option.value} />
  ), []);

  const handleOnChange = useCallback(() => {
    // reset wayStarInquiryId when eligibilityStatus is manually changed
    insuranceForm.form.setFieldValue(
      insuranceForm.makeObjectNamePath('wayStarInquiryId', fieldName),
      undefined
    );
  }, []);

  return (
    <>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldValue }) => {
            const currentInsurance = getFieldValue(
              insuranceForm.getMainFormItemName()
            )?.[fieldName];
            return (
              <InsuranceEligibilityStatusComponent
                insurance={currentInsurance}
                span={span}
                showTooltip
              >
                <FormItem
                  name={insuranceForm.makeListNamePath('eligibilityStatus', fieldName)}
                  required
                >
                  <EnumSelectComponent
                    options={(
                      map([
                        EligibilityStatusEnum.ACTIVE,
                        EligibilityStatusEnum.INACTIVE
                      ])
                    )}
                    onChange={handleOnChange}
                    optionRenderer={optionRenderer}
                    allowClear
                  />
                </FormItem>
              </InsuranceEligibilityStatusComponent>
            );
          }
        }
      </FormItem>
      <FormItem
        name={insuranceForm.makeListNamePath('wayStarInquiryId', fieldName)}
        hidden
      >
        <div />
      </FormItem>
    </>
  );
};
