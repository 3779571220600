import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { OnboardingCardDescriptionComponent } from './OnboardingCardDescriptionComponent';
import { OnboardingCardResumeVisitButtonComponent } from './OnboardingCardResumeVisitButtonComponent';
import { OnboardingCardComponent } from './OnboardingCardComponent';

export interface InitialAssessmentCardComponentProps {
  patientInfo: PatientInfo;
}

const DESCRIPTION = 'Initial visit will talk about patient\'s medical history, nutrition, lifestyle and help patient set up goals.';

export const InitialAssessmentCardComponent = ({
  patientInfo,
}: InitialAssessmentCardComponentProps) => {
  const initialAssessmentVisitEvent = patientInfo.enrollmentInfo?.initialAssessmentVisitEvent
    ? new ClinicEventsService(patientInfo.enrollmentInfo?.initialAssessmentVisitEvent)
    : {} as ClinicEventsService;
  const {
    id,
    isCheckedIn,
    isCompleted,
  } = initialAssessmentVisitEvent;

  const renderDescription = () => (
    <OnboardingCardDescriptionComponent
      description={DESCRIPTION}
    >
      {
        (isCheckedIn && !isCompleted)
          && (
            <OnboardingCardResumeVisitButtonComponent
              patientId={patientInfo.id}
              clinicEventId={id || ''}
            />
          )
      }
    </OnboardingCardDescriptionComponent>
  );

  return (
    <OnboardingCardComponent
      title="Initial Visit"
      description={renderDescription()}
      patientInfo={patientInfo}
      clinicEvent={initialAssessmentVisitEvent}
      visitType={VisitTypeEnum.INITIAL}
      mixpanelEvent={MixpanelEvents.CreateInitialVisitDrawerOpen}
    />
  );
};
