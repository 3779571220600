import { Select } from 'antd';
import { InputType } from '../../../Input/types';
import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface VisitTypeSelectComponentProps extends InputType<VisitTypeEnum> {
  disabled?: boolean;
  initialType?: VisitTypeEnum;
}

export const VisitTypeSelectComponent = ({
  value,
  onChange,
  disabled,
  initialType,
}: VisitTypeSelectComponentProps) => (
  <Select value={value} onChange={onChange} disabled={disabled}>
    <Select.Option value={VisitTypeEnum.INITIAL}>
      Initial Visit (20 min)
    </Select.Option>
    <Select.Option value={VisitTypeEnum.TECH_ONBOARDING}>
      Tech Onboarding (20 min)
    </Select.Option>
    <Select.Option value={VisitTypeEnum.POST_MD_FOLLOW_UP}>
      Post-MD Follow-up (10 min)
    </Select.Option>
    <Select.Option value={VisitTypeEnum.ADDITIONAL}>Additional (20 min)</Select.Option>
    <Select.Option value={VisitTypeEnum.TECH}>Tech Support (20 min)</Select.Option>
    {initialType && [VisitTypeEnum.FOLLOW_UP, VisitTypeEnum.INIT].includes(initialType) && (
      <>
        <Select.Option value={VisitTypeEnum.INIT} disabled>
          Initial Assessment (Old)
        </Select.Option>
        <Select.Option value={VisitTypeEnum.FOLLOW_UP} disabled>
          Follow Up (Old)
        </Select.Option>
      </>
    )}
  </Select>
);
