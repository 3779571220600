import { Dayjs } from 'dayjs';
import {
  UcMedicationManagement,
  MedicationComplianceEnum,
  MedicationReminderEnum,
  MedicationReminderDeclineReasonEnum,
} from '../uc-api-sdk';
import TimezoneService from '../helpers/timezone/timezoneService';

export interface MedicationmangementInfo {
  medicationManagement?: UcMedicationManagement;
}

export class MedicationManagementService {
  private medicationManagement: UcMedicationManagement | undefined;

  public reconciliationCompliance: string | undefined;

  public reconciliationComplianceOther: string | undefined;

  public otherInfo: string | undefined;

  public extraNote: string | undefined;

  public medicationCompliance: MedicationComplianceEnum | undefined;

  public medicationComplianceLastModifiedAt: Dayjs | undefined;

  public medicationReminder: MedicationReminderEnum | undefined;

  public medicationReminderLastModifiedAt: Dayjs | undefined;

  public medicationReminderDeclineReason: MedicationReminderDeclineReasonEnum | undefined;

  constructor(medicationManagement?: UcMedicationManagement) {
    const {
      reconciliationCompliance,
      reconciliationComplianceOther,
      otherInfo,
      extraNote,
      medicationCompliance,
      medicationReminder,
      medicationComplianceLastModifiedAt,
      medicationReminderLastModifiedAt,
      medicationReminderDeclineReason,
    } = medicationManagement || {};

    this.medicationManagement = medicationManagement ?? undefined;
    this.reconciliationCompliance = reconciliationCompliance ?? undefined;
    this.reconciliationComplianceOther = reconciliationComplianceOther ?? undefined;
    this.otherInfo = otherInfo ?? undefined;
    this.extraNote = extraNote ?? undefined;
    this.medicationCompliance = medicationCompliance ?? undefined;
    this.medicationReminder = medicationReminder ?? undefined;
    this.medicationComplianceLastModifiedAt = (
      medicationComplianceLastModifiedAt
        ? TimezoneService.calcDateTimeDayjs(medicationComplianceLastModifiedAt)
        : undefined
    );
    this.medicationReminderLastModifiedAt = (
      medicationReminderLastModifiedAt
        ? TimezoneService.calcDateTimeDayjs(medicationReminderLastModifiedAt)
        : undefined
    );
    this.medicationReminderDeclineReason = medicationReminderDeclineReason ?? undefined;
  }

  get medComplianceUpdateInfo() {
    const updatedByUser = this.medicationManagement?.updatedMedicationComplianceByUser;
    return {
      updatedAt: this.medicationComplianceLastModifiedAt,
      updatedBy: updatedByUser?.id,
      updatedByUser,
    };
  }

  get note() {
    return this.medicationManagement?.note;
  }

  get medReminderUpdateInfo() {
    const updatedByUser = this.medicationManagement?.updatedMedicationReminderByUser;
    return {
      updatedAt: this.medicationReminderLastModifiedAt,
      updatedBy: updatedByUser?.id,
      updatedByUser,
    };
  }

  get medLastUpdatedInfo() {
    const updatedAt = this.medicationManagement?.updatedAt;
    const updatedByUser = this.medicationManagement?.updatedByUser;
    return {
      updatedAt: (
        updatedAt
          ? TimezoneService.calcDateTimeDayjs(updatedAt)
          : undefined
      ),
      updatedByUser,
      updatedBy: updatedByUser?.id,
    };
  }

  static checkManagementEdit = (
    oldM?: UcMedicationManagement | null,
    newM?: UcMedicationManagement | null,
  ) => ({
    isEditMedicationCompliance: oldM?.medicationCompliance !== newM?.medicationCompliance,
    isEditMedicationReminder: oldM?.medicationReminder !== newM?.medicationReminder,
  });
}
