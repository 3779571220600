import { first, includes, remove } from 'lodash';
import { baseAPIHelper } from '../../../../hooks/useTableChange/baseAPIHelper';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  InFilter,
  SearchRequest,
  ComplianceAlert,
  SmartAlertTaskClass,
  SmartAlertTaskStatus,
  useComplianceAlertSearch,
  PriorityLevelEnum,
  ComplianceAlertTypeEnum,
  Sort$Direction
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { ComplianceAlertTableComponent } from '../../component/ComplianceAlertTableComponent/ComplianceAlertTableComponent';
import { ComplianceAlertTypeOthers, ComplianceAlertTypeVisitDue } from '../../../../enumComponent/SmartAlertTypeEnumComponent/SmartAlertTypeEnumComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface ComplianceAlertTableContainerProps {
  memberId?: string;
}

export const ComplianceAlertTableContainer = ({
  memberId,
}: ComplianceAlertTableContainerProps) => {
  const defaultSorter = () => [{ direction: Sort$Direction.DESC, property: 'createdAt' }];

  const tableChange = useTableChange<
    SmartAlertTaskClass,
    SearchRequest<ComplianceAlert>['filter']
  >({
    // don't use parameter filter here, it reset processedFilter initially
    // use { aInitialFilter, ...tableChange.processedFilter, }
    sort: defaultSorter(),
    sortProcessor: (sorter) => sorterHelper(sorter),
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<ComplianceAlert>['filter'] = {};
      if (filters.closedBy) {
        newFilters.closedBy = first(filters.closedBy) as string;
      }
      if (filters.priority) {
        const filterIn: InFilter<PriorityLevelEnum> = {
          in: filters.priority as PriorityLevelEnum[],
        };
        newFilters.priorityIn = filterIn;
      }
      if (filters.alertType) {
        const types = [...filters.alertType];
        if (includes(filters.alertType, ComplianceAlertTypeVisitDue)) {
          types.push(
            ComplianceAlertTypeEnum.VISIT_DUE_INIT,
            ComplianceAlertTypeEnum.VISIT_DUE_FOLLOW_UP,
            ComplianceAlertTypeEnum.VISIT_DUE_POST_MD,
            ComplianceAlertTypeEnum.VISIT_DUE_TECH_ONBOARDING,
            ComplianceAlertTypeEnum.VISIT_OVERDUE,
          );
        }
        if (includes(filters.alertType, ComplianceAlertTypeOthers)) {
          types.push(
            ComplianceAlertTypeEnum.VISIT_OVERDUE,
            ComplianceAlertTypeEnum.INACTIVE_2_WEEK_LOW_RISK,
            ComplianceAlertTypeEnum.LEVEL_FIVE_HUB_USER_MISSING_CALL_LOG,
            ComplianceAlertTypeEnum.LEVEL_FIVE_NO_PROVIDERNOTE_IN_SIX_WEEKS,
            ComplianceAlertTypeEnum.LEVEL_FOUR_A1C_OVERDUE,
            ComplianceAlertTypeEnum.LEVEL_FOUR_NO_PROVIDERNOTE_IN_FOUR_WEEKS,
            ComplianceAlertTypeEnum.LEVEL_THREE_20_DAYS_LOW_ACTIVITY,
            ComplianceAlertTypeEnum.LEVEL_THREE_8_DAYS_LOW_ACTIVITY,
            ComplianceAlertTypeEnum.LEVEL_THREE_BIRTHDAY,
            ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_APP_ENGAGEMENT,
            ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_ENGAGEMENT,
            ComplianceAlertTypeEnum.LEVEL_THREE_PATIENT_NO_APP_ENGAGEMENT,
          );
        }
        remove(types, (it) => (
          [ComplianceAlertTypeOthers, ComplianceAlertTypeVisitDue]
            .includes(it as string)
        ));
        const filterIn: InFilter<ComplianceAlertTypeEnum> = {
          in: types as ComplianceAlertTypeEnum[],
        };
        newFilters.alertTypeIn = filterIn;
      }
      if (filters.status) {
        const s: SmartAlertTaskStatus = filters.status[0] as SmartAlertTaskStatus;
        newFilters.status = s;
      }
      return newFilters;
    },
  });

  const convertedPage = baseAPIHelper(tableChange.processedSort, tableChange.pageInfo);

  const info = useComplianceAlertSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: { memberId, ...tableChange.processedFilter },
      pageInfo: convertedPage,
    },
  });

  useUpdateListener('COMPLIANCE_ALERT_UPDATED', info.refetch);
  useUpdateListener('ALERT_SNOOZE_OR_UN_SNOOZE', info.refetch);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => (
        <ComplianceAlertTableComponent
          dataSource={value?.rawValue?.data?.content}
          onChange={tableChange.handleTableChange}
          pagination={info.pagination}
        />
      )}
    </FetchComponent>
  );
};
