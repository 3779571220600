import { ReactNode } from 'react';
import { TextComponent } from '../../../../../../../uiComponent/TextComponent/TextComponent';

export interface PrintDiseaseHistoryTitleComponentProps {
  title: ReactNode;
}

export const PrintDiseaseHistoryTitleComponent = ({
  title,
}: PrintDiseaseHistoryTitleComponentProps) => (
  <TextComponent size="medium" bold>
    {title}
  </TextComponent>
);
