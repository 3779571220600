export interface IcdCodeTitleComponentProps {

}

export const IcdCodeTitleComponent = () => (
  <div className="mb12">
    <div className="bold mb12">
      ICD Codes
    </div>
    <div>
      Add all chronic diagnoses from EMR. Exclude acute/temporary diagnoses.
    </div>
  </div>
);
