import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { InputTypeArray } from '../../../Input/types';
import { ParticipantsEnum } from '../../type/participants';

export interface ParticipantsCheckboxGroupComponentProps extends InputTypeArray<ParticipantsEnum> {
  disabled?: boolean;
}

export const ParticipantsCheckboxGroupComponent = ({
  value,
  onChange,
  disabled,
}: ParticipantsCheckboxGroupComponentProps) => {
  const handleChange: CheckboxGroupProps['onChange'] = (v) => {
    onChange?.(v as ParticipantsEnum[]);
  };

  return (
    <Checkbox.Group
      value={value}
      onChange={handleChange}
      disabled={disabled}
    >
      <Checkbox value={ParticipantsEnum.RdHc}>RD/HC</Checkbox>
      <Checkbox value={ParticipantsEnum.CA}>CM/CA</Checkbox>
      <Checkbox value={ParticipantsEnum.MD}>MD</Checkbox>
      <Checkbox value={ParticipantsEnum.MA}>MA</Checkbox>
    </Checkbox.Group>
  );
};
