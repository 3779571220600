import {
  Divider,
  Space,
  Typography,
} from 'antd';
import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { Icons } from '../../../../icons/Icons';
import {
  ClinicEvent, LimitedPatient,
} from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { VisitInfoComponent } from '../../../doctorVisit/component/VisitInfoComponent/VisitInfoComponent';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { CalendarEventTimeComponent } from '../CalendarEventTimeComponent/CalendarEventTimeComponent';
import { CalendarVisitTitleComponent } from '../CalendarVisitTitleComponent/CalendarVisitTitleComponent';
import { PatientVisitCardComponent } from '../PatientVisitCardComponent/PatientVisitCardComponent';

import { AccountHelper } from '../../../../helpers/account';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { DeleteModalComponent } from '../../../../uiComponent/DeleteModalComponent/DeleteModalComponent';
import './VisitViewComponent.scss';
import { CTAButtonComponent } from '../CTAButtonComponent/CTAButtonComponent';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { VisitsStatusComponent } from '../VisitsStatusComponent/VisitsStatusComponent';
import { RejoinZoomButtonComponentProps } from '../../../zoom/component/RejoinZoomButtonComponent/RejoinZoomButtonComponent';

const { Text, Title } = Typography;

export interface VisitViewComponentProps extends RejoinZoomButtonComponentProps {
  patientInfo?: LimitedPatient;
  visitInfo: ClinicEvent;
  isSubmitting?: boolean;
  onClose?: (closeOuter?: boolean) => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const VisitViewComponent: FC<VisitViewComponentProps> = ({
  patientInfo,
  visitInfo,
  isSubmitting = false,
  onClose,
  onEdit,
  onDelete,
}) => {
  const clinicEventService = new ClinicEventsService(visitInfo);
  const gotoCheckIn = useUpdate('GO_TO_CHECK_IN');

  return (
    <FixedComponent className="visit-view-component">
      <FixedComponent.Child>
        <div className="p20 flex gap3 fd-c">
          <div className="flex jc-e">
            <Space size="large">
              <DeleteModalComponent
                text="Delete this visit"
                onConfirm={onDelete}
                isLoading={isSubmitting}
              >
                <Icons.TrashCanIcon />
              </DeleteModalComponent>
              <Icons.EditIcon onClick={onEdit} />
              <Icons.CloseIcon onClick={() => onClose?.()} />
            </Space>
          </div>
          <div>
            <Title level={4}>
              <CalendarVisitTitleComponent
                clinicNumber={visitInfo.clinicInfo?.orgNumber ?? undefined}
                patientName={AccountHelper.getFullName(visitInfo?.patientInfo)}
                visitType={visitInfo.visitType ?? undefined}
              />
            </Title>
            <Text type="secondary">
              <CalendarEventTimeComponent
                startTime={TimezoneService.calcDateTimeDayjs(visitInfo.startTime as string)}
                endTime={TimezoneService.calcDateTimeDayjs(visitInfo.endTime as string)}
                showDay
              />
            </Text>
          </div>
          <div>
            <Title level={5}>Patient</Title>
            {patientInfo?.id && (
              <PatientProfileContainer
                patientId={patientInfo.id}
                showLoadingOverlay={false}
                getEnrolledProgram
                getAssignees
                getControlLevel
                getLastMeasurementDate
              >
                {(pInfo, isLoading) => (
                  <PatientVisitCardComponent
                    isLoading={isLoading}
                    patientInfo={pInfo}
                  />
                )}
              </PatientProfileContainer>
            )}
          </div>
          <VisitInfoComponent
            participants={visitInfo.visitParticipants ?? undefined}
            visitType={visitInfo.visitType ?? undefined}
            visitMethod={visitInfo.visitMethod ?? undefined}
            description={visitInfo.description ?? ''}
            transcribeId={visitInfo.transcribeId ?? undefined}
            transcribeStatus={visitInfo.transcribeStatus ?? undefined}
            isOnGoing={clinicEventService.isOnGoing}
            zoomEntry={clinicEventService.zoomEntry}
          />
        </div>
      </FixedComponent.Child>
      <FixedComponent.Child isFixed>
        <div>
          <Divider className="visit-view-component__divider" />
          <div className="px10 flex jc-sb ai-c">
            <div>
              <VisitsStatusComponent
                visitStatus={visitInfo.visitStatus}
                startTime={ClinicEventsService.calcTime(visitInfo.startTime, false) as Dayjs}
              />
            </div>
            <CTAButtonComponent
              patientId={visitInfo.patientId || ''}
              onClick={() => {
                gotoCheckIn.updateValue();
                onClose?.(true);
              }}
              visitInfo={new ClinicEventsService(visitInfo)}
              onReschedule={onEdit}
            />
          </div>
        </div>
      </FixedComponent.Child>
    </FixedComponent>
  );
};
