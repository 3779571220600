import { ReactNode, useState } from 'react';
import { InsuranceItemComponent } from '../../InsuranceItemComponent/InsuranceItemComponent';
import { InsuranceEligibilityVerifyButtonComponent } from '../../InsuranceEligibilityVerifyButtonComponent/InsuranceEligibilityVerifyButtonComponent';
import TooltipComponent from '../../../../../uiComponent/TooltipComponent/TooltipComponent';
import { InsuranceCardSubmitValue, useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import { LinkButton } from '../../../../../uiComponent/LinkButton/LinkButton';
import { InsuranceEligibilityReportContainer } from '../../../container/InsuranceEligibilityReportContainer/InsuranceEligibilityReportContainer';

export interface InsuranceCardLayoutComponentProps {
  children: ReactNode;
  span?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  required?: boolean;
}

export const InsurancePolicyNumberComponent = ({
  children,
  span = 5,
  required,
}: InsuranceCardLayoutComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <InsuranceItemComponent
      label={insuranceForm.getLabel('policyNumber')}
      content={children}
      span={span}
      required={required}
    />
  );
};

export const InsuranceRelationshipToInsuredComponent = ({
  children,
  span = 5,
  required,
}: InsuranceCardLayoutComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <InsuranceItemComponent
      label={insuranceForm.getLabel('patientRelationship')}
      content={children}
      span={span}
      required={required}
    />
  );
};

export interface InsuranceEligibilityStatusComponentProps
  extends InsuranceCardLayoutComponentProps {
  insurance: Partial<InsuranceCardSubmitValue>;
  showTooltip?: boolean;
}

export const InsuranceEligibilityStatusComponent = ({
  children,
  insurance,
  span = 4,
  showTooltip,
}: InsuranceEligibilityStatusComponentProps) => {
  const insuranceForm = useInsuranceForm();
  const [showDetails, setShowDetails] = useState(false);
  const inquiryId = insurance?.wayStarInquiryId;
  return (
    <>
      <InsuranceItemComponent
        label={(
          <div className="flex ai-c">
            {insuranceForm.getLabel('eligibilityStatus')}
            {
              showTooltip
              && (
                <TooltipComponent
                  type="info-icon"
                  title={insuranceForm.getTooltip('eligibilityStatus')?.tip}
                />
              )
            }
          </div>
        )}
        content={children}
        span={span}
        required
      />
      <InsuranceItemComponent
        label={<span>&nbsp;</span>}
        content={(
          <div className="flex ai-s jc-s gap2">
            {
              inquiryId
              && (
                <LinkButton
                  showUnderline
                  onClick={() => setShowDetails(true)}
                  className="no-style-btn"
                >
                  Details
                </LinkButton>
              )
            }
            <InsuranceEligibilityVerifyButtonComponent
              insurance={insurance}
            />
          </div>
        )}
        ellipsis={false}
      />
      {
        (showDetails && inquiryId)
        && (
          <InsuranceEligibilityReportContainer
            inquiryId={inquiryId}
            onDone={() => setShowDetails(false)}
          />
        )
      }
    </>
  );
};
