import {
  AppFunctions, Nullable, useAppFunctionsGet, useAppFunctionsUpdate
} from '../../../../uc-api-sdk';
import { AppNotificationComponent } from '../../component/AppNotificationComponent/AppNotificationComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { AppNotificationFormComponent } from '../../component/AppNotificationComponent/AppNotificationFormComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';

export interface AppNotificationContainerProp {
  patientId: string;
  onEdit?: () => void;
  isEditing?: boolean;
  showEdit?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const AppNotificationContainer = ({
  patientId,
  isEditing,
  showEdit,
  onEdit,
  onSubmit,
  onCancel,
}: AppNotificationContainerProp) => {
  const fetchHook = useAppFunctionsGet({
    options: {
      sendOnMount: true,
    },
    params: { id: patientId },
  });

  const updateHook = useAppFunctionsUpdate({});

  const initValue: Nullable<AppFunctions> | undefined = fetchHook.data?.data;

  const handleSubmit = (
    submitValue: AppFunctions
  ) => {
    ApiRequestHelper.tryCatch(
      updateHook.send({
        params: {
          id: patientId,
          document: {
            ...submitValue,
          },
        },
      }),
      {
        success: 'App Notification Setting Updated.',
        error: 'Failed to update App Notification Setting!',
        onSuccess: () => {
          onSubmit?.();
          fetchHook.refetch();
        },
      }
    );
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <PatientCommonCardComponent
      title="App Notification Settings"
      isEditing={isEditing}
      onEdit={onEdit}
      showEdit={showEdit}
      updateInfo={fetchHook.data?.data}
      content={(
        <FetchComponent info={fetchHook}>
          <AppNotificationComponent
            initValue={initValue || undefined}
          />
        </FetchComponent>
      )}
      formContent={(
        <LoadingOverlayComponent isLoading={initValue === undefined}>
          <AppNotificationFormComponent
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={initValue || undefined}
            isLoading={updateHook.isLoading}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
