import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { useCreateVisitForm } from '../../../visit/hook/useCreateVisitForm';

export interface WorklistVisitTypeDisplayComponentProps {
  value?: VisitTypeEnum;
  durationUnit?: string;
}

export const WorklistVisitTypeDisplayComponent = ({
  value,
  durationUnit = 'minutes',
}: WorklistVisitTypeDisplayComponentProps) => {
  const { getVisitDuration } = useCreateVisitForm();

  const getText = () => {
    switch (value) {
      case VisitTypeEnum.INIT:
        return 'Initial Assessment Visit';
      case VisitTypeEnum.INITIAL:
        return 'Initial Visit';
      case VisitTypeEnum.FOLLOW_UP:
        return 'Follow-up Visit';
      case VisitTypeEnum.POST_MD_FOLLOW_UP:
        return 'Post-MD Follow-up Visit';
      case VisitTypeEnum.ADDITIONAL: return 'Additional Visit';
      case VisitTypeEnum.TECH_ONBOARDING: return 'Tech-onboarding Visit';
      case VisitTypeEnum.TECH: return 'Tech Support Visit';
      default:
        return (<VisitTypeEnumComponent value={value} />);
    }
  };

  const renderDuration = () => (
    <TextComponent
      color="gray2"
      size="large"
      fontWeight={500}
    >
      (
      {getVisitDuration(value)}
      {' '}
      {durationUnit}
      )
    </TextComponent>
  );

  return (
    <div className="flex ai-e">
      {getText()}
      {' '}
      {renderDuration()}
    </div>
  );
};
