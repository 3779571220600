import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { BehaviorChangeEnum, usePatientUpdate } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { PatientBehaviorChangeEnumComponent } from '../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeEnumComponent/PatientBehaviorChangeEnumComponent';
import { PatientBehaviorChangeFormContentComponent } from '../../../patient/component/PatientBehaviorChangeFormContentComponent/PatientBehaviorChangeFormContentComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { InterventionContainerProps } from '../../type';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { BehaviorChangeTooltipComponent } from '../../component/BehaviorChangeTooltipComponent/BehaviorChangeTooltipComponent';

interface SubmitValue {
  behaviorChangeLevel: BehaviorChangeEnum;
}

export interface BehaviorChangeContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError' | 'patientId'>,
  NestedFormComponentChildProps<SubmitValue> {
  form?: FormInstance;
  visitId?: string;
  info: PatientInfo;
  showButtons?: boolean;
  submitOnChange?: boolean;
}

export const BehaviorChangeContainer = ({
  form,
  disabled,
  visitId,
  isEditing,
  onSubmit,
  onError,
  onValuesChange,
  onEdit,
  info,
  showEdit,
  onCancel,
  showButtons,
  submitOnChange
}: BehaviorChangeContainerProps) => {
  const { behaviorChangeInfo = {} } = info?.patientInfoService || {};
  const { behaviorChangeLevel } = behaviorChangeInfo;
  const updatePatientBehavior = usePatientUpdate({});
  const [newLevel, setNewLevel] = useState<BehaviorChangeEnum>(
    behaviorChangeLevel as BehaviorChangeEnum
  );

  const handleSubmit = useDebounce((newLevel) => {
    const req = updatePatientBehavior.send({
      params: {
        memberId: info.id,
        request: {
          visitId,
          profile: {
            behaviorChangeInfo: {
              behaviorChangeLevel: newLevel,
              isEdit: true,
            }
          }
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Behavior Change Level updated successfully',
      error: 'Failed to update Behavior Change Level',
      onSuccess: () => {
        onSubmit?.();
        info.refetch(['patientRefetch']);
      },
      onError,
    });
  }, 300, [newLevel]);

  const handleOnChange = (value: BehaviorChangeEnum) => {
    const changedValues = { behaviorChangeLevel: value };
    onValuesChange?.(changedValues, changedValues);
    if (submitOnChange) {
      setNewLevel(value);
      handleSubmit(value);
    } else {
      setNewLevel(value);
    }
  };

  useEffect(() => {
    if (behaviorChangeLevel !== newLevel) {
      setNewLevel(behaviorChangeLevel as BehaviorChangeEnum);
    }
  }, [behaviorChangeLevel]);

  return (
    <PatientCommonCardComponent
      title={(
        <div className="flex gap0">
          <span>Stage of Behavior Change</span>
          <TooltipComponent
            title={<BehaviorChangeTooltipComponent />}
            placement="right"
            type="info-icon"
            color="white"
            overlayStyle={{ minWidth: '611px' }}
          />
        </div>
      )}
      showHeaderOutside={false}
      isEditing={isEditing}
      onEdit={onEdit}
      showEdit={showEdit}
      updateInfo={behaviorChangeInfo}
      content={(
        <PatientBehaviorChangeEnumComponent
          value={behaviorChangeLevel as BehaviorChangeEnum}
        />
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={updatePatientBehavior.isLoading}
          showSkeleton
        >
          <Form
            form={form} // this form is to support nested form
            onFinish={() => handleSubmit(newLevel)}
          >
            <PatientBehaviorChangeFormContentComponent
              value={newLevel}
              onChange={handleOnChange}
              onCancel={onCancel}
              onSubmit={() => handleSubmit(newLevel)}
              showButtons={showButtons}
              disabled={disabled}
            />
          </Form>
        </LoadingOverlayComponent>
      )}
    />
  );
};
