import { Form } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthCardFormItemComponent } from './PriorAuthCardFormItemComponent';
import { PriorAuthFormItemLayoutComponent } from './PriorAuthFormItemLayoutComponent';
import { PriorAuthCheckboxFormItemContainer } from './PriorAuthCheckboxFormItemContainer';
import { PriorAuthHistoryComponent } from '../PriorAuthHistoryComponent/PriorAuthHistoryComponent';
import { PriorAuthActionButtonComponent } from '../PriorAuthActionButtonComponent/PriorAuthActionButtonComponent';

export interface PriorAuthFormItemComponentProps {
  patientId: string;
  insuranceId: string;
  primaryProvider?: string;
  primaryProviderId?: string;
  primaryPolicy?: string;
  fieldName?: number;
}

export const PriorAuthFormItemComponent = ({
  patientId,
  insuranceId,
  primaryProvider,
  primaryProviderId,
  primaryPolicy,
  fieldName,
}: PriorAuthFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  if (!patientId) {
    console.error('Patient ID is required');
    return null;
  }

  return (
    <PriorAuthFormItemLayoutComponent>
      <PriorAuthCheckboxFormItemContainer
        form={insuranceForm.form}
        fieldName={fieldName}
      />
      <FormItem
        noStyle
        shouldUpdate={
          insuranceForm.shouldUpdateFromList([
            'priorAuth',
            'priorAuthAuthPeriod',
          ], fieldName)
        }
      >
        {
          ({ getFieldValue }) => {
            const priorAuth = insuranceForm.getObjectValue('priorAuth', getFieldValue, fieldName);
            const priorAuthActiveCase = insuranceForm.getObjectValue('priorAuthActiveCase', getFieldValue, fieldName);
            if (priorAuth) {
              return (
                <div className="prior-auth-card-wrapper">
                  <div className="flex jc-e">
                    <PriorAuthActionButtonComponent
                      patientId={patientId}
                      insuranceId={insuranceId}
                      priorAuth={priorAuthActiveCase}
                      provider={primaryProvider}
                      providerId={primaryProviderId}
                      policyNumber={primaryPolicy}
                    />
                  </div>
                  <PriorAuthCardFormItemComponent
                    form={insuranceForm.form}
                    fieldName={fieldName}
                  />
                </div>
              );
            }
            return <div />;
          }
        }
      </FormItem>
      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthActiveCase')}
        hidden
      />
      <FormItem
        noStyle
        shouldUpdate={insuranceForm.shouldUpdateFromList(['priorAuthHistoryCases'], fieldName)}
      >
        {
          ({ getFieldValue }) => {
            const priorAuthHistoryCases = insuranceForm.getObjectValue('priorAuthHistoryCases', getFieldValue, fieldName);
            return (
              <div className="prior-auth-card-wrapper">
                <PriorAuthHistoryComponent
                  priorAuthCases={priorAuthHistoryCases}
                />
              </div>
            );
          }
        }
      </FormItem>
    </PriorAuthFormItemLayoutComponent>
  );
};
