import { useEffect, useMemo, useState } from 'react';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { EnrollmentActionEnum, LoanDevice, usePatientUpdateEnrollment } from '../../../../uc-api-sdk';
import { SubmitValue } from '../../../patient/hook/useAssignDeviceForm';
import { AssignDeviceFormComponent, AssignDeviceFormComponentProps } from '../../component/AssignDeviceFormComponent/AssignDeviceFormComponent';
import { ConsentFormMissingInfoComponent } from '../../component/ConsentFormMissingInfoComponent/ConsentFormMissingInfoComponent';
import { EnrollmentWrapperComponent } from '../../component/EnrollmentWrapperComponent/EnrollmentWrapperComponent';
import { useEnrollmentProgressContext } from '../../context/EnrollmentProgressContext/EnrollmentProgressContext';
import { EnrollmentSectionsEnum } from '../../context/EnrollmentProgressContext/type';
import { EnrollmentReq } from '../../type';

export interface AssignDevicesFormContainerProps {
  patientInfo: PatientInfo;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const AssignDevicesFormContainer = ({
  patientInfo,
  onSubmit,
  onClose,
}: AssignDevicesFormContainerProps) => {
  const [currValue, setCurrValue] = useState<SubmitValue>();
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});
  const { onSectionDone } = useEnrollmentProgressContext();
  const { send: sendMixpanel } = useMixpanelContext();
  const deviceUpdateHook = useUpdate('DEVICE_MONITOR_UPDATED');
  const deviceAssignUpdateHook = useUpdate('DEVICE_ASSIGNED');
  const vitals = patientInfo.enrollmentInfo?.programEnrollment?.vitalTypes || [];

  const sendReq = (value: EnrollmentReq<SubmitValue>) => {
    const memberId = patientInfo.id;
    const loanDevices: LoanDevice[] | undefined = (
      value?.loanDevice?.deviceId
        ? [{ ...value.loanDevice, memberId }]
        : []
    );
    sendMixpanel({ event: MixpanelEvents.AssignDeviceSubmit });
    const req = programEnrollmentUpdate.send({
      params: {
        memberId,
        request: {
          action: value.action,
          patientDevice: {
            patientTechLevel: value.techLevel,
            deviceDeliveryMethod: value.deliveryMethod,
            deviceList: value.deviceList,
            shippingAddress: value.shippingAddress,
            // if want to save shipping address to patient profile address, uncomment this
            // saveAddress: true,
          },
          loanDeviceRequest: loanDevices,
        },
      },
    });
    return ApiRequestHelper.tryCatch(req, 'Failed to update devices!');
  };

  const initialValues = useMemo<AssignDeviceFormComponentProps['initialValues']>(() => ({
    techLevel: patientInfo.enrollmentInfo?.patientDevice?.patientTechLevel
      ?? undefined,
    deliveryMethod: patientInfo.enrollmentInfo?.patientDevice?.deviceDeliveryMethod
      ?? undefined,
    deviceList: patientInfo.enrollmentInfo?.patientDevice?.deviceList
      ?? undefined,
    shippingAddress: patientInfo.enrollmentInfo?.patientDevice?.shippingAddress
      ?? undefined,
    loanDevice: patientInfo.enrollmentInfo?.loanDeviceRequest?.[0]
      ?? undefined,
  }), [patientInfo]);

  const handleSubmit = async (v: SubmitValue) => {
    const res = await sendReq({ ...v, action: EnrollmentActionEnum.ASSIGN_DEVICE });
    // if success
    if (res !== undefined) {
      deviceUpdateHook.updateValue();
      deviceAssignUpdateHook.updateValue();
      onSectionDone(EnrollmentSectionsEnum.Devices);
      onSubmit?.();
    }
  };

  const handleValuesChange: AssignDeviceFormComponentProps['onValuesChange'] = (_, allValues) => {
    setCurrValue(allValues);
  };

  const handleClose = () => {
    if (currValue) {
      sendReq(currValue);
    }
    onClose?.();
  };

  const { consentService } = patientInfo;

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!consentService.isSigned()) {
      interval = setInterval(() => {
        patientInfo.refetch(['consentRefetch']);
      }, 10000);
    } else {
      // refetch only what assignedDevices form needs
      patientInfo.refetch([
        'patientRefetch',
        'enrollmentInfoRefetch',
      ]);
    }

    return () => {
      clearInterval(interval);
    };
  }, [consentService.isSigned()]);

  return (
    <EnrollmentWrapperComponent
      onClose={handleClose}
    >
      {!consentService.hasConsent() || !consentService.isSigned()
        ? (
          <ConsentFormMissingInfoComponent
            description={(
              <div className="w400">
                You need to enroll patient and collect consent before assign devices.
                This view will refresh automatically after patient signs
                the consent form through the app.
              </div>
            )}
          />
        ) : (
          <AssignDeviceFormComponent
            initialValues={initialValues}
            isLoading={programEnrollmentUpdate.isLoading}
            vitals={vitals}
            hasSmartDevice={!!patientInfo.enrollmentInfo?.programEnrollment?.hasSmartDevice}
            onSubmit={handleSubmit}
            onValuesChange={handleValuesChange}
            onTechLevelSelect={() => onSectionDone(EnrollmentSectionsEnum.TechLevel)}
            onDeliveryMethodSelect={() => onSectionDone(EnrollmentSectionsEnum.DeliveryMethod)}
            onDevicesSelect={() => onSectionDone(EnrollmentSectionsEnum.Devices)}
          />
        )}
    </EnrollmentWrapperComponent>
  );
};
