import { DatePicker, Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { USA_DATE } from '../../../../constants/timeFormat';
import { PriorAuthAuthPeriodFormItemComponent } from '../PriorAuthAuthPeriodFormItemComponent/PriorAuthAuthPeriodFormItemComponent';

export interface PriorAuthApprovalFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthApprovalFormItemComponent = ({
  fieldName,
}: PriorAuthApprovalFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent
        fieldName={fieldName}
      />

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthApprovalDate', fieldName)}
        label={insuranceForm.getLabel('priorAuthApprovalDate')}
        required
      >
        <DatePicker
          format={USA_DATE}
          placeholder={USA_DATE}
        />
      </FormItem>

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthAuthorizationNumber', fieldName)}
        label={insuranceForm.getLabel('priorAuthAuthorizationNumber')}
        required
      >
        <Input />
      </FormItem>

      <PriorAuthAuthPeriodFormItemComponent
        fieldName={fieldName}
        rules={[
          {
            required: true,
            message: <MissingErrorComponent />,
            validator: (_, value) => (
              new Promise((resolve, reject) => {
                if (!value || !value[0] || !value[1]) {
                  reject();
                }
                resolve(true);
              }))
          }
        ]}
      />
    </>
  );
};
