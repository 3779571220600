import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { PatientSummaryRequestParams } from '../types';
import { patientSummaryServices } from '../PatientSummaryServices';

interface PatientSummaryResponse {
 link: string;
}

export const usePatientSummary = <RCN = undefined>(
  configs?: MakeRequestHookConfig<PatientSummaryRequestParams, GetOptions, RCN>,
) => {
  // @ts-ignore
  const baseConfigs: UseRequestFactory<
    PatientSummaryRequestParams,
    PatientSummaryResponse,
    // @ts-ignore
    typeof patientSummaryServices.getPatientSummary,
    GetOptions,
    string
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof patientSummaryServices.getPatientSummary>) => (
      patientSummaryServices.getPatientSummary(...args)
    ),
    ResponseClassCreator: patientSummaryServices.getResponse,
    typeName: 'usePatientSummary',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};
