import { PatientInfo } from '../../../contexts/PatientInfoContext/PatientInfoContext';

export const isOldMobileAppVersion = (
  info: PatientInfo,
  mobileVersion: string
): boolean => {
  let os: string | null = null;
  const phoneOSVersion = info?.patientAppVersion?.mobileInfo?.phoneOSVersion;
  if (phoneOSVersion?.startsWith('i')) {
    os = 'iOS';
  } else if (phoneOSVersion?.startsWith('A')) {
    os = 'Android';
  }

  const patientsVersion = info?.patientAppVersion?.mobileInfo?.appVersion;
  if (!mobileVersion || !os || !patientsVersion) {
    return false;
  }

  const targetVersionStr = mobileVersion.split(';').find(item => item.trim().startsWith(`${os}:`));
  if (!targetVersionStr) {
    return false;
  }

  const targetVersions = targetVersionStr.split(':')[1].trim().split(',').map(version => version.trim());
  if (!targetVersions) {
    return false;
  }
  return !targetVersions?.includes(patientsVersion);
};

export default isOldMobileAppVersion;
