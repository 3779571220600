import { map } from 'lodash';
import { ArticlePushFrequencyEnum } from '../../../../uc-api-sdk';

import { InputType } from '../../../Input/types';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { ArticleFrequencyEnumComponent } from '../../../../enumComponent/AriticleFrequencyEnumComponent/ArticleFrequencyEnumComponent';

export interface ArticleFrequencySelectComponentProps extends
  InputType<ArticlePushFrequencyEnum> {
}

export const ArticleFrequencySelectComponent = ({
  value,
  onChange,
  disabled,
}: ArticleFrequencySelectComponentProps) => (
  <EnumSelectComponent
    options={map(ArticlePushFrequencyEnum)}
    optionRenderer={ArticleFrequencyEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);
