import { InputType } from '../../../Input/types';
import { MedicationComplianceEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { MedicationListReviewEnumComponent } from '../../../../enumComponent/MedicationListReviewEnumComponent/MedicationListReviewEnumComponent';

export interface MedicationListReviewSelectComponentProps
  extends InputType<MedicationComplianceEnum> { }

export const MedicationListReviewSelectComponent = ({
  value,
  onChange,
}: MedicationListReviewSelectComponentProps) => {
  const optionRenderer: EnumSelectComponentProps<MedicationComplianceEnum>['optionRenderer'] = (
    option,
  ) => (
    <MedicationListReviewEnumComponent value={option.value} />
  );

  return (
    <EnumSelectComponent
      value={value}
      onChange={onChange}
      optionRenderer={optionRenderer}
      options={MedicationComplianceEnum}
      allowClear
    />
  );
};
