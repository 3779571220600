import {
  MutableRefObject, useCallback, useRef,
  useState
} from 'react';
import './Resizable.scss';
import classNames from 'classnames';
import { keys } from 'lodash';
import { Size } from '../../uiHooks/useResizeObserver/useResizeObserver';

export interface StyleState {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

export enum ResizeDirection {
  // n = 'n', // not implemented yet
  // nw = 'nw', // not implemented yet
  // w = 'w', // not implemented yet
  // sw = 'sw', // not implemented yet
  // ne = 'ne', // not implemented yet
  s = 's',
  se = 'se',
  e = 'e',
}

export interface ResizableProps {
  children: React.ReactNode;
  disable?: boolean;
  innerRef?: MutableRefObject<HTMLDivElement>;
  className?: string;
  resizeHandleClassName?: string;
  defaultWidth?: number;
  defaultHeight?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  onSizeChange?: (size: Size) => void;
  directions?: ResizeDirection[]
}

export const Resizable = ({
  children,
  disable,
  innerRef,
  className = '',
  resizeHandleClassName = '',
  directions = keys(ResizeDirection) as ResizeDirection[],
  defaultWidth,
  defaultHeight,
  minWidth = 0,
  minHeight = 0,
  maxWidth = 0,
  maxHeight = 0,
  onSizeChange,
}: ResizableProps) => {
  const divRef = innerRef || useRef(null);
  const [
    styleState,
    setStyleState
  ] = useState<StyleState>({
    width: defaultWidth,
    height: defaultHeight,
    top: undefined,
    left: undefined,
    bottom: undefined,
    right: undefined,
  });

  const handleOnMouseDown = useCallback((
    direction: ResizeDirection
  ) => {
    if (disable || !divRef.current) {
      return;
    }
    const handleResizeStart = (e: MouseEvent) => {
      const rect = divRef.current?.getBoundingClientRect() || {
        x: 0,
        y: 0,
        right: 0,
        left: 0,
        width: 0,
        height: 0,
      };
      let computedWidth = rect.width;
      let computedHeight = rect.height;
      let computedTop;
      let computedLeft;
      let computedRight;
      let computedBottom;
      switch (direction) {
        // case ResizeDirection.n: {
        //   computedHeight = rect.bottom - e.clientY;
        //   if (computedHeight > minHeight) {
        //     computedTop = e.clientY;
        //   }
        //   break;
        // }
        // case ResizeDirection.nw: {
        //   computedWidth = rect.right - e.clientX;
        //   computedHeight = rect.bottom - e.clientY;
        //   computedTop = e.clientY;
        //   computedLeft = e.clientX;
        //   break;
        // }
        // case ResizeDirection.w: {
        //   computedWidth = rect.right - e.clientX;
        //   break;
        // }
        // case ResizeDirection.sw: {
        //   computedWidth = rect.right - e.clientX;
        //   computedHeight = e.clientY - rect.top;
        //   computedLeft = e.clientX;
        //   break;
        // }
        // case ResizeDirection.ne: {
        //   computedWidth = e.clientX - rect.left;
        //   computedHeight = rect.bottom - e.clientY;
        //   computedTop = e.clientY;
        //   break;
        // }
        case ResizeDirection.s: {
          computedHeight = e.clientY - rect.top;
          break;
        }
        case ResizeDirection.se: {
          computedWidth = e.clientX - rect.left;
          computedHeight = e.clientY - rect.top;
          break;
        }
        case ResizeDirection.e: {
          computedWidth = e.clientX - rect.left;
          break;
        }
        default:
          break;
      }
      const newWidth = computedWidth > minWidth ? computedWidth : minWidth;
      const newHeight = computedHeight > minHeight ? computedHeight : minHeight;
      setStyleState({
        width: newWidth,
        height: newHeight,
        top: computedTop,
        left: computedLeft,
        bottom: computedBottom,
        right: computedRight,
      });
      onSizeChange?.({ width: newWidth, height: newHeight });
    };
    const handleResizeStop = () => {
      document.removeEventListener('mousemove', handleResizeStart);
      document.removeEventListener('mouseup', handleResizeStop);
    };
    document.addEventListener('mousemove', handleResizeStart);
    document.addEventListener('mouseup', handleResizeStop);
  }, [
    disable,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  ]);

  const renderHandles = () => (
    directions.map((direction) => (
      <div
        className={classNames({
          'resizable-handle': true,
          [`resize-handle-${direction}`]: true,
          [resizeHandleClassName]: !!resizeHandleClassName,
        })}
        onMouseDown={() => handleOnMouseDown(direction)}
        role="button"
        aria-hidden
      />
    ))
  );

  return (
    <div
      ref={divRef}
      className={classNames({
        resizable: true,
        [className]: !!className,
      })}
      style={styleState}
    >
      {children}
      {renderHandles()}
    </div>
  );
};
