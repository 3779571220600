import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from 'antd';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { CHSServices } from '../../../../services/CHSServices/CHSServices';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { MessageHistoryComponent } from '../../component/MessageHistoryComponent/MessageHistoryComponent';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { NotificationStorageKey } from '../../../../services/CHSServices/subscription/useNotificationClient';
import { ClientStatusEnum } from '../../../../lib/notificationClient';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

import './MessageHistoryContainer.scss';

export const MessageHistoryContainer = () => {
  const {
    userMap,
    handleSetMultipleUserMap,
  } = useMessageServicesContext();
  const {
    patientChannel,
    isLoadingPatientChannel,
    patientId,
    getPatientChannelHistoryFirstTime,
    setNotificationTarget,
    setPatientActiveSubscription,
    handleGetMorePatientMessages,
    scrollToBottom,
    publishACKMessage,
  } = useMessagePatientContext();
  const [
    notificationClientStatus
  ] = useSessionStorage(
    NotificationStorageKey.CLIENT_STATUS as string as StorageKeyEnum,
  );
  const isNotificationConnected = notificationClientStatus === ClientStatusEnum.CONNECTED;

  const location = useLocation();
  const { startEvent, endEvent } = useMpTrackingHelper(
    {
      eventStart: MixpanelEvents.ChatStart,
      eventEnd: MixpanelEvents.ChatEnd,
      parent: location.pathname.includes('care-portal/messages') ? MixpanelEventsParents.MessagePage : MixpanelEventsParents.PatientProfile,
      patientId
    }
  );

  const handleLoadMoreMessages = useDebounce((fromTimestamp?: string) => {
    handleGetMorePatientMessages(fromTimestamp);
  }, 100, [handleGetMorePatientMessages]);

  const handlePublishACK = useDebounce(() => {
    publishACKMessage();
  }, 100, [publishACKMessage], { leading: true });

  const handleScrollToBottom = useDebounce((bottomRef) => {
    scrollToBottom(bottomRef);
  }, 150);

  useEffect(() => {
    getPatientChannelHistoryFirstTime();
    startEvent();
    setNotificationTarget(patientId);
    setPatientActiveSubscription();
    return () => {
      endEvent();
      setNotificationTarget('');
      setPatientActiveSubscription(false);
    };
  }, []);

  useDeepCompareEffect(() => {
    // load missing userMap
    const missingPatientIds = new Set();
    const missingEmployeeIds = new Set();
    patientChannel?.messages?.forEach((message) => {
      const { publisher, userRole } = message.payload;
      if (!publisher || userMap[publisher]) return;
      const isPatient = CHSServices.isPatient(userRole);
      if (isPatient) {
        missingPatientIds.add(publisher);
      } else {
        missingEmployeeIds.add(publisher);
      }
    });
    handleSetMultipleUserMap(
      Array.from(missingPatientIds) as string[],
      Array.from(missingEmployeeIds) as string[],
    );
  }, [patientChannel?.messages]);

  useEffect(() => {
    if (patientChannel?.isUnread) {
      handlePublishACK?.();
    }
  }, [patientChannel?.isUnread]);

  return (
    <ErrorBoundaryComponent identifier="MessageHistoryContainer">
      <OverlayLoading loading={isLoadingPatientChannel} />
      {
        !isNotificationConnected
        && (
          <Alert
            message="Message service is disconnected. Please refresh the page."
            className="message-history-container__disconnected-alert bold"
            type="warning"
          />
        )
      }
      <MessageHistoryComponent
        patientChannel={patientChannel}
        onLoadMoreMessages={handleLoadMoreMessages}
        onScrollToBottom={handleScrollToBottom}
      />
    </ErrorBoundaryComponent>
  );
};
