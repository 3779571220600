import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { PatientService } from '../../../../services/PatientService';
import { ControlLevelCardComponent } from '../../../patient/component/ControlLevelCardComponent/ControlLevelCardComponent';
import { WorklistBasicInformationFormContainer } from '../../container/WorklistBasicInformationFormContainer/WorklistBasicInformationFormContainer';
import './SimplifiedWorklistHealthOverviewComponent.scss';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

export interface SimplifiedWorklistHealthOverviewComponentProps {
  patientId: string;
  onSubmit?: () => void;
}

export const SimplifiedWorklistHealthOverviewComponent = ({
  patientId,
  onSubmit,
}: SimplifiedWorklistHealthOverviewComponentProps) => {
  const { info } = usePatientContext();
  const {
    controlLevelService,
    patientInfoService,
  } = info || {};

  return (
    <div className="new-worklist-health-overview">
      <WorklistSectionHeaderComponent
        title="Patient Health Overview"
        className="mb20"
      />
      <WorklistContentComponent>
        <LoadingOverlayComponent isLoading={!patientInfoService}>
          <NestedFormComponent>
            <WorklistBasicInformationFormContainer
              patientId={patientId}
              patientInfoService={patientInfoService as PatientService}
              onSubmit={onSubmit}
            />
          </NestedFormComponent>
          <ControlLevelCardComponent
            patientId={patientId}
            cardInfo={controlLevelService?.getControlLevelInfo() || undefined}
            mode="worklist"
            className="bg-white pt10 pb10 ControlLevelCardComponent-in-worklist"
            onA1CGoalSubmit={() => info?.refetch(['controlLevelRefetch'])}
          />
        </LoadingOverlayComponent>
      </WorklistContentComponent>
    </div>
  );
};
