import { Modal } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  PatientHiatusStatus,
  PatientHiatusStatusEnum,
  PauseServiceInfo,
  usePatientGetEnrolledPrograms,
  usePatientUpsertPatientHiatusStatus,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { PauseServiceViewComponent } from '../../component/PauseServiceViewComponent/PauseServiceViewComponent';
import { PauseServiceFormContainer } from '../PauseServiceFormContainer/PauseServiceFormContainer';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { PauseServiceNamesComponent } from '../../component/PauseServiceComponent/PauseServiceNamesComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PauseServiceViewContainerProps {
  memberId: string;
  onCancel?: () => void;
}

export const PauseServiceViewContainer = ({
  memberId,
  onCancel,
}: PauseServiceViewContainerProps) => {
  const { value: isEditing, setTrue: openEdit, setFalse: closeEdit } = useBoolean();
  const updatePauseService = usePatientUpsertPatientHiatusStatus({});
  const refetchPatientHeader = useUpdate('PATIENT_INFO_UPDATED');
  const updateHook = useUpdate('PAUSE_SERVICE_UPDATED');

  const getEnrolledProgram = usePatientGetEnrolledPrograms({
    params: {
      memberId,
    },
  });

  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};
  const enrolledProgram = getEnrolledProgram.data?.data;

  const resumeAllService = () => {
    const buildResumeRequest = (serviceInfo?: PauseServiceInfo): PauseServiceInfo | null => ({
      status: PatientHiatusStatusEnum.RESUMED,
      isEdit: serviceInfo && !!(serviceInfo?.status !== PatientHiatusStatusEnum.RESUMED),
    });

    let request: PatientHiatusStatus | null = null;
    if (enrolledProgram?.hiatusDetail) {
      const { hiatusDetail } = enrolledProgram;
      request = {
        vitalMonitoring:
          buildResumeRequest(hiatusDetail.vitalMonitoring ?? undefined) ?? undefined,
        visitService:
          buildResumeRequest(hiatusDetail.visitService ?? undefined) ?? undefined,
        billingReport:
          buildResumeRequest(hiatusDetail.billingReport ?? undefined) ?? undefined,
      };
    }

    if (request) {
      const req = updatePauseService.send({
        params: {
          memberId,
          request,
        },
      });
      ApiRequestHelper.tryCatch(req, {
        // todo
        error: 'Services updated Failed',
        success: 'Services updated successfully',
        onSuccess: () => {
          refetchPatientHeader.updateValue();
          // update care note
          updateHook.updateValue();
          onCancel?.();
        },
      });
    }
  };

  const confirmResume = () => {
    if (enrolledProgram && enrolledProgram.hiatusDetail) {
      Modal.confirm({
        title: (
          <div className="inline-block">
            Are you sure to resume
            {' '}
            <PauseServiceNamesComponent
              pauseVitalMonitoring={(enrolledProgramService?.isVitalMonitoringPaused())
                || undefined}
              pauseVisitService={(enrolledProgramService?.isVisitServicePaused()) || undefined}
              pauseBilling={(enrolledProgramService?.isBillingPaused()) || undefined}
            />
            ?
          </div>),
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        onOk: resumeAllService,
      });
    }
  };

  const handleCancelEditForm = () => {
    if (!enrolledProgramService?.isHiatusStatusPaused()) {
      onCancel?.();
    } else {
      closeEdit();
    }
  };

  const handleSubmit = () => {
    closeEdit();
    getEnrolledProgram.refetch();
    updateHook.updateValue();
  };

  return (
    <FetchComponent info={getEnrolledProgram}>
      {!enrolledProgramService?.isHiatusStatusPaused() || isEditing
        ? (
          <PauseServiceFormContainer
            memberId={memberId}
            enrolledProgram={{
              hiatusDetail: enrolledProgram?.hiatusDetail,
            }}
            onResumeAll={confirmResume}
            onCancel={handleCancelEditForm}
            onSubmit={handleSubmit}
          />
        ) : (
          <PauseServiceViewComponent
            enrolledProgram={{
              hiatusDetail: enrolledProgram?.hiatusDetail,
            }}
            onResumeAll={confirmResume}
            onEdit={openEdit}
          />
        )}
    </FetchComponent>
  );
};
