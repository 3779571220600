import { ReactNode } from 'react';
import { filter } from 'lodash';
import { SorterResult } from 'antd/lib/table/interface';
import { TableContextProvider, useTableContext } from '../../../../contexts/TableContext/TableContext';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientCareDetail, Sort$Direction, SortInfo } from '../../../../uc-api-sdk';
import { DefaultPatientCareSorter, PatientCareFilter, patientCareFilterProcessor } from '../../hook/usePatientCareTableOnChange';
import { getOrder } from '../../../../helpers/table';

export interface PatientCareTableContextProps {
  children: ReactNode;
}

export const PatientCareTableContext = ({
  children,
}: PatientCareTableContextProps) => (
  <TableContextProvider<PatientCareDetail, PatientCareFilter>
    params={{
      cachePagination: false,
      name: TableCacheNameEnum.PatientCare,
      sortProcessor: (sorter) => {
        const processedSorter = sorterHelper(sorter);
        filter(processedSorter, (x: SortInfo) => !['medianBp', 'a1cLevel'].includes(x.property || ''));

        const { field, order } = (sorter || {}) as SorterResult<PatientCareDetail>;
        if (order && field === 'medianBp') {
          processedSorter.unshift({
            property: 'controlLevel.bpStatData.sbp',
            direction: getOrder({ order }) as unknown as Sort$Direction,
          });
          return processedSorter;
        }

        if (order && field === 'a1cLevel') {
          processedSorter.unshift({
            property: 'controlLevel.bgStatData.latestA1c',
            direction: getOrder({ order }) as unknown as Sort$Direction,
          });
          return processedSorter;
        }

        if (processedSorter.length === 0) {
          return DefaultPatientCareSorter;
        }
        return processedSorter;
      },
      filterProcessor: patientCareFilterProcessor
    }}
  >
    {children}
  </TableContextProvider>
);

export const usePatientCareTableContext = () => {
  const value = useTableContext<PatientCareDetail, PatientCareFilter>();
  return value;
};
