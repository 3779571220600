import { map } from 'lodash';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import './EnrollmentCareProtocolComponent.scss';
import { PatientProtocolEnum } from '../../../../uc-api-sdk';
import { careProtocols } from './constant';
import { CARE_PROTOCOL_BACKGROUND_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';

export interface CareProtocolComponentProps {
  recommendedProtocol: PatientProtocolEnum;
}

export const EnrollmentCareProtocolComponent = ({
  recommendedProtocol,
}: CareProtocolComponentProps) => {
  const renderTitle = (): string => careProtocols[recommendedProtocol as keyof typeof careProtocols]?.title || 'Missing Protocol Title!';

  const renderContent = () => (
    map(careProtocols[recommendedProtocol as keyof typeof careProtocols]?.content, (v, i) => (
      <li key={i}>{v}</li>
    )) || 'Missing Protocol Content!'
  );

  return (
    <div className="care-protocol mt5">
      <h4>Care Protocol</h4>
      <CardComponent hovered className="care-protocol-card">
        <div className="care-protocol-content">
          <h5 className="care-protocol-title">{renderTitle()}</h5>
          <ul className="care-protocol-list">
            {renderContent()}
          </ul>
          <Icon info={CARE_PROTOCOL_BACKGROUND_ICON} className="care-protocol-icon" />
        </div>
      </CardComponent>
    </div>
  );
};
