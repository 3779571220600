import { map } from 'lodash';
import { ReactNode } from 'react';

export interface DelimitedListComponentProps<T> {
  render: (v: T, idx: number) => ReactNode;
  list?: T[];
  className?: string;
  delimiter?: ReactNode;
  itemClassName?: string;
}

export const DelimitedListComponent = <T, >({
  list,
  render,
  className,
  delimiter = ', ',
  itemClassName,
}: DelimitedListComponentProps<T>) => (
  <div className={className}>
    {
      map(list, (v, idx) => (
        <div key={idx} className={itemClassName}>
          {render(v, idx)}
          {
            (idx !== (list?.length || 0) - 1)
            && delimiter
          }
        </div>
      ))
    }
  </div>
  );
