import { Button } from 'antd';
import classNames from 'classnames';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import useDebounce from '../../hooks/useDebounce/useDebounce';

import './ScalableComponent.scss';

const isScalingClassName = 'is-scalable-scaling';

export interface ScalableComponentProps {
  scaleFactor?: number;
  alwaysShow?: boolean;
  controlBtnSize?: 'small' | 'middle' | 'large';
  children?: React.ReactNode;
}

export const ScalableComponent = ({
  scaleFactor = 1.1,
  alwaysShow,
  children,
  controlBtnSize = 'middle',
}: ScalableComponentProps) => {
  const scalableRef = useRef<HTMLDivElement>(null);

  const removeIsScalingClass = useDebounce((target?: HTMLDivElement | null) => {
    (target as HTMLDivElement)?.classList.remove(isScalingClassName);
  }, 50);

  const handleScale = (isScaleUp: boolean) => {
    if (!scalableRef?.current) {
      return;
    }
    const target = scalableRef.current;
    const targetParent = target.parentElement;
    let currentScale = parseFloat(
      target.style.transform.replace('scale(', '').replace(')', '')
    ) || 1;
    if (isScaleUp) {
      // Zoom in
      currentScale *= scaleFactor;
    } else {
      // Zoom out
      currentScale /= scaleFactor;
      if (currentScale < 1) {
        currentScale = 1;
      }
    }
    if (currentScale !== 1) {
      // After scale, it would take another interaction from user to update scrolling size
      // turn off then turn on overflow for scalable to improve UX
      (targetParent as HTMLDivElement)?.classList.add(isScalingClassName);
    }

    target.style.transform = `scale(${currentScale})`;
    removeIsScalingClass(targetParent as HTMLDivElement);
  };
  return (
    <div
      className={classNames({
        'scalable-wrapper': true,
        'always-show': alwaysShow,
      })}
    >
      <div className="scalable-content__body">
        <div ref={scalableRef} className="scalable-content">
          {children}
        </div>
      </div>
      <div className="scalable-control-buttons">
        <Button
          onClick={() => handleScale(false)}
          size={controlBtnSize}
        >
          <ZoomOutOutlined />
        </Button>
        <Button
          onClick={() => handleScale(true)}
          size={controlBtnSize}
        >
          <ZoomInOutlined />
        </Button>
      </div>
    </div>
  );
};
