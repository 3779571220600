import { ArticlePushFrequencyEnum } from '../../uc-api-sdk';

export interface ArticleFrequencyEnumComponentProps {
  value?: ArticlePushFrequencyEnum;
}

export const ArticleFrequencyEnumComponent = ({
  value,
}: ArticleFrequencyEnumComponentProps) => {
  const getValue = () => {
    switch (value) {
      case ArticlePushFrequencyEnum.WEEKLY: return 'Weekly';
      case ArticlePushFrequencyEnum.MONTHLY: return 'Monthly';
      case ArticlePushFrequencyEnum.NEVER: return 'Never';
      default: return 'Recommended (Default)';
    }
  };

  return (<>{getValue()}</>);
};
