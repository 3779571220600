import { useMemo, useState } from 'react';
import {
  filter, forEach, map, reduce,
} from 'lodash';
import { SelectCoverRangeFormComponent, SelectCoverRangeFormComponentProps, SelectCoverRangeFormSubmitValue } from '../../component/SelectCoverRangeFormComponent/SelectCoverRangeFormComponent';
import { OnCallFor, RoleTypeEnum } from '../../../../uc-api-sdk';
import { useCoworker } from '../../../../hooks/useCoworker/useCoworker';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { useOnCallContext } from '../../context/OnCallContext/OnCallContext';
import { sortHelper } from '../../../oncall/helper/helper';

export interface SelectCoverRangeContainerProps {
  onClose?: () => void;
}

export interface EmployeeInfoWithRole {
  id: string;
  name: string;
  role: RoleTypeEnum;
}

export const SelectCoverRangeContainer = ({
  onClose,
}: SelectCoverRangeContainerProps) => {
  const { userInfo, userId } = useLoggedInUserFromContext();
  const { onOnCallSubmit, onCallFor, onCallGet } = useOnCallContext({ type: 'patientCare' });
  const currentRoles = userInfo?.allRoleTypes || [];
  const [RdCount, setRdCount] = useState(0);
  const [HcCountInRd, setHcCountInRd] = useState(0);
  const [HcCount, setHcCount] = useState(0);
  const [CaCount, setCaCount] = useState(0);
  const userRoleCount = currentRoles
    .filter((x) => [RoleTypeEnum.CA, RoleTypeEnum.RD, RoleTypeEnum.HC].includes(x)).length;

  const onCallRoles = useMemo(() => {
    const roleSet = new Set<RoleTypeEnum>();
    if (currentRoles?.includes(RoleTypeEnum.RD)) {
      roleSet.add(RoleTypeEnum.RD);
      roleSet.add(RoleTypeEnum.HC);
    }
    if (currentRoles?.includes(RoleTypeEnum.HC)) {
      roleSet.add(RoleTypeEnum.HC);
    }
    if (currentRoles?.includes(RoleTypeEnum.CA)) {
      roleSet.add(RoleTypeEnum.CA);
    }
    return Array.from(roleSet);
  }, [currentRoles]);

  const membersSearch = useCoworker({
    roleFilter: onCallRoles,
    limitOnCallRole: true,
    needToFilter: false,
  });

  const sortedData = useMemo(() => sortHelper(
    membersSearch.myCoWorkerList.filter((member) => member.id !== userId)
  ), [membersSearch.myCoWorkerList]);

  const membersWithRolesMap = useMemo(() => {
    const result: Map<RoleTypeEnum, object[]> = new Map();
    if (currentRoles?.includes(RoleTypeEnum.RD)) {
      const membersHCInRD = filter(sortedData, (member) => (
        member.role === RoleTypeEnum.HC
      ));
      const membersRD = filter(sortedData, (member) => (
        member.role === RoleTypeEnum.RD
      ));
      result.set(RoleTypeEnum.RD, membersRD);
      result.set(RoleTypeEnum.HC, membersHCInRD);
      setRdCount(membersRD.length);
      setHcCountInRd(membersHCInRD.length);
    }
    if (currentRoles?.includes(RoleTypeEnum.HC)) {
      const members = filter(sortedData, (member) => (
        member.role === RoleTypeEnum.HC
      ));
      result.set(RoleTypeEnum.HC, members);
      setHcCount(members.length);
    }
    if (currentRoles?.includes(RoleTypeEnum.CA)) {
      const members = filter(sortedData, (member) => (
        member.role === RoleTypeEnum.CA
      ));
      result.set(RoleTypeEnum.CA, members);
      setCaCount(members.length);
    }
    return result;
  }, [
    sortedData,
    setRdCount,
    setCaCount,
    setHcCount,
    setHcCountInRd,
  ]);

  const handleSubmit = async (value: SelectCoverRangeFormSubmitValue) => {
    const onCallForResult: OnCallFor[] = [];
    const RdIds: string[] = [];
    const HcIdsInRd: string[] = [];
    forEach(value.membersRD, (member) => {
      if (member.split(' ')[1] === 'RD') {
        RdIds.push(member.split(' ')[0]);
      }
      if (member.split(' ')[1] === 'HC') {
        HcIdsInRd.push(member.split(' ')[0]);
      }
    });
    if (RdIds.length > 0) {
      onCallForResult.push({
        employeeIds: RdIds,
        role: RoleTypeEnum.RD,
        priority: value.priorityRD ?? undefined,
        allSelected: RdIds.length === RdCount,
      });
    }
    if (HcIdsInRd.length > 0) {
      onCallForResult.push({
        employeeIds: HcIdsInRd,
        role: RoleTypeEnum.HC,
        priority: value.priorityRD ?? undefined,
        allSelected: HcIdsInRd.length === HcCountInRd,
      });
    }
    const HcIds = map(value.membersHC, (member) => (
      member.split(' ')[0]
    ));
    if (HcIds.length > 0) {
      onCallForResult.push({
        employeeIds: HcIds,
        role: RoleTypeEnum.HC,
        priority: value.priorityHC ?? undefined,
        allSelected: HcIds.length === HcCount,
      });
    }
    const CaIds = map(value.membersCA, (member) => (
      member.split(' ')[0]
    ));
    if (CaIds.length > 0) {
      onCallForResult.push({
        employeeIds: CaIds,
        role: RoleTypeEnum.CA,
        priority: value.priorityCA ?? undefined,
        allSelected: CaIds.length === CaCount,
      });
    }

    onOnCallSubmit(onCallForResult, {
      success: 'You\'ve turned On-call Coverage Mode on',
      error: 'Failed to turn On-call Coverage Mode on',
    });
  };

  const initialValues = useMemo<SelectCoverRangeFormComponentProps['initialValues']>(() => {
    if (onCallFor) {
      const initialValue: SelectCoverRangeFormComponentProps['initialValues'] = {
        membersRD: [],
        membersCA: [],
        membersHC: [],
      };
      reduce(onCallFor, (res, curr) => {
        const members = reduce(curr.employeeIds, (r, c) => {
          r.push(`${c} ${curr.role}`);
          return r;
        }, [] as string[]);

        switch (curr.role) {
          case RoleTypeEnum.HC:
            if (!currentRoles.includes(RoleTypeEnum.RD)) {
              res.membersHC = members;
              res.priorityHC = curr.priority ?? undefined;
            } else {
              res.membersRD = res.membersRD?.concat(members);
              res.priorityRD = curr.priority ?? undefined;
            }
            break;
          case RoleTypeEnum.CA:
            res.membersCA = members;
            res.priorityCA = curr.priority ?? undefined;
            break;
          case RoleTypeEnum.RD:
            res.membersRD = members;
            res.priorityRD = curr.priority ?? undefined;
            break;
          default:
            break;
        }
        return res;
      }, initialValue);
      return initialValue;
    }
    return undefined;
  }, [
    onCallFor,
  ]);

  if (onCallFor.length === 0 && onCallGet?.isLoading) {
    return null;
  }

  return (
    <SelectCoverRangeFormComponent
      membersWithRolesMap={membersWithRolesMap}
      multipleRoles={userRoleCount > 1}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
