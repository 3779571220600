import { Spin, TablePaginationConfig } from 'antd';
import { ReactNode, useMemo } from 'react';
import { FetchComponentProvider } from '../../contexts/FetchComponentContext/FetchComponentContext';
import { APIResponse, PageResponse } from '../../uc-api-sdk';
import { OverlayComponent } from '../overlayComponent/OverlayComponent';
import { ErrorBoundaryComponent } from '../ErrorBoundaryComponent/ErrorBoundaryComponent';
import { GeneralConfigs } from '../../configs/GeneralConfig/GeneralConfig';

export interface Info<T = unknown, D = unknown> {
  isLoading: boolean;
  error: Error | undefined;
  data: D | null | undefined;
  refetch: () => void;
  dataObj: T | null | undefined;
  pagination?: TablePaginationConfig | false;
}

export interface FetchComponentStyleProps {
  alwaysShowChildren?: boolean;
  showOnRefetch?: boolean;
  loadingOverlay?: ReactNode;
  errorOverlay?: ReactNode | ((error: Error) => ReactNode);
  emptyOverlay?: ReactNode;
  showLoadingOverlay?: boolean;
  showErrorOverlay?: boolean;
  showEmptyOverlay?: boolean;
  shouldHaveContext?: boolean;
  alwaysShowPagination?: boolean;
}

export interface FetchComponentProps<T = unknown, D = unknown> extends FetchComponentStyleProps {
  children: ((value: T, info: Info<T, D>) => ReactNode) | ReactNode;
  info: Info<T, D>;
}

/**
 * FetchComponent can be used for all req hook
 * the only required props that it needs is the "info"
 * which will get the return value of the request hook
 * and it provides overlays for Loading, null and undefined
 * response, and error messages
 * Note: the overlays can all be overridden
 */
export const FetchComponent = <T, D>({
  children,
  alwaysShowChildren = false,
  showOnRefetch = false,
  info,
  loadingOverlay = <Spin size="large" />,
  errorOverlay = <h2 className="danger">Error!</h2>,
  emptyOverlay = <h2>Empty...</h2>,
  showLoadingOverlay = true,
  showErrorOverlay = true,
  showEmptyOverlay = false,
  shouldHaveContext = true,
  alwaysShowPagination = true,
}: FetchComponentProps<T, D>) => {
  const shouldRenderChildren = alwaysShowChildren
    || (!info.isLoading && !info.error && !!info.dataObj)
    || (showOnRefetch && !!info.dataObj);

  const errorOverlayValue = useMemo(() => {
    if (typeof errorOverlay === 'function' && info.error) {
      return errorOverlay(info.error);
    }
    return errorOverlay as ReactNode;
  }, [errorOverlay, info.error]);

  const getPaginationInfo = () => {
    const response = (info?.data as APIResponse<PageResponse<D | null | undefined>>);
    const paginatedResponse = response?.data;
    if (paginatedResponse?.page !== undefined) {
      const pageTotalSize = paginatedResponse?.totalSize || 0;
      const { defaultPageSize } = GeneralConfigs.pagination;
      if (!alwaysShowPagination && pageTotalSize <= (paginatedResponse?.size || defaultPageSize)) {
        return false;
      }
      const pInfo: TablePaginationConfig = {
        showTotal: ((_total, range) => (
          `${range[0]}-${range[1]} of ${_total}`
        )),
        pageSize: paginatedResponse.size as number,
        current: paginatedResponse.page as number,
        total: paginatedResponse.totalSize as number,
        pageSizeOptions: GeneralConfigs.pagination.defaultPageSizeOptions,
      };
      return pInfo;
    }
    return false;
  };

  const renderChild = () => {
    const newInfo = { ...info };
    newInfo.pagination = getPaginationInfo();
    if (typeof children === 'function') {
      return children(info.dataObj as T, newInfo);
    }
    return children;
  };

  const renderOverlaysAndChild = () => (
    <OverlayComponent
      overlay={loadingOverlay}
      showOverlay={showLoadingOverlay && info.isLoading}
    >
      <OverlayComponent
        overlay={errorOverlayValue}
        showOverlay={showErrorOverlay && !!info.error}
      >
        <OverlayComponent
          overlay={showEmptyOverlay && emptyOverlay}
          showOverlay={!info.isLoading && !info.error && !info.dataObj && showEmptyOverlay}
        >
          <ErrorBoundaryComponent identifier="FetchComponent">
            {shouldRenderChildren && renderChild()}
          </ErrorBoundaryComponent>
        </OverlayComponent>
      </OverlayComponent>
    </OverlayComponent>
  );

  if (shouldHaveContext) {
    return (
      <FetchComponentProvider value={info}>
        {renderOverlaysAndChild()}
      </FetchComponentProvider>
    );
  }
  return renderOverlaysAndChild();
};

export const componentString = `
export interface PaginationInfo {
  pageSize: number;
  current: number;
  total: number;
  showSizeChanger: boolean,
}

export interface Info<T = unknown, D = unknown> {
  isLoading: boolean;
  error: Error | undefined;
  data: D | null | undefined;
  refetch: () => void;
  dataObj: T | null | undefined;
  pagination?: PaginationInfo | false;
}

export interface FetchComponentStyleProps {
  alwaysShowChildren?: boolean;
  showOnRefetch?: boolean;
  loadingOverlay?: ReactNode;
  errorOverlay?: ReactNode | ((error: Error) => ReactNode);
  emptyOverlay?: ReactNode;
  showLoadingOverlay?: boolean;
  showErrorOverlay?: boolean;
  showEmptyOverlay?: boolean;
  shouldHaveContext?: boolean;
}

export interface FetchComponentProps<T = unknown, D = unknown> extends FetchComponentStyleProps {
  children: ((value: T, info: Info<T, D>) => ReactNode) | ReactNode;
  info: Info<T, D>;
}

export const DEFAULT_PAGE_SIZE = 10;

/**
 * FetchComponent can be used for all req hook
 * the only required props that it needs is the "info"
 * which will get the return value of the request hook
 * and it provides overlays for Loading, null and undefined
 * response, and error messages
 * Note: the overlays can all be overridden
 */
export const FetchComponent = <T, D>({
  children,
  alwaysShowChildren = false,
  showOnRefetch = false,
  info,
  loadingOverlay = <Spin size="large" />,
  errorOverlay = <h2 className="danger">Error!</h2>,
  emptyOverlay = <h2>Empty...</h2>,
  showLoadingOverlay = true,
  showErrorOverlay = true,
  showEmptyOverlay = false,
  shouldHaveContext = true,
}: FetchComponentProps<T, D>) => {
  const shouldRenderChildren = alwaysShowChildren
    || (!info.isLoading && !info.error && !!info.dataObj)
    || (showOnRefetch && !!info.dataObj);

  const errorOverlayValue = useMemo(() => {
    if (typeof errorOverlay === 'function' && info.error) {
      return errorOverlay(info.error);
    }
    return errorOverlay as ReactNode;
  }, [errorOverlay, info.error]);

  const getPaginationInfo = () => {
    const response = (info?.data as APIResponse<PageResponse<D | null | undefined>>);
    const paginatedResponse = response?.data;
    if (paginatedResponse?.page !== undefined) {
      if ((paginatedResponse?.totalSize || 0) <= (paginatedResponse?.size || DEFAULT_PAGE_SIZE)) {
        return false;
      }
      const pInfo: PaginationInfo = {
        pageSize: paginatedResponse.size as number,
        current: paginatedResponse.page as number,
        total: paginatedResponse.totalSize as number,
        showSizeChanger: false,
      };
      return pInfo;
    }
    return false;
  };

  const renderChild = () => {
    const newInfo = { ...info };
    newInfo.pagination = getPaginationInfo();
    if (typeof children === 'function') {
      return children(info.dataObj as T, newInfo);
    }
    return children;
  };

  const renderOverlaysAndChild = () => (
    <OverlayComponent
      overlay={loadingOverlay}
      showOverlay={showLoadingOverlay && info.isLoading}
    >
      <OverlayComponent
        overlay={errorOverlayValue}
        showOverlay={showErrorOverlay && !!info.error}
      >
        <OverlayComponent
          overlay={showEmptyOverlay && emptyOverlay}
          showOverlay={!info.isLoading && !info.error && !info.dataObj && showEmptyOverlay}
        >
          <ErrorBoundaryComponent>
            {shouldRenderChildren && renderChild()}
          </ErrorBoundaryComponent>
        </OverlayComponent>
      </OverlayComponent>
    </OverlayComponent>
  );

  if (shouldHaveContext) {
    return (
      <FetchComponentProvider value={info}>
        {renderOverlaysAndChild()}
      </FetchComponentProvider>
    );
  }
  return renderOverlaysAndChild();
};
`;
