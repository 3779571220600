import { DatePicker, Form } from 'antd';
import { useEffect } from 'react';
import moment from 'moment';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface PriorAuthSubmissionDateFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthSubmissionDateFormItemComponent = ({
  fieldName,
}: PriorAuthSubmissionDateFormItemComponentProps) => {
  const parentForm = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form: parentForm });
  const status = Form.useWatch(
    insuranceForm.makeObjectNamePath('priorAuthStatus', fieldName),
    parentForm,
  );

  useEffect(() => {
    if (!status) return;
    const initialValue = insuranceForm.getObjectValue(
      'priorAuthSubmissionDate',
      insuranceForm.form.getFieldValue,
      fieldName,
    );
    if (!initialValue) {
      const today = moment();
      insuranceForm.form.setFieldValue(
        insuranceForm.makeObjectNamePath('priorAuthSubmissionDate', fieldName),
        today,
      );
    }
  }, [status]);

  return (
    <FormItem
      name={insuranceForm.makeListNamePath('priorAuthSubmissionDate', fieldName)}
      label={insuranceForm.getLabel('priorAuthSubmissionDate')}
    >
      <DatePicker
        format={USA_DATE}
        placeholder={USA_DATE}
      />
    </FormItem>
  );
};
