import { flatten } from 'lodash';
import { FiltersValueType } from '../../../contexts/TableFiltersSorterContext/TableFiltersSorterContext';
import { Filter } from '../../../hooks/useTableChange/useTableChange';
import {
  ControlLevelEnum,
  InFilter,
  Nullable,
  PatientCareItemEnum,
  PatientTypeEnum,
  PriorityLevelEnum,
  ProgramCategorySearchFilterEnum,
  SortInfo,
  VitalEnumType,
  WorkTypeEnum
} from '../../../uc-api-sdk';

export interface PatientCareFilter {
  programsIn?: Nullable<InFilter<ProgramCategorySearchFilterEnum>>;
  patientTypeIn?: Nullable<InFilter<PatientTypeEnum>>;
  clinicIdIn?: Nullable<InFilter<string>>;
  workTypesIn?: Nullable<InFilter<WorkTypeEnum>>;
  doctorIdIn?: Nullable<InFilter<string>>;
  itemsIn?: Nullable<InFilter<PatientCareItemEnum>>;
  vitalsIn?: Nullable<InFilter<VitalEnumType>>;
  priorityIn?: Nullable<InFilter<PriorityLevelEnum>>;
  idIn?: Nullable<InFilter<string>>;
  includeTestPatient?: Nullable<boolean>;
  controlLevelIn?: Nullable<InFilter<ControlLevelEnum>>;
}

export const DefaultPatientCareSorter: SortInfo[] = [];

export const patientCareFilterProcessor = (
  filters: Filter<string>,
  updateFilters?: (f: FiltersValueType) => void
) => {
  const fil: PatientCareFilter = {};
  if (filters.firstName?.length) {
    fil.idIn = {
      in: filters.firstName as string[]
    };
  }
  if (filters.programs?.length) {
    fil.programsIn = {
      in: filters.programs as ProgramCategorySearchFilterEnum[]
    };
  }
  if (filters.vitals?.length) {
    fil.vitalsIn = {
      in: filters.vitals as VitalEnumType[]
    };
  }

  const flattenDoctorIds = flatten(filters.doctorId);
  fil.doctorIdIn = flattenDoctorIds.length > 0
    ? { in: flattenDoctorIds as unknown as string[] } : null;

  if (filters.clinicId) {
    fil.clinicIdIn = {
      in: (
        typeof filters.clinicId[0] === 'object'
          ? filters.clinicId[0]
          : filters.clinicId as unknown as string[]
      )
    };
  }
  if (filters.workTypes) {
    fil.workTypesIn = {
      in: filters.workTypes as WorkTypeEnum[],
    };
  }
  if (filters.items?.length) {
    fil.itemsIn = {
      in: filters.items as PatientCareItemEnum[]
    };
  }
  if (filters.priority?.length) {
    fil.priorityIn = {
      in: filters.priority as PriorityLevelEnum[]
    };
  }
  if (filters.patientType?.length) {
    fil.patientTypeIn = {
      in: filters.patientType as PatientTypeEnum[]
    };
  }
  if (filters.includeTestPatient) {
    fil.includeTestPatient = filters.includeTestPatient as unknown as boolean;
  }
  if (filters.controlLevel?.length) {
    fil.controlLevelIn = {
      in: filters.controlLevel as ControlLevelEnum[]
    };
  }
  updateFilters?.(fil as FiltersValueType);
  return fil;
};
