import { Button } from 'antd';
import { useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { DiseaseHistoryConditionAutoCompleteComponent, DiseaseHistoryConditionAutoCompleteComponentProps } from '../DiseaseHistoryConditionAutoCompleteComponent.tsx/DiseaseHistoryConditionAutoCompleteComponent';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';

interface AddChronicDiseaseValue {
  chronicDisease?: string;
  associatedEnum?: ChronicDiseaseEnum;
}

export interface DiseaseHistoryAddAnotherConditionComponentProps {
  onAdd: (chronicDisease: string, associatedEnum?: ChronicDiseaseEnum) => void;
  onCancel: () => void;
  disabledOptions?: DiseaseHistoryConditionAutoCompleteComponentProps['disabledOptions'];
}

export const DiseaseHistoryAddAnotherConditionComponent = ({
  onAdd,
  onCancel,
  disabledOptions,
}: DiseaseHistoryAddAnotherConditionComponentProps) => {
  const [value, setValue] = useState<AddChronicDiseaseValue>();

  const handleOnAdd = useDebounce(() => {
    const {
      chronicDisease,
      associatedEnum
    } = value || {};
    if (chronicDisease) onAdd(chronicDisease, associatedEnum);
  }, 300, [value?.chronicDisease, value?.associatedEnum], { leading: true });

  const handleOnChange: DiseaseHistoryConditionAutoCompleteComponentProps['onChange'] = (
    chronicDisease,
    associatedEnum,
  ) => {
    setValue({ chronicDisease, associatedEnum });
  };

  return (
    <div>
      <span>
        Name of condition
      </span>
      <div className="flex">
        <DiseaseHistoryConditionAutoCompleteComponent
          onChange={handleOnChange}
          disabledOptions={disabledOptions}
        />
        <Button
          type="primary"
          ghost
          disabled={!value}
          onClick={handleOnAdd}
          className="ml10"
        >
          Add Condition
        </Button>
        <Button
          type="text"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
