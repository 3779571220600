import { get } from 'lodash';
import { USA_DATE } from '../../../../constants/timeFormat';
import { AccountHelper } from '../../../../helpers/account';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { ColumnType } from '../../../../types/table';
import { EmployeeStatus, PatientResult } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { createPatientColumns } from '../../../patient/component/PatientsTableComponent/columns';
import { PatientNameComponent } from '../../../patient/component/PatientsTableComponent/PatientNameComponent';
import { createMultiProviderSearchFilter } from '../../../table/filter/MultiProviderSearchFilter';
import { createPatientSearchFilter } from '../../../table/filter/PatientSearchFilter';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

// dataIndex used in sorter(field)
// API require sort: {property: field, ...}
export const DataIndexBPDevice = 'bpDeviceInfo.recentUsedDeviceMacId';
export const DataIndexBPPairDate = 'bpDeviceInfo.pairedTime';
export const DataIndexBPDeviceLastUsed = 'bpDeviceInfo.recentUsedTime';
export const DataIndexBGDevice = 'bgDeviceInfo.recentUsedDeviceMacId';
export const DataIndexBGPairDate = 'bgDeviceInfo.pairedTime';
export const DataIndexBGDeviceLastUsed = 'bgDeviceInfo.recentUsedTime';

const renderDate = (
  dateString?: string | null,
  timezone?: string | null,
) => (
  !dateString
    ? <EmptyComponent />
    : (
      TimezoneService
        .calcDateTimeDayjs(dateString, timezone ?? undefined)
        .format(USA_DATE)
    )
);

export const createProviderViewPatientColumns = {
  ...createPatientColumns,
  name: (selectedOrgId?: string): ColumnType<PatientResult> => ({
    title: 'Patient',
    dataIndex: 'profile.firstName',
    key: 'id',
    ...createPatientSearchFilter({ selectedOrgId }),
    render: (value, record) => (
      <PatientNameComponent
        firstName={record.profile?.firstName}
        lastName={record.profile?.lastName}
        id={record.id || ''}
      />
    ),
    width: 180,
    sorter: true,
  }),
  provider: (
    selectedOrgId?: string,
    isDoctor?: boolean,
  ): ColumnType<PatientResult> => ({
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    width: 180,
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(rec.profile?.doctorUser)}
      />
    ),
    ...createMultiProviderSearchFilter({
      containerId: 'patientTableProviderSearchContainer',
      needToFilter: true,
      clinicIdList: selectedOrgId ? [selectedOrgId] : undefined,
      statusListFilter: isDoctor ? undefined : [EmployeeStatus.ACTIVATED, EmployeeStatus.PENDING]
    }),
  }),
  insuranceProvider: (): ColumnType<PatientResult> => ({
    key: 'insuranceProvider',
    title: 'Insurance Provider',
    dataIndex: ['profile', 'insuranceProvider'],
    render: (value) => (
      <DisplayOrEmptyComponent value={value} />
    ),
  }),
  bpDeviceMacId: (): ColumnType<PatientResult> => ({
    key: 'bpDeviceMacId',
    title: 'BP Device',
    dataIndex: DataIndexBPDevice,
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={get(rec, DataIndexBPDevice)}
      />
    ),
  }),
  bpPairDate: (): ColumnType<PatientResult> => ({
    key: 'bpPairDate',
    title: 'BP Pair Date',
    dataIndex: DataIndexBPPairDate,
    sorter: true,
    render: (v, rec) => (
      renderDate(
        get(rec, DataIndexBPPairDate),
        rec?.clinic?.timezone
      )
    ),
  }),
  bpDeviceLastUsed: (): ColumnType<PatientResult> => ({
    key: 'bpDeviceLastUsed',
    title: 'BP Last Used Date',
    dataIndex: DataIndexBPDeviceLastUsed,
    sorter: true,
    render: (v, rec) => (
      renderDate(
        get(rec, DataIndexBPDeviceLastUsed),
        rec?.clinic?.timezone
      )
    ),
  }),
  bgDeviceMacId: (): ColumnType<PatientResult> => ({
    key: 'bgDeviceMacId',
    title: 'BG Device',
    dataIndex: DataIndexBGDevice,
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={get(rec, DataIndexBGDevice)}
      />
    ),
  }),
  bgPairDate: (): ColumnType<PatientResult> => ({
    key: 'bgPairDate',
    title: 'BG Pair Date',
    dataIndex: DataIndexBGPairDate,
    sorter: true,
    render: (v, rec) => (
      renderDate(
        get(rec, DataIndexBGPairDate),
        rec?.clinic?.timezone
      )
    ),
  }),
  bgDeviceLastUsed: (): ColumnType<PatientResult> => ({
    key: 'bgDeviceLastUsed',
    title: 'BG Last Used Date',
    dataIndex: DataIndexBGDeviceLastUsed,
    sorter: true,
    render: (v, rec) => (
      renderDate(
        get(rec, DataIndexBGDeviceLastUsed),
        rec?.clinic?.timezone
      )
    ),
  }),
};
