import TimezoneService from '../helpers/timezone/timezoneService';
import EmployeeInfo from '../hooks/useUserInfo/employeeInfo';
import { HealthCondition } from '../uc-api-sdk';

interface PatientHealthConditionInfo {
  healthCondition: HealthCondition;
}

export class PatientHealthConditionService {
  private healthCondition: HealthCondition;

  constructor(info: PatientHealthConditionInfo) {
    this.healthCondition = info.healthCondition;
  }

  get healthConditions() {
    return this.healthCondition.healthConditions || [];
  }

  get updatedAt() {
    return this.healthCondition.updatedAt;
  }

  get dayjsUpdatedAt() {
    const { updatedAt } = this.healthCondition;
    if (!updatedAt) return undefined;
    return TimezoneService.calcDateTimeDayjs(updatedAt);
  }

  get updatedByName() {
    const updatedByUser = this.healthCondition.updatedByUser || undefined;
    return EmployeeInfo.getFullNameWithTitle(updatedByUser);
  }

  get icdCodes() {
    return this.healthConditions
      ?.map((condition) => condition.code)
      .filter((code): code is string => !!code) ?? [];
  }
}
