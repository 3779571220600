import { useMemo } from 'react';
import { map } from 'lodash';
import { SimplifiedWorklistGoalsSetupComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistGoalsSetupComponent';
import { SimplifiedWorklistSocialSupportComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistSocialSupportComponent';
import { SimplifiedWorklistMedicationComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistMedicationComponent';
import { SimplifiedWorklistHealthOverviewComponent } from '../SimplifiedWorklistHealthOverviewComponent/SimplifiedWorklistHealthOverviewComponent';
import { SimplifiedWorklistNextVisitComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistNextVisitComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { SimplifiedWorklistDiagnosesComponent } from '../SimplifiedWorklistSectionComponent/SimplifiedWorklistDiagnosesComponent';

export interface MDFollowUpWorklistSectionComponentProps {
  patientId: string;
}

export const MDFollowUpWorklistSectionComponent = ({
  patientId,
}: MDFollowUpWorklistSectionComponentProps) => {
  const items = useMemo(() => ([
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistDiagnosesComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistGoalsSetupComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistSocialSupportComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistMedicationComponent
          patientId={patientId}
          itemNumber={itemNumber}
        />
      )
    },
    {
      render: (itemNumber: number) => (
        <SimplifiedWorklistNextVisitComponent
          itemNumber={itemNumber}
          visitType={VisitTypeEnum.POST_MD_FOLLOW_UP}
        />
      )
    }
  ]), []);

  return (
    <>
      <SimplifiedWorklistHealthOverviewComponent
        patientId={patientId}
      />
      {
        map(items, (item, index) => (
          <div key={index}>
            {item.render(index + 1)}
          </div>
        ))
      }
    </>
  );
};
