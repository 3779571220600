import { RightOutlined } from '@ant-design/icons';
import {
  Button, Card, Form, FormProps
} from 'antd';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';
import {
  PatientDiagnosesEnum,
  ProgramCategoryEnum,
  VitalEnumType,
  PatientProtocolEnum,
  HealthConditionItem,
  PatientComplexity,
} from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { FormType } from '../../../Input/types';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { EnrollmentBasicInfoComponent } from '../EnrollmentBasicInfoComponent/EnrollmentBasicInfoComponent';
import { EnrollmentConsentFormAlertComponent } from '../EnrollmentConsentFormAlertComponent/EnrollmentConsentFormAlertComponent';
import { HaveSmartPhoneRadioComponent } from '../HaveSmartPhoneRadioComponent/HaveSmartPhoneRadioComponent';
import { JoinProgramRadioComponent } from '../JoinProgramRadioComponent/JoinProgramRadioComponent';
import { PatientVitalsAndProgramsComponent } from '../PatientVitalsAndProgramsComponent/PatientVitalsAndProgramsComponent';
import { RejectedAlertComponent } from '../RejectedAlertComponent/RejectedAlertComponent';
import { RejectReasonInputComponent } from '../RejectReasonInputComponent/RejectReasonInputComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { InsuranceSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';
import { InsuranceFormContainer } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';
import { useInsuranceBlockPrompt } from '../../../Insurance/hook/useInsuranceBlockPrompt';
import { IntroduceUnifiedCareBtnComponent } from '../IntroduceUnifiedCareBtnComponent/IntroduceUnifiedCareBtnComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface SubmitValue extends InsuranceSubmitValue {
  diagnoses?: PatientDiagnosesEnum[];
  icdTable?: HealthConditionItem[] | null;
  complexity?: PatientComplexity | null;
  vitals?: VitalEnumType[];
  programs?: ProgramCategoryEnum[];
  wouldJoin?: boolean;
  notJoiningReason?: string;
  provider?: string;
  spokenLanguage?: string[];
  username?: string;
  mobilePhoneNum?: string;
  homePhoneNum?: string;
  countryCode?: string;
  appOTPLogin?: boolean;
  hasSmartPhone?: boolean;
  clinicId?: string;
}

export interface ProgramEnrollmentFormComponentBasePros {
  patientInfo: PatientInfo;
  onOpenConsentForm?: () => void;
  isVitalsAndProgramsDone?: boolean;
  onVitalsAndProgramsDone?: () => void;
}

export interface ProgramEnrollmentFormComponentProps extends
  Omit<FormType<SubmitValue>, 'onValuesChange'>,
  ProgramEnrollmentFormComponentBasePros {
  onReject: (value: SubmitValue) => void;
  refetch?: () => void;
  patientId: string;
  onValuesChange?: (
    changedValues: Partial<SubmitValue>,
    allValues?: SubmitValue
  ) => void;
}

export const ProgramEnrollmentFormComponent = ({
  patientId,
  initialValues,
  onSubmit,
  onReject,
  refetch,
  onValuesChange,
  patientInfo,
  isLoading,
  onOpenConsentForm,
  isVitalsAndProgramsDone,
  onVitalsAndProgramsDone,
}: ProgramEnrollmentFormComponentProps) => {
  const {
    medicalOrganizationService,
    isLoading: medicalOrgLoading,
    fetchMedicalOrganization,
  } = usePatientMedicalOrganizationContext();
  const medicalOrgAvailablePrograms = (
    medicalOrgLoading
      ? undefined
      : medicalOrganizationService?.availablePrograms()
  );
  const isPatientEnrolled = patientInfo.enrollmentService.isEnrolled();
  const isPatientRejected = patientInfo.enrollmentService.isRejected();
  // The current correct status is obtained from enrolledProgramService.
  // The patientInfo.enrollmentService is solely utilized for flow of patient enrollment.
  // The status is specifically confined to the Enrollment page.
  const isDischarged = patientInfo.enrolledProgramService?.isDischarged?.();
  const isEnrolled = patientInfo.enrolledProgramService?.isEnrolled?.();
  const associatedList = patientInfo.patientInfoService?.patientAssociatedClinics;

  const peForm = useProgramEnrollmentForm();
  const { form } = peForm;
  const programs = peForm.getValue('programs', peForm.form.getFieldValue);
  const [eligiblePrograms, setEligiblePrograms] = useState<ProgramCategoryEnum[]>([]);
  const { value: valid, setTrue, setFalse } = useBoolean(true);
  const {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt,
  } = useInsuranceBlockPrompt();
  const diagnoses = Form.useWatch(peForm.getName('diagnoses'), peForm.form);

  useUpdateListener('INSURANCE_UPDATED', () => patientInfo.refetch(['patientInsuranceRefetch']));

  const updateEligiblePrograms = useDebounce(async (diagnoses?: PatientDiagnosesEnum[]) => {
    if (diagnoses) {
      const { vitals = [], programs = [] } = await peForm.getPrefilledValues(diagnoses);
      setEligiblePrograms(programs as ProgramCategoryEnum[]);

      form.setFieldsValue({
        vitals: compact(vitals) as VitalEnumType[],
        programs: compact(programs) as ProgramCategoryEnum[],
      });
    }
  });

  const onFormChange: FormType<SubmitValue>['onValuesChange'] = async (changedValues, allValues) => {
    let newChangedValues = { ...changedValues };
    if (peForm.getName('clinicId') in changedValues) {
      const { diagnoses = [] } = allValues;
      const { vitals = [], programs = [] } = await peForm.getPrefilledValues(diagnoses);
      setEligiblePrograms(programs as ProgramCategoryEnum[]);

      const newVitalsPrograms = {
        vitals: compact(vitals) as VitalEnumType[],
        programs: compact(programs) as ProgramCategoryEnum[],
      };
      form.setFieldsValue(newVitalsPrograms);
      newChangedValues = { ...newChangedValues, ...newVitalsPrograms };
    }
    onValuesChange?.(newChangedValues, allValues);
  };

  const handleSubmit: FormProps['onFinish'] = (
    v: SubmitValue
  ) => {
    if (isInsuranceEdit) {
      insuranceBlockPrompt();
      return;
    }
    if (v.wouldJoin) {
      onSubmit?.(v);
    } else {
      onReject?.(v);
    }
  };

  useEffect(() => {
    updateEligiblePrograms(diagnoses);
  }, [diagnoses]);

  const renderPatientDiagnoses = () => (
    <div className="ml10">
      {!isDischarged && (
        <div>
          {isPatientRejected
            ? (
              <div className="mb20">
                <RejectedAlertComponent />
              </div>
            ) : (
              <div className="mb20">
                <EnrollmentConsentFormAlertComponent
                  patientInfo={patientInfo}
                  onSignConsent={onOpenConsentForm}
                  onResign={refetch}
                />
              </div>
            )}
        </div>
      )}
      <div className="ta-r">
        <IntroduceUnifiedCareBtnComponent />
      </div>
      <div className="mb20">
        <TextComponent
          className="mb5"
          size="large"
          bold
        >
          Diagnosis / ICD code
        </TextComponent>
        <Card>
          <DiagnosisIcdCodeFormContainer
            form={form}
            initialValues={{
              icdTable: initialValues?.icdTable ?? undefined,
            }}
            patientId={patientId}
            formButtons={null}
          />
        </Card>
      </div>
      <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['diagnoses', 'vitals', 'programs'])}>
        {({ getFieldValue }) => {
          const show = peForm.shouldVitalsAndPrograms(getFieldValue);

          const updatedProtocol = (): PatientProtocolEnum => {
            if (diagnoses?.includes(PatientDiagnosesEnum.DM2)) {
              return PatientProtocolEnum.DM2;
            }
            if (diagnoses?.includes(PatientDiagnosesEnum.HTN)) {
              return PatientProtocolEnum.HTN;
            }
            return PatientProtocolEnum.OTHER;
          };

          return show ? (
            <>
              <PatientVitalsAndProgramsComponent
                isEnrolled={isEnrolled}
                clinicProgramParticipation={medicalOrgAvailablePrograms}
                isSaasModel={medicalOrganizationService?.isSaasModel}
                eligiblePrograms={eligiblePrograms}
                onClinicChange={fetchMedicalOrganization}
                associatedList={associatedList}
                onValuesChange={onValuesChange}
                recommendedProtocol={updatedProtocol()}
              />
              {!isVitalsAndProgramsDone && (
                <Button
                  className="mt20"
                  type="primary"
                  disabled={programs?.length === 0}
                  onClick={onVitalsAndProgramsDone}
                >
                  Confirm
                </Button>
              )}
            </>
          ) : null;
        }}
      </FormItem>
    </div>
  );

  const renderInsuranceAndJoin = () => (
    <div className="mt20">
      <div className="my15">
        <h4>Insurance</h4>
        <InsuranceFormContainer
          patientInfo={patientInfo.patientInfo || {}}
          onEdit={setIsInsuranceEdit}
        />
      </div>
      <FormItem noStyle info={peForm.getInfo('wouldJoin')}>
        <JoinProgramRadioComponent />
      </FormItem>
      <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin'])}>
        {({ getFieldValue }) => {
          const show = peForm.shouldShowNotJoinReason(getFieldValue);
          return show ? (
            <FormItem
              info={peForm.getInfo('notJoiningReason')}
              className="mt20"
              required
            >
              <RejectReasonInputComponent />
            </FormItem>
          ) : null;
        }}
      </FormItem>
    </div>
  );

  const renderBasicInfo = () => (
    <div className="mt20">
      <h4>Confirm Basic Information</h4>
      <EnrollmentBasicInfoComponent
        form={form}
        patientId={patientId}
        setValid={setTrue}
        setInvalid={setFalse}
      />
      <FormItem noStyle info={peForm.getInfo('hasSmartPhone')}>
        <HaveSmartPhoneRadioComponent />
      </FormItem>
      <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin', 'hasSmartPhone'])}>
        {({ getFieldValue }) => {
          const show = peForm.shouldShowNoSmartPhoneWarning(getFieldValue);
          return show ? (
            <p className="text-red mt10">
              * Patient will only be able to choose non-App solution
            </p>
          ) : null;
        }}
      </FormItem>
    </div>
  );

  const renderSubmitButton = () => (
    <FormItem
      noStyle
      shouldUpdate={peForm.shouldUpdate(['wouldJoin', 'hasSmartPhone', 'programs'])}
    >
      {({ getFieldValue }) => {
        const isJoining = peForm.getValue('wouldJoin', getFieldValue);
        const lastFieldHasValue = peForm.hasValue('hasSmartPhone', getFieldValue);
        const programs = peForm.getValue('programs', getFieldValue) || [];
        if (isJoining === false || isPatientEnrolled) {
          return (
            <Button
              type="primary"
              htmlType="submit"
              disabled={programs?.length === 0 || !valid}
            >
              Submit
            </Button>
          );
        }
        if (lastFieldHasValue) {
          return (
            <div className="flex jc-c ali-c">
              <Button
                className="input-med"
                type="primary"
                htmlType="submit"
                disabled={programs?.length === 0 || !valid}
              >
                Enroll Patient
                {' '}
                <RightOutlined />
              </Button>
            </div>
          );
        }
        return null;
      }}
    </FormItem>
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={onFormChange}
      onFinish={handleSubmit}
      disabled={isLoading}
      layout="vertical"
      className="flex h100"
    >
      <FixedComponent className="f1">
        <FixedComponent.Child>
          {renderPatientDiagnoses()}
          {isVitalsAndProgramsDone && renderInsuranceAndJoin()}
          <FormItem noStyle shouldUpdate={peForm.shouldUpdate(['wouldJoin'])}>
            {({ getFieldValue }) => {
              const show = peForm.shouldShowBasicInformation(getFieldValue);
              return show ? renderBasicInfo() : null;
            }}
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="pt30">
          {renderSubmitButton()}
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
