import { map } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { BOTextComponent } from '../BOTextComponent/BOTextComponent';
import { LogbookCellComponent } from '../LogbookCellComponent/LogbookCellComponent';
import { LogbookComponentProps } from '../LogbookComponent/LogbookComponent';
import { LogbookModalComponent } from '../LogbookComponent/LogbookModalComponent';
import { LogbookInfoComponent } from '../LogbookInfoComponent/LogbookInfoComponent';
import { BOMeasurementService } from '../../helper/BOMeasurementService';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface BOTableCellComponentProps {
  data: BOMeasurementService[];
}

export const BOTableCellComponent: FC<BOTableCellComponentProps> = ({
  data,
}) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean();

  const children = useMemo(() => map(data, (v, i) => (
    <LogbookCellComponent
      key={i}
      text={(
        <BOTextComponent
          bloodOxygen={v.bloodOxygen.value}
          perfusionIndex={v.perfusionIndex.value}
        />
      )}
      color={v.severityColor}
      hasNote={!!v.userNote}
    />
  )), [data]);

  const renderTab = useCallback<LogbookComponentProps<BOMeasurementService>['renderTab']>((dataValue, isSelected, onSelect) => (
    <LogbookCellComponent
      color={dataValue.severityColor}
      text={(
        <BOTextComponent
          bloodOxygen={dataValue.bloodOxygen.value}
          perfusionIndex={dataValue.perfusionIndex.value}
        />
      )}
      hasNote={!!dataValue.userNote}
      onClick={onSelect}
      reverseColor={!isSelected}
    />
  ), []);

  const renderLogbookInfo = useCallback<LogbookComponentProps<BOMeasurementService>['renderLogbookInfo']>((dataValue) => (
    <LogbookInfoComponent
      vitalType={VitalEnumType.PO}
      hadActivity={dataValue.hadActivity}
      hadInsulin={dataValue.hadInsulin}
      hadMedicine={dataValue.hadMedicine}
      moodTags={dataValue.moodTags || []}
      patientNote={dataValue.userNote}
    />
  ), []);

  const renderTitle = useCallback<LogbookComponentProps<BOMeasurementService>['renderTitle']>((dataValue) => (
    TimezoneService.getTimeOfDayText(dataValue.timeOfDay)
  ), []);

  return (
    <>
      <LogbookModalComponent<BOMeasurementService>
        open={open}
        onCancel={closeModal}
        logbookProps={{
          data,
          renderLogbookInfo,
          renderTitle,
          renderTab,
        }}
      />
      <LogbookCellComponent.Group onClick={openModal}>
        {children}
      </LogbookCellComponent.Group>
    </>
  );
};
