import {
  Button,
  Form, Space
} from 'antd';
import { ReactNode, useState } from 'react';
import { MedicationContextProvider } from '../../../../contexts/MedicationContext/MedicationContext';
import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import {
  Nullable,
  MedicationComplianceEnum,
  MedicationReminderEnum,
  MedicationReminderDeclineReasonEnum,
} from '../../../../uc-api-sdk';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { MedicationType } from '../../type/type';
import { AddMedicationDrawerComponent } from '../AddMedicationDrawerComponent/AddMedicationDrawerComponent';
import { MedicationComplianceFormItemsComponent, MedicationComplianceFormItemOptions } from '../MedicationTabFormComponent/MedicationComplianceFormItemsComponent';
import { FormType } from '../../../Input/types';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { getDeclineReasonText } from '../../../../enumComponent/MedicationListReminderDeclineReasonEnumComponent/MedicationListReminderDeclineReasonEnumComponent';
import { getMedicationReviewText } from '../../../../enumComponent/MedicationListReviewEnumComponent/MedicationListReviewEnumComponent';
import { getMedicationReminderText } from '../../../../enumComponent/MedicationListReminderServiceEnumComponent/MedicationListReminderServiceEnumComponent';
import { MedicationManagementService } from '../../../../services/MedicationManagementService';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

import './MedicalHistoryMedicationSectionComponent.scss';

export interface MedicationManagementType {
  extraNote: string;
  medicationCompliance: MedicationComplianceEnum;
  medicationComplianceLastModifiedAt?: string;
  medicationReminder: MedicationReminderEnum;
  medicationReminderLastModifiedAt?: string;
  medicationReminderDeclineReason: MedicationReminderDeclineReasonEnum;
  note: string;
}

export interface ChildrenValue {
  addMedication: ReactNode;
}

export interface MedicalHistoryMedicationSectionComponentProps
  extends FormType<MedicationManagementType>,
  MedicationComplianceFormItemOptions {
  form?: FormOptions['form']; // parent's form controls
  onDelete?: (memberId: string) => void;
  onInsert?: (value: MedicationType) => void;
  onUpdate?: (value: MedicationType, id: Nullable<string> | undefined) => void;
  onFinish?: (v: MedicationManagementType) => void;
  onCancel?: () => void;
  children?: (value: ChildrenValue) => ReactNode;
  mode: 'Edit' | 'View';
  patientId?: string;
  onClickAddMedication?: () => void;
  discontinuedMedications: MedicationType[],
  prescribedMedications: MedicationType[],
}

export const MedicalHistoryMedicationSectionComponent = ({
  form,
  initialValues,
  onDelete,
  onInsert,
  onUpdate,
  onFinish,
  onCancel,
  children,
  mode,
  onValuesChange,
  patientId,
  onClickAddMedication,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
  discontinuedMedications,
  prescribedMedications,
  medicationReviewRequired,
  medicationReminderRequired,
  medicationReviewHidden,
}: MedicalHistoryMedicationSectionComponentProps) => {
  const hook = useMedicationContainerForm({ form });
  const [prevMeds, setPrevMeds] = useState(0);
  const [curMeds, setCurMeds] = useState(0);
  const showDeclineReason = initialValues?.medicationReminder === MedicationReminderEnum
    .PATIENT_DECLINED_MEDICATION_REMINDER;
  const medicationManagementService = new MedicationManagementService(initialValues);

  const handleSubmit = async (v: MedicationManagementType) => {
    onFinish?.(v);
  };

  const renderFormItems = () => (
    <>
      <MedicationComplianceFormItemsComponent
        medicationReviewHidden={medicationReviewHidden}
        medicationComplianceLastInfo={medicationManagementService.medComplianceUpdateInfo}
        medicationReminderLastInfo={medicationManagementService.medReminderUpdateInfo}
        medicationReviewRequired={medicationReviewRequired}
        medicationReminderRequired={medicationReminderRequired}
      />
      <div className="pb20 mt20">
        <Space>
          {formButtons}
        </Space>
      </div>
    </>
  );

  const renderItems = () => (
    <Space direction="vertical" size={20} className="mb20">
      <div>
        <div className="text-gray-scale-2">Medication Review</div>
        <DisplayOrEmptyComponent
          value={getMedicationReviewText(initialValues?.medicationCompliance)}
        />
      </div>
      <div>
        <div className="text-gray-scale-2">Note</div>
        <DisplayOrEmptyComponent value={initialValues?.note} />
      </div>
      <div>
        <div className="text-gray-scale-2">Medication Reminder Service</div>
        <DisplayOrEmptyComponent
          value={getMedicationReminderText(initialValues?.medicationReminder)}
        />
      </div>
      {showDeclineReason && (
        <div>
          <div className="text-gray-scale-2">Decline Reason</div>
          <DisplayOrEmptyComponent
            value={getDeclineReasonText(initialValues?.medicationReminderDeclineReason)}
          />
        </div>
      )}
    </Space>
  );

  const renderChildren = () => {
    const hasMedication = !!(prescribedMedications.length || discontinuedMedications.length);
    if (!hasMedication) {
      return (
        <NoDataWithActionComponent
          description="No medication record at this time."
        >
          <AddMedicationDrawerComponent
            patientId={patientId}
            onClickAddMedication={onClickAddMedication}
          >
            {
              ({ onClick }) => (
                <Button
                  type="link"
                  onClick={onClick}
                >
                  Add Medication
                </Button>
              )
            }
          </AddMedicationDrawerComponent>
        </NoDataWithActionComponent>
      );
    }
    return children?.({
      addMedication: (
        <AddMedicationDrawerComponent
          patientId={patientId}
          disabled={hook.formDisabled}
          onClickAddMedication={onClickAddMedication}
        />
      ),
    });
  };

  const renderFormBody = () => {
    if (mode === 'View') {
      return renderItems();
    }
    if (!form) {
      return (
        <Form
          form={hook.form}
          className="mb20"
          onFinish={hook.handleSubmit(handleSubmit)}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          disabled={hook.formDisabled}
          layout="vertical"
        >
          {renderFormItems()}
        </Form>
      );
    }
    return renderFormItems();
  };

  return (
    <MedicationContextProvider
      prevMeds={prevMeds}
      curMeds={curMeds}
      setPrevMeds={setPrevMeds}
      setCurMeds={setCurMeds}
      onDelete={onDelete}
      onInsert={onInsert}
      onUpdate={onUpdate}
    >
      <div id="medicationFormBody">
        {renderFormBody()}
        <div className="my15">
          {renderChildren()}
        </div>
      </div>
    </MedicationContextProvider>
  );
};
