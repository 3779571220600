import { map, omit } from 'lodash';
import { MessageHistoryMessage } from '../../../services/CHSServices/types/data';

export const omitUnusedMessageFields = (messages: MessageHistoryMessage[]) => (
  map(messages, (message) => {
    let omittedMessage = omit(message, 'payload.pn_apns');
    omittedMessage = omit(omittedMessage, 'payload.pn_gcm');
    omittedMessage = omit(omittedMessage, 'tag');
    return omittedMessage;
  })
);
