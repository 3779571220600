import { Table } from 'antd';
import { FC, useEffect } from 'react';
import { useMedicationContext } from '../../../contexts/MedicationContext/MedicationContext';
import { MedicationType } from '../../../features/medication/type/type';
import { useTableColumns } from '../../../hooks/table/useTableColumns/useTableColumns';
import { createMedicationColumns } from '../columns';

export interface DiscontinuedMedicationsTableComponentProps {
  medications?: MedicationType[];
}

export const DiscontinuedMedicationsTableComponent: FC<
  DiscontinuedMedicationsTableComponentProps> = ({
    medications = [],
  }) => {
    const columnNames: (keyof typeof createMedicationColumns)[] = [
      'name',
      'endDate',
      'type',
      'dosage',
      'settings',
    ];
    const columns = useTableColumns(createMedicationColumns, columnNames);
    const { setPrevMeds } = useMedicationContext();

    useEffect(() => {
      setPrevMeds(medications.length);
    }, [medications.length]);

    if (medications.length === 0) {
      return <div />;
    }

    return (
      <div>
        <div className="mb10 bold">Discontinued Medications</div>
        <Table
          className="medicationsTable border default-border-radius"
          dataSource={medications}
          columns={columns}
          rowKey={(record) => (record.id)}
          pagination={medications.length > 10 ? {} : false}
          scroll={{ x: 'max-content' }}
        />
      </div>
    );
  };
