import { map } from 'lodash';
import './PatientHealthConditionControlLevelTableComponent.scss';

export interface PatientControlLevelTableRowData {
  badge: JSX.Element; // The badge can be any JSX element (like your Badge component)
  description: JSX.Element | string; // The description can be either JSX or string
}

export interface PatientHealthConditionControlLevelTableComponentProps {
  data: PatientControlLevelTableRowData[];
}

export const PatientHealthConditionControlLevelTableComponent = (
  { data } : PatientHealthConditionControlLevelTableComponentProps
) => (
  <table className="control-level-table">
    <tbody>
      {map(data, (row, ri) => (
        <tr key={ri}>
          <td className="badge-column">
            {row.badge}
          </td>
          <td>
            <div className="text-column">
              {row.description}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
