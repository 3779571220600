import { useCallback, useState } from 'react';
import { ClinicEventsService } from '../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { makeUnsavedNotesKey, useUnsavedNotes } from '../../../hooks/useUnsavedNotes/useUnsavedNotes';
import { useRefState } from '../../../hooks/useRefState/useRefState';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';

export type WorklistRequiredField = 'VISIT_SUMMARY';

type WorklistErrors<E extends string> = {
  [field in E | WorklistRequiredField]?: boolean;
}

export const getWorklistUnsavedNoteKey = (clinicEventId?: string) => (
  makeUnsavedNotesKey('worklist', clinicEventId || '')
);

export const useWorklistActions = <E extends string>(
  clinicEvent: ClinicEventsService,
) => {
  const {
    getUnsavedNotes,
    setUnsavedNotes,
  } = useUnsavedNotes(getWorklistUnsavedNoteKey(clinicEvent.id));
  const [
    getVisitSummary,
    _setVisitSummary,
  ] = useRefState<string | undefined>(clinicEvent.visitSummary ?? getUnsavedNotes());
  const [
    errors,
    setErrors,
  ] = useState<WorklistErrors<E | WorklistRequiredField>>({});
  const [
    isVisitSummaryChanged,
    setIsVisitSummaryChanged,
  ] = useState((
    getVisitSummary() !== clinicEvent.visitSummary
  ));

  const handleSetError = useCallback((
    key: E | WorklistRequiredField,
    value: boolean,
  ) => {
    setErrors((prev) => {
      if (prev[key] === value) return prev;
      return {
        ...prev,
        [key]: value,
      };
    });
  }, []);

  const setVisitSummary = useCallback((value?: string) => {
    handleSetError('VISIT_SUMMARY', false);
    _setVisitSummary(value);
    setUnsavedNotes(value);
    setIsVisitSummaryChanged((
      value !== undefined
      && value !== clinicEvent.visitSummary
    ));
  }, []);

  const validateVisitSummary = useCallback(() => {
    if (!getVisitSummary()) {
      handleSetError('VISIT_SUMMARY', true);
      return false;
    }
    return true;
  }, []);

  return useGetContextValue({
    getVisitSummary,
    isVisitSummaryChanged,
    setVisitSummary,
    errors,
    handleSetError,
    validateVisitSummary,
  });
};
