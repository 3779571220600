import { Select } from 'antd';
import { ReactNode, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import classNames from 'classnames';
import { SelectType } from '../../features/Input/types';

dayjs.extend(dayjsTimezone);

export interface DaysRangeSelectComponentProps extends Omit<SelectType<number>, 'options'> {
  optionRenderer?: (value: number) => string;
  rangeOptions?: number[];
  timezone?: string;
  options?: Array<{
    label: ReactNode;
    value: number;
  }>;
  onChangeDayjs?: (value?: Dayjs) => void;
}

const defaultOptions = [
  7, // days
  14,
  30,
  60,
  90,
  180,
  360,
  Infinity // all
];

// value (input): number as range (ie 7, 14,....)
// onChange (output): Dayjs
export const LastDaysRangeSelectComponent = ({
  value,
  onChange,
  onChangeDayjs,
  rangeOptions = defaultOptions,
  optionRenderer = (v) => (
    v === Infinity ? 'All time' : `Last ${v} days`
  ),
  options,
  timezone,
  className = '',
  ...props
}: DaysRangeSelectComponentProps) => {
  const getOptions = useCallback(() => (
    options
    || (
      rangeOptions.map((v) => ({
        label: optionRenderer(v),
        value: v,
      }))
    )
  ), [options]);

  const handleOnChange = useCallback((v: number) => {
    const newValue = v === Infinity ? undefined : v;
    const now = timezone ? dayjs().tz(timezone) : dayjs();
    const endDate = now.endOf('day');
    const startDate = !newValue ? undefined : endDate.subtract(newValue, 'days').startOf('day');
    onChange?.(newValue);
    onChangeDayjs?.(startDate);
  }, []);

  return (
    <Select
      options={getOptions()}
      showArrow
      dropdownMatchSelectWidth
      className={classNames({
        'days-range-select w-mc': true,
        [className]: !!className,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      onChange={handleOnChange}
    />
  );
};
