import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactNode } from 'react';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { usePatientSearchUcMedicationManagement } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { InterventionContainerProps } from '../../../intervention/type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { MedicalHistoryMedicationContainer, MedicalHistoryMedicationContainerProps } from '../../container/MedicalHistoryMedicationContainer';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { MedicationGuideModalComponent } from '../../../../uiComponent/Medication/MedicationGuideModalComponent/MedicationGuideModalComponent';
import { MedicationComplianceFormItemOptions } from '../MedicationTabFormComponent/MedicationComplianceFormItemsComponent';

export interface MedicationCommonCardComponentProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps,
  MedicationComplianceFormItemOptions {
  form?: MedicalHistoryMedicationContainerProps['form'];
  onMedicationSubmit?: () => void;
  title?: ReactNode;
  showHeaderOutside?: boolean;
  shouldResetCompliance?: boolean;
}

export const MedicationCommonCardComponent = ({
  patientId,
  visitId,
  form,
  isEditing,
  onEdit,
  showEdit,
  onSubmit,
  onMedicationSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
  title,
  showHeaderOutside = true,
  medicationReviewRequired,
  medicationReminderRequired,
  medicationReviewHidden,
  shouldResetCompliance,
}: MedicationCommonCardComponentProps) => {
  const {
    isOpen: isGuideOpen,
    open: openGuide,
    close: closeGuide,
  } = useOpen();
  const medInfo = usePatientSearchUcMedicationManagement({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          prescribedMedication: {
            memberId: patientId,
            active: true,
            isEnded: false,
          },
          discontinuedMedication: {
            memberId: patientId,
            active: true,
            isEnded: true,
          },
        },
      },
    },
  });

  useUpdateListener('PATIENT_INFO_UPDATED', medInfo.refetch);
  useUpdateListener('WORKLIST_UPDATED', medInfo.refetch);

  const handleSubmit = () => {
    medInfo.refetch();
    onSubmit?.();
  };

  const handleMedicationSubmit = () => {
    medInfo.refetch();
    onMedicationSubmit?.();
  };

  if (medInfo.data === undefined) {
    return null;
  }

  return (
    <>
      <FetchComponent
        info={medInfo}
        showErrorOverlay={false}
        alwaysShowChildren
      >
        {(data) => {
          const {
            medicationManagement,
            discontinuedMedications,
            prescribedMedications,
            lastUpdatedAt,
            updatedByUser,
          } = data?.rawValue?.data || {};
          return (
            <PatientCommonCardComponent
              isEditing={isEditing}
              showEdit={showEdit}
              updateInfo={{ updatedAt: lastUpdatedAt, updatedByUser }}
              onEdit={onEdit}
              title={(
                title
                ?? (
                  <>
                    Medication List
                    <InfoCircleOutlined onClick={openGuide} />
                  </>
                )
              )}
              showHeaderOutside={showHeaderOutside}
              content={(
                <MedicalHistoryMedicationContainer
                  form={form}
                  memberId={patientId}
                  visitId={visitId}
                  mode="View"
                  onCancel={onCancel}
                  onMedicationSubmit={handleMedicationSubmit}
                  medicationManagement={medicationManagement}
                  prescribedMedications={prescribedMedications}
                  discontinuedMedications={discontinuedMedications}
                />
              )}
              formContent={(
                <MedicalHistoryMedicationContainer
                  form={form}
                  memberId={patientId}
                  visitId={visitId}
                  mode="Edit"
                  onCancel={onCancel}
                  onSubmit={handleSubmit}
                  onMedicationSubmit={handleMedicationSubmit}
                  onValuesChange={onValuesChange}
                  onError={onError}
                  formButtons={formButtons}
                  medicationManagement={medicationManagement}
                  prescribedMedications={prescribedMedications}
                  discontinuedMedications={discontinuedMedications}
                  medicationReviewRequired={medicationReviewRequired}
                  medicationReminderRequired={medicationReminderRequired}
                  medicationReviewHidden={medicationReviewHidden}
                  shouldResetCompliance={shouldResetCompliance}
                />
              )}
            />
          );
        }}
      </FetchComponent>
      <Modal
        open={isGuideOpen}
        width="max-content"
        title="Common Medications, Classes, and Abbreviations"
        onCancel={closeGuide}
        footer={null}
        destroyOnClose
      >
        <MedicationGuideModalComponent />
      </Modal>
    </>
  );
};
