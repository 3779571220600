import dayjs from 'dayjs';
import { useState } from 'react';
import { Form } from 'antd';
import { CalendarDrawerComponent } from '../../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import { usePatientContext } from '../../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../../contexts/UpdateContext/UpdateContext';
import { VisitTypeEnum } from '../../../../../uc-api-sdk';
import { useDeepCompareEffect } from '../../../../../hooks/useDeepCompareEffect';
import { Assignee } from '../../../../../services/PatientAssigneesService';
import { useCreateVisitForm } from '../../../../visit/hook/useCreateVisitForm';
import { FormConnectorContextProvider } from '../../../../../contexts/FormConnectorContext/FormConnectorContext';
import { VisitTypeSelectCalendarValue } from '../../../../visit/component/VisitTypeSelectComponent/VisitTypeSelectCalendarComponent';

export interface MakeSchedulesModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  visitType?: VisitTypeEnum;
  assignees?: Assignee[];
  shouldTriggerVisitType?: boolean; // to simulate onChange for visitType
}

export const MakeSchedulesModalComponent = ({
  visitType,
  isOpen = false,
  onClose,
  assignees = [],
  shouldTriggerVisitType,
}: MakeSchedulesModalComponentProps) => {
  const { info } = usePatientContext();
  const { id = '' } = info || {};
  const [date] = useState(dayjs());
  const [visitForm] = Form.useForm();
  const createVisitForm = useCreateVisitForm();
  // this is to make sure form is loaded
  const isVisitTypeSet = !!visitForm?.getFieldValue(createVisitForm.getName('visitType'));

  useUpdateListener('GO_TO_CHECK_IN', () => onClose?.());
  useDeepCompareEffect(() => {
    if (isOpen && shouldTriggerVisitType && isVisitTypeSet) {
      visitForm?.setFieldValue(
        createVisitForm.getName('visitType'),
        {
          value: visitType,
          manuallyTriggered: true,
          shouldJump: false,
        } as VisitTypeSelectCalendarValue
      );
    }
  }, [shouldTriggerVisitType, isVisitTypeSet, isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <FormConnectorContextProvider form={visitForm}>
      <CalendarDrawerComponent
        isOpen={isOpen}
        onClose={onClose}
        visitInitialValue={{
          dateTime: date,
          patientId: id,
          assignees,
          visitType,
        }}
      />
    </FormConnectorContextProvider>
  );
};
