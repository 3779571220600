import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';

export interface ControlLevelTagComponentProps {
  text?: string;
  className?: string;
  showCard?: boolean;
}

export const ControlLevelTagComponent = {
  ControlledTag: () => (
    <TagComponent
      className="control-level-tag-wrapper"
      type="success"
      iconType="none"
      background="oval"
    >
      Controlled
    </TagComponent>
  ),
  UncontrolledTag: () => (
    <TagComponent
      className="control-level-tag-wrapper"
      type="error"
      iconType="none"
      background="oval"
    >
      Uncontrolled
    </TagComponent>
  ),
  UncategorizedTag: () => (
    <TagComponent
      className="control-level-tag-wrapper"
      type="processing"
      iconType="none"
      background="oval"
    >
      Uncategorized
    </TagComponent>
  )
};
