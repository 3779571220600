import { ReactNode } from 'react';
import { UpdatedByAt, UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';

export interface MedicationSelectLabelInfoComponentProps {
  label?: ReactNode;
  lastInfo?: UpdatedByAt;
}

export const MedicationSelectLabelInfoComponent = ({
  label,
  lastInfo
}: MedicationSelectLabelInfoComponentProps) => (
  <div className="flex w100 jc-sb">
    <div>
      {label}
    </div>
    {
      lastInfo?.updatedAt
      && (
        <UpdatedByAtComponent
          prefix="Last updated on:"
          info={lastInfo}
        />
      )
    }
  </div>
);
