import { ReactNode } from 'react';
import classNames from 'classnames';
import './WorklistSectionHeaderComponent.scss';

export interface WorklistSectionHeaderComponentProps {
  title: ReactNode;
  number?: number;
  className?: string;
}

export const WorklistSectionHeaderComponent = ({
  title,
  number,
  className = '',
}: WorklistSectionHeaderComponentProps) => (
  <div
    className={classNames({
      'worklist-section-header flex ai-c': true,
      [className]: !!className,
    })}
  >
    {number && (
      <div className="worklist-section-header__number flex bg-black jc-c ai-c">
        <div className="b7">
          {number}
        </div>
      </div>
    )}
    <div className="flex fs16 b7 jc-c">
      <div>
        {title}
      </div>
    </div>
  </div>
);
