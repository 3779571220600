import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { WorklistSocialSupportComponent } from '../WorklistSocialSupportComponent/WorklistSocialSupportComponent';
import { PatientService } from '../../../../services/PatientService';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

export interface SimplifiedWorklistSocialSupportComponentProps {
  patientId: string;
  itemNumber?: number;
}

export const SimplifiedWorklistSocialSupportComponent = ({
  patientId,
  itemNumber = -1
}: SimplifiedWorklistSocialSupportComponentProps) => {
  const {
    info: { patientInfoService } = {}
  } = usePatientContext();

  return (
    <div className="simplified-worklist-section">
      <WorklistSectionHeaderComponent
        number={itemNumber}
        title="Social Support"
      />

      <LoadingOverlayComponent isLoading={!patientInfoService}>
        <NestedFormComponent>
          <WorklistSocialSupportComponent
            patientId={patientId}
            patientInfoService={patientInfoService as PatientService}
            hideTitle
          />
        </NestedFormComponent>
      </LoadingOverlayComponent>
    </div>
  );
};
