import {
  ReactNode,
  useCallback,
  useState
} from 'react';
import {
  first,
  forEach,
  groupBy,
  map,
} from 'lodash';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { convertFileToBase64 } from '../../../../helpers/file';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ModalWidth880 } from '../../../../constants/modal';
import { LLMViewSourceClick } from '../../type';

import './LLMViewSourceComponent.scss';
import { SiderComponent } from '../../../../uiComponent/SiderComponent/SiderComponent';
import { DraggableModalComponent, DraggableModalComponentProps } from '../../../../uiComponent/DraggableModalComponent/DraggableModalComponent';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { ScalableComponent } from '../../../../uiComponent/ScalableComponent/ScalableComponent';

export interface LLMViewSourceData {
  key: string; // to map back to parent's sourceData
  file: File; // currently support only pdf and image
  imageIndex: number;
}

interface MappedSource extends LLMViewSourceData {
  fileName: string;
  sourceIndex: string;
}

interface MappedImagesSource {
  fileName: string;
  images: string[];
}

export interface LLMViewSourceComponentProps {
  getSourceData?: () => LLMViewSourceData[];
  renderNavigator: (
    mappedSource: MappedSource[],
    onClick: LLMViewSourceClick,
  ) => ReactNode;
  disabled?: boolean;
  emptySourceText?: ReactNode;
}

export const LLMViewSourceComponent = ({
  getSourceData,
  renderNavigator,
  disabled,
  emptySourceText = 'No source found',
}: LLMViewSourceComponentProps) => {
  const {
    isOpen,
    open,
    close
  } = useOpen(false);
  const [
    mappedSource,
    setMappedSource
  ] = useState<MappedSource[] | undefined>();
  const [
    mappedImagesSource,
    setMappedImagesSource
  ] = useState<MappedImagesSource[] | undefined>();

  const renderTrigger = useCallback<NonNullable<DraggableModalComponentProps['trigger']>>(({
    onOpen,
  }) => (
    <LinkButton
      useBlueColor
      onClick={onOpen}
      disabled={disabled}
    >
      View source file
    </LinkButton>
  ), []);

  const makeSourceIndex = (fileName: string, imageIndex: number) => `${fileName}-${imageIndex}`;

  const process = useCallback(async (sourceData: LLMViewSourceData[]) => {
    const groupByFileName = groupBy(sourceData, (d) => d.file.name);
    // mapped id: `${fileName}-${imageIndex}`
    // 1/ make sourceIndex=<mapped id> so parent component can decide render with jumping link
    const mappedSource = [] as MappedSource[];
    forEach(groupByFileName, (dataInGroup) => {
      const fileName = first(dataInGroup)?.file?.name;
      if (!fileName) {
        console.warn('No file name found');
        return;
      }
      forEach(dataInGroup, (d) => {
        mappedSource.push({
          ...d,
          fileName,
          sourceIndex: makeSourceIndex(fileName, d.imageIndex),
        });
      });
    });
    // 2/ retrieve images in base64 and mapped id
    const mappedSourcePromises = map(groupByFileName, async (dataInGroup) => {
      const file = first(dataInGroup)?.file;
      if (!file) {
        console.warn('No file found');
        return {
          fileName: '',
          images: [],
        };
      }
      const fileName = file.name;
      const images = await convertFileToBase64(file) || [];
      return {
        fileName,
        images,
      };
    });
    const mappedImagesSource = await Promise.all(mappedSourcePromises);
    setMappedSource(mappedSource);
    setMappedImagesSource(mappedImagesSource);
  }, []);

  const handleOnClickMappedSourceItem: LLMViewSourceClick = (
    fileName,
    imageIndex,
  ) => {
    const sourceIndex = makeSourceIndex(fileName, imageIndex);
    const element = document.getElementById(sourceIndex);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    console.warn('source index not found');
  };

  useDeepCompareEffect(() => {
    if (!getSourceData) {
      setMappedImagesSource([]);
      return;
    }
    if (isOpen && !mappedImagesSource) {
      const sourceData = getSourceData();
      process(sourceData);
    }
  }, [isOpen, mappedImagesSource]);

  return (
    <DraggableModalComponent
      onOpen={open}
      onCancel={close}
      trigger={renderTrigger}
      title="File Viewer"
      width={ModalWidth880}
      className="llm-view-source"
      destroyOnClose
      resizable={{
        minWidth: 300,
        minHeight: 300,
      }}
    >
      {
        !mappedImagesSource?.length
          ? emptySourceText
          : (
            <>
              <SiderComponent
                className="llm-view-source__navigator"
                content={(
                  <>
                    {
                      renderNavigator(
                        mappedSource || [],
                        handleOnClickMappedSourceItem,
                      )
                    }
                  </>
                )}
              />
              <ScalableComponent>
                <div className="llm-view-source__content">
                  <LoadingOverlayComponent isLoading={!mappedImagesSource}>
                    {
                      map(mappedImagesSource?.flat(), (mappedImage, idx) => (
                        <div key={idx} className="mb20">
                          {
                            map(mappedImage.images, (image, imgIdx) => (
                              <div
                                id={makeSourceIndex(mappedImage.fileName, imgIdx)}
                                key={makeSourceIndex(mappedImage.fileName, imgIdx)}
                                className="llm-view-source__image"
                              >
                                <img
                                  src={image}
                                  alt={mappedImage.fileName}
                                />
                              </div>
                            ))
                          }
                        </div>
                      ))
                    }
                  </LoadingOverlayComponent>
                </div>
              </ScalableComponent>
            </>
          )
      }
    </DraggableModalComponent>
  );
};
