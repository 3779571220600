import { Moment } from 'moment';
import { useMemo } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import {
  APIResponse,
  PatientEnrolledProgram,
  PatientHiatusStatus,
  PatientHiatusStatusEnum,
  PauseServiceInfo,
  useClinicEventSearch,
  usePatientUpsertPatientHiatusStatus,
} from '../../../../uc-api-sdk';
import {
  PatientHiatusFormValue,
  PauseServiceFormComponent,
  PauseServiceFormComponentProps
} from '../../component/PauseServiceFormComponent/PauseServiceFormComponent';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { RangeValue } from '../../component/PauseServiceComponent/PauseServiceRangePickerComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';

export interface PauseServiceFormContainerProps {
  memberId: string;
  onCancel?: () => void;
  onSubmit?: (res: APIResponse<PatientHiatusStatus> | null | undefined) => void;
  enrolledProgram: PatientEnrolledProgram;
  onResumeAll: () => void;
}

export const PauseServiceFormContainer = ({
  memberId,
  onCancel,
  onSubmit,
  enrolledProgram,
  onResumeAll,
}: PauseServiceFormContainerProps) => {
  const hiatusDetails = enrolledProgram?.hiatusDetail;
  const { info } = usePatientContext();
  const { patientInfo } = info || {};
  const updatePauseService = usePatientUpsertPatientHiatusStatus({});
  const refetchPatientHeader = useUpdate('PATIENT_INFO_UPDATED');
  const updateHook = useUpdate('PAUSE_SERVICE_UPDATED');

  const calendarInfo = useClinicEventSearch({
    options: { sendOnMount: false }
  });

  const initialValues = useMemo(() => {
    const parseInitValue = (info: PauseServiceInfo | null | undefined) => {
      const isNotResumed = !!(info?.status && info?.status !== PatientHiatusStatusEnum.RESUMED);
      return {
        // checkbox
        isChecked: isNotResumed || false,
        // reason
        reason: isNotResumed ? info?.reason : undefined,
        // time span
        timespan: isNotResumed ? [
          info?.startDate
            ? TimezoneService.calcDateTime(info.startDate)
            : null,
          info?.endDate
            ? TimezoneService.calcDateTime(info.endDate)
            : null,
        ] as RangeValue : undefined
      };
    };

    const vitalsMonitoringParsedValue = parseInitValue(hiatusDetails?.vitalMonitoring);
    const visitServiceParsedValue = parseInitValue(hiatusDetails?.visitService);
    const billingParsedValue = parseInitValue(hiatusDetails?.billingReport);

    const v: PatientHiatusFormValue = {
      vitalMonitoring: vitalsMonitoringParsedValue.isChecked,
      vitalMonitoringReason: vitalsMonitoringParsedValue.reason,
      vitalMonitoringTimeSpan: vitalsMonitoringParsedValue.timespan,

      visitService: visitServiceParsedValue.isChecked,
      visitServiceReason: visitServiceParsedValue.reason,
      visitServiceTimeSpan: visitServiceParsedValue.timespan,

      billing: billingParsedValue.isChecked,
      billingReason: billingParsedValue.reason,
      billingTimeSpan: billingParsedValue.timespan,

      note: hiatusDetails?.status !== PatientHiatusStatusEnum.RESUMED ? hiatusDetails?.note : null,
    };

    return v;
  }, [hiatusDetails]);

  // For all request build functions:
  // - isEdit: mark as true only when the user checks or unchecks the service.
  // - status:
  //   - if the update value is true, mark it as PAUSED.
  //   - if the initial value exists and the update value is false, mark it as RESUMED.
  const buildVitalMonitoringRequest = (
    updateHiatusDetails?: PatientHiatusFormValue
  ): PauseServiceInfo | null => ({
    isEdit: !!updateHiatusDetails?.vitalMonitoring || !!initialValues?.vitalMonitoring,
    status: !updateHiatusDetails?.vitalMonitoring
      ? PatientHiatusStatusEnum.RESUMED : PatientHiatusStatusEnum.PAUSED,
    reason: updateHiatusDetails?.vitalMonitoringReason,
    startDate: updateHiatusDetails?.vitalMonitoringTimeSpan?.[0]
      ? TimezoneService.getUTC(updateHiatusDetails?.vitalMonitoringTimeSpan[0] as Moment) : null,
    endDate: updateHiatusDetails?.vitalMonitoringTimeSpan?.[1]
      ? TimezoneService.getUTC(updateHiatusDetails?.vitalMonitoringTimeSpan[1] as Moment) : null,
  });

  const buildVisitServiceRequest = (
    updateHiatusDetails?: PatientHiatusFormValue
  ): PauseServiceInfo | null => ({
    isEdit: !!updateHiatusDetails?.visitService || !!initialValues?.visitService,
    status: !updateHiatusDetails?.visitService
      ? PatientHiatusStatusEnum.RESUMED : PatientHiatusStatusEnum.PAUSED,
    reason: updateHiatusDetails?.visitServiceReason,
    startDate: (updateHiatusDetails?.visitServiceTimeSpan
      && updateHiatusDetails?.visitServiceTimeSpan[0])
      ? TimezoneService.getUTC(updateHiatusDetails?.visitServiceTimeSpan[0] as Moment) : null,
    endDate: (updateHiatusDetails?.visitServiceTimeSpan
      && updateHiatusDetails?.visitServiceTimeSpan[1])
      ? TimezoneService.getUTC(updateHiatusDetails?.visitServiceTimeSpan[1] as Moment) : null,
  });

  const buildBillingRequest = (
    updateHiatusDetails?: PatientHiatusFormValue
  ): PauseServiceInfo | null => ({
    isEdit: !!updateHiatusDetails?.billing || !!initialValues?.billing,
    status: !updateHiatusDetails?.billing
      ? PatientHiatusStatusEnum.RESUMED : PatientHiatusStatusEnum.PAUSED,
    reason: updateHiatusDetails?.billingReason,
    startDate: (updateHiatusDetails?.billingTimeSpan && updateHiatusDetails?.billingTimeSpan[0])
      ? TimezoneService.getUTC(updateHiatusDetails?.billingTimeSpan[0] as Moment) : null,
    endDate: (updateHiatusDetails?.billingTimeSpan && updateHiatusDetails?.billingTimeSpan[1])
      ? TimezoneService.getUTC(updateHiatusDetails?.billingTimeSpan[1] as Moment) : null,
  });

  const hiatusDetailRequest = async (updateHiatusDetails: PatientHiatusFormValue) => {
    const request: PatientHiatusStatus = {
      vitalMonitoring: buildVitalMonitoringRequest(updateHiatusDetails ?? undefined) ?? undefined,
      visitService: buildVisitServiceRequest(updateHiatusDetails ?? undefined) ?? undefined,
      billingReport: buildBillingRequest(updateHiatusDetails ?? undefined) ?? undefined,
      note: updateHiatusDetails.note ?? undefined,
    };

    const req = updatePauseService.send({
      params: {
        memberId,
        request,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      // todo
      error: 'Service updated failed.',
      success: 'Services updated successfully.',
      onSuccess: (v) => {
        refetchPatientHeader.updateValue();
        // update care note
        updateHook.updateValue();
        onSubmit?.(v);
      },
    });
  };

  // to show the warning if there is any follow up visit in the pause period
  const onDateChange: PauseServiceFormComponentProps['onDateChange'] = (values) => {
    if (values) {
      calendarInfo.send({
        params: {
          filter: {
            patientId: patientInfo?.id,
            startTimeRange: {
              gte: values[0]?.clone().startOf('day').toISOString(),
              lte: values[1]?.clone().endOf('day').toISOString(),
            },
          },
          pageInfo: {
            // @ts-ignore
            countOnly: true,
          }
        }
      });
    }
  };

  return (
    <PauseServiceFormComponent
      isLoading={updatePauseService.isLoading}
      onDateChange={onDateChange}
      needRescheduleVisit={(calendarInfo.data?.data?.totalSize ?? 0) > 0}
      onCancel={onCancel}
      onSubmit={hiatusDetailRequest}
      initialValues={initialValues}
      onResumeAll={onResumeAll}
    />
  );
};
