import { MedicationInstructionEnum } from '../../uc-api-sdk';

export interface MedicationInstructionEnumComponentProps {
  value: MedicationInstructionEnum;
}

export const MedicationInstructionEnumComponent = ({
  value,
}: MedicationInstructionEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case MedicationInstructionEnum.BEFORE_MEAL: return 'Before meal';
      case MedicationInstructionEnum.WITH_MEAL: return 'With meal';
      case MedicationInstructionEnum.AFTER_MEAL: return 'After meal';
      case MedicationInstructionEnum.BETWEEN_MEAL: return 'Between meal';
      case MedicationInstructionEnum.AT_BEDTIME: return 'At bedtime';
      case MedicationInstructionEnum.UPON_WAKING: return 'Upon waking';
      case MedicationInstructionEnum.NOT_SPECIFIED: return 'Not specified';
      default: return null;
    }
  };

  return (<span>{getText()}</span>);
};
