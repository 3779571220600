import { message } from 'antd';
import { useState } from 'react';
import { Transcribe, TranscribeTypeEnum } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { BillableTimeContainer } from '../../../billableTime/container/BillableTimeContainer/BillableTimeContainer';
import { BillableComponentEnum } from '../../../billableTime/hook/useBillableEventTracker/useBillableEventTrackerComp';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { TranscribeContainerChildrenProps } from '../../container/TranscribeContainer/TranscribeContainer';
import { TranscribeVisitDrawerFormContainer } from '../../container/TranscribeVisitDrawerFormContainer/TranscribeVisitDrawerFormContainer';
import { TranscribeA1CDrawerComponent } from '../TranscribeA1CDrawerComponent/TranscribeA1CDrawerComponent';
import { useTranscribeDrawer } from '../TranscribeDrawerComponent/TranscribeDrawerComponent';
import { TranscribeDrawerLayoutComponent } from './TranscribeDrawerLayoutComponent';

export interface TranscribeDrawerBodyComponentProps
  extends TranscribeContainerChildrenProps {
  showNextPatientButton?: boolean;
  getNextTranscribe?: (fetchInPlace?: boolean) => Promise<Transcribe | null | undefined>;
  isLoadingNextTranscribe?: boolean;
  isNextPatientButtonDisabled?: boolean;
  handleSetShowCompleted?: () => void;
}

export const TranscribeDrawerBodyComponent = ({
  isLoadingTranscribe,
  transcribeData,
  showNextPatientButton,
  getNextTranscribe,
  isLoadingNextTranscribe,
  isNextPatientButtonDisabled,
  handleSetShowCompleted,
}: TranscribeDrawerBodyComponentProps) => {
  const {
    handleOpenTranscribeDrawer,
  } = useTranscribeDrawer();
  const [hasValidA1C, setHasValidA1C] = useState(false);
  const { id, transcribeType } = transcribeData || {};
  const isVisitTranscribe = transcribeType === TranscribeTypeEnum.VISIT;

  /**
   * @params {fetchInPlace: boolean}: similar to refetch, try to fetch at the same page
  */
  const handleGoToNextTranscribe = async (fetchInPlace?: boolean) => {
    const shouldFetchInPlace = (
      hasValidA1C
      || (typeof fetchInPlace === 'boolean' ? fetchInPlace : undefined)
    );
    setHasValidA1C(false);
    if (!getNextTranscribe) return;
    const nextTranscribe = await getNextTranscribe(shouldFetchInPlace);
    const nextTranscribeId = nextTranscribe?.id;
    if (nextTranscribeId) {
      handleOpenTranscribeDrawer(nextTranscribeId, nextTranscribe);

      if (id === nextTranscribeId) {
        message.info('No new transcribe');
        return;
      }

      return;
    }
    if (nextTranscribe === null && handleSetShowCompleted) {
      handleSetShowCompleted();
    }
  };

  const renderBody = (transcribeData: Transcribe) => {
    if (transcribeType === TranscribeTypeEnum.A1C) {
      return (
        <TranscribeA1CDrawerComponent
          transcribeData={transcribeData}
          onValidSubmit={() => setHasValidA1C(true)}
        />
      );
    }
    if (transcribeType === TranscribeTypeEnum.VISIT) {
      return (
        <TranscribeVisitDrawerFormContainer
          transcribeData={transcribeData}
          handleGoToNextTranscribe={handleGoToNextTranscribe}
          shouldGoNextAfterSave={showNextPatientButton}
        />
      );
    }
    return null;
  };

  return (
    <LoadingOverlayComponent
      isLoading={isLoadingTranscribe}
      showSkeleton
    >
      {
        transcribeData && transcribeData.patientId
          ? (
            <PatientProfileContainer
              key={transcribeData.id}
              patientId={transcribeData.patientId}
              getComplexity={isVisitTranscribe}
              getInsurance={isVisitTranscribe}
              getHealthCondition={isVisitTranscribe}
              getMedicationManagement={isVisitTranscribe}
              getEnrolledProgram
              getBillableTime
              getControlLevel
              getBpBaseline
            >
              <TranscribeDrawerLayoutComponent
                transcribeType={transcribeType as TranscribeTypeEnum}
                transcribeData={transcribeData}
                showNextPatientButton={showNextPatientButton}
                handleGoToNextTranscribe={handleGoToNextTranscribe}
                isLoadingNextTranscribe={isLoadingNextTranscribe}
                isNextPatientButtonDisabled={isNextPatientButtonDisabled}
              >
                <BillableTimeContainer
                  patientId={transcribeData.patientId}
                  comp={BillableComponentEnum.Transcribing}
                />
                {renderBody(transcribeData as Transcribe)}
              </TranscribeDrawerLayoutComponent>
            </PatientProfileContainer>
          ) : null
      }
    </LoadingOverlayComponent>
  );
};
