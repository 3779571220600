import { CarePlan } from '../../../../../uc-api-sdk';
import { PrintCarePlanClinicalGoalComponent } from '../PrintCarePlanClinicalGoalComponent/PrintCarePlanClinicalGoalComponent';
import { PrintCarePlanDiseaseHistoryComponent } from '../PrintCarePlanDiseaseHistoryComponent/PrintCarePlanDiseaseHistoryComponent';
import { PrintCarePlanDiagnosisHealthConditionsComponent } from '../PrintCarePlanDiagnosisHealthConditionsComponent/PrintCarePlanDiagnosisHealthConditionsComponent';
import { PrintCarePlanSocialSupportComponent } from '../PrintCarePlanSocialSupportComponent/PrintCarePlanSocialSupportComponent';
import { PrintCarePlanVitalsMonitoringGoalComponent } from '../PrintCarePlanVitalsMonitoringGoalComponent/PrintCarePlanVitalsMonitoringGoalComponent';
import { EnrolledProgramService } from '../../../../../services/EnrolledProgramService';
import { PrintCarePlanBehaviorChangeComponent } from '../PrintCarePlanBehaviorChangeComponent/PrintCarePlanBehaviorChangeComponent';
import { PrintCarePlanMedicationComponent } from '../PrintCarePlanMedicationComponent/PrintCarePlanMedicationComponent';

export interface PrintVisitCarePlanComponentProps {
  enrolledProgramService?: EnrolledProgramService;
  carePlan?: CarePlan;
}

export const PrintVisitCarePlanComponent = ({
  enrolledProgramService,
  carePlan,
}: PrintVisitCarePlanComponentProps) => {
  const {
    profile,
    healthCondition,
    diseaseHistory,
    clinicGoal,
  } = carePlan || {};
  return (
    <>
      <div className="print-care-plan-preview-health-condition">
        <PrintCarePlanDiagnosisHealthConditionsComponent
          // diagnosis={healthCondition?.diagnoses}
          healthConditions={healthCondition?.healthConditions}
        />
      </div>
      <div className="print-care-plan-preview-disease-history">
        <PrintCarePlanDiseaseHistoryComponent
          diseaseHistory={diseaseHistory?.diseaseHistory}
        />
      </div>
      <div className="print-care-plan-preview-clinical-goals">
        <PrintCarePlanClinicalGoalComponent
          clinicalGoals={clinicGoal?.doc?.clinicalGoals || []}
        />
      </div>
      <div className="print-care-plan-preview-vitals-monitoring-goal">
        <PrintCarePlanVitalsMonitoringGoalComponent
          monitoringVitals={enrolledProgramService?.getMonitoringVitals() || []}
        />
      </div>
      <div className="print-care-plan-preview-behavior-change">
        <PrintCarePlanBehaviorChangeComponent
          behaviorChange={profile?.behaviorChangeInfo?.behaviorChangeLevel ?? undefined}
        />
      </div>
      <div className="print-care-plan-preview-social-support">
        <PrintCarePlanSocialSupportComponent
          profile={profile}
        />
      </div>
      <div className="print-care-plan-preview-medication-reconciliation-compliance">
        <PrintCarePlanMedicationComponent
          carePlan={carePlan}
        />
      </div>
    </>
  );
};
