import { forEach } from 'lodash';
import { useMessageServicesContext } from '../../../contexts/MessageContext/MessageServicesContext';
import { SubscriptionMessagePayload, MessageEventType, SubscriptionMessageVisitPayload } from '../types';
import { useZoomContext } from '../../../contexts/ZoomContext/ZoomContext';
import { useCalendarNotificationContext } from '../../../contexts/CalendarNotificationContext/CalendarNotificationContext';

export const useMessageSubscription = () => {
  const {
    handleMessageNotification,
  } = useMessageServicesContext();
  const {
    openNotification: openZoomNotification,
  } = useZoomContext();
  const {
    openNotification: openReminderNotification,
  } = useCalendarNotificationContext();

  const handleVideoPayload = ({
    clinicEventId
  }: SubscriptionMessageVisitPayload) => {
    if (!clinicEventId) return;
    openZoomNotification(clinicEventId);
  };

  const handleVisitReminderPayload = ({
    clinicEventId
  }: SubscriptionMessageVisitPayload) => {
    if (!clinicEventId) return;
    openReminderNotification(clinicEventId);
  };

  const handleMessagePayload = (payload: SubscriptionMessagePayload) => {
    handleMessageNotification(payload);
  };

  const processMessagePayload = (
    payload: SubscriptionMessagePayload,
  ) => {
    const { type } = payload;
    switch (type) {
      case MessageEventType.VIDEO_NOTIFICATION:
        handleVideoPayload(payload);
        return;
      case MessageEventType.VISIT_REMINDER:
        handleVisitReminderPayload(payload);
        return;
      case MessageEventType.CONSENT_NOTIFICATION:
        return;
      default:
        // TODO: [t] need to improve this by adding type for WS messagePayload
        // to determine if it is for Chat message specifically
        handleMessagePayload(payload);
    }
  };

  const handleMessageEvent = (
    message: string[],
  ) => {
    forEach(message, (msg) => {
      try {
        // stringified<payload: <stringified object>>
        if (!msg) return;
        const messageObj = JSON.parse(msg) || {};
        let parsedPayload: SubscriptionMessagePayload;
        if (messageObj.payload) {
          // chat
          parsedPayload = JSON.parse(messageObj.payload);
        } else {
          // video notification
          parsedPayload = messageObj;
        }
        processMessagePayload(parsedPayload);
      } catch (error) {
        console.error('Failed to parse message error: ', error);
      }
    });
  };

  return {
    handleMessageEvent,
  };
};
