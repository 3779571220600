import { Modal } from 'antd';
import { PatientHealthConditionControlLevelComponent } from './PatientHealthConditionControlLevelComponent';
import './PatientHealthConditionControlLevelComponent.scss';

export interface PatientHealthConditionControlLevelModalComponentProps {
  isOpen: boolean;
  close: () => void;
}

export const PatientHealthConditionControlLevelModalComponent = ({
  isOpen,
  close,
}: PatientHealthConditionControlLevelModalComponentProps) => (
  <Modal
    className="patient-health-condition-control-level-modal"
    footer={null}
    width={750}
    open={isOpen}
    centered
    onCancel={close} /* Handles close button or outside click */
    maskClosable={false}
    title={(
      <div className="title">
        Patient Health Condition Control Level
      </div>
    )}
    destroyOnClose
  >
    <PatientHealthConditionControlLevelComponent />
  </Modal>
);
