import './BehaviorChangeTooltipComponent.scss';
import { Typography } from 'antd';

const { Text } = Typography;

export const BehaviorChangeTooltipComponent = () => {
  const contentList = [
    {
      title: 'Precontemplation',
      content: 'Patients are not considering changing their behavior in the next six months.',
    },
    {
      title: 'Contemplation',
      content: 'Patients recognize the problem but are not yet ready to make a change.',
    },
    {
      title: 'Preparation',
      content: 'Patients are ready to take action and committed to making a change.',
    },
    {
      title: 'Action',
      content: 'Patients are actively modifying their behavior to address the problem.',
    },
    {
      title: 'Maintenance',
      content: 'Patients are working to sustain the change and prevent relapse.',
    },
    {
      title: 'Relapse',
      content: 'Patients slip back into old behaviors and habits',
    },
  ];

  const renderTableCell = (title: string, content: string) => (
    <div className="flex jc-c ai-c p10 behavior-change-tooltip-component__table-cell">
      <Text className="b7 w30">{title}</Text>
      <Text className="p0 w70">{content}</Text>
    </div>
  );

  return (
    <div className="behavior-change-tooltip-component">
      <Text className="b7">
        How to determine patient's Stages of Behavior Change
      </Text>
      <div className="behavior-change-tooltip-component__table mt20">
        {contentList.map(({ title, content }) => (
          <div key={title}>
            {renderTableCell(title, content)}
          </div>
        ))}
      </div>
      <div className="mt20">
        <Text>
          The stages of behavior change are based on the
          <strong> Transtheoretical Model (TTM),</strong>
          also known as
          <strong> the Stages of Change Model</strong>
          . This model helps identify the stage of behavioral change patients are in.
        </Text>
      </div>
    </div>
  );
};
