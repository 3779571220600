import { useUpdateEffect } from 'usehooks-ts';
import { useRef } from 'react';
import { useICDCodeRecommendationContext } from '../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ICDCodeSubmitValue } from '../../../ICDCode/hook/useICDCodeForm';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';
import { PatientDiagnosesEnum } from '../../../../uc-api-sdk';

export interface SimplifiedWorklistDiagnosesComponentProps {
  patientId: string;
  itemNumber?: number;
}

export const SimplifiedWorklistDiagnosesComponent = ({
  patientId,
  itemNumber = -1
}: SimplifiedWorklistDiagnosesComponentProps) => {
  const { info: { patientInfoService, enrolledProgramService } = {} } = usePatientContext();
  const {
    handleFetchRecommendation,
    handleChangeRecommendProtocol,
  } = useICDCodeRecommendationContext();
  const initialFetch = useRef<boolean>();
  const isLoading = (
    patientInfoService?.diagnoses === undefined
    || patientInfoService?.healthConditionService === undefined
    || patientInfoService?.complexityService === undefined
  );
  const patientHealthConditions = patientInfoService?.healthConditionService?.healthConditions;
  const patientDiagnoses = patientInfoService?.diagnoses || [];
  const patientComplexity = patientInfoService?.complexityService?.patientComplexity || {};
  const vitals = enrolledProgramService?.getVitals();
  const programCategories = enrolledProgramService?.getPrograms();

  const handleOnValuesChange = (
    changedValues: Partial<ICDCodeSubmitValue>,
    allValues: unknown,
  ) => {
    if ('diagnoses' in changedValues) {
      handleChangeRecommendProtocol(changedValues.diagnoses as PatientDiagnosesEnum[]);
    }
    if ('icdTable' in changedValues) {
      handleFetchRecommendation((allValues as ICDCodeSubmitValue).icdTable);
    }
  };

  useUpdateEffect(() => {
    if (initialFetch.current || patientHealthConditions === undefined) {
      return () => 0;
    }
    initialFetch.current = true;
    handleFetchRecommendation(patientHealthConditions);
    return () => {
      initialFetch.current = undefined;
    };
  }, [patientHealthConditions]);

  return (
    <div className="simplified-worklist-section">
      <WorklistSectionHeaderComponent
        number={itemNumber}
        title="Diagnosis/ICD code"
      />

      <PatientCommonCardComponent
        title=""
        content={(<div />)}
        formContent={(
          <LoadingOverlayComponent isLoading={isLoading}>
            <NestedFormComponent>
              <DiagnosisIcdCodeFormContainer
                patientId={patientId}
                initialValues={{
                  diagnoses: patientDiagnoses,
                  icdTable: patientHealthConditions,
                  complexity: patientComplexity,
                }}
                onValuesChange={handleOnValuesChange}
                programCategory={programCategories}
                vitals={vitals}
                formButtons={null}
                refetchSync={false}
              />
            </NestedFormComponent>
          </LoadingOverlayComponent>
        )}
        showEdit={false}
        isEditing
      />
    </div>
  );
};
