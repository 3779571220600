import { ReactNode } from 'react';
import { usePatientGetDiseaseHistory } from '../../../../uc-api-sdk';
import { DiseaseHistoryListComponent } from '../../../diseaseHistory/component/DiseaseHistoryListComponent/DiseaseHistoryListComponent';
import { DiseaseHistoryFormContainer } from '../../../diseaseHistory/container/DiseaseHistoryFormContainer/DiseaseHistoryFormContainer';
import { InterventionContainerProps } from '../../../intervention/type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { FormType } from '../../../Input/types';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface ProblemDiseaseHistoryContainerProps extends InterventionContainerProps {
  title?: ReactNode;
  onError?: () => void;
  onValuesChange?: FormType<unknown>['onValuesChange'];
  formButtons?: FormType<unknown>['formButtons'];
}

export const ProblemDiseaseHistoryContainer = ({
  title = 'Disease History',
  patientId,
  visitId,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onCancel,
  showEdit,
  showHeaderOutside,
  onValuesChange,
  formButtons,
}: ProblemDiseaseHistoryContainerProps) => {
  const diseaseHistoryInfo = usePatientGetDiseaseHistory({
    params: { memberId: patientId },
  });

  const refetch = useDebounce(diseaseHistoryInfo.refetch, 500);

  useUpdateListener('ICD_UPDATED', refetch);
  useUpdateListener('WORKLIST_UPDATED', refetch);

  const handleSubmit = () => {
    refetch();
    onSubmit?.();
  };

  const diseaseHistoryData = diseaseHistoryInfo.data?.data;

  return (
    <PatientCommonCardComponent
      title={title}
      showHeaderOutside={showHeaderOutside}
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={diseaseHistoryData}
      showEdit={showEdit}
      content={(
        <DiseaseHistoryListComponent
          diseaseHistoryList={diseaseHistoryData?.diseaseHistory || []}
          notes={diseaseHistoryData?.notes || []}
          isLoading={diseaseHistoryInfo.isLoading}
        />
      )}
      formContent={(
        <DiseaseHistoryFormContainer
          diseaseHistory={diseaseHistoryData}
          onSubmit={handleSubmit}
          onCancel={onCancel}
          onError={onError}
          patientId={patientId}
          isLoading={diseaseHistoryInfo.isLoading}
          onValuesChange={onValuesChange}
          formButtons={formButtons}
          visitId={visitId}
        />
      )}
    />
  );
};
