import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  find,
  map,
  omit,
  pick
} from 'lodash';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  ClinicalGoalItem,
  DiseaseHistoryItem,
  HealthConditionItem,
  PatientProtocolEnum,
  useHealthConditionGetRecommendDiseaseAndGoal,
  usePatientGetRecommendVitalAndProgram,
  usePatientGetPatientRecommendedCareProtocol,
  PatientDiagnosesEnum,
  ClinicGoalEnum
} from '../../uc-api-sdk';
import { usePatientContext } from '../PatientInfoContext/PatientInfoContext';
import { getDefaultClinicalGoalValue } from '../../helpers/clinicalGoal/getDefaultClinicalGoalValue';
import { useRefState } from '../../hooks/useRefState/useRefState';

enum RecommendationEnum {
  recommendedDiseaseHistory = 'recommendedDiseaseHistory',
  recommendedClinicalGoal = 'recommendedClinicalGoal',
}

interface RecommendationValue {
  [RecommendationEnum.recommendedDiseaseHistory]?: DiseaseHistoryItem[] | null;
  [RecommendationEnum.recommendedClinicalGoal]?: ClinicalGoalItem[] | null;
}

export interface ICDCodeRecommendationContextValue extends RecommendationValue {
  handleFetchRecommendation: (
    healthConditionItems?: HealthConditionItem[],
  ) => void;
  isLoading: boolean;
  processRecommendedClinicalGoal: (
    oldGoals?: ClinicalGoalItem[] | null,
    newGoals?: ClinicalGoalItem[] | null,
  ) => ClinicalGoalItem[];
  processRecommendedDiseaseHistory: (
    oldHistories?: DiseaseHistoryItem[] | null,
    newHistories?: DiseaseHistoryItem[] | null,
  ) => DiseaseHistoryItem[];
  recommendProtocol: PatientProtocolEnum | undefined;
  handleChangeRecommendProtocol: (
    diagnoses?: PatientDiagnosesEnum[]
  ) => void;
}

const ICDCodeRecommendationContext = createContext<
  ICDCodeRecommendationContextValue | undefined
>(undefined);

export const useICDCodeRecommendationContext = (
  values?: `${RecommendationEnum}`[]
) => {
  const context = useContext(ICDCodeRecommendationContext);
  const pickedContextValues = pick(context, values || []);
  const otherContextValues = omit(context, map(RecommendationEnum, (value) => value));
  return useGetContextValue({
    ...otherContextValues,
    ...pickedContextValues
  }) as ICDCodeRecommendationContextValue;
};

export interface ICDCodeRecommendationContextProviderProps {
  children: ReactNode;
}
export const ICDCodeRecommendationContextProvider = ({
  children,
}: ICDCodeRecommendationContextProviderProps) => {
  const hcRecommendInfo = useHealthConditionGetRecommendDiseaseAndGoal({});
  const recommendVitalInfo = usePatientGetRecommendVitalAndProgram({});
  const recommendProtocolInfo = usePatientGetPatientRecommendedCareProtocol({});
  const [
    recommendProtocol,
    setRecommendProtocol,
  ] = useState<ICDCodeRecommendationContextValue['recommendProtocol']>(undefined);
  const { info } = usePatientContext();
  const memberId = info?.id;
  const [
    getCurrentHealthConditionItems,
    setCurrentHealthConditionItems
  ] = useRefState<HealthConditionItem[] | undefined>();

  const isLoading = (
    hcRecommendInfo.isLoading
    || recommendVitalInfo.isLoading
    || recommendProtocolInfo.isLoading
  );
  const {
    diseaseList: recommendedDiseaseHistory,
    clinicalGoalList: recommendedClinicalGoal,
  } = hcRecommendInfo?.data?.data || {};

  const processRecommendedClinicalGoal: ICDCodeRecommendationContextValue['processRecommendedClinicalGoal'] = (
    oldGoals,
    newGoals,
  ) => map(newGoals, (goal) => {
    // recommended clinical goal is always from template
    const existingGoal = find(oldGoals, { condition: goal.condition });
    let newGoal = goal;
    if (existingGoal) {
      newGoal = {
        ...existingGoal,
        // only this need to be updated
        isManualInput: goal.isManualInput,
      };
    }
    if (
      !newGoal.clinicalGoalName
      || newGoal.clinicalGoalName === ClinicGoalEnum.CHOOSE_THE_OPTIONS
    ) {
      const defaultGoalValue = getDefaultClinicalGoalValue(
        goal?.condition,
        getCurrentHealthConditionItems()
      );
      newGoal.clinicalGoalName = defaultGoalValue;
    }
    return newGoal;
  });

  const processRecommendedDiseaseHistory: ICDCodeRecommendationContextValue['processRecommendedDiseaseHistory'] = (
    oldHistories,
    newHistories,
  ) => map(newHistories, (history) => {
    // recommended disease history is always from template
    const existingHistory = find(oldHistories, { disease: history.disease });
    if (existingHistory) {
      return {
        ...existingHistory,
        // only this need to be updated
        isManualInput: history.isManualInput,
      };
    }
    return history;
  });

  const handleChangeRecommendProtocol: ICDCodeRecommendationContextValue['handleChangeRecommendProtocol'] = (
    diagnoses
  ) => {
    if (!diagnoses) {
      return;
    }
    recommendProtocolInfo.send({
      params: {
        memberId: memberId as string,
        request: {
          diagnoses,
          icdCodes: [],
        }
      },
    });
  };

  useEffect(() => {
    if (recommendProtocolInfo.data?.data) {
      setRecommendProtocol(recommendProtocolInfo.data.data as PatientProtocolEnum);
    }
  }, [recommendProtocolInfo.data]);

  const handleFetchRecommendation: ICDCodeRecommendationContextValue['handleFetchRecommendation'] = async (
    healthConditionItems,
  ) => {
    if (!healthConditionItems?.length) {
      return;
    }
    setCurrentHealthConditionItems(healthConditionItems);
    hcRecommendInfo.send({
      params: { healthConditionItems },
    });
  };

  const contextValue = useGetContextValue<ICDCodeRecommendationContextValue>({
    handleFetchRecommendation,
    handleChangeRecommendProtocol,
    recommendProtocol,
    isLoading,
    recommendedClinicalGoal,
    recommendedDiseaseHistory,
    processRecommendedClinicalGoal,
    processRecommendedDiseaseHistory,
  });

  return (
    <ICDCodeRecommendationContext.Provider value={contextValue}>
      {children}
    </ICDCodeRecommendationContext.Provider>
  );
};
