import { compact, get, map } from 'lodash';
import { ReactNode } from 'react';
import { Medication } from '../../../../../uc-api-sdk';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { createMedicationColumns } from '../../../../../tables/MedicationsTables/columns';
import { DelimitedListComponent } from '../../../../../uiComponent/DelimitedListComponent/DelimitedListComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { MedicationType } from '../../../../medication/type/type';

import './PrintCarePlanMedicationItemComponent.scss';

export interface PrintCarePlanMedicationItemComponentProps {
  medication?: Medication;
}

export const PrintCarePlanMedicationItemComponent = ({
  medication,
}: PrintCarePlanMedicationItemComponentProps) => {
  if (!medication) {
    return null;
  }

  const renderContent = () => {
    const listOrder: (keyof typeof createMedicationColumns)[] = [
      'type',
      'status',
      'dosageFrequency',
      'dosage',
      'instruction',
      'note',
    ];
    const infoList = map(listOrder, (key, index) => {
      const { dataIndex, render } = createMedicationColumns[key]();
      const value = get(medication, dataIndex || '');
      if (!value) return null;
      return render?.(value, medication as (Medication & MedicationType), index);
    }) as ReactNode[];

    return (
      <DelimitedListComponent
        className="print-care-plan-preview__medication-item-content"
        list={compact(infoList)}
        render={(v) => (v as ReactNode)}
        delimiter="; "
        itemClassName="print-care-plan-preview__medication-item-content__item"
      />
    );
  };

  return (
    <div className="print-care-plan-preview__medication-item">
      <PrintCarePlanSimpleItemComponent
        label={(
          <TextComponent size="medium" fontWeight={600}>
            {medication.name}
            :
          </TextComponent>
        )}
        content={renderContent()}
      />
    </div>
  );
};
