import { useTranslateTranslate } from '../../../uc-api-sdk';

export const useMultilingualTranslation = () => {
  const translateAPI = useTranslateTranslate({});

  const multilingualTranslate = async (note: string, language: string) => {
    try {
      const response = await translateAPI.send({
        params: {
          request: {
            content: note,
            targetLanguage: language,
          }
        }
      });
      if (response && response.data) {
        return response.data.translatedContent || '';
      }
      console.error('Translation response is null or undefined');
    } catch (error) {
      console.error('Translation error:', error);
    }
    return '';
  };

  return {
    multilingualTranslate,
  };
};
