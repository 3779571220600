import { PatientHealthConditionControlLevelDetailComponent } from './PatientHealthConditionControlLevelDetailComponent';
import './PatientHealthConditionControlLevelComponent.scss';

export const PatientHealthConditionControlLevelComponent = () => (
  <div className="health-condition-control-level-component">
    <div className="explanation-text">
      Patient Health Condition Control Level measures
      how effectively a patient's diseases are being managed.
      Below are the criteria used to define control level of a patient with different vitals.
    </div>
    <PatientHealthConditionControlLevelDetailComponent />
  </div>
);
