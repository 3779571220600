import { Form } from 'antd';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import { MedicationReminderEnum, usePatientUpdateUcMedicationManagement } from '../../../../uc-api-sdk';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';
import { MedicationReminderFormItemComponent } from '../../../medication/component/MedicationTabFormComponent/MedicationReminderFormItemComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicationListNoteComponent } from '../../../medication/component/MedicationListComponent/MedicationListNoteComponent';
import { MedicationManagementService } from '../../../../services/MedicationManagementService';

import './WorklistMedReminderConsentFormContainer.scss';

interface SubmitValue {
  medicationReminder: MedicationReminderEnum;
  note?: string;
}

export interface WorklistMedReminderConsentFormContainerProps
  extends NestedFormComponentChildProps {
  patientId: string;
}

export const WorklistMedReminderConsentFormContainer = ({
  patientId,
  disabled,
  onSubmit,
  onError,
  onValuesChange,
}: WorklistMedReminderConsentFormContainerProps) => {
  const { clinicEvent } = useWorklistContext();
  const hook = useMedicationContainerForm();
  const {
    medicationManagementService,
    isLoadingObj,
  } = usePatientContext()?.info || {};
  const medManagementInfo = usePatientUpdateUcMedicationManagement({});
  const isLoading = medManagementInfo.isLoading || isLoadingObj?.medicationManagementLoading;

  const handleSubmit = (values: SubmitValue) => {
    const {
      isEditMedicationReminder
    } = MedicationManagementService.checkManagementEdit({
      medicationReminder: medicationManagementService?.medicationReminder,
    }, values);
    ApiRequestHelper.tryCatch(
      medManagementInfo.send({
        params: {
          memberId: patientId,
          request: {
            ...values,
            visitId: clinicEvent?.id,
            isEditMedicationReminder,
          },
        }
      }),
      {
        success: 'Medication Reminder Consent updated successfully',
        error: 'Failed to update Medication Reminder Consent.',
        onSuccess: onSubmit,
        onError,
      }
    );
  };

  return (
    <PatientCommonCardComponent
      title=""
      showHeaderOutside={false}
      isEditing
      showEdit={false}
      content={null}
      formContent={(
        <div className="relative worklist-med-reminder-consent">
          <OverlayLoading loading={!!isLoading} />
          {
            medicationManagementService
            && (
              <Form
                form={hook.form}
                initialValues={{
                  [hook.getName('medicationReminder')]: medicationManagementService?.medicationReminder,
                  [hook.getName('medicationReminderDeclineReason')]: (
                    medicationManagementService?.medicationReminderDeclineReason
                  ),
                  [hook.getName('note')]: medicationManagementService?.note,
                }}
                onValuesChange={onValuesChange}
                onFinish={handleSubmit}
                disabled={disabled || hook.formDisabled}
                layout="vertical"
              >
                <MedicationReminderFormItemComponent
                  required
                  medicationReminderLastInfo={medicationManagementService?.medReminderUpdateInfo}
                />
                <FormItem
                  info={hook.getInfo('note')}
                  labelCol={{ span: 24 }}
                >
                  <MedicationListNoteComponent />
                </FormItem>
              </Form>
            )
          }
        </div>
      )}
    />
  );
};
