/* eslint-disable no-shadow */
// eslint-disable-next-line no-shadow
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useEventListener } from 'usehooks-ts';
import { _StorageKeyEnum } from './storageKey';
import { useDeepCompareMemo } from '../useDeepCompareEffect';

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  let parsedValue: T | undefined;
  try {
    parsedValue = JSON.parse(value ?? '');
  } catch {
    // console.error('parsing error on', { value });
  }
  return parsedValue || undefined;
}

type SetValue<T> = Dispatch<SetStateAction<T>>

type RemoveItem = () => void;

const useBaseSessionStorage = <T = unknown>(
  key: _StorageKeyEnum,
  initialValue: T,
): [T | undefined, SetValue<T>, RemoveItem] => {
  // Get from session storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.sessionStorage.getItem(key as string);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${String(key)}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue: SetValue<T> = useCallback((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting sessionStorage key “${String(key)}” even though environment is not a client`,
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to session storage
      window.sessionStorage.setItem(key as string, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useSessionStorage hook are notified
      window.dispatchEvent(new Event('session-storage'));
    } catch (error) {
      console.warn(`Error setting sessionStorage key “${String(key)}”:`, error);
    }
  }, []);

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  const handleStorageChange = useCallback(
    (event: Event) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  const removeItem = useCallback(() => {
    window.sessionStorage.removeItem(key as string);
  }, [key]);

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueTosessionStorage
  // See: useSessionStorage()
  useEventListener('session-storage', handleStorageChange);

  return useDeepCompareMemo(() => (
    [storedValue, setValue, removeItem]
  ), [storedValue]);
};

const useSessionStorage = <T = unknown>(
  key: _StorageKeyEnum,
  initialValue = null as T,
) => useBaseSessionStorage<T>(key, initialValue);

export {
  _StorageKeyEnum as StorageKeyEnum,
  useSessionStorage,
};
