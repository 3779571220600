import { Switch, Tooltip } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';

export interface MedicationReminderFormItemComponentProps { }

export const MedicationReminderSwitchFormItemComponent = () => {
  const formHook = useMedicationForm();

  const getTooltip = (
    shouldDisableReminder: boolean,
    hasFrequency: boolean,
  ) => {
    if (!shouldDisableReminder) {
      // only show tooltip when disabled
      return undefined;
    }
    if (!hasFrequency) {
      return 'To enable reminder, please select the dosing frequency first';
    }
    return 'The reminder does not yet support the dosing frequency selected above';
  };

  return (
    <div className="flex ai-bl jc-sb">
      <div>
        Enable medication reminder
      </div>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldValue }) => {
            const shouldDisableReminder = formHook.shouldDisableReminder(getFieldValue);
            const hasFrequency = !!formHook.getValue('frequency', getFieldValue);
            return (
              <Tooltip
                title={getTooltip(shouldDisableReminder, hasFrequency)}
                placement="topRight"
                destroyTooltipOnHide
              >
                <FormItem
                  info={formHook.getInfo('enableReminder')}
                  valuePropName="checked"
                >
                  <Switch
                    size="small"
                    disabled={shouldDisableReminder}
                  />
                </FormItem>
              </Tooltip>
            );
          }
        }
      </FormItem>
    </div>
  );
};
