import { message } from 'antd';
import { isEmpty } from 'lodash';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { InsuranceSubmitValue, PriorAuthSubmitValue } from '../../../hooks/formHook/useInsuranceForm';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import {
  usePatientInsuranceCancelPriorAuth,
  usePatientInsuranceUpdate,
  usePatientInsuranceUpdateInsuranceNote,
  usePatientInsuranceUpdatePriorAuth
} from '../../../uc-api-sdk';
import {
  InsuranceRenewPriorAuthParams,
  usePatientInsuranceRenewPriorAuth
} from './insuranceApis';
import { useInsuranceHelper } from './useInsuranceHelper';

export const useInsuranceUpdateHandler = (
  memberId?: string,
) => {
  const {
    parseFormValues,
    parsePriorAuthCaseFormValues,
    validatePriorAuthCase,
  } = useInsuranceHelper();
  const updateInsuranceInfo = usePatientInsuranceUpdate({});
  const updateInsuranceNoteHook = usePatientInsuranceUpdateInsuranceNote({});
  const renewPriorAuthInfo = usePatientInsuranceRenewPriorAuth({});
  const cancelPriorAuthInfo = usePatientInsuranceCancelPriorAuth({});
  const updatePriorAuthCaseInfo = usePatientInsuranceUpdatePriorAuth({});

  const updateInsurance = <T extends InsuranceSubmitValue>(
    submitValues?: T,
    options?: Parameters<typeof ApiRequestHelper['tryCatch']>[1],
    patientId?: string,
  ) => {
    if (isEmpty(submitValues)) {
      return undefined;
    }
    if (!validatePriorAuthCase(submitValues)) {
      message.error('Failed to update insurance: Missing required fields.');
      return undefined;
    }
    const values = parseFormValues(submitValues);
    if (!isEmpty(values)) {
      if (!memberId && !patientId) {
        throw new Error('memberId or patientId is required');
      }
      return ApiRequestHelper.tryCatch(
        updateInsuranceInfo.send({
          params: {
            request: {
              memberId: memberId || patientId,
              ...values,
            },
          },
        }),
        options,
      );
    }
    return undefined;
  };

  const updateInsuranceNote = (
    note: string,
    options?: Parameters<typeof ApiRequestHelper['tryCatch']>[1],
    patientId?: string,
  ) => {
    if (!memberId && !patientId) {
      throw new Error('memberId or patientId is required');
    }
    return ApiRequestHelper.tryCatch(
      updateInsuranceNoteHook.send({
        params: {
          memberId: memberId || patientId || '',
          note,
        },
      }),
      options,
    );
  };

  const updatePriorAuthCase = <T extends PriorAuthSubmitValue>(
    values: T,
    options?: Parameters<typeof ApiRequestHelper['tryCatch']>[1],
    patientId?: string,
  ) => {
    const formValues = parsePriorAuthCaseFormValues(values);
    if (!isEmpty(formValues)) {
      if (!memberId && !patientId) {
        throw new Error('memberId or patientId is required');
      }
      return (
        ApiRequestHelper.tryCatch(
          updatePriorAuthCaseInfo.send({
            params: {
              memberId: memberId || patientId || '',
              request: formValues,
            }
          }),
          options,
        )
      );
    }
    return undefined;
  };

  const cancelPriorAuthCase = (
    options?: Parameters<typeof ApiRequestHelper['tryCatch']>[1],
    patientId?: string
  ) => {
    if (!memberId && !patientId) {
      throw new Error('memberId or patientId is required');
    }
    return ApiRequestHelper.tryCatch(
      cancelPriorAuthInfo.send({
        params: {
          memberId: memberId || patientId || '',
        },
      }),
      options
    );
  };

  const renewPriorAuthCase = (
    options?: Parameters<typeof ApiRequestHelper['tryCatch']>[1],
    values?: Omit<InsuranceRenewPriorAuthParams, 'memberId'>,
    patientId?: string,
  ) => {
    if (!memberId && !patientId) {
      throw new Error('memberId or patientId is required');
    }
    return ApiRequestHelper.tryCatch(
      renewPriorAuthInfo.send({
        params: {
          memberId: memberId || patientId || '',
          ...values
        },
      }),
      options
    );
  };

  return useGetContextValue({
    updateInsuranceInfo,
    updateInsurance,
    cancelPriorAuthInfo,
    cancelPriorAuthCase,
    renewPriorAuthInfo,
    renewPriorAuthCase,
    updatePriorAuthCaseInfo,
    updatePriorAuthCase,
    updateInsuranceNote,
  });
};
