import { InputType } from '../../../Input/types';
import { MedicationReminderEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { MedicationListReminderServiceEnumComponent } from '../../../../enumComponent/MedicationListReminderServiceEnumComponent/MedicationListReminderServiceEnumComponent';

export interface MedicationListReminderServiceSelectComponentProps
  extends InputType<MedicationReminderEnum> { }

export const MedicationListReminderServiceSelectComponent = ({
  value,
  onChange,
}: MedicationListReminderServiceSelectComponentProps) => {
  const optionRenderer: EnumSelectComponentProps<MedicationReminderEnum>['optionRenderer'] = (
    option,
  ) => (
    <MedicationListReminderServiceEnumComponent value={option.value} />
  );

  return (
    <EnumSelectComponent
      value={value}
      onChange={onChange}
      optionRenderer={optionRenderer}
      options={MedicationReminderEnum}
      allowClear
    />
  );
};
