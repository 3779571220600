import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FC } from 'react';

const { TextArea } = Input;
export interface MedicationNoteInputComponentProps {
  value?: TextAreaProps['value'];
  onChange?: TextAreaProps['onChange']
}

export const MedicationNoteInputComponent: FC<MedicationNoteInputComponentProps> = ({
  value,
  onChange,
}) => (
  <TextArea
    rows={3}
    value={value}
    onChange={onChange}
    maxLength={250}
    placeholder="Note"
    allowClear
    onKeyDown={(e) => {
      e.stopPropagation();
    }}
  />
);
