import { isEmpty } from 'lodash';
import {
  CarePlan,
  MedicationReminderEnum,
  VisitTypeEnum,
} from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { MedicationListReminderServiceEnumComponent } from '../../../../../enumComponent/MedicationListReminderServiceEnumComponent/MedicationListReminderServiceEnumComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { MedicationListReviewEnumComponent } from '../../../../../enumComponent/MedicationListReviewEnumComponent/MedicationListReviewEnumComponent';
import { PrintCarePlanMedicationItemComponent } from './PrintCarePlanMedicationItemComponent';

export interface PrintCarePlanMedicationComponentProps {
  carePlan?: CarePlan;
}

export const PrintCarePlanMedicationComponent = ({
  carePlan,
}: PrintCarePlanMedicationComponentProps) => {
  const {
    visit,
    medication,
    medicationList,
  } = carePlan || {};
  if (isEmpty(medication)) {
    return null;
  }
  if (visit?.visitType === VisitTypeEnum.INITIAL) {
    return (
      <>
        <div className="page-break" />
        <TextComponent className="pop-text">
          Obtain Patient Consent for Medication Reminders
        </TextComponent>
        <MedicationListReminderServiceEnumComponent
          value={medication.medicationReminder as MedicationReminderEnum}
        />
      </>
    );
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Medication
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label="Medication Review:"
        content={(
          <MedicationListReviewEnumComponent
            value={medication?.medicationCompliance}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Note:"
        content={<DisplayOrEmptyComponent value={medication?.note} />}
      />
      <PrintCarePlanSimpleItemComponent
        label="Medication Reminder Service:"
        content={(
          <MedicationListReminderServiceEnumComponent
            value={medication?.medicationReminder as MedicationReminderEnum}
          />
        )}
      />
      {
        !!medicationList?.length && (
          <>
            <TextComponent
              className="mt12"
              size="medium"
              bold
            >
              Prescribed Medication
            </TextComponent>
            <ul>
              {
                medicationList.map((medicationItem) => (
                  <li key={medicationItem?.id}>
                    <PrintCarePlanMedicationItemComponent
                      medication={medicationItem}
                    />
                  </li>
                ))
              }
            </ul>
          </>
        )
      }
    </>
  );
};
