import { Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { CPTCodeFormItemComponent } from '../CPTCodeFormItemComponent/CPTCodeFormItemComponent';
import { PriorAuthAuthPeriodFormItemComponent } from '../PriorAuthAuthPeriodFormItemComponent/PriorAuthAuthPeriodFormItemComponent';

export interface PriorAuthPendingFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthPendingFormItemComponent = ({
  fieldName,
}: PriorAuthPendingFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();

  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent
        fieldName={fieldName}
      />

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthAuthorizationNumber', fieldName)}
        label={insuranceForm.getLabel('priorAuthAuthorizationNumber')}
      >
        <Input />
      </FormItem>

      <CPTCodeFormItemComponent
        fieldName={fieldName}
      />

      <PriorAuthAuthPeriodFormItemComponent
        fieldName={fieldName}
      />
    </>
  );
};
