import { Space } from 'antd';
import { ReactNode } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { PatientCommonCardTitleComponent } from '../PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { UpdatedByAt, UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';

import './PatientCommonCardComponent.scss';

export interface PatientCommonCardComponentProps<T extends UpdatedByAt> {
  title: ReactNode;
  showEdit?: boolean;
  content: ReactNode;
  formContent?: ReactNode;
  updateInfo?: T | null | undefined;
  isEditing?: boolean;
  onEdit?: () => void;
  showHeaderOutside?: boolean;
  showUpdateInfo?: boolean;
}

export const PatientCommonCardComponent = <T extends UpdatedByAt>({
  title,
  content,
  formContent,
  showEdit = true,
  isEditing = false,
  onEdit,
  updateInfo,
  showHeaderOutside = true,
  showUpdateInfo = true,
}: PatientCommonCardComponentProps<T>) => {
  const renderHeader = () => (
    <div className="flex jc-sb mb20">
      <PatientCommonCardTitleComponent>
        {title}
      </PatientCommonCardTitleComponent>
      {showUpdateInfo && (
      <div className="patient-common-card-last-time-update">
        <Space>
          {showEdit && (
            <EditOutlined
              className="patient-common-card-edit"
              onClick={onEdit}
            />
          )}
          <UpdatedByAtComponent
            info={updateInfo}
          />
        </Space>
      </div>
      )}
    </div>
  );

  return (
    <div className="patient-common-card-component-wrapper">
      {showHeaderOutside && renderHeader()}
      <div className="patient-common-card-component">
        {!showHeaderOutside && renderHeader()}
        <div className="patient-common-card-content">
          {isEditing ? formContent : content}
        </div>
      </div>
    </div>
  );
};
