import { VisitTypeEnum } from '../../../uc-api-sdk';

export const allVisitTypes: VisitTypeEnum[] = [
  VisitTypeEnum.INITIAL,
  VisitTypeEnum.TECH_ONBOARDING,
  VisitTypeEnum.POST_MD_FOLLOW_UP,
  VisitTypeEnum.ADDITIONAL,
  VisitTypeEnum.TECH,
  VisitTypeEnum.INIT,
  VisitTypeEnum.FOLLOW_UP,
];
