import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { MakeSchedulesComponent } from '../../../patient/component/PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesComponent';

export interface FollowUpScheduleButtonComponentProps {
  disabled?: boolean;
}

export const FollowUpScheduleButtonComponent = ({
  disabled,
}: FollowUpScheduleButtonComponentProps) => {
  const { info } = usePatientContext();
  return (
    <MakeSchedulesComponent
      disabled={disabled}
      btnText="Schedule the Visit"
      btnType="primary"
      icon={null}
      visitType={VisitTypeEnum.ADDITIONAL}
      shouldTriggerVisitType
      assignees={info?.assigneesService?.getAssignees()}
    />
  );
};
