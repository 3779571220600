import { InputType } from '../../../Input/types';
import { MedicationReminderDeclineReasonEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { MedicationReminderDeclineReasonEnumComponent } from '../../../../enumComponent/MedicationListReminderDeclineReasonEnumComponent/MedicationListReminderDeclineReasonEnumComponent';

export interface MedicationListDeclineReasonSelectComponentProps
  extends InputType<MedicationReminderDeclineReasonEnum> { }

export const MedicationListDeclineReasonSelectComponent = ({
  value,
  onChange,
}: MedicationListDeclineReasonSelectComponentProps) => {
  const optionRenderer: EnumSelectComponentProps<MedicationReminderDeclineReasonEnum>['optionRenderer'] = (
    option,
  ) => (
    <MedicationReminderDeclineReasonEnumComponent value={option.value} />
  );

  return (
    <EnumSelectComponent
      value={value}
      onChange={onChange}
      optionRenderer={optionRenderer}
      options={MedicationReminderDeclineReasonEnum}
      allowClear
    />
  );
};
