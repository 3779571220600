import { isEmpty } from 'lodash';
import { HealthConditionItem } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintHealthConditionsComponent } from '../../../../vitals/component/PrintVitalComponent/PrintHealthConditionsComponent';

import './PrintCarePlanDiagnosisHealthConditionsComponent.scss';

export interface PrintCarePlanDiagnosisHealthConditionsComponentProps {
  // diagnosis?: PatientDiagnosesEnum[] | null;
  healthConditions?: HealthConditionItem[] | null;
}

export const PrintCarePlanDiagnosisHealthConditionsComponent = ({
  // diagnosis,
  healthConditions,
}: PrintCarePlanDiagnosisHealthConditionsComponentProps) => {
  if (isEmpty(healthConditions)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Diagnosis and Health Conditions
      </TextComponent>
      {/* <div className="mb5">
        <div className="print-care-plan-preview__diagnosis">
          <PrintCarePlanSimpleItemComponent
            label="Diagnosis:"
            content={
              diagnosis?.length
                ? (
                  <DelimitedListComponent
                    list={diagnosis}
                    render={(v) => <PatientDiagnosesEnumComponent value={v} />}
                    className="flex-i f-w"
                  />
                ) : <EmptyComponent />
            }
          />
        </div>
      </div> */}
      <PrintHealthConditionsComponent
        healthConditions={healthConditions || []}
      />
    </>
  );
};
