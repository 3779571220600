import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Card } from 'antd';
import { useFutureClinicEventContext } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import { MakeSchedulesComponent } from '../../../patient/component/PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesComponent';
import { PatientProtocolEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { FutureFollowUpScheduleComponent } from '../FutureFollowUpScheduleComponent/FutureFollowUpScheduleComponent';
import { useICDCodeRecommendationContext } from '../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface SimplifiedWorklistFutureClinicEventComponentProps {
  visitType?: VisitTypeEnum;
}

export const SimplifiedWorklistFutureClinicEventComponent = ({
  visitType = VisitTypeEnum.INITIAL,
}: SimplifiedWorklistFutureClinicEventComponentProps) => {
  const { futureClinicEventServices } = useFutureClinicEventContext();
  const hasVisits = !isEmpty(futureClinicEventServices);
  const { recommendProtocol } = useICDCodeRecommendationContext();
  const { info } = usePatientContext();
  const { controlLevelService } = info || {};

  const currentProtocol = useMemo(() => controlLevelService?.getControlLevelInfo()?.protocol, [
    controlLevelService?.getControlLevelInfo()
  ]);

  const renderTextOne = () => (
    <div>
      1. Schedule a
      <strong> Post-MD Follow-up visit </strong>
      on the same day as the patient's next provider appointment.
    </div>
  );

  const renderDMInitTextTwo = () => (
    <div>
      2. This patient follows the DM2 care protocol,
      schedule an additional visit with the RD/HC within one week.
    </div>
  );

  const renderHtnInitTextTwo = () => (
    <div>
      2. This patient follows the HTN care protocol,
      if the patient is uncontrolled,
      offer an additional visit with the RD/HC. Schedule it if the patient accepts.
    </div>
  );

  const renderDefaultInitTextTwo = () => (
    <div>
      2. This patient follows the Default care protocol,
      schedule an additional visit with the RD/HC within one week.
    </div>
  );

  const renderDMFollowUpTextTwo = () => (
    <div>
      2. This patient follows the DM2 care protocol,
      make sure patient has an upcoming additional visit scheduled with RD/HC.
    </div>
  );

  const renderHtnFollowUpTextTwo = () => (
    <div>
      2. This patient follows the HTN care protocol,
      if the patient is uncontrolled,
      offer an additional visit with the RD/HC. Schedule it if the patient accepts.
    </div>
  );

  const renderDefaultFollowUpTextTwo = () => (
    <div>
      2. This patient follows the Default care protocol,
      make sure patient has an upcoming additional visit scheduled with RD/HC.
    </div>
  );

  const renderTextTwo = () => {
    if (visitType === VisitTypeEnum.INITIAL) {
      if (recommendProtocol === PatientProtocolEnum.DM2
        || currentProtocol === PatientProtocolEnum.DM2) {
        return renderDMInitTextTwo();
      }
      if (recommendProtocol === PatientProtocolEnum.HTN
        || currentProtocol === PatientProtocolEnum.HTN) {
        return renderHtnInitTextTwo();
      }
      if (recommendProtocol === PatientProtocolEnum.OTHER
        || currentProtocol === PatientProtocolEnum.OTHER) {
        return renderDefaultInitTextTwo();
      }
    }
    if (visitType === VisitTypeEnum.POST_MD_FOLLOW_UP) {
      if (recommendProtocol === PatientProtocolEnum.DM2
        || currentProtocol === PatientProtocolEnum.DM2) {
        return renderDMFollowUpTextTwo();
      }
      if (recommendProtocol === PatientProtocolEnum.HTN
        || currentProtocol === PatientProtocolEnum.HTN) {
        return renderHtnFollowUpTextTwo();
      }
      if (recommendProtocol === PatientProtocolEnum.OTHER
        || currentProtocol === PatientProtocolEnum.OTHER) {
        return renderDefaultFollowUpTextTwo();
      }
    }
    return null;
  };

  return (
    <Card className="flex fd-c gap2">
      {renderTextOne()}
      {renderTextTwo()}
      {
        hasVisits
        && (
          <div className="pt20">
            <FutureFollowUpScheduleComponent clinicEvents={futureClinicEventServices} />
          </div>
        )
      }
      <div className="pt12">
        <MakeSchedulesComponent
          btnText="Schedule new visit(s)"
          icon={null}
        />
      </div>
    </Card>
  );
};
