import { PatientDiagnosesEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PatientDiagnosesEnumComponentProps {
  value?: PatientDiagnosesEnum | null;
}

export const PatientDiagnosesEnumComponent = ({
  value,
}: PatientDiagnosesEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case PatientDiagnosesEnum.CHF: return 'CHF';
      case PatientDiagnosesEnum.COPD: return 'COPD';
      case PatientDiagnosesEnum.DM: return 'DM';
      case PatientDiagnosesEnum.DM2: return 'DM2';
      case PatientDiagnosesEnum.DM_OTHERS: return 'DM Others';
      // Chronic kidney disease, also called chronic kidney failure;
      case PatientDiagnosesEnum.CKF: case PatientDiagnosesEnum.CKD: return 'CKD';
      case PatientDiagnosesEnum.RDD: return 'RDD';
      case PatientDiagnosesEnum.HYPOTENSION: return 'Hypotension';
      case PatientDiagnosesEnum.ESRD_DIALYSIS: return 'ESRD Dialysis';
      case PatientDiagnosesEnum.HLD: return 'HLD';
      case PatientDiagnosesEnum.HTN: return 'HTN';
      case PatientDiagnosesEnum.OBESITY: return 'Obesity';
      case PatientDiagnosesEnum.OTHERS: return 'Others';
      case PatientDiagnosesEnum.PRE_DM: return 'PreDM';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
