import React from 'react';
import { ControlLevelEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';
import { ControlLevelTagComponent } from '../../features/patients/component/ControlLevelTagComponent/ControlLevelTagComponent';
import './ControlLevelEnumComponent.scss';

export interface ControlLevelEnumComponentProps {
  value: ControlLevelEnum | undefined;
  type?: 'tag' | 'filter' | 'name';
}

export const ControlLevelEnumComponent = ({
  value,
  type = 'tag',
}: ControlLevelEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case ControlLevelEnum.CONTROLLED:
        return <ControlLevelTagComponent.ControlledTag />;
      case ControlLevelEnum.UNCONTROLLED:
        return <ControlLevelTagComponent.UncontrolledTag />;
      default:
        return <ControlLevelTagComponent.UncategorizedTag />;
    }
  };

  const getName = (): React.ReactNode => {
    switch (value) {
      case ControlLevelEnum.CONTROLLED:
        return <span className="controlled-text">Controlled</span>;
      case ControlLevelEnum.UNCONTROLLED:
        return <span className="uncontrolled-text">Uncontrolled</span>;
      default:
        return <span className="uncategorized-text">Uncategorized</span>;
    }
  };

  const getFilter = (): React.ReactNode => {
    switch (value) {
      case ControlLevelEnum.CONTROLLED:
        return 'Controlled';
      case ControlLevelEnum.UNCONTROLLED:
        return 'Uncontrolled';
      case ControlLevelEnum.UNCATEGORIZED:
        return 'Uncategorized';
      default:
        return <EmptyComponent />;
    }
  };

  switch (type) {
    case 'tag':
      return <>{getTag()}</>;
    case 'filter':
      return <>{getFilter()}</>;
    case 'name':
      return <>{getName()}</>;
    default:
      return <>{getTag()}</>;
  }
};
