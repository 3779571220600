import { MedicationReminderEnum } from '../../uc-api-sdk';

export const getMedicationReminderText = (value?: MedicationReminderEnum | null) => {
  switch (value) {
    case MedicationReminderEnum.PATIENT_ACCEPTED_MEDICATION_REMINDER:
      return 'Patient accepted medication reminder';
    case MedicationReminderEnum.PATIENT_DECLINED_MEDICATION_REMINDER:
      return 'Patient declined medication reminder';
    default:
      return null;
  }
};

export interface MedicationListReminderServiceEnumComponentProps {
  value: MedicationReminderEnum | null;
}

export const MedicationListReminderServiceEnumComponent = ({
  value,
}: MedicationListReminderServiceEnumComponentProps) => (
  <span>{getMedicationReminderText(value)}</span>);
