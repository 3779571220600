import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { SimplifiedWorklistFutureClinicEventComponent } from '../SimplifiedWorklistFutureClinicEventComponent/SimplifiedWorklistFutureClinicEventComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';

export interface SimplifiedWorklistNextVisitComponentProps {
  itemNumber?: number;
  visitType?: VisitTypeEnum;
}

export const SimplifiedWorklistNextVisitComponent = ({
  itemNumber = -1,
  visitType = VisitTypeEnum.INITIAL,
}: SimplifiedWorklistNextVisitComponentProps) => (
  <div className="simplified-worklist-section">
    <WorklistSectionHeaderComponent
      number={itemNumber}
      title="Schedule Next Visit(s)"
    />
    <NestedFormComponent>
      <WorklistContentComponent>
        <SimplifiedWorklistFutureClinicEventComponent visitType={visitType} />
      </WorklistContentComponent>
    </NestedFormComponent>
  </div>
);
