import { map } from 'lodash';
import { MedicalAlertCategoryEnumComponent } from '../../../enumComponent/MedicalAlertCategoryEnumComponent/MedicalAlertCategoryEnumComponent';
import { getAlertEventValue, MedicalAlertEventTypeEnumComponent } from '../../../enumComponent/MedicalAlertEventTypeEnumComponent/MedicalAlertEventTypeEnumComponent';
import { PriorityEnumComponent } from '../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { ColumnType } from '../../../types/table';
import {
  EventTypeEnum,
  MedicalAlertCategoryEnum,
  MedicalAlertSummary,
  PriorityLevelEnum,
} from '../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { MedicalAlertNameComponent } from '../component/MedicalAlertNameComponent/MedicalAlertNameComponent';
import { getAlertPriorityFilters, getAlertPrioritySorter } from './tableUtils';

interface CreateAlertSummaryColumnsProps {
  availableAlertDisplayNames: string[];
}

export const createAlertSummaryColumns = ({
  availableAlertDisplayNames,
} = {} as CreateAlertSummaryColumnsProps) => ({
  alertDisplayName: (): ColumnType<MedicalAlertSummary> => ({
    key: 'alertDisplayName',
    dataIndex: 'alertDisplayName',
    title: 'Medical alerts',
    width: 275,
    render: (v, record) => (
      <MedicalAlertNameComponent
        alertName={record.alertDisplayName || ''}
        priority={record.priority as PriorityLevelEnum}
      />
    ),
    filters: map(
      availableAlertDisplayNames,
      (v) => ({ text: v, value: v })
    ),
    onFilter: (value, record) => record.alertDisplayName === value,
  }),
  eventType: (): ColumnType<MedicalAlertSummary> => ({
    key: 'eventType',
    dataIndex: 'eventType',
    title: 'Related vitals',
    width: 155,
    render: (v, record) => (
      <MedicalAlertEventTypeEnumComponent
        value={record.eventType}
      />
    ),
    filters: map([
      EventTypeEnum.BP,
      EventTypeEnum.BG
    ], (v) => ({ text: v, value: v })),
    onFilter: (value, record) => getAlertEventValue(record.eventType) === value,
  }),
  priority: (): ColumnType<MedicalAlertSummary> => ({
    key: 'priority',
    dataIndex: 'priority',
    title: 'Priority',
    width: 120,
    render: (v, record) => (
      <PriorityEnumComponent
        value={record.priority as PriorityLevelEnum}
        type="filter"
      />
    ),
    ...getAlertPriorityFilters(),
    ...getAlertPrioritySorter(),
  }),
  alertCategory: (): ColumnType<MedicalAlertSummary> => ({
    key: 'alertCategory',
    dataIndex: 'alertCategory',
    title: 'Alert type',
    width: 155,
    render: (v, record) => (
      <MedicalAlertCategoryEnumComponent
        value={(record.alertCategory || '') as MedicalAlertCategoryEnum}
      />
    ),
    filters: map(
      MedicalAlertCategoryEnum,
      (v) => ({
        text: <MedicalAlertCategoryEnumComponent value={(v || '') as MedicalAlertCategoryEnum} />,
        value: v
      })
    ),
    onFilter: (value, record) => record.alertCategory === value,
  }),
  totalCount: (): ColumnType<MedicalAlertSummary> => ({
    key: 'totalCount',
    dataIndex: 'totalCount',
    title: '# triggered',
    width: 120,
    sorter: {
      compare: (a, b) => (
        typeof a?.totalCount === 'number' && typeof b?.totalCount === 'number'
          ? a.totalCount - b.totalCount
          : 0
      ),
    },
  }),
  lastTriggeredOnTs: (): ColumnType<MedicalAlertSummary> => ({
    key: 'lastTriggeredOnTs',
    dataIndex: 'lastTriggeredOnTs',
    title: 'Last triggered on',
    width: 165,
    render: (v, record) => (
      <DisplayDateComponent
        value={record.lastTriggeredOnTs}
        format="USA_DATE"
      />
    ),
    sorter: {
      compare: (a, b) => (
        typeof a?.lastTriggeredOnTs === 'number' && typeof b?.lastTriggeredOnTs === 'number'
          ? a.lastTriggeredOnTs - b.lastTriggeredOnTs
          : 0
      ),
    },
  }),
});
