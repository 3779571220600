import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OverlayComponent } from '../../uiComponent/overlayComponent/OverlayComponent';
import { useUserActiveContext } from '../UserActiveContext/UserActiveContext';

export interface LastIdle {
  timestamp: number | undefined;
  timeLength: number;
}
export interface Value {
  isActive: boolean;
  sessionId: string;
  lastIdle: LastIdle;
  resetSessionId: () => string;
  resetIdleTime: () => void;
}

export const BillableTimeContext = React.createContext<Value>({
  isActive: true,
  sessionId: '',
  lastIdle: {
    timestamp: undefined,
    timeLength: 0,
  },
  resetSessionId: () => {
    console.error('resetSessionId: BillableTimeContext has not been implemented yet!');
    return '';
  },
  resetIdleTime: () => {
    console.error('resetIdleTime: BillableTimeContext has not been implemented yet!');
  },
});

export interface BillableTimeProviderProps {
  children: React.ReactNode;
}

const initialLastIdle: LastIdle = { timeLength: 0, timestamp: undefined };

export const BillableTimeProvider: FC<BillableTimeProviderProps> = ({
  children,
}) => {
  const { isActive } = useUserActiveContext();
  const [lastIdle, setLastIdle] = useState<LastIdle>(initialLastIdle);
  const [sessionId, setSessionId] = useState(uuidv4());

  useEffect(() => {
    if (isActive) {
      setLastIdle((prev) => ({
        timeLength: Date.now() - (prev.timestamp || Date.now()),
        timestamp: undefined,
      }));
    } else {
      setLastIdle({
        timestamp: Date.now(),
        timeLength: 0,
      });
    }
  }, [isActive]);

  const resetSessionId = useCallback(() => {
    const newId = uuidv4();
    setSessionId(newId);
    return newId;
  }, [setSessionId]);

  const resetIdleTime = useCallback(() => {
    setLastIdle(initialLastIdle);
  }, [setLastIdle]);

  const contextValue = useMemo<Value>(() => ({
    isActive,
    sessionId,
    lastIdle,
    resetSessionId,
    resetIdleTime,
  }), [
    isActive,
    sessionId,
    lastIdle,
    resetSessionId,
    resetIdleTime,
  ]);

  const renderNotActiveOverlay = (
    <h1>Inactive</h1>
  );

  return (
    <BillableTimeContext.Provider value={contextValue}>
      <OverlayComponent overlay={renderNotActiveOverlay} showOverlay={false}>
        {children}
      </OverlayComponent>
    </BillableTimeContext.Provider>
  );
};

export const useBillableTimeContext = () => {
  const billableTimeContextValue = useContext<Value>(BillableTimeContext);
  return billableTimeContextValue;
};
