import { Button, Form, Input } from 'antd';
import { A1CCustomizeGoalComponent } from '../../component/A1CCustomizeGoalComponent/A1CCustomizeGoalComponent';
import { BgGoal, usePatientUpdatePatientA1cGoal } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface A1CCustomizeGoalModalContainerProps {
  patientId: string;
  initialValues?: BgGoal;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const A1CCustomizeGoalModalContainer = ({
  patientId,
  initialValues,
  onSubmit,
  onCancel,
}: A1CCustomizeGoalModalContainerProps) => {
  const a1cCustomizeGoalInfo = usePatientUpdatePatientA1cGoal({});
  const disabledButton = a1cCustomizeGoalInfo.isLoading;
  const updateHook = useUpdate('CUSTOMIZED_A1C_GOAL_UPDATED');

  const handleSubmit = (values: BgGoal) => {
    ApiRequestHelper.tryCatch(
      a1cCustomizeGoalInfo.send({
        params: {
          memberId: patientId,
          goal: values,
        },
      }),
      {
        success: 'Updated A1C goal successfully',
        error: 'Failed to update A1C goal',
        onSuccess: () => {
          updateHook.updateValue();
          onSubmit?.();
        },
      }
    );
  };

  const debouncedSubmit = useDebounce(handleSubmit);

  return (
    <Form
      onFinish={debouncedSubmit}
      initialValues={{
        a1c: initialValues?.a1c,
        // make new note every edit
      }}
      layout="vertical"
    >
      <div>
        <FormItem
          name="a1c"
          label="A1c goal"
          required
        >
          <A1CCustomizeGoalComponent />
        </FormItem>
        <TextComponent
          color="gray2"
        >
          Values can be set only within the range of 5.0 to 8.0, with increments of 0.1.
        </TextComponent>
      </div>
      <FormItem
        name="note"
        label="Note"
        className="mt20"
        required
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </FormItem>
      <div className="flex ai-c jc-e mt20">
        <Button
          onClick={onCancel}
          disabled={disabledButton}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={a1cCustomizeGoalInfo.isLoading}
          disabled={disabledButton}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};
