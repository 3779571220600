import {
  Col, Row, Skeleton, Space, Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { Avatar } from '../../../../uiComponent/Avatar/Avatar';
import { PatientVitalListComponent } from '../../../patient/component/PatientVitalListComponent/PatientVitalListComponent';
import {
  GenderEnum,
  LastMeasurementResponse,
  VitalEnumType,
} from '../../../../uc-api-sdk';
import { BirthdayComponent } from '../../../../uiComponent/BirthdayComponent/BirthdayComponent';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { GenderEnumComponent } from '../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { ControlLevelInfoComponent } from '../../../controlLevel/component/ControlLevelInfoComponent/ControlLevelInfoComponent';
import { ControlLevelService } from '../../../../services/ControlLevelService';
import './PatientInfoComponent.scss';
import { PatientProgramStatusComponent } from '../../../patient/component/PatientProgramStatusComponent/PatientProgramStatusComponent';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';

const { Text } = Typography;

export interface PatientInfoComponentProps {
  isLoading?: boolean;
  id: string;
  vitals?: VitalEnumType[];
  fullName?: string;
  gender?: GenderEnum;
  dob?: Dayjs;
  controlLevelService?: ControlLevelService;
  enrolledProgramService?: EnrolledProgramService;
  provider?: string;
  lastMeasurementDate?: LastMeasurementResponse[];
}

export const PatientInfoComponent = ({
  isLoading,
  id,
  fullName,
  gender,
  dob,
  controlLevelService,
  enrolledProgramService,
  provider,
  vitals,
  lastMeasurementDate,
}: PatientInfoComponentProps) => {
  const { createCarePortalPatientUrl } = useChangePage();
  const firstColumnSpan = 7;

  const renderPatientInfo = () => (
    <div className="patient-info-component">
      <div className="flex ai-c gap2 mb5">
        <Space>
          <Avatar name={fullName} />
          <div>
            <div>
              <Space wrap className="patient-info-component__name-complexity">
                <Link to={createCarePortalPatientUrl({ patientId: id })}>{fullName}</Link>
                <ControlLevelInfoComponent
                  patientId={id}
                  cardInfo={controlLevelService?.getControlLevelInfo() || undefined}
                />
              </Space>
            </div>
            <Text type="secondary">
              <GenderEnumComponent value={gender} type="long" />
              {', '}
              <BirthdayComponent value={dob} ageFirst />
            </Text>
          </div>
        </Space>
      </div>
      <Row className="mb5">
        <Col span={firstColumnSpan}>
          <Text type="secondary">
            Status
          </Text>
        </Col>
        <Col>
          <PatientProgramStatusComponent
            enrolledProgramService={enrolledProgramService}
            controlService={controlLevelService}
            showProgramCategory={false}
          />
        </Col>
      </Row>
      <Row className="mb5">
        <Col span={firstColumnSpan}>
          <Text type="secondary">
            Vitals
          </Text>
        </Col>
        <Col>
          <PatientVitalListComponent value={vitals} lastMeasurementDate={lastMeasurementDate} />
        </Col>
      </Row>
      <Row>
        <Col span={firstColumnSpan}>
          <Text type="secondary">
            Provider
          </Text>
        </Col>
        <Col>
          {provider}
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="border default-br p20 flex fd-c gap3">
      {isLoading ? <Skeleton active /> : renderPatientInfo()}
    </div>
  );
};
