import { uniq } from 'lodash';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import {
  HealthConditionItem,
  PatientComplexity,
  PatientDiagnosesEnum,
  useIcdCodeConfigClassifyByIcdCode
} from '../../../uc-api-sdk';

export interface ICDCodeSubmitValue {
  diagnoses: PatientDiagnosesEnum[];
  icdTable: HealthConditionItem[];
  complexity: PatientComplexity | undefined;
}

export const useICDCodeForm = (
  options?: FormOptions,
) => {
  const diagnosesAndCodeClassifyInfo = useIcdCodeConfigClassifyByIcdCode({});
  const factory = useFormHookFactory({
    diagnoses: {
      name: 'diagnoses',
    },
    icdSelect: {
      name: 'icdSelect',
    },
    icdTable: {
      name: 'icdTable',
    },
    complexity: {
      name: 'complexity'
    }
  }, options);

  const onIcdChange = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedValues: any,
  ) => {
    if (factory.getName('icdSelect') in changedValues) {
      const oldDx = factory.form.getFieldValue(factory.getName('diagnoses')) || [];
      const res = await diagnosesAndCodeClassifyInfo.send({
        params: {
          request: {
            icdCodes: [changedValues.icdSelect.code]
          },
        }
      });
      const addedDx = res?.data?.diagnoses || [];
      const newDx = uniq([...oldDx, ...addedDx]);

      factory.form.setFieldValue(factory.getName('diagnoses'), newDx);
    }
  };

  return {
    ...factory,
    onIcdChange,
  };
};
