/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Form, Input
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import dayjs from 'dayjs';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { isEqual, keys, map } from 'lodash';
import { InterventionSubmitCancelButtons } from '../../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { useClinicalGoalForm } from '../../../hook/useClinicalGoalForm';
import { InterventionFormComponentProps } from '../../../type';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { GoalDescriptionSelectComponent } from '../GoalDescriptionSelectComponent/GoalDescriptionSelectComponent';
import { MeetingStatusSelectComponent } from '../../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { ClinicGoalTemplate, ClinicalGoalItem, ConditionEnum } from '../../../../../uc-api-sdk';
import './GoalFormComponent.scss';
import { ClinicalGoalVitalsLabelComponent } from '../ClinicalGoalVitalsLabelComponent/ClinicalGoalVitalsLabelComponent';
import { GoalFormTimeComponent } from '../GoalFormTimeComponent/GoalFormTimeComponent';
import { AddGoalConditionComponent } from '../AddGoalConditionComponent/AddGoalConditionComponent';
import { ClinicalGoalFreeTextVitalsLabelComponent } from '../ClinicalGoalFreeTextVitalsLabelComponent/ClinicalGoalFreeTextVitalsLabelComponent';
import { MeasurementSummaryComponent } from '../../../../mtpr/component/MeasurementSummaryComponent/MeasurementSummaryComponent';
import { clinicGoalConditionOrderMap } from '../../../constant/clinicGoal';
import { useBillableTimeInterventionComp } from '../../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { usePatientContext } from '../../../../../contexts/PatientInfoContext/PatientInfoContext';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../../billableTime/contants/contants';
import { useICDCodeRecommendationContext } from '../../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';

export interface SubmitValue {
  clinicalGoals: ClinicalGoalItem[]
}

export type Status = string;
export type Description = string;

export interface ClinicalGoalCardFormComponentProps extends
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InterventionFormComponentProps<SubmitValue> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // @ts-ignore
  templates: Dictionary<ClinicGoalTemplate>;
  defaultConditions?: DefaultOptionType[];
  disabled?: boolean;
  requireClinicalGoal?: boolean;
}

export const ClinicalGoalCardFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  templates,
  onValuesChange,
  defaultConditions,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
  disabled,
  requireClinicalGoal,
}: ClinicalGoalCardFormComponentProps) => {
  const { info } = usePatientContext();
  const clinicalGoalForm = useClinicalGoalForm();
  const [isAddAGoalClicked, setIsAddAGoalClicked] = useState(false);
  const { send } = useBillableTimeInterventionComp();
  const [currentDate] = useState(dayjs());
  const [conditionAdded, setConditionAdded] = useState<(ConditionEnum | string)[]>(
    map(initialValues?.clinicalGoals, (value) => (
      (value.condition || value.customCategory) as ConditionEnum | string
    )));
  const {
    recommendedClinicalGoal,
    processRecommendedClinicalGoal,
  } = useICDCodeRecommendationContext(['recommendedClinicalGoal']);

  useEffect(() => {
    if (recommendedClinicalGoal === undefined) {
      return;
    }
    const currentClinicalGoals = clinicalGoalForm.form.getFieldsValue(true)[
      clinicalGoalForm.getMainFormItemName()
    ] ?? initialValues?.clinicalGoals;
    const newClinicalGoalList = processRecommendedClinicalGoal(
      currentClinicalGoals,
      recommendedClinicalGoal || []
    );
    if (!isEqual(currentClinicalGoals, newClinicalGoalList)) {
      clinicalGoalForm.form.setFieldValue(
        clinicalGoalForm.getMainFormItemName(),
        newClinicalGoalList,
      );
      onValuesChange?.({
        clinicalGoals: newClinicalGoalList,
      }, {
        ...clinicalGoalForm.form.getFieldsValue(true),
        clinicalGoals: newClinicalGoalList,
      });
    }
  }, [recommendedClinicalGoal]);

  const getClinicalGoalOrder = (condition: ConditionEnum) => {
    // the undefined condition will have this order number + 1
    const orderLength = keys(clinicGoalConditionOrderMap).length;
    return clinicGoalConditionOrderMap[condition] || (orderLength + 1);
  };

  const handleClickAddAGoal = () => {
    setIsAddAGoalClicked(true);
    send({
      patientId: info?.id,
      component: BillableTimeInterventionComponentEnum.ConductMTPR,
      detail: BillableTimeInterventionDetailEnum.OutstandingMtprAddAGoal,
    });
  };

  return (
    <Form
      name="clinicalGoalForm"
      form={clinicalGoalForm.form}
      onFinish={onSubmit}
      initialValues={initialValues}
      disabled={clinicalGoalForm.formDisabled || isLoading || disabled}
      onValuesChange={onValuesChange}
      colon={false}
    >
      <Form.List name={clinicalGoalForm.getMainFormItemName()}>
        {
          (fields, { add, remove }) => {
            const handleAddInputCondition = (newCondition: string) => {
              const newGoal = {
                customCategory: newCondition,
                customContent: '',
                isManualInput: true
              };
              add(newGoal);
              setConditionAdded([...conditionAdded, newGoal.customCategory]);
              setIsAddAGoalClicked(false);
            };

            const handleSelectCondition = (newCondition: string) => {
              const newGoal = {
                condition: newCondition as ConditionEnum,
                clinicalGoalName: templates[newCondition]?.goals[0],
                isManualInput: true
              };
              add(newGoal);
              setConditionAdded([...conditionAdded, newGoal.condition]);
              setIsAddAGoalClicked(false);
            };

            const handleClear = (fieldName: number) => {
              remove(fieldName);
              const newConditionAdded = [...conditionAdded];
              newConditionAdded.splice(fieldName, 1);
              setConditionAdded(newConditionAdded);
            };
            return (
              <>
                <div className="clinical-goal-list">
                  {
                    map(fields, ((field) => {
                      const { key, ...restField } = field;
                      const condition: ConditionEnum = (
                        clinicalGoalForm.getObjectValue(
                          'condition',
                          clinicalGoalForm.form.getFieldValue,
                          field.name,
                        )
                      );

                      const customCategory = (
                        clinicalGoalForm.getObjectValue(
                          'customCategory',
                          clinicalGoalForm.form.getFieldValue,
                          field.name,
                        )
                      );

                      const updatedDate: string = (
                        clinicalGoalForm.getObjectValue(
                          'updatedDate',
                          clinicalGoalForm.form.getFieldValue,
                          field.name,
                        )
                      );

                      const isManualInput: boolean = (
                        clinicalGoalForm.getObjectValue(
                          'isManualInput',
                          clinicalGoalForm.form.getFieldValue,
                          field.name,
                        )
                      );

                      return (
                        <div
                          key={key}
                          className="clinicalGoals"
                          style={{ order: getClinicalGoalOrder(condition) }}
                        >
                          <MeasurementSummaryComponent
                            condition={condition}
                            customCategory={customCategory}
                          />
                          <div className="intervention-goal-type">
                            {
                              !customCategory
                                ? (
                                  <FormItem
                                    {...restField}
                                    name={clinicalGoalForm.makeListNamePath('condition', field.name)}
                                  >
                                    <ClinicalGoalVitalsLabelComponent
                                      value={templates[condition]?.condition}
                                    />
                                  </FormItem>
                                ) : (
                                  <FormItem
                                    {...restField}
                                    name={clinicalGoalForm.makeListNamePath('customCategory', field.name)}
                                  >
                                    <ClinicalGoalFreeTextVitalsLabelComponent
                                      customCategory={customCategory}
                                    />
                                  </FormItem>
                                )
                            }
                          </div>
                          <div className="intervention-goal-form-component">
                            {
                              !customCategory
                                ? (
                                  <FormItem
                                    {...restField}
                                    name={clinicalGoalForm.makeListNamePath('clinicalGoalName', field.name)}
                                    className="goal-form-description"
                                    required
                                    hideRequiredMark
                                  >
                                    <GoalDescriptionSelectComponent
                                      options={templates[condition]?.goals}
                                    />
                                  </FormItem>
                                )
                                : (
                                  <FormItem
                                    {...restField}
                                    name={clinicalGoalForm.makeListNamePath('customContent', field.name)}
                                    className="goal-form-description"
                                    required
                                    hideRequiredMark
                                  >
                                    <Input.TextArea
                                      className="goal-description-input"
                                      autoSize={{ minRows: 1 }}
                                      placeholder="Enter the goal for this condition"
                                    />
                                  </FormItem>
                                )
                            }
                            <div className="goal-form-status flex ai-bl">
                              <FormItem
                                {...restField}
                                name={clinicalGoalForm.makeListNamePath('goalStatus', field.name)}
                                label="Status"
                                className="goal-form-meeting-status"
                              >
                                <MeetingStatusSelectComponent />
                              </FormItem>
                              <div>
                                <div className="goal-form-date-and-deleteIcon">
                                  <GoalFormTimeComponent
                                    value={updatedDate ?? currentDate.toISOString()}
                                  />
                                  {
                                    isManualInput
                                    && (
                                      <DeleteOutlined
                                        onClick={() => handleClear(field.name)}
                                      />
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }))
                  }
                </div>
                <div className="clinic-goals-form-buttons">
                  {
                    isAddAGoalClicked
                      ? (
                        <AddGoalConditionComponent
                          options={defaultConditions}
                          conditionAdded={conditionAdded}
                          onAdd={handleAddInputCondition}
                          onCancel={() => setIsAddAGoalClicked(false)}
                          onSelect={handleSelectCondition}
                        />
                      ) : (
                        <Form.Item>
                          <Button
                            type="link"
                            className="p0"
                            onClick={handleClickAddAGoal}
                            icon={<PlusOutlined />}
                          >
                            Add a Goal
                          </Button>
                        </Form.Item>
                      )
                  }
                </div>
              </>
            );
          }
        }
      </Form.List>
      <FormItem
        noStyle
        shouldUpdate={clinicalGoalForm.shouldUpdateList}
      >
        {
          ({ getFieldValue }) => {
            const selectedGoals = getFieldValue(clinicalGoalForm.getMainFormItemName());
            return (
              <FormItem
                key={new Date().getTime()}
                className="require-clinical-goal-placeholder"
                name="require-clinic-goal-placeholder"
                rules={(
                  requireClinicalGoal
                    ? clinicalGoalForm.makeClinicalGoalRules(selectedGoals) : []
                )}
              >
                <div className="display-none" />
              </FormItem>
            );
          }
        }
      </FormItem>
      {formButtons}
    </Form>
  );
};
