import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { usePatientSummary } from '../../../../services/PatientSummaryServices/hooks/usePatientSummary';
import { PDFViewerComponent } from '../../../../uiComponent/PDFViewerComponent/PDFViewerComponent';
import './PhysicianReportContainer.scss';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export interface PhysicianReportContainerProps {
  isPreFollowUpSummary?: boolean;
  startTime?: string;
  endTime?: string;
  patientId: string;
}

export const PhysicianReportContainer = ({
  isPreFollowUpSummary,
  patientId,
  startTime,
  endTime
}: PhysicianReportContainerProps) => {
  const { userId } = useLoggedInUserFromContext();

  const [startTimeLocal, setStartTimeLocal] = useState(startTime || TimezoneService.getUTC(dayjs().subtract(3, 'month')));
  const [endTimeLocal, setEndTimeLocal] = useState(endTime || TimezoneService.getUTC(dayjs()));

  useEffect(() => {
    if (startTime && endTime) {
      setStartTimeLocal(startTime);
      setEndTimeLocal(endTime);
    }
  }, [startTime, endTime]);

  const patientSummaryHook = usePatientSummary({
    options: { sendOnMount: !!patientId },
    params: {
      content: {
        is_pre_follow_up: isPreFollowUpSummary || false,
        user_id: userId || '',
        patient_id: patientId || '',
        start_time: startTimeLocal,
        end_time: endTimeLocal,
      },
    },
  });

  return (
    <FetchComponent info={patientSummaryHook}>
      <div className="physician-report-container">
        <PDFViewerComponent fileUrl={patientSummaryHook.data?.link ?? ''} height="100%" />
      </div>
    </FetchComponent>
  );
};
