import { useCallback } from 'react';
import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../types/form';
import { useGetContextValue } from '../useGetContextValue/useGetContextValue';
import { FrequencyDisplayType } from '../../uc-api-sdk';
import { useIterableFormHook } from '../useIterableFormHook/useIterableFormHook';

export const isDosingFrequencySupported = (frequency?: FrequencyDisplayType) => (
  !(
    [
      undefined,
      null,
      FrequencyDisplayType.QOD,
      FrequencyDisplayType.QWK,
      FrequencyDisplayType.Others,
    ].includes(frequency)
  )
);

export const hasMultipleDosage = (frequency: FrequencyDisplayType) => (
  (
    [
      FrequencyDisplayType.BID,
      FrequencyDisplayType.TID,
      FrequencyDisplayType.QID,
      FrequencyDisplayType.Q6H,
      FrequencyDisplayType.Q8H,
    ].includes(frequency)
  )
);

export const useMedicationForm = (options?: FormOptions) => {
  const dosageFormHook = useIterableFormHook('dosage', {
    singleDose: {
      name: '',
      label: '',
      required: true,
    }
  }, options);

  const factory = useFormHookFactory({
    type: {
      name: 'type',
      label: 'Route',
      required: true,
    },
    medicationName: {
      name: 'medicationName',
      label: 'Medication Name',
      required: true,
    },
    status: {
      name: 'status',
      label: 'Status',
    },
    otherMD: {
      name: 'otherMD',
      label: "Not prescribed by this patient's primary provider",
    },
    frequency: {
      name: 'frequency',
      label: 'Frequency',
      required: true,
    },
    enableReminder: {
      name: 'enableReminder',
      label: '',
    },
    startDate: {
      name: 'startDate',
      label: 'Start date',
    },
    endDate: {
      name: 'endDate',
      label: 'Discontinue date',
    },
    instruction: {
      name: 'instruction',
      label: 'Timing',
    },
    note: {
      name: 'note',
      label: 'Note',
    },
    isSameDosage: {
      name: 'isSameDosage',
      label: 'Same dose every intake?',
    },
    ...dosageFormHook.formInput
  }, options);

  const handleDosageFrequencyChange = useCallback((
    value?: FrequencyDisplayType,
  ) => {
    if (!isDosingFrequencySupported(value)) {
      factory.form.setFieldValue(
        factory.getName('enableReminder'),
        false,
      );
    }
  }, []);

  const shouldDisableReminder = useCallback((
    getFieldValue: GetFieldValue,
  ) => (
    !isDosingFrequencySupported(
      factory.getValue('frequency', getFieldValue)
    )
  ), []);

  // check if the isSameDosage field should be disabled and set to default true.
  const shouldDisableSameDosageField = useCallback((
    getFieldValue: GetFieldValue,
  ) => {
    const frequency = factory.getValue('frequency', getFieldValue);
    if (!hasMultipleDosage(frequency)) {
      factory.form.setFieldValue(
        factory.getName('isSameDosage'),
        true,
      );
      return true;
    }
    return false;
  }, []);

  return useGetContextValue({
    ...dosageFormHook,
    ...factory,
    handleDosageFrequencyChange,
    shouldDisableReminder,
    shouldDisableSameDosageField,
  });
};
