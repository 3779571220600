import { Button, Modal, ModalProps } from 'antd';
import {
  useState,
  CSSProperties,
  ReactNode,
  useCallback,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { partialRight } from 'lodash';
import { useUpdateEffect } from 'usehooks-ts';
import classNames from 'classnames';
import { useOpen } from '../../hooks/useBoolean/useOpen';
import { DraggableComponent, DraggableComponentProps } from '../DraggableComponent/DraggableComponent';

import './DraggableModalComponent.scss';
import { Resizable, ResizableProps } from '../Resizable/Resizable';

export interface TriggerProps {
  onOpen: () => void;
}

export interface DraggableHandleProps {
  title?: ReactNode;
  handleCursor?: CSSProperties['cursor'];
  handleClassName?: string;
}

export interface DraggableChildrenProps {
  onOpen: () => void;
  onClose: () => void;
}

export interface DraggableModalComponentProps
  extends Omit<ModalProps, 'children'>,
  DraggableHandleProps {
  onOpen?: () => void;
  onCancel?: () => void;
  trigger?: (props: TriggerProps) => ReactNode;
  triggerBtnText?: ReactNode;
  showTrigger?: boolean;
  children: ReactNode | ((props: DraggableChildrenProps) => ReactNode);
  shouldDisableBody?: boolean;
  onDragStop?: DraggableComponentProps['onStop'];
  resizable?: boolean | Omit<ResizableProps, 'children'>;
}

export const DraggableModalComponent = ({
  onOpen,
  onCancel,
  trigger,
  triggerBtnText = 'Open',
  showTrigger = true,
  handleCursor = 'move',
  title = '',
  handleClassName = '',
  children,
  open,
  className = '',
  wrapClassName = '',
  destroyOnClose = true,
  mask = false,
  maskClosable = false,
  closable = true,
  shouldDisableBody = true,
  onDragStop,
  resizable,
  ...modalProps
}: DraggableModalComponentProps) => {
  const {
    isOpen,
    open: setOpen,
    close: setClose,
    setValue: setIsOpen,
  } = useOpen(open);
  const [disabled, setDisabled] = useState(true);

  const handleOnOpen = () => {
    setOpen();
    onOpen?.();
  };

  const handleOnClose = () => {
    setClose();
    onCancel?.();
  };

  useUpdateEffect(() => {
    if (typeof open === 'boolean') {
      setIsOpen?.(open);
    }
  }, [open]);

  const renderHandle = useCallback(() => (
    <div
      className={classNames({
        'draggable-modal-handle': true,
        'flex jc-sb ai-c': true,
      })}
      style={{ cursor: handleCursor }}
      onMouseEnter={() => {
        if (disabled) setDisabled(false);
      }}
      onMouseLeave={() => setDisabled(true)}
      // fix eslintjsx-a11y/mouse-events-have-key-events
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
      onFocus={() => 0}
      onBlur={() => 0}
    >
      <div
        className={classNames({
          'handle f1': true,
          [handleClassName]: !!handleClassName,
        })}
      >
        {title}
      </div>
      {
        closable
        && (
          <div className="closable-icon">
            <CloseOutlined onClick={handleOnClose} />
          </div>
        )
      }
    </div>
  ), [title, disabled]);

  const renderBody = useCallback((
    modal: ReactNode,
    disabled?: boolean,
  ) => (
    <DraggableComponent
      disabled={shouldDisableBody ? disabled : false}
      cancel={
        resizable ? '.draggable-modal__resize-handle' : ''
      }
      onStop={onDragStop}
    >
      {
        resizable
          ? (
            <Resizable
              resizeHandleClassName="draggable-modal__resize-handle"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(typeof resizable === 'object' ? resizable : {})}
            >
              {modal}
            </Resizable>
          ) : modal
      }
    </DraggableComponent>
  ), []);

  return (
    <>
      {
        showTrigger
        && (
          trigger?.({ onOpen: handleOnOpen })
          || (
            <Button onClick={handleOnOpen}>
              {triggerBtnText}
            </Button>
          )
        )
      }
      <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
        open={isOpen}
        onCancel={handleOnClose}
        title={renderHandle()}
        modalRender={partialRight(renderBody, disabled)}
        destroyOnClose={destroyOnClose}
        mask={mask}
        closable={false}
        maskClosable={maskClosable}
        wrapClassName={classNames({
          'draggable-modal': true,
          [wrapClassName]: !!wrapClassName,
        })}
        className={className}
        footer={null}
      >
        {
          typeof children === 'function'
            ? children({ onOpen: handleOnOpen, onClose: handleOnClose })
            : children
        }
      </Modal>
    </>
  );
};
