import { Col, Row, Switch } from 'antd';
import { AppFunctions } from '../../../../uc-api-sdk';
import './AppNotificationComponent.scss';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { ArticleFrequencyEnumComponent } from '../../../../enumComponent/AriticleFrequencyEnumComponent/ArticleFrequencyEnumComponent';

export interface AppNotificationComponentProps {
  initValue?: AppFunctions;
}

export const AppNotificationComponent = ({
  initValue,
}: AppNotificationComponentProps) => {
  const hasMeasurement = initValue?.measurementReminder !== undefined
    ? initValue?.measurementReminder : false;
  const hasMedication = initValue?.medicationReminder !== undefined
    ? initValue?.medicationReminder : false;

  return (
    <div className="AppNotificationComponent">
      <Row gutter={20}>
        <Col span={6}>
          <TitleContentTextComponent
            title="Educational Article Notification"
            content={(
              <ArticleFrequencyEnumComponent
                value={initValue?.articlePushFrequency ?? undefined}
              />
            )}
          />
        </Col>
        <Col span={6}>
          <TitleContentTextComponent
            title="Measurement Reminder Notification"
            content={(
              <Switch
                disabled
                checked={hasMeasurement || undefined}
              />
            )}
          />
        </Col>
        <Col span={6}>
          <TitleContentTextComponent
            title="Medication Reminder Notification"
            content={(
              <Switch
                disabled
                checked={hasMedication || undefined}
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
