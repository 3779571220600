import { Row, Typography } from 'antd';
import { map } from 'lodash';
import { FC, ReactNode } from 'react';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { InfoComponent, InfoComponentProps } from '../InfoComponent/InfoComponent';

const { Title } = Typography;

export interface DemographicCareTeamInfoComponentProps {
  clinicName?: ReactNode;
  providerName?: string;
  medicalId?: string;
  assignedToCA?: string;
  assignedToRD?: string;
}

export const DemographicCareTeamInfoComponent: FC<DemographicCareTeamInfoComponentProps> = ({
  clinicName,
  providerName,
  medicalId,
  assignedToCA,
  assignedToRD,
}) => {
  const dataSet: InfoComponentProps[] = [
    {
      name: 'Clinic Name*',
      value: clinicName || <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Provider Name*',
      value: providerName || <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Medical Record Number (MRN)*',
      value: medicalId || <EmptyComponent />,
      span: 6,
    },
  ];

  const dataSet2: InfoComponentProps[] = [
    {
      name: 'Patient\'s assigned CM/CA',
      value: assignedToCA || <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Patient\'s assigned RD/HC',
      value: assignedToRD || <EmptyComponent />,
      span: 6,
    },
  ];

  const renderColumns = (data: InfoComponentProps[]) => map(data, (d, index) => (
    <InfoComponent
      key={index}
      name={d.name}
      value={d.value}
      span={d.span}
      onClick={d.onClick}
      type={d.type}
    />
  ));

  return (
    <div className="flex gap3 fd-c">
      <Title level={5}>Clinic Information</Title>
      <Row align="bottom" gutter={7}>
        {renderColumns(dataSet)}
      </Row>
      <Row gutter={7}>
        {renderColumns(dataSet2)}
      </Row>
    </div>
  );
};
