import { useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { ArticlePushFrequencyEnum } from '../../../../uc-api-sdk';

export interface AppFunctions {
  articlePushFrequency?: ArticlePushFrequencyEnum;
  measureVital?: boolean;
  medicationReminder?: boolean;
}

export const useAppNotificationForm = () => {
  const factory = useFormHookFactory({
    articleFrequency: {
      name: 'articlePushFrequency',
      label: 'Educational Article Notification',
    },
    measurementReminder: {
      name: 'measurementReminder',
      label: 'Measurement Reminder Notification',
    },
    medicationReminder: {
      name: 'medicationReminder',
      label: 'Medication Reminder Notification',
    },
  });

  return factory;
};
