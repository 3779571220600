import { map } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { BGHelper } from '../../helper/BGHelper';
import { LogbookCellComponent } from '../LogbookCellComponent/LogbookCellComponent';
import { LogbookComponentProps } from '../LogbookComponent/LogbookComponent';
import { LogbookModalComponent } from '../LogbookComponent/LogbookModalComponent';
import { LogbookInfoComponent } from '../LogbookInfoComponent/LogbookInfoComponent';
import { MeasurementTagsIconsComponent } from '../MeasurementTagsIconsComponent/MeasurementTagsIconsComponent';
import { BGMeasurementService } from '../../helper/BGMeasurementService';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface BGTableCellComponentProps {
  data: BGMeasurementService[];
}

export const BGTableCellComponent: FC<BGTableCellComponentProps> = ({
  data,
}) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean();

  const children = useMemo(() => map(data, (v, i) => (
    <LogbookCellComponent
      key={i}
      text={v.bloodGlucose.value}
      color={v.severityColor}
      hasNote={!!v.userNote}
      bottomLeftIcon={(
        <MeasurementTagsIconsComponent
          hadMedicine={v.hadMedicine}
          color="#FFF"
        />
      )}
    />
  )), [data]);

  const renderTab = useCallback<LogbookComponentProps<BGMeasurementService>['renderTab']>((dataValue, isSelected, onSelect) => (
    <LogbookCellComponent
      color={dataValue.severityColor}
      text={BGHelper.getValueUnitText(
        dataValue.bloodGlucose.value,
        dataValue.bloodGlucose.unit,
      )}
      hasNote={!!dataValue.userNote}
      onClick={onSelect}
      reverseColor={!isSelected}
      bottomLeftIcon={(
        <MeasurementTagsIconsComponent
          hadMedicine={dataValue.hadMedicine}
          color={isSelected
            ? '#FFF'
            : dataValue.severityColor}
        />
      )}
    />
  ), []);

  const renderLogbookInfo = useCallback<LogbookComponentProps<BGMeasurementService>['renderLogbookInfo']>((dataValue) => (
    <LogbookInfoComponent
      vitalType={VitalEnumType.BG}
      hadActivity={dataValue.hadActivity}
      hadInsulin={dataValue.hadInsulin}
      hadMedicine={dataValue.hadMedicine}
      moodTags={dataValue.moodTags || []}
      patientNote={dataValue.userNote}
    />
  ), []);

  const renderTitle = useCallback<LogbookComponentProps<BGMeasurementService>['renderTitle']>((dataValue) => (
    BGHelper.getMeasurementText(dataValue.mealType, dataValue.beforeMeal)
  ), []);

  return (
    <>
      <LogbookModalComponent
        open={open}
        onCancel={closeModal}
        logbookProps={{
          data,
          renderLogbookInfo,
          renderTitle,
          renderTab,
        }}
      />
      <LogbookCellComponent.Group onClick={openModal}>
        {children}
      </LogbookCellComponent.Group>
    </>
  );
};
