import { FileUploadMessagePayload } from '../../../../services/CHSServices/types/data';
import { PDFViewerContainer } from '../../../../uiContainer/PDFViewerContainer/PDFViewerContainer';
import { MessageFileUploadItemComponent } from '../MessageFileUploadItemComponent/MessageFileUploadItemComponent';

import './MessagePdfComponent.scss';

export interface MessagePdfComponentProps extends Pick<FileUploadMessagePayload, 'text' | 'fileKey'> {
  fileKeyName: string;
}

export const MessagePdfComponent = ({
  text,
  fileKey,
  fileKeyName,
}: MessagePdfComponentProps) => (
  <div className="message-item__pdf-content">
    <MessageFileUploadItemComponent
      text={text}
      button={(
        <span className="text-overflow-wrap">
          {fileKeyName}
        </span>
      )}
      className="message-pdf-component"
    >
      <PDFViewerContainer
        fileKey={fileKey}
      />
    </MessageFileUploadItemComponent>
  </div>
);
