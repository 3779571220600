/* eslint-disable max-len */
import {
  Checkbox, Col, Row, Tooltip
} from 'antd';
import { useUpdateEffect } from 'usehooks-ts';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InputTypeArray } from '../../../Input/types';
import { APIResponse, PatientClassificationResult, PatientDiagnosesEnum } from '../../../../uc-api-sdk';
import { PatientDiagnosesEnumComponent } from '../../../../enumComponent/PatientDiagnosesEnumComponent/PatientDiagnosesEnumComponent';

interface PatientDiagnosesInputComponentProps extends InputTypeArray<PatientDiagnosesEnum> {
  disabled?: boolean;
  icdCodes?: string[];
  getDiagnosisBasedOnIcd?: (icdCodes: string[]) =>
    Promise<APIResponse<PatientClassificationResult> | null | undefined>;
}

export const PatientDiagnosesInputComponent = ({
  value,
  onChange,
  disabled,
  icdCodes,
  getDiagnosisBasedOnIcd,
}: PatientDiagnosesInputComponentProps) => {
  const handleChange = (checkedValue: CheckboxValueType[]) => {
    onChange?.(checkedValue as PatientDiagnosesEnum[]);
  };

  useUpdateEffect(() => {
    const asyncFunc = async () => {
      const diagnosisReq = await getDiagnosisBasedOnIcd?.(icdCodes || []);
      const addedDx = diagnosisReq?.data?.diagnoses || [];
      const updatedDx: PatientDiagnosesEnum[] = Array.from(new Set([...(value || []), ...addedDx]));
      onChange?.(updatedDx);
    };

    asyncFunc();
  }, [JSON.stringify(icdCodes)]);

  const renderCheckbox = (diagnose: PatientDiagnosesEnum, span = 3) => (
    <Col span={span}>
      <Checkbox value={diagnose}>
        <PatientDiagnosesEnumComponent value={diagnose} />
      </Checkbox>
      {diagnose === PatientDiagnosesEnum.DM_OTHERS && (
        <Tooltip
          className="secondary-gray"
          title="This includes DM in pregnancy, childbirth, and puerperium, etc."
          placement="bottom"
          overlayStyle={{ minWidth: '440px' }}
        >
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Col>
  );

  return (
    <Checkbox.Group
      className="w100 mb40"
      value={value}
      onChange={handleChange}
      disabled={disabled}
    >
      <Row className="mb10">
        {renderCheckbox(PatientDiagnosesEnum.HTN)}
        {renderCheckbox(PatientDiagnosesEnum.HLD)}
        {renderCheckbox(PatientDiagnosesEnum.DM2)}
        {renderCheckbox(PatientDiagnosesEnum.PRE_DM)}
        {renderCheckbox(PatientDiagnosesEnum.DM_OTHERS, 4)}
        {renderCheckbox(PatientDiagnosesEnum.COPD)}
      </Row>
      <Row>
        {renderCheckbox(PatientDiagnosesEnum.OBESITY)}
        {renderCheckbox(PatientDiagnosesEnum.CKD)}
        {renderCheckbox(PatientDiagnosesEnum.CHF)}
        {renderCheckbox(PatientDiagnosesEnum.RDD)}
        {renderCheckbox(PatientDiagnosesEnum.HYPOTENSION, 4)}
        {renderCheckbox(PatientDiagnosesEnum.OTHERS)}
      </Row>
    </Checkbox.Group>
  );
};
