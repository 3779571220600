/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import moment from 'moment';
import { USA_DATE } from '../constants/timeFormat';
import { Insurance } from '../features/Insurance/hook/insuranceApis';
import { AccountHelper } from '../helpers/account';
import { HeightService } from '../helpers/height';
import TimezoneService from '../helpers/timezone/timezoneService';
import { WeightService } from '../helpers/weight';
import {
  HealthCondition, Patient,
  PatientComplexity,
  PatientDiagnosesEnum,
  ProgramCategoryEnum
} from '../uc-api-sdk';
import { PatientComplexityService } from './PatientComplexityService';
import { PatientHealthConditionService } from './PatientHealthConditionService';
import { PhoneService } from './PhoneService';

export interface PatientServiceArg {
  patientInfo: Patient;
  insurance?: Insurance | null;
  complexity?: PatientComplexity | null;
  patientDiagnoses?: PatientDiagnosesEnum[] | null;
  healthCondition?: HealthCondition | null;
}

export class PatientService {
  private patientInfo: Patient;

  private patientDiagnoses?: PatientDiagnosesEnum[] | null;

  private insurance?: Insurance | null;

  private healthCondition?: HealthCondition | null;

  private complexity?: PatientComplexity | null;

  private phones?: PhoneService;

  constructor(info: PatientServiceArg) {
    this.patientInfo = info.patientInfo;
    this.insurance = info.insurance;
    this.patientDiagnoses = info.patientDiagnoses;
    this.healthCondition = info.healthCondition;
    this.complexity = info.complexity;
    this.phones = new PhoneService(this.patientInfo.phone ?? []);
  }

  get patient() { return this.patientInfo; }

  get patientInsurance() { return this.insurance; }

  get patientAssociatedClinics() {
    return this.patientInfo.associatedList ?? [];
  }

  get address() {
    return this.patientInfo.address?.[0];
  }

  get diagnoses() {
    return this.patientDiagnoses;
  }

  get healthConditionService() {
    if (!this.healthCondition) return this.healthCondition;
    return new PatientHealthConditionService({
      healthCondition: this.healthCondition,
    });
  }

  get complexityService() {
    if (!this.complexity) return this.complexity;
    return new PatientComplexityService({
      complexity: this.complexity,
    });
  }

  get updateInfo() {
    return {
      updatedAt: this.patient.updatedAt,
      updatedByUser: this.patient.updatedByUser,
    };
  }

  get watchInfo() {
    return this.patientInfo.watchInfo;
  }

  get firstName() {
    return this.patientInfo.profile?.firstName || undefined;
  }

  get lastName() {
    return this.patientInfo.profile?.lastName || undefined;
  }

  get nickName() {
    return this.patientInfo.profile?.nickName || undefined;
  }

  get behaviorChange() {
    return this.patientInfo.profile?.behaviorChangeInfo?.behaviorChangeLevel || undefined;
  }

  get behaviorChangeInfo() {
    return this.patientInfo.profile?.behaviorChangeInfo || undefined;
  }

  get isDeleted() {
    return this.patientInfo.deleted || false;
  }

  public getFullName() {
    return AccountHelper.getFullName(this.patientInfo.profile);
  }

  public getFullNameWithNickname() {
    return AccountHelper.getFullNameNickname(this.patientInfo.profile);
  }

  // eslint-disable-next-line class-methods-use-this
  public getActivePrograms() {
    // @deprecated
    console.error('getActivePrograms as been removed');
    return [] as ProgramCategoryEnum[];
  }

  public getGender() {
    return this.patientInfo.profile?.gender ?? undefined;
  }

  public getProviderId() {
    return this.patientInfo.profile?.doctorId;
  }

  public getProviderName() {
    return AccountHelper.getFullName(this.patientInfo.profile?.doctorUser) || undefined;
  }

  public getProviderLastNameAndFirstName() {
    return AccountHelper.getLastNameAndFirstName(this.patientInfo.profile?.doctorUser) || undefined;
  }

  public getWeight() {
    // this will just return value, unit might not be the standard unit. Not recommended to use
    return this.patientInfo.profile?.weight?.value;
  }

  public getWeightWithUnit() {
    return this.patientInfo.profile?.weight ?? undefined;
  }

  public getWeightSource() {
    return this.patientInfo.profile?.weight?.source || undefined;
  }

  public getWeightInLb() {
    return WeightService.getWeightInLb(this.patientInfo.profile?.weight || {});
  }

  public isWeightChanged(weight: number, unit: string = WeightService.defaultWeightUnit) {
    const currentWeight = Number(this.getWeightInLb());
    const newWeight = Number(WeightService.getWeightInLb({ value: weight, unit }));
    return currentWeight !== newWeight;
  }

  public getWeightDate() {
    return this.patientInfo.profile?.weight?.updatedAt
      ? TimezoneService.calcDateTimeDayjs(
        this.patientInfo.profile?.weight?.updatedAt,
        this.getTimezone()
      ).format(USA_DATE) : undefined;
  }

  public isHeightChanged(height: number) {
    // height should be in cm
    const currentHeight = Number(this.getHeight()) || 0;
    return currentHeight !== height;
  }

  public getHeight() {
    // this will just return value, unit might not be the standard unit. Not
    // recommended to use
    // default unit should be cm, confirmed with Daisy.
    return this.patientInfo.profile?.height?.value;
  }

  public getHeightWithUnit() {
    return this.patientInfo.profile?.height ?? undefined;
  }

  public getHeightInFt() {
    return HeightService.cmToFeet(this.patientInfo.profile?.height?.value || 0);
  }

  public getMedicalId() {
    return this.patientInfo.medicalRecordId || undefined;
  }

  public getSpokenLanguage() {
    const spokenLanguage = this.patientInfo.profile?.languages?.[0];
    return spokenLanguage ? [spokenLanguage] : undefined;
  }

  public getAppLanguage() {
    return this.patientInfo.profile?.appLanguage || undefined;
  }

  public getMobilePhoneInfo() {
    return this.phones?.getMobilePhoneInfo();
  }

  public getHomePhoneInfo() {
    return this.phones?.getHomePhoneInfo();
  }

  public getMobilePhoneNumber() {
    return this.phones?.getMobilePhoneNumber() || undefined;
  }

  public getIsSmartPhone() {
    return this.phones?.getIsSmartPhone();
  }

  public getMobilePhoneOTP() {
    return this.phones?.getMobilePhoneOTP();
  }

  public getHomePhoneNumber() {
    return this.phones?.getHomePhoneNumber() || undefined;
  }

  public getEmail() {
    return this.patientInfo.email || undefined;
  }

  public getAddress() {
    return {
      address: this.address?.streetName,
      address2: this.address?.unit,
      city: this.address?.city,
      state: this.address?.state,
      zipcode: this.address?.postCode,
    };
  }

  public getBirthday() {
    const birthday = this.patientInfo.profile?.birthday;
    if (!birthday) return undefined;
    return dayjs(birthday);
  }

  public getBirthdayMoment() {
    return moment(this.patientInfo.profile?.birthday);
  }

  public getAge() {
    const birthday = this.getBirthday();
    if (!birthday) return undefined;
    const now = dayjs().endOf('day');
    return now.diff(birthday.startOf('day'), 'years');
  }

  public getRace() {
    return this.patientInfo.profile?.race || undefined;
  }

  public getUserName() {
    return this.patientInfo.loginId || undefined;
  }

  public getClinicName() {
    return this.patientInfo.clinic?.nickName || undefined;
  }

  public getClinicId() {
    return this.patientInfo.clinic?.id || undefined;
  }

  public getClinic() {
    return this.patientInfo.clinic || undefined;
  }

  public getTimezone() {
    return this.patientInfo.clinic?.timezone || undefined;
  }
}
