import {
  Button, Divider, Form, Input
} from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { last } from 'lodash';
import { useMemo } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Transcribe, VisitTypeEnum } from '../../../../uc-api-sdk';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { CardTitleComponent } from '../../../../uiComponent/CardTypographyComponent/CardTitleComponent/CardTitleComponent';
import { ChronicDiagnosesNoteComponent } from '../../../../uiComponent/ChronicDiagnosesNoteComponent/ChronicDiagnosesNoteComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TranscribeBasicInfoFormItemComponent } from '../../../../uiComponent/Transcribing/component/TranscribeBasicInfoFormItemComponent/TranscribeBasicInfoFormItemComponent';
import { TranscribeBasicInfoFormItemValues } from '../../../../uiComponent/Transcribing/component/TranscribeBasicInfoFormItemComponent/type';
import { DefaultICDCodeFormValues } from '../../../ICDCode/component/ICDCodeFormComponent/type';
import { InsuranceFormContainer } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';
import { useConfirmEligibilityEffect } from '../../../Insurance/hook/useConfirmEligibilityEffect';
import { useInsuranceBlockPrompt } from '../../../Insurance/hook/useInsuranceBlockPrompt';
import { AddLabResultDrawerContainer } from '../../container/LabResultTableContainer/AddLabResultDrawerContainer';
import { BpBaselineFormComponentValues, BpBaselineFormItemComponent } from '../BpBaselineFormComponent/BpBaselineFormComponent';
import { TranscribeAddressFormItemComponent } from '../TranscribeAddressFormitemComponent/TranscribeAddressFormItemComponent';
import { TranscribeAddressFormItemValues } from '../TranscribeAddressFormitemComponent/type';
import { TranscribeVisitCardComponent } from '../TranscribeVisitCardComponent/TranscribeVisitCardComponent';
import './TranscribeVisitDrawerFormComponent.scss';
import { MedicationManagementType } from '../../../medication/component/MedicalHistoryMedicationSectionComponent/MedicalHistoryMedicationSectionComponent';
import { useICDCodeForm } from '../../../ICDCode/hook/useICDCodeForm';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { MedicationCommonCardComponent } from '../../../medication/component/MedicationCommonCardComponent/MedicationCommonCardComponent';

export interface TranscribeVisitFormValues
  extends TranscribeBasicInfoFormItemValues,
  TranscribeAddressFormItemValues,
  DefaultICDCodeFormValues,
  BpBaselineFormComponentValues,
  MedicationManagementType {
  transcribeNote?: string;
}

export interface TranscribeVisitDrawerFormComponentProps {
  patientId: string;
  transcribe?: Transcribe;
  onSubmit: (values: TranscribeVisitFormValues, completeTranscribe?: boolean) => void;
  submitText?: string;
  saveText?: string;
  showSaveForLaterButton?: boolean;
  onValuesChange?: FormProps['onValuesChange'];
  disabled?: boolean;
  showSubmitButton?: boolean;
  isLoading?: boolean;
}

export const TranscribeVisitDrawerFormComponent = ({
  patientId,
  transcribe,
  onSubmit,
  submitText = 'Complete Transcribing',
  saveText = 'Save',
  showSaveForLaterButton = true,
  onValuesChange,
  disabled,
  showSubmitButton = true,
  isLoading,
}: TranscribeVisitDrawerFormComponentProps) => {
  const { info } = usePatientContext();
  const [form] = useForm();
  const icdForm = useICDCodeForm({ form });

  const {
    patientInfoService, medicationManagementService,
    refetch, enrolledProgramService, controlLevelService
  } = info || {};
  const {
    healthConditionService,
    complexityService,
    patientInsurance,
  } = patientInfoService || {};
  const {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt
  } = useInsuranceBlockPrompt();
  // for confirm eligibility effect
  const confirmEligibilityEffect = useConfirmEligibilityEffect({
    initialPatientInfo: patientInfoService?.patient,
    initialInsuranceInfo: patientInsurance
  });
  useUpdateListener('INSURANCE_ELIGIBILITY_UPDATED', () => refetch?.(['patientInsuranceRefetch']));

  const handleOnFinish = useDebounce(async (
    values: TranscribeVisitFormValues,
  ) => {
    if (isInsuranceEdit) {
      insuranceBlockPrompt();
      return;
    }
    await confirmEligibilityEffect(values);
    onSubmit(values, true);
  }, 250, [onSubmit, isInsuranceEdit]);

  const showBaseLine = !controlLevelService?.hasBpBaseline()
    && transcribe?.visitType === VisitTypeEnum.INIT;

  const handleOnSave = useDebounce(async () => {
    try {
      const res = form.getFieldsValue(true);
      const shouldValidate = showBaseLine && !res.skipBpBaseline;
      if (shouldValidate) {
        const errors = await form.validateFields(['bpBaselineDate', 'systolic_blood_pressure', 'diastolic_blood_pressure', 'pulse']);
        if (errors.errorFields && errors.errorFields.length > 0) {
          return;
        }
      }
      await confirmEligibilityEffect(res);
      onSubmit(res as TranscribeVisitFormValues);
    } catch (error) {
      console.error(error);
    }
  }, 250, [form, onSubmit]);

  const initialValues: Partial<TranscribeVisitFormValues> = useMemo(() => ({
    firstName: patientInfoService?.firstName || '',
    lastName: patientInfoService?.lastName || '',
    dob: patientInfoService?.getBirthdayMoment(),
    nickName: patientInfoService?.nickName || '',
    providerId: patientInfoService?.getProviderId() as string,
    medicalId: patientInfoService?.getMedicalId() as string,
    spokenLanguage: patientInfoService?.getSpokenLanguage() as string[],
    weight: patientInfoService?.getWeightInLb() as number,
    height: patientInfoService?.getHeight() as number,
    mobilePhone: patientInfoService?.getMobilePhoneNumber() as string,
    homePhone: patientInfoService?.getHomePhoneNumber() as string,
    email: patientInfoService?.getEmail() as string,
    address: patientInfoService?.getAddress().address as string,
    address2: patientInfoService?.getAddress().address2 as string,
    city: patientInfoService?.getAddress().city as string,
    state: patientInfoService?.getAddress().state as string,
    zipcode: patientInfoService?.getAddress().zipcode as string,
    medicationCompliance: medicationManagementService?.medicationCompliance,
    medicationReminder: medicationManagementService?.medicationReminder,
    medicationReminderDeclineReason: medicationManagementService?.medicationReminderDeclineReason,
    note: medicationManagementService?.note ?? undefined,
    transcribeNote: last(transcribe?.notes)?.note || '',
    diagnoses: patientInfoService?.diagnoses,
    icdTable: healthConditionService?.healthConditions,
    complexity: complexityService?.patientComplexity,
  }), [
    transcribe?.notes,
    patientInfoService,
  ]);

  const handleOnValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    if (changedValues.skipBpBaseline) {
      form.resetFields(['bpBaselineDate', 'systolic_blood_pressure', 'diastolic_blood_pressure', 'pulse']);
    }
    onValuesChange?.(changedValues, values);
    icdForm.onIcdChange(changedValues);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      scrollToFirstError
      name="transcribeVisitDrawerForm"
      onValuesChange={handleOnValuesChange}
      initialValues={initialValues}
      disabled={isLoading || disabled}
    >
      <TranscribeVisitCardComponent
        updatedAt={info?.patientInfo?.updatedAt}
      >
        <div>
          <CardTitleComponent size="default">
            <span>Basic Information</span>
          </CardTitleComponent>
          <TranscribeBasicInfoFormItemComponent
            form={form}
            patientInfo={info}
          />
        </div>

        <div>
          <CardTitleComponent size="default">
            <span>Address</span>
          </CardTitleComponent>
          <TranscribeAddressFormItemComponent
            form={form}
          />
        </div>

        <div>
          <CardTitleComponent size="default">
            <span>Insurance</span>
          </CardTitleComponent>
          <InsuranceFormContainer
            patientInfo={patientInfoService?.patient || {}}
            onEdit={setIsInsuranceEdit}
          />
        </div>
      </TranscribeVisitCardComponent>
      {showBaseLine && (
        <TranscribeVisitCardComponent
          title="BP Baseline"
          updatedAt={null}
        >
          <BpBaselineFormItemComponent
            enrollmentDate={
              TimezoneService.calcDateTimeDayjs(
                enrolledProgramService?.getEnrollmentDate() || '',
                patientInfoService?.getTimezone()
              ).format(USA_DATE)
            }
            form={form}
          />
        </TranscribeVisitCardComponent>
      )}

      <TranscribeVisitCardComponent
        title="Diagnosis / ICD Code"
        updatedAt={info?.patientInfoService?.healthConditionService?.updatedAt}
      >
        <CardTextComponent>
          <ChronicDiagnosesNoteComponent />
          <DiagnosisIcdCodeFormContainer
            form={form}
            initialValues={{
              icdTable: initialValues.icdTable,
            }}
            isLoading={isLoading}
            patientId={patientId}
            formButtons={null}
          />
        </CardTextComponent>
      </TranscribeVisitCardComponent>

      <div className="transcribing-visit__medication">
        <TranscribeVisitCardComponent
          title=""
          updatedAt={(
            info?.medicationManagementService?.medLastUpdatedInfo?.updatedAt?.toISOString()
          )}
          cardBordered={false}
          cardClassName="py0i"
        >
          <MedicationCommonCardComponent
            form={form}
            patientId={patientId}
            isEditing
            showEdit={false}
            showHeaderOutside={false}
            medicationReviewRequired={false}
            medicationReminderRequired
            medicationReviewHidden
            formButtons={null}
          />
        </TranscribeVisitCardComponent>
      </div>

      <TranscribeVisitCardComponent
        title="Lab Result"
      >
        <CardTextComponent>
          <ChronicDiagnosesNoteComponent />
        </CardTextComponent>
        <AddLabResultDrawerContainer
          patientId={patientId}
        />
      </TranscribeVisitCardComponent>
      <div className="transcribe-form-fixed-bottom mt30 w100">
        <Divider className="transcribe-form-divider" />
        <FormItem
          name="transcribeNote"
          id="transcribeNote"
        >
          <Input.TextArea
            placeholder="Leave notes"
            rows={2}
          />
        </FormItem>

        <div className="flex mt20">
          {
            showSubmitButton
              ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              ) : null
          }
          {
            showSaveForLaterButton
              ? (
                <Button
                  type="link"
                  onClick={handleOnSave}
                  disabled={disabled}
                >
                  {saveText}
                </Button>
              ) : null
          }
        </div>
      </div>
    </Form>
  );
};
