import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  uniqBy,
  sortBy,
  isEmpty
} from 'lodash';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useSendMessage } from '../../hooks/useSendMessage/useSendMessage';
import {
  MessageHistory, MessageHistoryMessage, MessageType
} from '../../services/CHSServices/types/data';
import {
  MessageACKPublisher, PatientMessageChannel
} from '../../types/message';
import { Patient } from '../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useMessageServicesContext } from './MessageServicesContext';
import { MAX_MSG_PER_CHANNEL } from '../../services/CHSServices/constants';
import { CHSServices } from '../../services/CHSServices/CHSServices';
import { PublishMessageResponseData } from '../../services/CHSServices/types';
import { useRefState } from '../../hooks/useRefState/useRefState';
import { useEffectWithPrevValue } from '../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { omitUnusedMessageFields } from '../../features/message/helper';

export interface MessagePatientContextValue {
  draft?: string;
  file?: RcFile;
  setFile: (file?: RcFile) => void;
  setDraft: (value?: string) => void;
  patientId: string;
  patientChannel?: PatientMessageChannel;
  patientInfo?: Patient | null;
  isLoadingPatientChannel: boolean;
  getPatientChannelHistoryFirstTime: () => void;
  handleGetMorePatientMessages: (
    fromTimestamp?: string,
    count?: number,
    shouldUpdateHasMore?: boolean,
  ) => void;
  publishACKMessage: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  markChannelAsUnread: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  handleSendMessage: (
    payload: MessageHistoryMessage['payload'],
    initChannel?: boolean,
  ) => Promise<PublishMessageResponseData>;
  scrollToBottom: (ref: MutableRefObject<Element | null>) => void;
  setNotificationTarget: (value: string) => void;
  setPatientActiveSubscription: (isAdded?: boolean) => void;
}

const MessagePatientContext = createContext<MessagePatientContextValue | undefined>(undefined);

export const useMessagePatientContext = () => {
  const context = useContext(MessagePatientContext);
  return (context || {}) as MessagePatientContextValue;
};

export interface MessagePatientContextProviderProps {
  children: ReactNode;
  patientId: string;
  patientInfo?: Patient | null;
  // will fetch history on new notification
  listenToNewNotification?: boolean;
  // to determine if the context is used in patient profile,
  // to prevent unnecessary channelMap update
  isInPatientProfile?: boolean;
}
export const MessagePatientContextProvider = ({
  children,
  patientId,
  patientInfo,
  listenToNewNotification = true,
  isInPatientProfile,
}: MessagePatientContextProviderProps) => {
  const {
    userInfo,
  } = useLoggedInUserFromContext();
  const {
    channelMap,
    userMap,
    handleSetUserMap,
    handleSetPatientMapData,
    handleGetChannelHistory,
    handleInitChannel,
    handleSetChannel,
    setNotificationTarget,
    setActiveSubscription,
    setOnGoingChannel,
  } = useMessageServicesContext();
  const newPatientMessageNotification = channelMap[patientId]?.newNotification;
  const [
    patientChannel,
    setPatientChannel,
  ] = useState<PatientMessageChannel>({
    ...channelMap[patientId],
    patientId,
    hasMoreMessages: false,
    fromTimestamp: undefined,
    messageHistory: {} as MessageHistory,
  });
  const [
    getShouldUpdateChannelMap,
    setShouldUpdateChannelMap,
  ] = useRefState(false);
  const [isLoadingPatientChannel, setIsLoadingPatientChannel] = useState(false);
  const [draft, setDraft] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<RcFile | undefined>(undefined);

  const {
    sendMessage,
  } = useSendMessage({ patientId, patientInfo });

  const setPatientChannelHistory = (
    messageHistory: MessageHistory,
    shouldUpdateHasMore = true,
  ) => {
    if (isEmpty(messageHistory)) {
      return;
    }
    setPatientChannel((prev) => {
      const nonEmptyMessages = messageHistory.messages || [];
      const hasMoreMessages = nonEmptyMessages.length === MAX_MSG_PER_CHANNEL;
      const combinedHistoryWithDatetime = [
        ...nonEmptyMessages,
        ...(prev?.messages || []),
      ].map((msg) => ({
        ...msg,
        dateTime: CHSServices.convertTimestamp(msg.timestamp),
      }));
      const dedupedHistoryMessages = uniqBy(combinedHistoryWithDatetime, (msg) => msg.dateTime);
      const readableMessages = CHSServices.getListOfReadableMessages(dedupedHistoryMessages);
      const sortedHistoryMessages = sortBy(readableMessages, (msg) => msg.dateTime);

      const omittedMessages = omitUnusedMessageFields(sortedHistoryMessages);

      setIsLoadingPatientChannel(false);

      if (getShouldUpdateChannelMap()) {
        // IFF a text message is sent to channel
        handleSetChannel(patientId, messageHistory);
        setShouldUpdateChannelMap(false);
      }

      return {
        ...prev,
        lastClientACK: messageHistory.lastClientACK,
        isUnread: !!messageHistory?.teamUnread,
        messages: omittedMessages,
        lastMsg: CHSServices.getLastMessage(omittedMessages),
        hasMoreMessages: shouldUpdateHasMore ? hasMoreMessages : prev.hasMoreMessages,
        fromTimestamp: omittedMessages?.[0]?.timestamp,
        messageHistory,
      };
    });
  };

  const getPatientChannelHistory = async (
    fromTimestamp?: string,
    count = MAX_MSG_PER_CHANNEL,
    shouldUpdateHasMore = true,
  ): Promise<void> => {
    setIsLoadingPatientChannel(true);
    // fromTimestamp is to support load more message in the past
    const res = await handleGetChannelHistory({
      patientIds: [patientId],
      fromTimestamp,
      count,
    });
    const history = res?.[0] as MessageHistory;
    setPatientChannelHistory(history, shouldUpdateHasMore);
  };

  const getPatientChannelHistoryFirstTime = async () => {
    getPatientChannelHistory();
    if (!userMap[patientId]) {
      handleSetUserMap(patientId, true);
    }
  };

  const handleGetMorePatientMessages: MessagePatientContextValue['handleGetMorePatientMessages'] = (
    fromTimestamp, // to fetch new message
    count = MAX_MSG_PER_CHANNEL,
    shouldUpdateHasMore = true,
  ) => {
    if (!fromTimestamp) {
      setPatientChannel((prev) => ({
        ...prev,
        hasMoreMessages: false,
      }));
    }
    const fTimestamp = fromTimestamp || patientChannel.fromTimestamp || '0';
    getPatientChannelHistory(fTimestamp, count, shouldUpdateHasMore);
  };

  const handleSendMessage: MessagePatientContextValue['handleSendMessage'] = async (
    payload,
    initChannel = true,
  ) => {
    let res;
    try {
      if (initChannel) {
        await handleInitChannel(patientId);
      }
      res = await sendMessage(payload);
      if (!res?.success) {
        throw new Error(res?.error || '');
      }
      if (initChannel) {
        // sent a text message to patient successfully
        setOnGoingChannel(patientId);
        setShouldUpdateChannelMap(true);
      }
      setDraft(undefined);
      return res;
    } catch (error) {
      const axiosError = (error as AxiosError).response?.data as string;
      return {
        success: false,
        error: axiosError || res?.error || 'Failed to send message',
      };
    }
  };

  const publishACKMessage: MessagePatientContextValue['publishACKMessage'] = () => (
    patientChannel?.isUnread
      ? handleSendMessage({
        type: MessageType.ACK,
        publisher: `${MessageACKPublisher.READ}-${userInfo?.id}`,
      }, false)
      : null
  );

  const markChannelAsUnread: MessagePatientContextValue['markChannelAsUnread'] = async () => {
    if (patientChannel?.isUnread) {
      return null;
    }
    const res = handleSendMessage({
      type: MessageType.ACK,
      publisher: `${MessageACKPublisher.UNREAD}-${userInfo?.id}`,
    }, true);
    if (isInPatientProfile) {
      // add channelMap before fetching placeholder
      handleSetChannel(patientId, {
        ...patientChannel?.messageHistory,
        teamUnread: 1,
      });
    }
    return res;
  };

  const scrollToBottom: MessagePatientContextValue['scrollToBottom'] = (
    ref,
  ) => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  };

  const setPatientActiveSubscription: MessagePatientContextValue['setPatientActiveSubscription'] = (isAdded = true) => setActiveSubscription?.(patientId, !!isAdded);

  useDeepCompareEffect(() => {
    if (patientInfo) {
      handleSetPatientMapData(patientInfo);
    }
  }, [patientInfo]);

  useEffect(() => {
    if (listenToNewNotification && newPatientMessageNotification?.receivedAt) {
      if (!isInPatientProfile || channelMap[patientId]) {
        // if there is channelMap for channel,
        // channel is from channels api or ongoing
        setShouldUpdateChannelMap(true);
      }
      setTimeout(() => {
        handleGetMorePatientMessages(
          CHSServices.parseTimestamp(Date.now()),
          1,
          false
        );
      }, 100);
    }
  }, [newPatientMessageNotification?.receivedAt, channelMap[patientId]]);

  useEffectWithPrevValue(channelMap[patientId], (prev) => {
    if (!prev) return;
    if (!listenToNewNotification && channelMap[patientId]) {
      // when not listening to new notification
      // update patient channel based on channelMap
      setPatientChannel({
        ...channelMap[patientId],
        patientId,
        messageHistory: {} as MessageHistory,
      });
    }
  });

  const contextValue = useGetContextValue<MessagePatientContextValue>({
    patientId,
    patientChannel,
    patientInfo,
    isLoadingPatientChannel,
    getPatientChannelHistoryFirstTime,
    handleGetMorePatientMessages,
    publishACKMessage,
    markChannelAsUnread,
    handleSendMessage,
    scrollToBottom,
    draft,
    setDraft,
    file,
    setFile,
    setNotificationTarget,
    setPatientActiveSubscription,
  });

  return (
    <MessagePatientContext.Provider key={patientId} value={contextValue}>
      {children}
    </MessagePatientContext.Provider>
  );
};
