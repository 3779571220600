import { Checkbox, Col, Row } from 'antd';
import { find, intersection, map } from 'lodash';
import { useWatch } from 'antd/lib/form/Form';
import { useMemo } from 'react';
import {
  AssociatedInfo, ProgramCategoryEnum, VitalEnumType, PatientProtocolEnum
} from '../../../../uc-api-sdk';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import { EligibleProgramsTooltipComponent } from '../../../../uiComponent/EligibleProgramsTooltipComponent/EligibleProgramsTooltipComponent';
import { VitalsToMonitorTooltipComponent } from '../../../../uiComponent/VitalsToMonitorTooltipComponent/VitalsToMonitorTooltipComponent';
import { getBasicProgramCategoriesOptions } from '../../helper/ProgramCategoriesOptions';
import { getBasicVitalsOptions } from '../../helper/VitalsOptions';
import { ProviderSelectContainer, ProviderSelectContainerProps } from '../../../../uiComponent/Transcribing/component/ProviderSelectContainer/ProviderSelectContainer';

import './PatientVitalsAndProgramsComponent.scss';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PotentialPatientInfoComponent } from '../../../patient/component/PotentialPatientInfoComponent/PotentialPatientInfoComponent';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { useProgramEnrollmentForm } from '../../hook/useProgramEnrollmentForm/useProgramEnrollmentForm';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { EnrollmentCareProtocolComponent } from '../EnrollmentCareProtocolComponent.tsx/EnrollmentCareProtocolComponent';

export interface PatientVitalsAndProgramsComponentProps {
  isEnrolled?: boolean;
  clinicProgramParticipation?: ProgramCategoryEnum[];
  isSaasModel?: boolean;
  eligiblePrograms: ProgramCategoryEnum[];
  onClinicChange?: (clinicId: string) => void;
  associatedList?: AssociatedInfo[];
  onValuesChange?: (changedValues: Record<string, unknown>) => void;
  recommendedProtocol? : PatientProtocolEnum;
}

const vitalsOptions = getBasicVitalsOptions([
  VitalEnumType.BG,
  VitalEnumType.BP,
  VitalEnumType.HS,
  VitalEnumType.PO,
]);

export const PatientVitalsAndProgramsComponent = ({
  isEnrolled,
  clinicProgramParticipation,
  isSaasModel,
  eligiblePrograms,
  onClinicChange,
  associatedList,
  onValuesChange,
  recommendedProtocol,
}: PatientVitalsAndProgramsComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();
  const peForm = useProgramEnrollmentForm();
  const programs = useWatch(peForm.getName('programs'), peForm.form);

  const updateFieldValue: typeof peForm['form']['setFieldValue'] = (
    key: keyof typeof peForm['formInput'],
    value: unknown,
  ) => {
    peForm.form.setFieldsValue({ [key]: value });
    onValuesChange?.({ [key]: value });
  };

  const availablePrograms = useMemo(() => {
    if (!clinicProgramParticipation) return undefined;
    const orderedPrograms = intersection(
      [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM, ProgramCategoryEnum.VALUE_BASED],
      clinicProgramParticipation,
    );
    return getBasicProgramCategoriesOptions(orderedPrograms);
  }, [clinicProgramParticipation, programs]);

  useDeepCompareEffect(() => {
    // to make sure selected programs are within the available programs
    if (programs?.length && availablePrograms) {
      const availableProgramsValues = map(availablePrograms, 'value');
      const updatedPrograms = intersection(availableProgramsValues, programs);
      updateFieldValue(peForm.getName('programs'), updatedPrograms);
    }
  }, [
    map(availablePrograms, 'value'),
    programs
  ]);

  const handleOnProviderListLoaded: ProviderSelectContainerProps['onProviderListLoaded'] = (
    providerList,
  ) => {
    const clinicId = peForm.getValue('clinicId', peForm.form.getFieldValue);
    const currentProvider = peForm.getValue('provider', peForm.form.getFieldValue);
    const providerListIds = map(providerList, 'id');
    const associatedProviderId = find(associatedList, { clinicId })?.doctorId;
    // if provider list for new selected clinic includes the selected provider, then keep selection
    // else select provider from associated list, if applicable
    // else select the first provider from the list
    if (!providerListIds.includes(currentProvider)) {
      let newProviderId = providerListIds[0] ?? undefined;
      if (associatedProviderId && providerListIds.includes(associatedProviderId)) {
        newProviderId = associatedProviderId;
      }
      updateFieldValue(peForm.getName('provider'), newProviderId);
    }
  };

  return (
    <div className="patient-enrollment-vitals-and-programs">
      <p>Recommended based on the diagnose selection.</p>
      <CardComponent hovered className="vitals-monitoring-card">
        <VitalsToMonitorTooltipComponent>
          <h4>Vitals to Monitor</h4>
        </VitalsToMonitorTooltipComponent>
        <FormItem info={peForm.getInfo('vitals')}>
          <Checkbox.Group
            className="checkbox-group-btn-style w100"
          >
            <Row gutter={[10, 10]}>
              {map(vitalsOptions, ({ label, value }) => (
                <Col span={6} key={value as string}>
                  <Checkbox className="block" value={value}>{label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </FormItem>
        <Row
          gutter={10}
          className="confirm-patient-clinic mt10"
        >
          <Col span={24}>
            <h4>Confirm patient's clinic</h4>
          </Col>
          <FormItem
            noStyle
            shouldUpdate={peForm.shouldUpdate(['clinicId', 'provider'])}
          >
            {
              ({ getFieldValue }) => {
                const clinicId = peForm.getValue('clinicId', getFieldValue);
                return (
                  <>
                    <Col span={12}>
                      <FormItem
                        info={peForm.getInfo('clinicId')}
                        required
                      >
                        <ClinicSelectComponent
                          getAllClinics={!isProvider}
                          disabled={isEnrolled}
                          getClinicData={(clinic) => onClinicChange?.(clinic?.id || '')}
                          paginatedSearch={false}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        info={peForm.getInfo('provider')}
                        required
                      >
                        <ProviderSelectContainer
                          clinicIds={clinicId ? [clinicId] : undefined}
                          onProviderListLoaded={handleOnProviderListLoaded}
                        />
                      </FormItem>
                    </Col>
                  </>
                );
              }
            }
          </FormItem>
          <Col span={24} className="mt5">
            <PotentialPatientInfoComponent
              associatedList={associatedList}
            />
          </Col>
        </Row>
        <EligibleProgramsTooltipComponent>
          <h4 className="mt5">Billing Program Selection</h4>
        </EligibleProgramsTooltipComponent>
        <FormItem info={peForm.getInfo('programs')}>
          <Checkbox.Group
            className="checkbox-group-btn-style programs-checkbox-group w100"
          >
            <Row gutter={10}>
              {
                map(
                  availablePrograms,
                  ({ label, value }) => (
                    <Col span={12} key={value}>
                      <Checkbox
                        className="block"
                        value={value}
                        disabled={(
                          isSaasModel !== true
                          && !eligiblePrograms.includes(value)
                        )}
                      >
                        {label}
                      </Checkbox>
                    </Col>
                  )
                )
              }
            </Row>
          </Checkbox.Group>
        </FormItem>
        {recommendedProtocol && (
          <EnrollmentCareProtocolComponent
            recommendedProtocol={recommendedProtocol}
          />
        )}
      </CardComponent>
    </div>
  );
};
