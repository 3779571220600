import { useCallback } from 'react';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { FrequencyDisplayType } from '../../../uc-api-sdk';

export type MedicationReminderFormValue = {
  enableReminder: boolean;
  reminders: {
    timeSelect: number
  }[];
}

export const useMedicationReminderForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('reminders', {
    timeSelect: {
      name: 'timeSelect',
      label: ''
    }
  }, options);

  const checkIfFrequencyEveryHour = useCallback((
    frequency?: FrequencyDisplayType | null,
  ) => (
    [
      FrequencyDisplayType.Q6H,
      FrequencyDisplayType.Q8H
    ].includes(frequency || '' as FrequencyDisplayType)
  ), []);

  const shouldDisableTimeSelect = useCallback((
    index: number,
    frequency?: FrequencyDisplayType | null,
  ) => {
    if (!checkIfFrequencyEveryHour(frequency) || index === 0) {
      return false;
    }
    return true;
  }, []);

  return useGetContextValue({
    ...factory,
    checkIfFrequencyEveryHour,
    shouldDisableTimeSelect
  });
};
