import { filter, map } from 'lodash';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { ClinicEvent, VisitParticipant, useClinicEventGetPtFutureVisits } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { CalendarVisitParticipantComponent } from '../../../calendar/component/CalendarParticipantListComponent/CalendarVisitParticipantComponent';
import { ListComponent } from '../../../../uiComponent/ListComponent/ListComponent';

export interface UpcomingVisitsContainerProps {
  visitId?: string;
}

export const UpcomingVisitsContainer = ({
  visitId,
}: UpcomingVisitsContainerProps) => {
  const { info } = usePatientContext();
  const { id = '' } = info || {};

  const clinicEvents = useClinicEventGetPtFutureVisits({
    options: { sendOnMount: !!id },
    params: {
      id,
    },
  });

  if (!id || clinicEvents.isLoading) return null;

  const renderVisit = (c: ClinicEvent, index: number) => (
    <div key={index}>
      {'- '}
      <DisplayDateComponent value={c.startTime} format="DATE_TIME_AM_PM" />
      {': '}
      <VisitTypeEnumComponent value={c.visitType} />
      {' - '}
      <ListComponent
        value={c.visitParticipants as VisitParticipant[]}
        splitter=", "
        renderItem={(v) => (
          <CalendarVisitParticipantComponent
            value={v}
          />
        )}
      />
    </div>
  );

  // Exclude the current visit from the list of upcoming visits
  const visits = filter(clinicEvents.data?.data, (c: ClinicEvent) => c.id !== visitId);

  return (
    <FetchComponent info={clinicEvents}>
      <div className="text-gray-scale-2 fs12 mb10">
        {visits.length > 0 && <div className="mt5">Patient's upcoming visit(s):</div>}
        {map(visits, renderVisit)}
      </div>
    </FetchComponent>
  );
};
