import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { MAX_INSURANCE_COUNT } from '../../hook/useInsuranceHelper';

export interface InsuranceAddButtonComponentProps {
  onAdd: () => void;
  listLength: number;
  disabled?: boolean;
}

export const InsuranceAddButtonComponent = ({
  onAdd,
  listLength,
  disabled,
}: InsuranceAddButtonComponentProps) => {
  const isMax = listLength === MAX_INSURANCE_COUNT;

  if (!listLength) {
    return null;
  }

  if (isMax) {
    return (
      <TooltipComponent
        type={null}
        title={`Currently, only ${MAX_INSURANCE_COUNT} insurances are supported`}
      >
        <TextComponent underline>
          Want to add more insurance?
        </TextComponent>
      </TooltipComponent>
    );
  }

  return (
    <LinkButton
      onClick={onAdd}
      useBlueColor
      disabled={disabled}
    >
      <PlusOutlined />
      Add insurance plan
    </LinkButton>
  );
};
