import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useBoolean } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import EnvConfig from '../../configs/envConfig/envConfig';

const THRESHOLD = EnvConfig.idleTime * 1000;

export interface UserActiveContextValue {
  isActive: boolean;
}

const UserActiveContext = createContext<
  UserActiveContextValue | undefined
>(undefined);

export const useUserActiveContext = () => {
  const context = useContext(UserActiveContext);
  if (!context) throw new Error('UserActiveContext not found');
  return context as UserActiveContextValue;
};

export interface UserActiveContextProviderProps {
  children: ReactNode;
}
export const UserActiveContextProvider = ({
  children,
}: UserActiveContextProviderProps) => {
  let timeoutTimer: NodeJS.Timeout | null = null;
  const { value: isActive, setTrue, setFalse } = useBoolean(true);

  const clearThisTimeout = () => {
    if (timeoutTimer !== null) {
      clearTimeout(timeoutTimer);
    }
  };

  const createTimeout = () => {
    clearThisTimeout();
    timeoutTimer = setTimeout(() => { setFalse(); }, THRESHOLD);
  };

  useEffect(() => {
    createTimeout();
    return clearThisTimeout;
  }, []);

  const handleEvent = useCallback(() => {
    setTrue();
    createTimeout();
  }, [setTrue, setFalse]);

  useEffect(() => {
    window.addEventListener('keydown', handleEvent);
    window.addEventListener('mousedown', handleEvent);
    window.addEventListener('mousemove', handleEvent);
    window.addEventListener('scroll', handleEvent);

    return () => {
      window.removeEventListener('keydown', handleEvent);
      window.removeEventListener('mousedown', handleEvent);
      window.removeEventListener('mousemove', handleEvent);
      window.removeEventListener('scroll', handleEvent);
    };
  }, [handleEvent]);

  useEffect(() => {
    console.log(isActive ? 'user active' : 'user inactive');
  }, [isActive]);

  const contextValue = useGetContextValue<UserActiveContextValue>({
    isActive,
  });

  return (
    <UserActiveContext.Provider value={contextValue}>
      {children}
    </UserActiveContext.Provider>
  );
};
