import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Nullable } from '../../uc-api-sdk';

type SetStateWithPrevState<T> = (
  prevState?: Nullable<T>
) => Nullable<T> | undefined;
type SetStateProps<T> = (
  T | SetStateWithPrevState<T>
);
interface SetStateOptions {
  forceUpdate?: boolean;
}
type SetState<T> = (
  props: SetStateProps<T>,
  options?: SetStateOptions,
) => void;

// use it when you want to prevent unnecessary re-renders, and guarantee updated value in hooks
// forceUpdate is optional, and not recommended to use for every case
// @return [getState, setState]
export const useRefState = <T>(initialState?: T) => {
  const state = useRef<Nullable<T> | undefined>(initialState);
  const [, forceUpdate] = useState(0);

  const getState = useCallback((): Nullable<T> | undefined => (
    Object.freeze(state.current) // immutable state
  ), []);

  const setState = useCallback<SetState<Nullable<T> | undefined>>((
    props,
    options = {},
  ) => {
    if (typeof props === 'function') {
      state.current = (props as SetStateWithPrevState<T>)(state.current);
    } else {
      state.current = props;
    }
    if (options?.forceUpdate) {
      forceUpdate((prev) => prev + 1);
    }
  }, []);

  useEffect(() => () => {
    state.current = undefined;
  }, []);

  return [getState, setState] as [typeof getState, typeof setState];
};
