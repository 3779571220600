import { Form } from 'antd';
import dayjs from 'dayjs';
import { useMedicationReminderForm } from '../../hook/useMedicationFormReminder';
import { MedicationReminderTimeSelectFormItemComponent } from '../MedicationReminderTimeSelectFormItemComponent/MedicationReminderTimeSelectFormItemComponent';
import { FrequencyDisplayType } from '../../../../uc-api-sdk';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';

import './MedicationReminderListFormItemComponent.scss';

const FrequencyHourMap = {
  [FrequencyDisplayType.Q6H]: 6,
  [FrequencyDisplayType.Q8H]: 8,
} as Record<FrequencyDisplayType, number>;

const TimeMap = {
  '8am': 8 * 60,
  '1230pm': 12 * 60 + 30,
  '6pm': 18 * 60,
  '9pm': 21 * 60,
};

export interface MedicationReminderListFormItemComponentProps {
  frequency?: FrequencyDisplayType;
}

export const MedicationReminderListFormItemComponent = ({
  frequency,
}: MedicationReminderListFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const medicationReminderForm = useMedicationReminderForm({ form });

  const getTimesForEvery = (
    everyHour: number,
    startMinutes = 8 * 60, // 8am
  ) => {
    if (!everyHour) return [];
    const times = Math.floor(24 / everyHour);
    const startOfDay = dayjs().startOf('day');
    const startHourDayjs = startOfDay.add(startMinutes, 'minutes');
    return Array(times).fill(0).map((_, i) => {
      const nextTime = startHourDayjs.add(i * everyHour, 'hour');
      const nextHour = nextTime.hour();
      const nextMinutes = nextTime.minute();
      return nextHour * 60 + nextMinutes;
    });
  };

  const getDefaultTimeSlots = (frequency?: FrequencyDisplayType) => {
    switch (frequency) {
      case FrequencyDisplayType.QD:
      case FrequencyDisplayType.OM:
        return [
          TimeMap['8am']
        ];
      case FrequencyDisplayType.ON:
        return [
          TimeMap['6pm']
        ];
      case FrequencyDisplayType.BID:
        return [
          TimeMap['8am'],
          TimeMap['6pm'],
        ];
      case FrequencyDisplayType.TID:
        return [
          TimeMap['8am'],
          TimeMap['1230pm'],
          TimeMap['6pm'],
        ];
      case FrequencyDisplayType.QID:
        return [
          TimeMap['8am'],
          TimeMap['1230pm'],
          TimeMap['6pm'],
          TimeMap['9pm'],
        ];
      case FrequencyDisplayType.Q6H:
        return getTimesForEvery(FrequencyHourMap[frequency]);
      case FrequencyDisplayType.Q8H:
        return getTimesForEvery(FrequencyHourMap[frequency]);
      default:
        return [];
    }
  };

  const getReminderLabel = (fieldName: number) => {
    switch (fieldName) {
      case 0:
        return '1st reminder';
      case 1:
        return '2nd reminder';
      case 2:
        return '3rd reminder';
      default:
        return `${fieldName + 1}th reminder`;
    }
  };

  const handleChangeTimeForEveryHourFrequency = (value?: number) => {
    if (
      !value
      || !medicationReminderForm.checkIfFrequencyEveryHour(frequency)
    ) {
      return;
    }
    const newTimes = getTimesForEvery(FrequencyHourMap[frequency as FrequencyDisplayType], value);
    newTimes.forEach((time, index) => {
      medicationReminderForm.form.setFieldValue(
        medicationReminderForm.makeObjectNamePath('timeSelect', index),
        time
      );
    });
  };

  useEffectWithPrevValue(frequency, (prev) => {
    if (!prev) {
      // check for initial values
      const reminders = (
        medicationReminderForm.form.getFieldValue(
          medicationReminderForm.getMainFormItemName()
        )
      );
      if (reminders?.length) return;
    }
    const defaultTimeSlots = getDefaultTimeSlots(frequency);
    medicationReminderForm.form.setFieldValue(
      medicationReminderForm.getMainFormItemName(),
      defaultTimeSlots.map((timeSelect) => ({ timeSelect }))
    );
  });

  return (
    <div className="medication-reminder-list__form flex f-w">
      <Form.List name={medicationReminderForm.getMainFormItemName()}>
        {
          (fields) => (
            fields.map((field) => (
              <div
                key={field.name}
                className="medication-reminder__item"
              >
                <MedicationReminderTimeSelectFormItemComponent
                  fieldName={field.name}
                  label={getReminderLabel(field.name)}
                  disabled={medicationReminderForm.shouldDisableTimeSelect(field.name, frequency)}
                  onChange={handleChangeTimeForEveryHourFrequency}
                />
              </div>
            ))
          )
        }
      </Form.List>
    </div>
  );
};
