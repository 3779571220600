import medicationHTN from '../../../assets/images/medication-HTN.png';
import medicationDM from '../../../assets/images/medication-DM.png';
import medicationAbbreviations from '../../../assets/images/medication-Abbreviations.png';

export interface MedicationGuideModalComponentProps { }

export const MedicationGuideModalComponent = () => (
  <div className="flex">
    <div className="flex fd-c">
      <img
        src={medicationHTN}
        alt="medication-HTN"
        width={600}
        height={400}
      />
      <img
        src={medicationDM}
        alt="medication-DM"
        width={600}
        height={400}
      />
    </div>
    <div>
      <img
        src={medicationAbbreviations}
        alt="medication-Abbreviations"
        width={400}
        height={600}
      />
    </div>
  </div>
);
