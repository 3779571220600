import { MESSAGE_ICON_2 } from '../../../../../assets/commonIconInfo';
import { Icon } from '../../../../../icons/Icon';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export const PrintStayInTouchReminderComponent = () => (
  <>
    <div>
      <Icon info={MESSAGE_ICON_2} />
    </div>
    <div className="print-reminder">
      <div className="flex f-w">
        Stay in touch with your care team through secure
        {' '}
        <TextComponent bold size="small">Unified Care APP messaging</TextComponent>
        {' '}
        or phone calls at
        {' '}
        <TextComponent bold size="small">866-899-3998.</TextComponent>
      </div>
    </div>
  </>
);
