import { WeightService } from '../../../helpers/weight';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GenderTexts } from '../constants';

export const useDemographicsForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    firstName: {
      name: 'firstName',
      label: 'First Name',
      required: true,
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      required: true,
    },
    nickName: {
      name: 'nickName',
      label: 'Nickname',
    },
    birthday: {
      name: 'birthday',
      label: 'DOB',
      required: true,
    },
    gender: {
      name: 'gender',
      label: 'Sex at birth',
      required: true,
      tooltip: {
        tip: GenderTexts.genderTooltip,
        type: 'info-icon',
      },
    },
    genderIdentity: {
      name: 'genderIdentity',
      label: 'Gender Identity',
      tooltip: {
        tip: GenderTexts.genderIdentityTooltip,
        type: 'info-icon',
      },
    },
    race: {
      name: 'race',
      label: 'Race',
    },
    languages: {
      name: 'languages',
      label: 'Preferred Spoken Language',
      required: true,
    },
    behaviorChange: {
      name: 'behaviorChange',
      label: 'Stage of Behavior Change',
    },
    height: {
      name: 'height',
      label: 'Height',
    },
    weight: {
      name: 'weight',
      label: `Weight (${WeightService.weightUnitDisplay})`,
      required: true,
    },
    patientTechLevel: {
      name: 'patientTechLevel',
      label: 'Tech Level',
      required: true,
    },
    isTestUser: {
      name: 'isTestUser',
      label: "It's a test patient",
    },
    clinicId: {
      name: 'clinicId',
      label: 'Clinic Name',
      required: true,
    },
    doctorId: {
      name: 'doctorId',
      label: 'Provider Name',
      required: true,
    },
    medicalRecordId: {
      name: 'medicalRecordId',
      label: 'Medical Record Number (MRN)',
      required: true,
    },
    assignedToRD: {
      name: 'assignedToRD',
      label: 'Patient\'s assigned RD/HC',
      required: true,
    },
    assignedToCA: {
      name: 'assignedToCA',
      label: 'Patient\'s assigned CM/CA',
      required: true,
    },
    phone: {
      name: 'phone',
      label: 'Mobile Phone',
      required: true,
    },
    phone2: {
      name: 'phone2',
      label: 'Home Phone',
    },
    smartPhone: {
      name: 'smartPhone',
      label: '',
    },
    canUseForLogin: {
      name: 'canUseForLogin',
      label: ' ',
    },
    loginId: {
      name: 'loginId',
      label: 'Username',
    },
    password: {
      name: 'password',
      label: '',
    },
    email: {
      name: 'email',
      label: 'E-mail',
    },
    address: {
      name: 'address',
      label: 'Address',
    },
    address2: {
      name: 'address2',
      label: 'Apt/Suite',
    },
    city: {
      name: 'city',
      label: 'City',
    },
    state: {
      name: 'state',
      label: 'State',
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zip Code',
    },
    communitySupport: {
      name: 'communitySupport',
      label: 'Community / Social Support',
    },
    contactInfo: {
      name: 'contactInfo',
      label: 'Contact Information',
    },
    otherProviders: {
      name: 'otherProviders',
      label: 'Other Providers',
    },
  }, options);

  return factory;
};
