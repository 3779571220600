import dayjs, { Dayjs } from 'dayjs';
import {
  Fragment,
  RefObject,
  useEffect,
  useRef
} from 'react';
import { last, map } from 'lodash';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { MessageHistoryItemWrapperComponent } from '../MessageHistoryItemWrapperComponent/MessageHistoryItemWrapperComponent';
import { MessageChannelSectionHrDateComponent } from '../MessageChannelSectionHrDateComponent/MessageChannelSectionHrDateComponent';
import { MessageChannelSectionHrPatientACKComponent } from '../MessageChannelSectionHrPatientACKComponent/MessageChannelSectionHrPatientACKComponent';
import { MessageChannelSectionHrMoreMessageComponent } from '../MessageChannelSectionHrMoreMessageComponent/MessageChannelSectionHrMoreMessageComponent';
import { MessageHistoryEmptyComponent } from '../MessageHistoryEmptyComponent/MessageHistoryEmptyComponent';
import { USA_DATE } from '../../../../constants/timeFormat';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';

import './MessageHistoryComponent.scss';
import { PatientMessageChannel } from '../../../../types/message';

export interface MessageHistoryComponentProps {
  patientChannel?: PatientMessageChannel;
  onLoadMoreMessages?: (fromTimestamp?: string) => void;
  onScrollToBottom?: (ref: RefObject<HTMLElement>) => void;
}

export const MessageHistoryComponent = ({
  patientChannel,
  onLoadMoreMessages,
  onScrollToBottom,
}: MessageHistoryComponentProps) => {
  const bottomRef = useRef(null);
  const {
    userMap,
  } = useMessageServicesContext();
  const billableTime = useBillableTimeInterventionComp();

  const handleClickLoadMoreMessage = () => {
    billableTime.send({
      patientId: patientChannel?.patientId,
      component: BillableTimeInterventionComponentEnum.Messaging,
      detail: BillableTimeInterventionDetailEnum.MessageThread,
    });
    onLoadMoreMessages?.();
  };

  const checkShouldDisplayHrDate = (
    currDateTime: Dayjs,
    prevDateTime?: number,
  ) => (
    !prevDateTime || currDateTime.format(USA_DATE) !== dayjs(prevDateTime).format(USA_DATE)
  );

  useEffect(() => {
    onScrollToBottom?.(bottomRef);
  }, [last(patientChannel?.messages)?.timestamp]);

  if (!patientChannel || !patientChannel.messages?.length) {
    return (
      <MessageHistoryEmptyComponent />
    );
  }

  return (
    <div className="message-history-component">
      {
        patientChannel.hasMoreMessages
          ? (
            <MessageChannelSectionHrMoreMessageComponent
              onClick={handleClickLoadMoreMessage}
            />
          )
          : null
      }
      {
        map(patientChannel?.messages, (m, index) => {
          const { messageId, payload, dateTime } = m;
          const publisherInfo = userMap[payload.publisher as string];

          const prevDateTime = patientChannel.messages?.[index - 1]?.dateTime;
          const nextDateTime = patientChannel.messages?.[index + 1]?.dateTime;

          const messageDateTime = dayjs(dateTime);
          const shouldDisplayDate = checkShouldDisplayHrDate(messageDateTime, prevDateTime);

          return (
            <Fragment key={messageId}>
              {
                shouldDisplayDate
                  ? (
                    <MessageChannelSectionHrDateComponent
                      date={dayjs(messageDateTime)}
                    />
                  )
                  : null
              }
              <MessageHistoryItemWrapperComponent
                dateTime={dayjs(messageDateTime)}
                messagePayload={payload}
                messageId={messageId}
                publisher={publisherInfo}
              />
              <MessageChannelSectionHrPatientACKComponent
                lastClientACK={patientChannel.lastClientACK}
                currDateTime={messageDateTime}
                nextDateTime={nextDateTime}
              />
            </Fragment>
          );
        })
      }
      <div ref={bottomRef} className="message-history-bottom" />
    </div>
  );
};
