import { map } from 'lodash';
import moment from 'moment';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  BillableProcessStatusEnum,
  BillableReviewActionType,
  BillableTimeReviewRequest,
  Sort$Direction,
  useBillableTimesReviewHandle,
  useBillableTimesReviewList,
  useBillableTimesReviewQueryRequiredReviewCountsWithRange
} from '../../../../uc-api-sdk';
import { BillableTimeReviewTableComponent, BillableTimeReviewTableDataSource } from '../../component/BillableTimeReviewTableComponent/BillableTimeReviewTableComponent';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useFromDateToDate } from '../../../../hooks/useFromDateToDate/useFromDateToDate';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';

export const defaultSorter = () => [
  { direction: Sort$Direction.DESC, property: 'startTime' },
];

export const BillableTimeReviewTableContainer = () => {
  const handleHook = useBillableTimesReviewHandle({});
  const updateHook = useUpdate('BILLABLE_TIME_REVIEW_UPDATED');

  const {
    value: date,
    fromToDate,
    handleChangeMonthAndGetStartEndOfMonth,
  } = useFromDateToDate({
    type: 'date',
    initial: moment(),
    unitOfTime: 'month',
  });

  const reviewRequiredCountHook = useBillableTimesReviewQueryRequiredReviewCountsWithRange({
    params: {
      startTime: fromToDate.fromDate.valueOf(),
      endTime: fromToDate.toDate.valueOf(),
    }
  });

  const handleConfirmBillableTime = async (id: string) => {
    ApiRequestHelper.tryCatch(handleHook.send({
      params: {
        request: {
          id,
          action: BillableReviewActionType.APPROVE,
        }
      }
    }), {
      success: 'Billable time has been added to the patient.',
      error: 'Failed to enter actual billable time.',
      onSuccess: () => {
        updateHook.updateValue();
      }
    });
  };

  const tableChange = useTableChange<BillableTimeReviewTableDataSource, BillableTimeReviewRequest>({
    sort: defaultSorter(),
    sortProcessor: (sorter) => sorterHelper(sorter),
  });

  const billableTimeHook = useBillableTimesReviewList({
    options: { sendOnMount: true },
    params: {
      request: {
        filter: {
          startTime: fromToDate.fromDate.valueOf(),
          endTime: fromToDate.toDate.valueOf(),
        },
        pageInfo: tableChange.pageInfo,
      }
    }
  });

  const handleRefetch = () => {
    billableTimeHook.refetch();
    reviewRequiredCountHook.refetch();
  };

  useUpdateListener('BILLABLE_TIME_REVIEW_UPDATED', handleRefetch);

  return (
    <FetchComponent
      info={billableTimeHook}
      showOnRefetch
    >
      {(data, info) => {
        const result: BillableTimeReviewTableDataSource[] = data?.data
          ? map(billableTimeHook.data?.data?.content, (v) => ({
            id: v.id as string,
            memberId: v.memberId as string,
            memberName: v.memberName as string,
            medicalOrganization: v.medicalOrganization as string,
            startTime: v.startTime as number,
            activity: v.activity as string[],
            originBillableTime: v.originBillableTime as number,
            correctedBillableTime: v.correctedBillableTime as number,
            totalTime: v.totalTime as number,
            status: v.status as BillableProcessStatusEnum,
            comment: v.comment as string,
            orgNumber: v.medicalOrgNumber as string,
          })) : [];
        return (
          <BillableTimeReviewTableComponent
            pagination={info.pagination}
            onChange={tableChange.handleTableChange}
            dataSource={result}
            date={date}
            confirmApproval={handleConfirmBillableTime}
            onChangeMonthAndYear={handleChangeMonthAndGetStartEndOfMonth}
            totalCount={info.data?.data?.totalSize || 0}
            reviewRequiredCount={reviewRequiredCountHook.data?.data?.count || 0}
          />
        );
      }}
    </FetchComponent>
  );
};
