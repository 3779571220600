import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useBaselineForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    bpBaselineDate: {
      name: 'bpBaselineDate',
      label: 'Date of measurement',
    },
    systolic_blood_pressure: {
      name: 'systolic_blood_pressure',
      label: 'SYS',
    },
    diastolic_blood_pressure: {
      name: 'diastolic_blood_pressure',
      label: 'DIA',
    },
    pulse: {
      name: 'pulse',
      label: 'Pulse',
    },
    skipBpBaseline: {
      name: 'skipBpBaseline',
      label: 'Cannot acquire a baseline meeting the requirement',
    },
  }, options);

  return factory;
};
