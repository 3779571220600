import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface DiagnosisTitleComponentProps {

}

export const DiagnosisTitleComponent = () => {
  const diagnoseTooltip = () => (
    <div className="mt10">
      <ul>
        <li>HTN - Hypertension</li>
        <li>HLD - Hyperlipidemia</li>
        <li>DM2 - Type 2 Diabetes</li>
        <li>PreDM - Pre-Diabetes</li>
        <li>DM Others - Includes Type 1 Diabetes and other types of diabetes.</li>
        <li>COPD - Chronic Obstructive Pulmonary Disease</li>
        <li>CKD - Chronic Kidney Disease</li>
        <li>CHF - Congestive Heart Failure</li>
        <li>RDD - Renal Dialysis Dependent</li>
        <li>Others - Any other diagnosis the patient might have.</li>
      </ul>
    </div>
  );

  return (
    <div className="flex fd-c mb10">
      <span className="flex mb8">
        <span className="bold">
          Diagnoses
        </span>
        <Tooltip
          className="secondary-gray"
          title={diagnoseTooltip}
          placement="bottom"
          overlayStyle={{ minWidth: '520px' }}
        >
          <InfoCircleOutlined className="ml5" />
        </Tooltip>
      </span>
    </div>
  );
};
