import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientCheckCodeIsBillable } from '../../../../uc-api-sdk';
import { ICDTableComponent, ICDTableComponentProps } from '../../component/ICDTableComponent/ICDTableComponent';
import { useICDCodeForm } from '../../hook/useICDCodeForm';

export interface ICDTableContainerProps extends ICDTableComponentProps {
  icdForm: ReturnType<typeof useICDCodeForm>;
}

export const ICDTableContainer = (props: ICDTableComponentProps) => {
  const { info } = usePatientContext();
  const billableInfo = usePatientCheckCodeIsBillable({
    options: { sendOnMount: false }
  });

  if (!info?.id) return null;

  const getBillableInfo = async (icdCode: string) => {
    const res = await billableInfo.send({
      params: {
        memberId: info?.id,
        icdCode,
      },
      options: { cache: true },
    });
    return res?.data;
  };

  return (
    <ICDTableComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      getBillableInfo={getBillableInfo}
    />
  );
};
