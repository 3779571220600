import { isEmpty } from 'lodash';
import { Measurement, PatientControlLevel } from '../uc-api-sdk';

export class ControlLevelService {
  private controlLevel?: PatientControlLevel;

  private ehrBpBaseLine?: Measurement;

  constructor(controlLevel?: PatientControlLevel, ehrBpBaseLine?: Measurement) {
    this.controlLevel = controlLevel;
    this.ehrBpBaseLine = ehrBpBaseLine;
  }

  public hasBpBaseline(): boolean {
    return (this.ehrBpBaseLine && this.ehrBpBaseLine?.isBaseline) || false;
  }

  public hasBgBaseline(): boolean {
    return !isEmpty(this.controlLevel?.bgBaseline);
  }

  public getControlLevelInfo(): PatientControlLevel {
    return this.controlLevel ?? {};
  }
}
