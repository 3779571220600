import { ReactNode } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { EditMedicationContainer } from '../../container/EditMedicationContainer';
import { MedicationType } from '../../type/type';
import { ExtendableDrawerButtonComponent, ExtendableDrawerButtonComponentProps } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.editMedication;

export interface EditMedicationDrawerComponentProps {
  medication: MedicationType;
  patientId?: string;
  buttonText?: ReactNode;
  buttonProps?: ExtendableDrawerButtonComponentProps['buttonProps'];
  autoEnableReminder?: boolean;
}
export const EditMedicationDrawerComponent = ({
  medication,
  patientId,
  buttonText = 'Edit',
  buttonProps = { type: 'text' },
  autoEnableReminder,
}: EditMedicationDrawerComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText={buttonText}
        buttonProps={buttonProps}
        onClick={setOpen}
      />
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        open={open}
        onClose={setClose}
        className="drawerComponent"
        title="Edit Medication"
        footer={null}
        placement="right"
        maskClosable={false}
        width={520}
      >
        <EditMedicationContainer
          onSubmit={setClose}
          initialValues={medication}
          patientId={patientId}
          autoEnableReminder={autoEnableReminder}
        />
      </ExtendableDrawerComponent>
    </>
  );
};
