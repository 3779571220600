import { useCallback } from 'react';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { SelectType } from '../../../Input/types';
import { DosageFrequencyEnumComponent } from '../../../../enumComponent/DosageFrequencyEnumComponent/DosageFrequencyEnumComponent';
import { FrequencyDisplayType } from '../../../../uc-api-sdk';

export interface MedicationDosageFrequencySelectComponentProps
  extends SelectType<FrequencyDisplayType> { }

export const MedicationDosageFrequencySelectComponent = ({
  value,
  onChange,
  allowClear = true
}: MedicationDosageFrequencySelectComponentProps) => {
  const optionRenderer = useCallback<
    NonNullable<EnumSelectComponentProps<FrequencyDisplayType>['optionRenderer']>
  >((option) => (
    <DosageFrequencyEnumComponent
      value={option.value}
      type="long"
    />
  ), []);

  return (
    <EnumSelectComponent
      value={value}
      onChange={onChange}
      options={FrequencyDisplayType}
      optionRenderer={optionRenderer}
      allowClear={allowClear}
    />
  );
};
