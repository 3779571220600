import dayjs from 'dayjs';
import { map } from 'lodash';
import { Divider } from 'antd';
import { UNIFIEDCARE_PILOT_LOGO } from '../../../../../assets/logoIconInfo';
import { AccountHelper } from '../../../../../helpers/account';
import { Icon } from '../../../../../icons/Icon';
import {
  CarePlan,
  GenderEnum,
  Height,
  Weight,
} from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { Title } from '../../../../../uiComponent/Title/Title';
import { GenderEnumComponent } from '../../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { HeightComponent } from '../../../../../uiComponent/HeightComponent/HeightComponent';
import { WeightComponent } from '../../../../../uiComponent/WeightComponent/WeightComponent';
import { ProgramCategoryEnumListComponent } from '../../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { EnrolledProgramService } from '../../../../../services/EnrolledProgramService';
import { VitalEnumTypeListComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { PrintVisitSummaryComponent } from '../PrintVisitSummaryComponent/PrintVisitSummaryComponent';
import { PrintCarePlanUserComponent } from '../PrintCarePlanUserComponent/PrintCarePlanUserComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { PrintStayInTouchReminderComponent } from '../PrintReminderComponent/PrintStayInTouchComponent';
import { PrintGeneralCarePlanComponent } from './PrintGeneralCarePlanComponent';
import { PrintVisitCarePlanComponent } from './PrintVisitCarePlanComponent';

import './PrintCarePlanPreviewComponent.scss';

export interface PrintCarePlanPreviewComponentProps {
  carePlan?: CarePlan;
}

export const PrintCarePlanPreviewComponent = ({
  carePlan,
}: PrintCarePlanPreviewComponentProps) => {
  const {
    assignees,
    profile,
    enrolledProgram,
    visit,
    medicalRecordId,
    source,
    careTeamMembers,
  } = carePlan || {};
  const isMigration = source === 'MIGRATION';
  const enrolledProgramService = new EnrolledProgramService(enrolledProgram ?? undefined);

  const renderPreviewBody = () => {
    const isVisitCarePlan = !!visit?.id;
    if (isVisitCarePlan) {
      return (
        <PrintVisitCarePlanComponent
          enrolledProgramService={enrolledProgramService}
          carePlan={carePlan}
        />
      );
    }
    // profile/general care plan
    return (
      <PrintGeneralCarePlanComponent
        enrolledProgramService={enrolledProgramService}
        carePlan={carePlan}
      />
    );
  };

  return (
    <div className="print-care-plan-preview">
      <div className="print-care-plan-preview-header">
        <Title noMargin>
          CARE PLAN
        </Title>
        <Icon
          className="unified-care-logo"
          info={UNIFIEDCARE_PILOT_LOGO}
        />
      </div>
      <div className="print-care-plan-preview-profile">
        <TextComponent className="pop-text">
          {AccountHelper.getFullName(profile)}
        </TextComponent>
        <div className="print-care-plan-preview-profile__details">
          <span>
            {dayjs().endOf('day').diff(dayjs(profile?.birthday), 'years')}
            {' y/o'}
          </span>
          <GenderEnumComponent value={profile?.gender as GenderEnum} />
          <span>
            MRN:
            {' '}
            <DisplayOrEmptyComponent value={medicalRecordId} />
          </span>
          <HeightComponent
            label="Ht:"
            height={profile?.height as Height}
          />
          <WeightComponent
            label="Wt:"
            weight={profile?.weight as Weight}
            className="flex"
          />
          <span>
            Enrolled programs:
            {' '}
            <ProgramCategoryEnumListComponent
              value={enrolledProgramService.getPrograms()}
              type="short"
            />
          </span>
          <span>
            Vitals:
            {' '}
            <VitalEnumTypeListComponent
              value={enrolledProgramService.getVitals() || []}
              type="short"
              splitter=", "
            />
          </span>
        </div>
      </div>
      <Divider className="print-care-plan-preview__divider bold-divider" />
      <div className="print-care-plan-preview-care-team">
        <div className="page-break" />
        <TextComponent className="pop-text">
          Care Team
        </TextComponent>
        <div className="print-care-plan-preview-care-team__names">
          <PrintCarePlanUserComponent
            profile={profile?.doctorUser}
          />
          <PrintCarePlanUserComponent
            profile={assignees?.assignedToRDUser}
          />
          <PrintCarePlanUserComponent
            profile={assignees?.assignedToCAUser}
          />
          {
            isMigration
              ? (
                map(careTeamMembers, (member, idx) => (
                  <PrintCarePlanUserComponent
                    key={idx}
                    profile={{ firstName: member, lastName: '' }}
                  />
                ))
              ) : null
          }
        </div>
      </div>
      <div className="print-care-plan-preview-reminder">
        <div className="page-break" />
        <PrintStayInTouchReminderComponent />
      </div>
      <Divider className="print-care-plan-preview__divider" />
      <div className="print-care-plan-preview-visit-summary">
        <div className="page-break" />
        <PrintVisitSummaryComponent visit={visit} />
      </div>
      {
        renderPreviewBody()
      }
      <div className="print-care-plan-preview-program-description mt40">
        <div className="page-break" />
        <span className="program-description__title">
          Program Description & Care Coordination:
        </span>
        The primary care physician collaborates with care team members to set up
        the patient's care plan and provide periodic in-clinic visits to review progress.
        The Unified Care clinicians remotely monitor patients vitals and provide
        point-of-care support and real-time behavioral coaching under
        the supervision of the primary care physician.
      </div>
      <div className="print-care-plan-preview-announcement pb20">
        <TextComponent italic color="gray2">
          This Care Plan was created under the supervision
          of the provider per the terms in the partnership agreement.
        </TextComponent>
      </div>
    </div>
  );
};
