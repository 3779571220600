import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { UpdatedByAt } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';
import { MedicationListDeclineReasonSelectComponent } from '../MedicationListComponent/MedicationListReminderDeclineReasonSelectComponent';
import { MedicationListReminderServiceSelectComponent } from '../MedicationListComponent/MedicationListReminderServiceSelectComponent';
import { MedicationSelectLabelInfoComponent } from './MedicationSelectLabelInfoComponent';

export interface MedicationReminderFormItemComponentProps {
  required?: boolean;
  hideRequiredMark?: boolean;
  medicationReminderLastInfo?: UpdatedByAt;
}

export const MedicationReminderFormItemComponent = ({
  required,
  hideRequiredMark = true,
  medicationReminderLastInfo,
}: MedicationReminderFormItemComponentProps) => {
  const hook = useMedicationContainerForm();

  return (
    <>
      <FormItem
        name={hook.getName('medicationReminder')}
        label={(
          <MedicationSelectLabelInfoComponent
            label={hook.getLabel('medicationReminder')}
            lastInfo={medicationReminderLastInfo}
          />
        )}
        labelCol={{ span: 24 }}
        rules={[{ required, message: <MissingErrorComponent /> }]}
        hideRequiredMark={hideRequiredMark}
      >
        <MedicationListReminderServiceSelectComponent />
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={hook.shouldUpdate(['medicationReminder'])}
      >
        {
          ({ getFieldValue }) => (
            hook.checkIfDeclinedReminder(getFieldValue) ? (
              <FormItem
                info={hook.getInfo('medicationReminderDeclineReason')}
                labelCol={{ span: 24 }}
              >
                <MedicationListDeclineReasonSelectComponent />
              </FormItem>
            ) : null
          )
        }
      </FormItem>
    </>
  );
};
