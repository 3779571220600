import { some } from 'lodash';
import {
  ClinicGoalEnum,
  ConditionEnum,
  HealthConditionItem,
  PatientDiagnosesEnum
} from '../../uc-api-sdk';

export const getDefaultClinicalGoalValue = (
  condition?: ConditionEnum | null,
  healthConditions?: HealthConditionItem[] | null,
): ClinicGoalEnum | null => {
  switch (condition) {
    case ConditionEnum.GLUCOSE_MANAGEMENT: {
      const isDM = some(healthConditions, (item) => (
        [
          PatientDiagnosesEnum.DM_OTHERS,
          PatientDiagnosesEnum.DM,
          PatientDiagnosesEnum.DM2,
        ].includes(item?.diagnosis as PatientDiagnosesEnum)
      ));
      if (isDM) return ClinicGoalEnum.GLUCOSE_MANAGEMENT_2;
      const isPreDM = some(healthConditions, (item) => (
        [
          PatientDiagnosesEnum.PRE_DM
        ].includes(item?.diagnosis as PatientDiagnosesEnum)
      ));
      if (isPreDM) {
        return ClinicGoalEnum.GLUCOSE_MANAGEMENT_5;
      }
      break;
    } case ConditionEnum.BLOOD_PRESSURE_MANAGEMENT:
      return ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_1;
    case ConditionEnum.LIPID_MANAGEMENT:
      return ClinicGoalEnum.LIPID_MANAGEMENT_9;
    case ConditionEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE:
      return ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1;
    case ConditionEnum.CHRONIC_KIDNEY_DISEASE:
      return ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_1;
    default:
      return null;
  }
  return null;
};
