import React, { ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { USA_DATE } from '../../constants/timeFormat';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface BirthdayComponentProps {
  value?: Dayjs;
  showHowOld?: boolean;
  ageFirst?: boolean;
}

export const BirthdayComponent = ({
  value,
  showHowOld = true,
  ageFirst = false,
}: BirthdayComponentProps) => {
  const age = (showHowOld ? (
    <>
      {dayjs().diff(dayjs(value), 'years')}
      {' yrs'}
    </>
  ) : null);

  const dob = dayjs(value).format(USA_DATE);

  const renderOrder = (firstValue: ReactNode, secondValue: ReactNode) => (
    <>
      {firstValue}
      {secondValue && (
        <>
          {' ('}
          {secondValue}
          )
        </>
      )}
    </>
  );

  const renderValue = ageFirst
    ? renderOrder(age, dob)
    : renderOrder(dob, age);

  return (
    value
      ? renderValue
      : <EmptyComponent />
  );
};
