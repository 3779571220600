import { Button } from 'antd';
import { BehaviorChangeEnum } from '../../../../uc-api-sdk';
import { PatientBehaviorChangeInputComponent } from '../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeInputComponent/PatientBehaviorChangeInputComponent';

export interface PatientBehaviorChangeFormContentComponentProps {
  onSubmit?: () => void;
  value?: string;
  onChange?: (value: BehaviorChangeEnum) => void;
  onCancel?: () => void;
  showButtons?: boolean;
  disabled?: boolean;
}

export const PatientBehaviorChangeFormContentComponent = ({
  onSubmit,
  value,
  onChange,
  onCancel,
  showButtons = true,
  disabled,
}: PatientBehaviorChangeFormContentComponentProps) => (
  <div className="flex fd-c gap2 w30">
    <PatientBehaviorChangeInputComponent
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    {
      showButtons && (
        <div className="flex gap2">
          <Button type="primary" onClick={onSubmit}>Save</Button>
          <Button type="ghost" onClick={onCancel}>Cancel</Button>
        </div>
      )
    }
  </div>
);
