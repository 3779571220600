import { first } from 'lodash';
import { useState } from 'react';
import { Col, Row } from 'antd';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import EmployeeInfo from '../../../../hooks/useUserInfo/employeeInfo';
import { Employee } from '../../../../types/user';
import { RoleTypeEnum, usePatientUpsertPatientAssignees } from '../../../../uc-api-sdk';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { EnrollmentAssignedCaRdSelectComponent } from '../../component/EnrollmentAssignedCaRdSelectComponent/EnrollmentAssignedCaRdSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface EnrollmentAssignedCareTeamSelectContainerProps {
  patientInfo: PatientInfo;
}

export const EnrollmentAssignedCareTeamSelectContainer = ({
  patientInfo,
}: EnrollmentAssignedCareTeamSelectContainerProps) => {
  const isLoadingAssignee = patientInfo.isLoadingObj.assigneesLoading;
  const patientId = patientInfo.id;
  const patientClinicId = patientInfo.patientInfoService?.patient.clinicId;
  const {
    assigneesService: patientAssigneesService,
    refetch,
  } = patientInfo || {};
  const [selectedAssigneeRdId, setSelectedAssigneeRdId] = useState<string[] | undefined>(
    patientAssigneesService?.getRdAssigneesId(),
  );
  const [selectedAssigneeCaId, setSelectedAssigneeCaId] = useState<string[] | undefined>(
    patientAssigneesService?.getCaAssigneesId(),
  );
  const updatePatientAssigneesInfo = usePatientUpsertPatientAssignees({});

  const onSubmitRD = useDebounce(async (
    values?: Employee[],
  ) => {
    const assignedRDs = [] as string[];
    values?.forEach((employee) => {
      const { id } = new EmployeeInfo({
        employee: employee as Employee,
        roleAssignments: employee.assignedRoles || [],
      });

      assignedRDs.push(id);
    });
    const req = updatePatientAssigneesInfo.send({
      params: {
        patientAssigneesRequest: {
          patientIds: [patientId],
          patientAssignees: {
            assignedToRD: first(assignedRDs),
          },
        },
      },
    });
    await ApiRequestHelper.tryCatch(req, {
      error: 'Failed to update RD/HC assignees',
      success: 'Patient RD/HC Assignee has been updated.',
    });
    refetch?.(['assigneesRefetch']);
  }, 1000, [
    patientId,
  ]);

  const onSubmitCA = useDebounce(async (
    values?: Employee[],
  ) => {
    const assignedCAs = [] as string[];
    values?.forEach((employee) => {
      const { id } = new EmployeeInfo({
        employee: employee as Employee,
        roleAssignments: employee.assignedRoles || [],
      });
      assignedCAs.push(id);
    });

    const req = updatePatientAssigneesInfo.send({
      params: {
        patientAssigneesRequest: {
          patientIds: [patientId],
          patientAssignees: {
            assignedToCA: first(assignedCAs),
          },
        },
      },
    });
    await ApiRequestHelper.tryCatch(req, {
      error: 'Failed to update CM/CA assignees',
      success: 'Patient CM/CA Assignee has been updated.',
    });
    refetch?.(['assigneesRefetch']);
  }, 1000, [
    patientId,
  ]);

  return (
    <Row gutter={10}>
      <Col span={6}>
        <FormItem
          label="Patient's assigned RD/HC"
          colon={false}
        >
          <EnrollmentAssignedCaRdSelectComponent
            patientId={patientId}
            roles={[RoleTypeEnum.RD, RoleTypeEnum.HC]}
            needToFilter={false}
            clinicIds={patientClinicId ? [patientClinicId] : undefined}
            value={selectedAssigneeRdId}
            onChange={setSelectedAssigneeRdId}
            onSubmit={onSubmitRD}
            isLoading={isLoadingAssignee}
            autoSubmit
          />
        </FormItem>
      </Col>
      <Col span={6}>
        <FormItem
          label="Patient's assigned CM/CA"
          colon={false}
        >
          <EnrollmentAssignedCaRdSelectComponent
            patientId={patientId}
            roles={[RoleTypeEnum.CA]}
            needToFilter={false}
            clinicIds={patientClinicId ? [patientClinicId] : undefined}
            value={selectedAssigneeCaId}
            onChange={setSelectedAssigneeCaId}
            onSubmit={onSubmitCA}
            isLoading={isLoadingAssignee}
            autoSubmit
          />
        </FormItem>
      </Col>
    </Row>
  );
};
