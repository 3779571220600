import { Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface PriorAuthCommentFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthCommentFormItemComponent = ({
  fieldName,
}: PriorAuthCommentFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <FormItem
      name={insuranceForm.makeListNamePath('priorAuthComments', fieldName)}
      label={insuranceForm.getLabel('priorAuthComments')}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 3 }}
      />
    </FormItem>
  );
};
