import { useMemo } from 'react';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';
import { MedicationCommonCardComponent, MedicationCommonCardComponentProps } from '../../../medication/component/MedicationCommonCardComponent/MedicationCommonCardComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface WorklistMedicationComponentProps
  extends Pick<MedicationCommonCardComponentProps, 'title' | 'showHeaderOutside'> {
  patientId: string;
  onSubmit?: () => void;
}

export const WorklistMedicationComponent = ({
  patientId,
  onSubmit,
  title,
  showHeaderOutside,
}: WorklistMedicationComponentProps) => {
  const { info } = usePatientContext() || {};
  const { clinicEvent } = useWorklistContext();
  const medicationManagementService = info?.medicationManagementService;

  const shouldResetCompliance = useMemo(() => {
    if (
      !clinicEvent
      || !medicationManagementService
    ) {
      return undefined;
    }
    return (
      // reset if
      // visit is PostMD and medicationComplianceLastModifiedAt is updated before visit checkin time
      clinicEvent?.visitType === VisitTypeEnum.POST_MD_FOLLOW_UP
      && (
        !clinicEvent?.checkInTime
        || !medicationManagementService?.medicationComplianceLastModifiedAt
        || (medicationManagementService.medicationComplianceLastModifiedAt
          .isBefore(clinicEvent?.checkInTime))
      )
    );
  }, [medicationManagementService?.medicationComplianceLastModifiedAt]);

  if (!medicationManagementService) {
    return <LoadingOverlayComponent isLoading />;
  }

  return (
    <NestedFormComponent>
      <WorklistContentComponent>
        <MedicationCommonCardComponent
          patientId={patientId}
          visitId={clinicEvent?.id}
          showEdit={false}
          isEditing
          formButtons={null}
          onSubmit={onSubmit}
          onMedicationSubmit={onSubmit}
          title={title}
          showHeaderOutside={showHeaderOutside}
          medicationReminderRequired
          shouldResetCompliance={shouldResetCompliance}
        />
      </WorklistContentComponent>
    </NestedFormComponent>
  );
};
