import { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { PriorAuthStartNewFormComponent, PriorAuthStartNewFormComponentProps } from '../../component/PriorAuthStartNewFormComponent/PriorAuthStartNewFormComponent';
import { PriorAuthStartSuccessComponent } from '../../component/PriorAuthStartSuccessComponent/PriorAuthStartSuccessComponent';
import { useInsuranceUpdateHandler } from '../../hook/insuranceUpdateHandler';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface PriorAuthStartNewFormContainerProps
  extends PriorAuthStartNewFormComponentProps {
  patientId: string;
}

export const PriorAuthStartNewFormContainer = ({
  patientId,
  initialValues,
  onSubmit,
  onCancel
}: PriorAuthStartNewFormContainerProps) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    renewPriorAuthInfo,
    renewPriorAuthCase,
  } = useInsuranceUpdateHandler(patientId);
  const insuranceUpdate = useUpdate('INSURANCE_UPDATED');
  const insuranceRenew = useUpdate('INSURANCE_RENEWED');
  const { isLoading } = renewPriorAuthInfo;

  useEffect(() => () => {
    // if success on close, refetch
    if (isSuccess) {
      // should pick only 1 value type to listen to updates
      insuranceUpdate.updateValue();
      insuranceRenew.updateValue();
    }
  }, [isSuccess]);

  const handleSubmit: PriorAuthStartNewFormComponentProps['onSubmit'] = async (
    values,
  ) => {
    renewPriorAuthCase(
      {
        success: '',
        error: 'Failed to renew prior-auth.',
        onSuccess: () => {
          onSubmit?.(values);
          setIsSuccess(true);
        }
      },
      {
        ...values,
        providerId: values.insuranceProviderId,
        insuranceId: initialValues?.insuranceId || '',
      }
    );
  };

  const handleOnOk = () => {
    onCancel?.();
  };

  const debouncedHandleSubmit = useDebounce(handleSubmit, 500, [handleSubmit]);

  if (isSuccess) {
    return (
      <PriorAuthStartSuccessComponent
        onOk={handleOnOk}
      />
    );
  }

  return (
    <PriorAuthStartNewFormComponent
      initialValues={initialValues}
      disabled={isLoading}
      onSubmit={debouncedHandleSubmit}
      onCancel={onCancel}
    />
  );
};
