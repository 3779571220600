import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { useClinicEventGetVisitWindowSuggestion } from '../../../../uc-api-sdk';
import { VisitTypeComponent } from '../../component/VisitTypeComponent/VisitTypeComponent';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';

export interface FollowUpdateVisitWindowComponentProps {
  patientId?: string;
  startTime?: Dayjs;
}

export const FollowUpdateVisitWindowComponent = ({
  patientId,
  startTime,
}: FollowUpdateVisitWindowComponentProps) => {
  const windowSuggestion = useClinicEventGetVisitWindowSuggestion({
    options: { sendOnMount: !!patientId && !!startTime },
    params: {
      memberId: patientId || '',
      request: {
        startTime: startTime?.toISOString().replace('Z', ''),
      }
    },
  });

  const visitTimes = useMemo(() => (windowSuggestion.data?.data
    ? {
      pastVisit: windowSuggestion.data?.data.at(0),
      futureVisit: windowSuggestion.data?.data.at(1),
    } : null
  ), [windowSuggestion.data?.data]);

  if (!visitTimes?.pastVisit?.visitType) return null;

  return (
    <FetchComponent info={windowSuggestion}>
      <div className="text-gray-scale-2 fs12 mb10">
        Patient's last
        {' '}
        <VisitTypeComponent
          className="inline-block"
          value={visitTimes.pastVisit?.visitType ?? undefined}
          type="full"
        />
        {' '}
        visit was on
        {' '}
        <DisplayDateComponent value={visitTimes.pastVisit?.startTime} format="USA_DATE" />
        , the next
        {' '}
        <VisitTypeComponent
          className="inline-block"
          value={visitTimes.futureVisit?.visitType ?? undefined}
          type="full"
        />
        {' '}
        should be done before
        {' '}
        <DisplayDateComponent value={visitTimes.futureVisit?.startTime} format="USA_DATE" />
        .
      </div>
    </FetchComponent>
  );
};
