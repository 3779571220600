import {
  Button, Card, Col, Row, Space
} from 'antd';
import { FC } from 'react';
import { ResultSourceEnumComponent } from '../../../../enumComponent/ResultSourceEnumComponent/ResultSourceEnumComponent';
import {
  VitalEnumTypeListComponent
} from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { ClinicStatus, MedicalOrganizationStatus } from '../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { HeaderPopoverComponent } from '../../../../uiComponent/HeaderPopoverComponent/HeaderPopoverComponent';
import { LabelWithValueComponent } from '../../../../uiComponent/LabelWithValueComonent/LabelWithValueComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { WatchedIndicatorContainer } from '../../container/WatchedIndicatorContainer/WatchedIndicatorContainer';
import { usePatientPageControllerContext } from '../../context/PatientPageControllerContext/PatientPageControllerContext';
import { ActionsComponent } from '../ActionsComponent/ActionsComponent';
import {
  BillableTimePopoverContentComponent
} from '../BillableTimePopoverContentComponent/BillableTimePopoverContentComponent';
import { ChatButtonComponent } from '../ChatButtonComponent/ChatButtonComponent';
import {
  MeasurementProgressPopoverContentComponent
} from '../MeasurementProgressPopoverContentComponent/MeasurementProgressPopoverContentComponent';
import { PatientAssigneeComponent } from '../PatientAssigneeComponent/PatientAssigneeComponent';
import { PatientDevicesComponent } from '../PatientDevicesComponent/PatientDevicesComponent';
import { PatientDobGenderComponent } from '../PatientDobGenderComponent/PatientDobGenderComponent';
import { PatientHealthConditionsComponent } from '../PatientHealthCondationsComponent/PatientHealthCondationsComponent';
import { PatientInfoCardComponentProps } from '../PatientInfoCardComponent/PatientInfoCardComponent';
import { PatientLanguageComponent } from '../PatientLanguageComponent/PatientLanguageComponent';
import { PatientWeightComponent } from '../PatientWeightComponent/PatientWeightComponent';
import { PhoneCallDropdownComponent } from '../PhoneCallDropdownComponent/PhoneCallDropdownComponent';
import {
  PatientCareProgressTagComponent
} from '../TagsComponent/PatientInfoProgressTagComponent/PatientCareProgressTagComponent';
import {
  PatientMeasureProgressTagComponent
} from '../TagsComponent/PatientInfoProgressTagComponent/PatientMeasureProgressTagComponent';
import {
  PatientStatusTagListComponent
} from '../TagsListComponent/PatientStatusTagListComponent/PatientStatusTagListComponent';
import './EnrolledPatientInfoCardComponent.scss';
import { ControlLevelInfoComponent } from '../../../controlLevel/component/ControlLevelInfoComponent/ControlLevelInfoComponent';
import { PatientProgramStatusComponent } from '../PatientProgramStatusComponent/PatientProgramStatusComponent';

export const EnrolledPatientInfoCardComponent: FC<PatientInfoCardComponentProps> = ({
  patientInfo,
  patientStatus,
  hasNewMessage = false,
  onClickChat,
  onClickCreateTask,
  onClickPrintVitalsData,
  onClickCreateMTPR,
  onClickStartEnrollment,
  onClickCreateVisit,
  onClickPrintCarePlan,
  onClickPrintPhysicianReport,
  onClickPreFollowUpSummary,
  onBillableTagClick,
  onMeasurementTagClick,
  hyperlinkToPatientProfile
}) => {
  const { showChatButton } = usePatientPageControllerContext();
  const { clinic } = patientInfo?.patientInfo || {};
  const span = 4;
  const pg = useChangePage();
  const goToPatientProfile = () => {
    pg.goToCarePortalPatient({
      patientId: patientInfo?.id || ''
    });
  };

  const renderName = () => (hyperlinkToPatientProfile ? (
    <Button className="m0 p0 fs18" type="link" onClick={goToPatientProfile}>
      {patientInfo?.patientInfoService?.getFullNameWithNickname()}
    </Button>
  ) : (
    patientInfo?.patientInfoService?.getFullNameWithNickname()
  ));

  return (
    <div className="patient-info-card-component">
      <Card bordered className="patient-info-card-component__content">
        <div className="flex fd-c gap1">
          <div className="flex ai-s jc-sb mb10">
            <Space className="flex ai-c" wrap>
              <Space>
                <Title noMargin>
                  {renderName()}
                </Title>
                <WatchedIndicatorContainer />
              </Space>
              <Space wrap>
                <PatientStatusTagListComponent value={patientStatus} />
                <ControlLevelInfoComponent
                  patientId={patientInfo?.id || ''}
                  cardInfo={patientInfo?.controlLevelService?.getControlLevelInfo() || undefined}
                />
                <HeaderPopoverComponent
                  current={patientInfo?.billableTimeService?.getBillableTime() || 0}
                  max={patientInfo?.billableTimeService?.getBillableTimeTarget() || 20}
                  qualified={patientInfo?.billableTimeService?.isQualified() || false}
                  processingMinute={patientInfo?.billableTimeService?.getProcessingMinute() || 0}
                  patientId={patientInfo?.id}
                  refetch={patientInfo?.refetch}
                  popoverContentRenderer={BillableTimePopoverContentComponent}
                  progressRenderer={PatientCareProgressTagComponent}
                  onClick={onBillableTagClick}
                />
                <HeaderPopoverComponent
                  current={patientInfo?.billableTimeService?.getMeasurementDays() || 0}
                  max={patientInfo?.billableTimeService?.getMeasurementDaysTarget() || 16}
                  qualified={patientInfo?.billableTimeService?.isDaysQualified() || false}
                  patientId={patientInfo?.id}
                  popoverContentRenderer={MeasurementProgressPopoverContentComponent}
                  progressRenderer={PatientMeasureProgressTagComponent}
                  onClick={onMeasurementTagClick}
                />
              </Space>
            </Space>
            <Space className="flex ai-c jc-e">
              {showChatButton
                && (
                  <ChatButtonComponent
                    hasNewMessage={hasNewMessage}
                    onClickChat={onClickChat}
                  />
                )}
              <PhoneCallDropdownComponent
                patientInfo={patientInfo?.patientInfo || {}}
                enrolledProgramInfo={patientInfo?.enrolledProgram || {}}
                isEnrolled
              />
              <ActionsComponent
                onClickCreateTask={onClickCreateTask}
                onClickPrintVitalsData={onClickPrintVitalsData}
                onClickCreateMTPR={onClickCreateMTPR}
                onClickCreateVisit={onClickCreateVisit}
                onClickPrintCarePlan={onClickPrintCarePlan}
                onClickPrintPhysicianReport={onClickPrintPhysicianReport}
                onClickPreFollowUpSummary={onClickPreFollowUpSummary}
              />
            </Space>
          </div>
          <div className="flex fd-c">
            <Row gutter={[30, 6]} className="patient-info-properties">
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  value={(
                    <PatientDobGenderComponent
                      dob={patientInfo?.patientInfoService?.getBirthday()}
                      gender={patientInfo?.patientInfoService?.getGender()}
                    />
                  )}
                />

              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Status: "
                  value={(
                    <PatientProgramStatusComponent
                      enrolledProgramService={patientInfo?.enrolledProgramService}
                      onClickStartEnrollment={onClickStartEnrollment}
                      controlService={patientInfo?.controlLevelService}
                    />
                  )}
                />

              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Vitals: "
                  value={(
                    <VitalEnumTypeListComponent
                      value={patientInfo?.enrolledProgramService?.getVitals() || []}
                      type="short"
                      splitter=", "
                    />
                  )}
                />
              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Clinic: "
                  value={(
                    <ClinicNameDisplayComponent
                      clinic={clinic ?? undefined}
                      showStatus={
                        [
                          ClinicStatus.TERMINATED,
                          ClinicStatus.TERMINATION_INITIATED,
                          MedicalOrganizationStatus.TERMINATED,
                          MedicalOrganizationStatus.TERMINATION_INITIATED
                        ].includes(
                          clinic?.medicalOrgStatus as MedicalOrganizationStatus
                          || clinic?.clinicStatus as ClinicStatus
                        )
                      }
                    />
                  )}
                />

              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Provider: "
                  value={`${patientInfo?.patientInfoService?.getProviderLastNameAndFirstName() || 'N/A'}`}
                />
              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Language: "
                  value={(
                    <PatientLanguageComponent
                      spokenLanguage={patientInfo?.patientInfoService?.getSpokenLanguage()}
                      appLanguage={patientInfo?.patientInfoService?.getAppLanguage()}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[30, 6]} className="patient-info-properties">
              <Col span={span} className="ellipse">
                <LabelWithValueComponent
                  showTooltip
                  label="Weight: "
                  title={(
                    <>
                      {!!patientInfo?.patientInfoService?.getWeightSource()
                        && (
                          <>
                            <ResultSourceEnumComponent
                              value={patientInfo?.patientInfoService?.getWeightSource()}
                            />
                            ,&nbsp;
                          </>
                        )}
                      <span>{patientInfo?.patientInfoService?.getWeightDate()}</span>
                      <PatientWeightComponent
                        weight={patientInfo?.patientInfoService?.getWeightWithUnit()}
                        height={patientInfo?.patientInfoService?.getHeightWithUnit()}
                      />
                    </>
                  )}
                  value={`${patientInfo?.patientInfoService?.getWeightInLb()} lbs`}
                />
              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="Devices: "
                  value={(
                    <PatientDevicesComponent
                      devices={patientInfo?.devicesService?.getDeviceList()}
                    />
                  )}
                />
              </Col>
              <Col span={span}>
                <LabelWithValueComponent
                  showTooltip
                  label="MR#: "
                  value={`${patientInfo?.patientInfoService?.getMedicalId() || 'N/A'}`}
                />
              </Col>

              <Col span={6}>
                <LabelWithValueComponent
                  showTooltip
                  label="Assignees: "
                  value={(
                    <PatientAssigneeComponent
                      assignees={patientInfo?.assignees}
                    />
                  )}
                />
              </Col>

              <Col span={6}>
                <LabelWithValueComponent
                  showTooltip
                  overlayClassName="health-condition-tooltip-overlay"
                  placement="bottomRight"
                  value={(
                    <>
                      <span>Health Conditions: </span>
                      {patientInfo?.patientHealthCondition?.healthConditions?.length ? (
                        <PatientHealthConditionsComponent
                          healthConditions={patientInfo?.patientHealthCondition?.healthConditions}
                        />
                      ) : 'N/A'}
                    </>
                  )}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Card>
    </div>
  );
};
