import { PatientDiagnosesEnum } from '../../../../uc-api-sdk';

export interface IcdCodeWarningComponentProps {
  checkedDx?: PatientDiagnosesEnum[];
  targetDx?: PatientDiagnosesEnum[];
}

export const IcdCodeWarningComponent = ({
  checkedDx = [],
  targetDx = [],
}: IcdCodeWarningComponentProps) => {
  if (checkedDx.length === 0 && targetDx.length === 0) {
    return null;
  }

  if (!checkedDx.every((item) => targetDx.includes(item))) {
    return (
      <div className="mb12 text-red">
        Please ensure that ICD codes are mapped
        to the corresponding diagnosis for billing purposes.
      </div>
    );
  }

  return null;
};
