import { PatientProtocolEnum } from '../../../../uc-api-sdk';

export const careProtocols = {
  [PatientProtocolEnum.HTN]: {
    title: 'HTN Care Protocol',
    content: [
      'CM/CA will conduct tech onboarding and an initial visit after enrollment.',
      'CM/CA will collect BP baseline from EHR or first in-clinic measurement.',
      'CM/CA will conduct a 10-minute in-clinic Post-MD Follow-up visit after each future provider visit.',
      'Perform medication reconciliation and set up reminders during the initial visit and at follow-up visits.',
      'RD/HC will answer patient’s questions online and handle medical alerts.',
      'Offer additional visits with RD/HC for patients with uncontrolled BP (above 140/90).'
    ],
  },
  [PatientProtocolEnum.DM2]: {
    title: 'DM2 Care Protocol',
    content: [
      'CM/CA will conduct tech onboarding and an initial visit after enrollment.',
      'CM/CA will collect BG baseline from EHR or by ordering A1C through the doctor.',
      'CM/CA will conduct a 10-minute in-clinic Post-MD Follow-up visit after each future provider visit.',
      'Perform medication reconciliation and set up reminders during the initial visit and at follow-up visits.',
      'RD/HC will answer patient’s questions online and handle medical alerts.',
      'RD/HC will conduct additional visit every 3 months.'
    ],
  },
  [PatientProtocolEnum.OTHER]: {
    title: 'Default Care Protocol',
    content: [
      'CM/CA will conduct tech onboarding and an initial visit after enrollment.',
      'Perform medication reconciliation and set up reminders during the initial visit and at follow-up visits.',
      'CM/CA will conduct a 10-minute in-clinic Post-MD Follow-up visit after each future provider visit.',
    ],
  }
};

export const defaultCareProtocol = {
  title: 'Default Care Protocol',
  content: [
    'CM/CA will conduct tech onboarding and an initial visit after enrollment.',
    'Perform medication reconciliation and set up reminders during the initial visit and at follow-up visits.',
    'CM/CA will conduct a 10-minute in-clinic Post-MD Follow-up visit after each future provider visit.',
  ],
};
