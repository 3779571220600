import { InputNumber, InputNumberProps } from 'antd';

export interface A1CCustomizeGoalComponentProps {
  id?: string;
  value?: number | null;
  onChange?: (value: number | null) => void;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
  disabled?: boolean;
}

export const A1CCustomizeGoalComponent = ({
  id,
  value,
  onChange,
  min = 5,
  max = 8,
  step = 0.1,
  precision = 1,
  disabled,
}: A1CCustomizeGoalComponentProps) => {
  const handleChange: InputNumberProps['onChange'] = (value) => {
    // null when it's over or under limits
    onChange?.((value as number ?? null));
  };

  return (
    <InputNumber
      id={id}
      value={value}
      onChange={handleChange}
      step={step}
      min={min}
      max={max}
      precision={precision}
      addonAfter="%"
      disabled={disabled}
      className="w100"
    />
  );
};
