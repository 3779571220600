import { Spin } from 'antd';
import {
  CSSProperties,
  ComponentType,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import {
  Calendar,
  momentLocalizer,
  Event,
  CalendarProps,
  EventProps,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { filter, find } from 'lodash';
import './BigCalendarComponent.scss';
import { Colors } from '../../../../hooks/useColors/colors';
import { BigCalendarHeaderComponent } from '../BigCalendarHeaderComponent/BigCalendarHeaderComponent';
import { BigCalendarToolbarComponent } from '../BigCalendarToolbarComponent/BigCalendarToolbarComponent';
import { PopoverContentRenderer, PopoverContentRendererProps } from '../BigCalendarEventComponent/BigCalendarEventComponent';

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

export interface CalendarEvent<T = unknown> extends Event {
  id?: string;
  uId?: string;
  colors?: Colors;
  showPopover?: boolean;
  showPopoverOnMount?: boolean;
  description?: string;
  info?: T;
  isEdit?: boolean;
  disabledFields?: string[];
  className?: string;
}

interface DropEventArg {
  start: Date | string;
  end: Date | string;
  isAllDay?: boolean;
  resourceId?: string;
  event: CalendarEvent;
}

export type Toolbar = Exclude<CalendarProps['components'], undefined>['toolbar'];

export interface BigCalendarProps<T = unknown> {
  newEventId?: string;
  popoverContentRenderer?: PopoverContentRenderer<T>;
  onPopoverClose?: PopoverContentRendererProps<T>['onClose'];
  eventRenderer?: ComponentType<EventProps<Event>>;
  extra?: ReactNode;
  events: CalendarEvent[];
  toolbar?: Toolbar;
  onSelecting?: CalendarProps['onSelecting'];
  onSelectSlot?: CalendarProps['onSelectSlot'];
  onSelect?: (event: CalendarEvent) => void;
  onEventDrop?: (data: DropEventArg) => void;
  onNavigate: CalendarProps['onNavigate'];
  onView?: CalendarProps['onView'];
  view?: CalendarProps['view'];
  selectedEventId?: string;
  startingHour?: number;
  defaultStartingHourView?: number | null;
  loading?: boolean;
  date?: Moment;
}

interface ComponentArg<T> {
  extra?: ReactNode;
  selectedEventId?: string;
  toolbar?: Toolbar;
  popoverContentRenderer?: PopoverContentRenderer<T>;
  onPopoverClose?: PopoverContentRendererProps<T>['onClose'];
  eventRenderer?: ComponentType<EventProps<Event>>;
}

const components = <T, >(arg: ComponentArg<T>): CalendarProps['components'] => ({
  header: (p) => (
    <BigCalendarHeaderComponent date={p.date} />
  ),
  toolbar: arg.toolbar || ((t) => (
    <BigCalendarToolbarComponent
      date={t.date}
      onView={t.onView}
      view={t.view}
      onNavigate={t.onNavigate}
      extra={arg.extra}
    />
  )),
  eventWrapper: (e) => (
    <div
      // @ts-ignore
      id={e.event.id}
      // @ts-ignore
      ref={e.event.id === arg.selectedEventId ? e.event.ref : undefined}
      // @ts-ignore
      key={e.event.id}
    >
      {/** @ts-ignore */}
      {e.children}
    </div>
  ),
  event: arg.eventRenderer,
});

export const BigCalendarComponent = <T, >({
  newEventId,
  popoverContentRenderer,
  onPopoverClose,
  eventRenderer,
  extra,
  events,
  onSelecting,
  onSelectSlot,
  onSelect,
  onNavigate,
  onEventDrop,
  onView,
  view,
  selectedEventId,
  startingHour = 0,
  defaultStartingHourView = 9,
  loading = false,
  date,
  toolbar,
}: BigCalendarProps<T>) => {
  const [
    scrollToTime,
    setScrollToTime,
  ] = useState<Date | undefined>();

  const { min, max } = useMemo(() => ({
    min: moment().startOf('day').hour(startingHour)
      .toDate(),
    max: moment().endOf('day')
      .toDate(),
  }), [startingHour]);

  const comp = useMemo(() => (
    components({
      extra,
      selectedEventId,
      popoverContentRenderer,
      onPopoverClose,
      eventRenderer,
      toolbar,
    })
  ), [
    extra,
    popoverContentRenderer,
    onPopoverClose,
    eventRenderer,
    toolbar,
    selectedEventId,
  ]);

  const selectedEventTime = useMemo(() => {
    const selectedEvent = find(events, (e: CalendarEvent) => e.id === selectedEventId);
    if (selectedEvent) {
      return selectedEvent.start;
    }
    return undefined;
  }, [events, selectedEventId]);

  useEffect(() => {
    if (typeof defaultStartingHourView === 'number') {
      // scrollToTime doesn't always work in initial occasions
      setTimeout(() => {
        const elements = document.querySelectorAll('.rbc-timeslot-group span.rbc-label');
        const defaultStartView = filter(elements, (e) => {
          let hourView = defaultStartingHourView;
          const timeOfDay = defaultStartingHourView >= 12 ? 'PM' : 'AM';
          hourView = defaultStartingHourView % 12;
          const hourViewRegex = new RegExp(
            String.raw`0?${hourView}:00\s?${timeOfDay}?`,
            'gi',
          );
          return hourViewRegex.test(e.innerHTML);
        }) as HTMLSpanElement[];
        defaultStartView?.[0]?.parentElement?.parentElement?.scrollIntoView();
      }, 250);
    }
  }, []);

  useEffect(() => {
    if (selectedEventTime) {
      setScrollToTime(selectedEventTime);
    }
  }, [selectedEventTime]);

  return (
    <div className="big-calendar-wrapper">
      <DnDCalendar
        className="big-calendar"
        components={comp}
        min={min}
        max={max}
        defaultDate={moment().toDate()}
        date={date?.toDate()}
        defaultView="week"
        events={events}
        showAllEvents
        localizer={localizer}
        onSelecting={onSelecting}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelect}
        onNavigate={onNavigate}
        onView={onView}
        view={view}
        selectable
        scrollToTime={scrollToTime}
        onEventDrop={onEventDrop}
        resizable={false}
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        eventPropGetter={(event: CalendarEvent) => {
          const createBorder = (color?: string) => `1px solid ${color}`;
          const createLeftBorder = () => `3px solid ${event.colors?.leftBorderColor}`;
          const textColor = event.colors?.textColor || undefined;
          const backgroundColor = event.colors?.backgroundColor || undefined;
          const border = createBorder(event.colors?.borderColor);
          const style: CSSProperties = {
            color: textColor,
            backgroundColor,
            borderRadius: 0,
          };
          if (event.id && event.id === newEventId) {
            style.outline = '3px dashed #888888';
            style.backgroundColor = '#A8A8A84d';
            style.border = 'none';
            style.zIndex = 200;
          } else if (event.id && event.id === selectedEventId) {
            style.outline = createLeftBorder();
            style.border = 'none';
            style.zIndex = 200;
          } else {
            style.borderLeft = createLeftBorder();
            style.borderRight = border;
            style.borderTop = border;
            style.borderBottom = border;
          }
          return {
            style,
          };
        }}
      />
      {loading && (
        <div className="big-calendar-spinner">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default BigCalendarComponent;
