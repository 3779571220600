import { Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { compact, map } from 'lodash';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { StartNowVisitButtonComponent, StartNowVisitButtonComponentProps } from '../../../patient/component/PatientVisitsListComponent/StartNowVisitButtonComponent/StartNowVisitButtonComponent';
import { useClinicEventCheckOverlapCalendarEvent, VisitTypeEnum } from '../../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { StartNowCreateVisitFormContainer, StartNowCreateVisitFormContainerProps } from '../StartNowCreateVisitFormContainer/StartNowCreateVisitFormContainer';
import { Title } from '../../../../uiComponent/Title/Title';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

import './StartNowVisitButtonContainer.scss';
import { StartNowUserOverlapNoticeComponent } from '../../component/StartNowUserOverlapNoticeComponent/StartNowUserOverlapNoticeComponent';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';

export interface StartNowVisitButtonContainerProps extends StartNowVisitButtonComponentProps {
  patientId: string;
  visitType: VisitTypeEnum;
  startNowOverlapInterval: number;
  onSubmit?: StartNowCreateVisitFormContainerProps['onSubmit'];
  onCheckedIn?: StartNowCreateVisitFormContainerProps['onCheckedIn'];
}

export const StartNowVisitButtonContainer = ({
  patientId,
  visitType,
  startNowOverlapInterval,
  onClick,
  onSubmit,
  onCheckedIn,
}: StartNowVisitButtonContainerProps) => {
  const {
    isOpen: popoverOpen,
    open: openPopover,
    close: closePopover,
    setValue: setPopoverOpen,
  } = useOpen();
  const [hidePopover, setHidePopover] = useState(false);
  const [ignoreOverlap, setIgnoreOverlap] = useState(false);
  const { userId = '' } = useLoggedInUserFromContext();
  const { parseParticipants } = useCreateVisitForm();
  const overlapInfo = useClinicEventCheckOverlapCalendarEvent({
    options: { sendOnMount: false },
  });

  const getEndTime = (startTime: Dayjs | string) => (
    dayjs(startTime).add(startNowOverlapInterval, 'minutes')
  );

  const handleOnClick = async () => {
    const startTime = dayjs();
    ApiRequestHelper.tryCatch(
      overlapInfo.send({
        params: {
          overlapEventRequest: {
            startTime: startTime?.toISOString().replace('Z', ''),
            endTime: getEndTime(startTime).toISOString().replace('Z', ''),
            memberIds: [userId],
          },
        }
      }),
      {
        success: '',
        error: '',
        onSuccess: () => {
          openPopover();
          onClick?.();
        }
      }
    );
  };

  const handleOnCheckedIn: StartNowCreateVisitFormContainerProps['onCheckedIn'] = (
    clinicEventId,
  ) => {
    closePopover();
    onCheckedIn?.(clinicEventId);
  };

  const handleOpenCalendar = () => {
    setHidePopover(true);
  };

  const handleOnCloseCalendar = () => {
    // popover is hidden but still open to keep calendar mounted
    setPopoverOpen(false);
    // delay to give better effect when reset value
    setTimeout(() => setHidePopover(false), 100);
  };

  const shouldDisableSubmit: StartNowCreateVisitFormContainerProps['shouldDisableSubmit'] = (
    values,
  ) => {
    // prevent check in action when user is not participant
    const participants = parseParticipants(values);
    const participantIds = compact(map(participants, 'participantId'));
    return !participantIds.includes(userId);
  };

  const renderPopoverContent = () => {
    if (overlapInfo?.data?.data === undefined) {
      return <LoadingOverlayComponent isLoading />;
    }
    const hasOverlap = !!overlapInfo.data.data?.[0]?.overlapEvents?.length;
    return (
      (!ignoreOverlap && hasOverlap)
        ? (
          <StartNowUserOverlapNoticeComponent
            startNowOverlapInterval={startNowOverlapInterval}
            onGetStartedClick={() => setIgnoreOverlap(true)}
            onCheckMyCalendarClick={handleOpenCalendar}
            onCloseCalendar={handleOnCloseCalendar}
          />
        ) : (
          <>
            <Title className="mb20">
              Confirm the visit details
            </Title>
            <StartNowCreateVisitFormContainer
              patientId={patientId}
              visitType={visitType}
              excludeParticipantIds={[userId]}
              getEndTime={getEndTime}
              onCheckedIn={handleOnCheckedIn}
              onSubmit={onSubmit}
              shouldDisableSubmit={shouldDisableSubmit}
              onCancel={closePopover}
            />
          </>
        )
    );
  };

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      title={null}
      content={renderPopoverContent}
      trigger="click"
      placement="left"
      overlayClassName="start-now-button-popover"
      showArrow={false}
      destroyTooltipOnHide
      overlayStyle={{ display: hidePopover ? 'none' : undefined }}
    >
      <StartNowVisitButtonComponent
        loading={!!overlapInfo.isLoading}
        onClick={handleOnClick}
      />
    </Popover>
  );
};
