import { Form, Space } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { ICDCodeHelpers } from '../../helper';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ICDTableComponentExtraProps, ICDTableComponentProps } from '../ICDTableComponent/ICDTableComponent';

import './ICDTableFormItemComponent.scss';
import { ICDTableContainer } from '../../container/ICDTableContainer/ICDTableContainer';
import { useICDCodeForm } from '../../hook/useICDCodeForm';

export interface ICDTableFormItemComponentProps
  extends Omit<ICDTableComponentExtraProps, 'selectedHealthCondition'> {
  name: string;
  // name for search select form item
  selectName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  required?: boolean;
  requiredMessage?: string;
  icdForm: ReturnType<typeof useICDCodeForm>;
}

export const ICDTableFormItemComponent = ({
  name,
  selectName,
  isLoading,
  disabled,
  required,
  requiredMessage = 'Missing!',
  icdForm,
}: ICDTableFormItemComponentProps) => (
  <LoadingOverlayComponent
    isLoading={isLoading}
    showSkeleton
    skeletonType="paragraph"
    skeletonParagraphRows={5}
  >
    <Form.Item
      noStyle
      shouldUpdate
    >
      {
          ({ getFieldValue }) => (
            <Form.List
              name={name}
              rules={[
                {
                  validator: (_, value, callback) => {
                    if (required && (!value || !value.length)) {
                      callback(requiredMessage);
                      return;
                    }
                    callback();
                  },
                },
              ]}
              prefixCls="icd-table"
            >
              {
                (fields, { add, remove }, { errors }) => {
                  const icdTableDatasource = getFieldValue(name);
                  const selectedIcd10Billable = selectName
                    ? getFieldValue(selectName)
                    : icdForm.getValue('icdSelect', getFieldValue);
                  const selectedHealthCondition = ICDCodeHelpers.getHealthConditionFromIcd10(
                    selectedIcd10Billable,
                  );
                  const handleOnAdd: ICDTableComponentProps['onAdd'] = (
                    healthCondition
                  ) => {
                    add(healthCondition, 0);
                  };

                  const handleOnRemove: ICDTableComponentProps['onRemove'] = (
                    index,
                  ) => {
                    remove(index);
                  };
                  return (
                    <>
                      <ICDTableContainer
                        disabled={disabled}
                        dataSource={icdTableDatasource}
                        selectedHealthCondition={selectedHealthCondition}
                        onAdd={handleOnAdd}
                        onRemove={handleOnRemove}
                        formFields={fields}
                        // @ts-ignore
                        icdForm={icdForm}
                      />
                      <Form.ErrorList
                        fieldId={name}
                        help={(
                          errors.length
                            ? (
                              <Space className="icd-table-form-error">
                                <InfoCircleFilled className="danger-svg" />
                                <div>{requiredMessage}</div>
                              </Space>
                            ) : null
                        )}
                      />
                    </>
                  );
                }
              }
            </Form.List>
          )
        }
    </Form.Item>
  </LoadingOverlayComponent>
);
