/* eslint-disable class-methods-use-this */
import axios from 'axios';
import EnvConfig from '../../configs/envConfig/envConfig';
import { PatientSummaryRequestFunction, PatientSummaryRequestParams } from './types';

const PatientSummaryDomain = EnvConfig.unicareCopilotUrl;

export class PatientSummaryServices {
  protected endPoints = {
    patientSummary: `${PatientSummaryDomain}/tools/patient-summary`,
  };

  getResponse = <T>(value: T) => value;

  getPatientSummary: PatientSummaryRequestFunction<PatientSummaryRequestParams, string> = (
    { params, headers },
  ) => axios.post(this.endPoints.patientSummary, params, { headers });
}

export const patientSummaryServices = new PatientSummaryServices();
