import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ReactNode } from 'react';
import { map } from 'lodash';
import {
  APIResponse,
  HealthConditionItem,
  PatientClassificationResult,
  PatientDiagnosesEnum,
} from '../../../../uc-api-sdk';
import { DiagnosisTitleComponent } from '../../../patient/container/PatientHealthConditionComplexityFormContainer/DiagnosisTitleComponent';
import { ICDCodeSubmitValue, useICDCodeForm } from '../../hook/useICDCodeForm';
import { PatientDiagnosesInputComponent } from '../../../enrollment/component/PatientDiagnosesInputComponent/PatientDiagnosesInputComponent';
import { ICDSearchDropdownContainer } from '../../container/ICDSearchDropdownContainer';
import { ICDTableFormItemComponent } from '../ICDTableFormItemComponent/ICDTableFormItemComponent';
import { FormType } from '../../../Input/types';
import { IcdCodeWarningComponent } from '../IcdCodeWarningComponent/IcdCodeWarningComponent';
import { IcdCodeTitleComponent } from '../../../patient/container/PatientHealthConditionComplexityFormContainer/IcdCodeTitleComponent';
import { PatientHealthConditionComplexityContainer } from '../../../patient/container/PatientHealthConditionComplexityContainer/PatientHealthConditionComplexityContainer';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export interface DiagnosisIcdCodeFormComponentProps extends FormType<ICDCodeSubmitValue> {
  form?: FormOptions['form'];
  formButtons?: ReactNode;
  getDiagnosisBasedOnIcd?: (icdCodes: string[]) =>
    Promise<APIResponse<PatientClassificationResult> | null | undefined>;
  targetDx?: PatientDiagnosesEnum[];
}

export const DiagnosisIcdCodeFormComponent = ({
  form,
  formButtons,
  onSubmit,
  isLoading,
  initialValues,
  getDiagnosisBasedOnIcd,
  targetDx,
  onValuesChange,
}: DiagnosisIcdCodeFormComponentProps) => {
  const hook = useICDCodeForm({ form });

  const renderFormItems = () => (
    <>
      <DiagnosisTitleComponent />
      <Form.Item noStyle shouldUpdate={hook.shouldUpdate(['icdTable'])}>
        {({ getFieldValue }) => {
          const healthConditions = hook.getValue('icdTable', getFieldValue) as HealthConditionItem[];
          const icdCodes = map(healthConditions, 'code').filter((v) => !!v) as string[];

          return (
            <FormItem name={hook.getName('diagnoses')}>
              <PatientDiagnosesInputComponent
                icdCodes={icdCodes}
                getDiagnosisBasedOnIcd={getDiagnosisBasedOnIcd}
              />
            </FormItem>
          );
        }}
      </Form.Item>
      <IcdCodeTitleComponent />
      <FormItem className="mb12" name={hook.getName('icdSelect')}>
        <ICDSearchDropdownContainer />
      </FormItem>
      <ICDTableFormItemComponent
        name={hook.getName('icdTable')}
        isLoading={isLoading}
        icdForm={hook}
      />
      <FormItem noStyle shouldUpdate={hook.shouldUpdate(['diagnoses', 'icdTable'])}>
        {({ getFieldValue }) => {
          const newDxs = (
            hook?.getValue('diagnoses', getFieldValue)
              ?.filter((i: PatientDiagnosesEnum) => i !== PatientDiagnosesEnum.OTHERS)
            || []
          );
          return (
            <IcdCodeWarningComponent
              checkedDx={newDxs}
              targetDx={targetDx ?? undefined}
            />
          );
        }}
      </FormItem>
      <Form.Item noStyle shouldUpdate={hook.shouldUpdate(['complexity'])}>
        {({ getFieldValue }) => {
          const newComplexity = hook?.getValue('complexity', getFieldValue) || undefined;
          return (
            <Form.Item name={hook.getName('complexity')}>
              <PatientHealthConditionComplexityContainer
                healthConditions={newComplexity}
                disabled={isLoading}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      {formButtons}
    </>
  );

  if (!form) {
    return (
      <Form
        form={hook.form}
        initialValues={initialValues}
        disabled={isLoading}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
        className="ml12 mt12"
      >
        {renderFormItems()}
      </Form>
    );
  }

  return renderFormItems();
};
