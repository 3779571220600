import {
  FormListFieldData,
  Table,
  TableProps
} from 'antd';
import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { icdTableColumns, makeRemoveICDColumn } from '../../../../tables/ICDTableColumns/useICDTableColumns';
import { HealthConditionItem } from '../../../../uc-api-sdk';

import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import './ICDTableComponent.scss';

export interface FormHealthCondition extends HealthConditionItem {
  formField?: FormListFieldData;
}

export interface ICDTableComponentExtraProps {
  onAdd?: (
    newHealthCondition: HealthConditionItem,
    allHealthConditions: HealthConditionItem[]
  ) => void;
  onRemove?: (index: number) => void;
  selectedHealthCondition?: HealthConditionItem;
  formFields?: FormListFieldData[];
}

export interface ICDTableComponentProps
  extends TableProps<FormHealthCondition>,
  ICDTableComponentExtraProps {
  dataSource: HealthConditionItem[];
  disabled?: boolean;
  isEdit?: boolean;
  getBillableInfo?: (icdCode: string) => Promise<boolean | null | undefined>;
}

export const ICDTableComponent = ({
  onAdd,
  onRemove,
  selectedHealthCondition,
  disabled = false,
  isEdit = true,
  formFields,
  getBillableInfo,
  dataSource,
}: ICDTableComponentProps) => {
  const [tableDataSource, setTableDataSource] = useState<HealthConditionItem[]>(dataSource || []);

  const handleAddICDCode = async (healthCondition: HealthConditionItem) => {
    const { code } = healthCondition;
    if (!code) return;
    let isBillable = false;
    try {
      isBillable = !!(await getBillableInfo?.(code));
    } catch (error) {
      console.error(error);
    } finally {
      const newTableDataSource = [healthCondition, ...tableDataSource];
      setTableDataSource(newTableDataSource);
      onAdd?.(
        {
          ...healthCondition,
          isBillable,
        },
        newTableDataSource,
      );
    }
  };

  useUpdateEffect(() => {
    if (!selectedHealthCondition?.code) return;
    const exists = tableDataSource.filter((v) => (
      v.code === selectedHealthCondition.code
    )).length;

    if (!exists) handleAddICDCode(selectedHealthCondition);
  }, [selectedHealthCondition?.code]);

  const handleRemoveICD = (index: number) => {
    const newTableDataSource = [...tableDataSource];
    newTableDataSource.splice(index, 1);
    setTableDataSource(newTableDataSource);
    onRemove?.(index);
  };

  const makeRemoveColumn = () => makeRemoveICDColumn(
    handleRemoveICD,
    disabled,
    isEdit
  );

  const columns = useTableColumns(
    {
      ...icdTableColumns(disabled),
      makeRemoveColumn,
    },
    [
      ...Object.keys(
        icdTableColumns(disabled),
      ) as Array<keyof typeof icdTableColumns>,
      'makeRemoveColumn',
    ],
    [disabled, makeRemoveColumn],
  );

  useDeepCompareEffect(() => {
    setTableDataSource(dataSource);
  }, [dataSource]);

  const formDataSource = useMemo(() => {
    if (!Array.isArray(formFields)) {
      return tableDataSource as FormHealthCondition[];
    }
    return tableDataSource.map((d, index: number) => ({
      ...d,
      formField: formFields[index],
    }));
  }, [tableDataSource, formFields]);

  return (
    <Table
      columns={columns}
      dataSource={formDataSource}
      className="icd-table-component mb12 border-gray-1"
      rowKey={(v) => v.code as string}
      pagination={false}
    />
  );
};
