import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';

export class TimeService {
  public static getTotalMins(time: Dayjs | Moment) {
    return time.get('hours') * 60 + time.get('minutes');
  }

  public static getDifference(
    startTime: Dayjs | Moment,
    endTime: Dayjs | Moment,
    unit: 'minutes' | 'seconds',
  ) {
    const diff = dayjs(endTime.toDate())
      .diff(dayjs(startTime.toDate()), unit);

    const diffInBiggerUnit = Math.floor(diff / 60);
    const diffInSmallerUnit = diff % 60;
    return `${diffInBiggerUnit.toString().padStart(2, '0')}:${diffInSmallerUnit.toString().padStart(2, '0')}`;
  }

  public static getDifferenceInHoursAndMinutes(
    startTime: Dayjs | Moment,
    endTime: Dayjs | Moment,
  ) {
    return TimeService.getDifference(startTime, endTime, 'minutes');
  }

  public static getDifferenceInMinutesAndSeconds(
    startTime: Dayjs | Moment,
    endTime: Dayjs | Moment,
  ) {
    return TimeService.getDifference(startTime, endTime, 'seconds');
  }

  public static getDifferenceInMinutes(
    startTime: Dayjs | Moment,
    endTime: Dayjs | Moment,
  ) {
    return dayjs(endTime.toDate())
      .diff(dayjs(startTime.toDate()), 'minutes');
  }

  public static getDifferenceInSeconds(
    startTime: Dayjs | Moment,
    endTime: Dayjs | Moment,
  ) {
    return dayjs(endTime.toDate())
      .diff(dayjs(startTime.toDate()), 'seconds');
  }

  public static addMinutesFromStartOfDay(time: Dayjs | Moment, minutes: number) {
    // @ts-ignore
    // both moment and dayjs have the same add function
    return time.clone().startOf('day').add(minutes, 'minutes');
  }

  public static getTimeLabel = (mins: number) => {
    const hour = Math.floor(mins / 60);
    let hourString = Number(hour === 12 ? hour : (hour % 12).toString().padStart(2, '0'));
    if (hourString === 0) hourString = 12;
    const minute = (mins % 60).toString().padStart(2, '0');
    const amPm = hour < 12 ? 'am' : 'pm';
    return `${hourString}:${minute} ${amPm}`;
  };

  public static getMinutesFromTimeLabel = (time: string) => {
    if (!time) return 0;
    const [timeStr, amPm] = time.toLowerCase().split(' ');
    const [hour, minute = 0] = timeStr.split(':');
    if (amPm === 'am' && hour === '12') return Number(minute);
    const hourIn24 = amPm === 'pm' && hour !== '12' ? Number(hour) + 12 : Number(hour);
    return hourIn24 * 60 + Number(minute);
  };

  public static validateTimeLabel = (time: string) => {
    if (!time) return Promise.resolve();
    if (time.toLowerCase().match(/^(0?[1-9]|1[0-2]):[0-5][0-9] [ap]m$/)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Time should be in HH:MM am/pm format.'));
  };
}
