import { ReactNode } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { InterventionContainerProps } from '../../../intervention/type';
import { DefaultICDCodeFormValues } from '../../../ICDCode/component/ICDCodeFormComponent/type';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { DiagnosisIcdCodeViewComponent } from '../../../enrollment/component/DiagnosisIcdCodeViewComponent/DiagnosisIcdCodeViewComponent';
import { DiagnosisIcdCodeFormContainer } from '../../../patient/container/DiagnosisIcdCodeFormContainer/DiagnosisIcdCodeFormContainer';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface ProblemsICDCodeValues extends DefaultICDCodeFormValues { }

export interface ProblemsICDCodeContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps {
  title?: ReactNode;
}

export const ProblemsICDCodeContainer = ({
  title = 'Diagnosis / ICD code',
  patientId,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onValuesChange,
  onCancel,
  formButtons,
  showHeaderOutside,
  showEdit,
}: ProblemsICDCodeContainerProps) => {
  if (!patientId) return null;
  const { info } = usePatientContext();

  const updateHook = useUpdate('PATIENT_INFO_UPDATED');

  const refetchInfo = useDebounce(() => {
    info?.refetch([
      'patientRefetch', // for diagnoses
      'healthConditionsRefetch',
      'patientComplexityRefetch',
    ]);
  }, 500, [info?.refetch]);
  useUpdateListener('PATIENT_INFO_UPDATED', refetchInfo);
  useUpdateListener('WORKLIST_UPDATED', refetchInfo);

  const healthConditionInfo = info?.patientHealthCondition || {};
  const healthConditions = healthConditionInfo.healthConditions || [];
  const dx = info?.patientInfoService?.diagnoses || [];
  const patientComplexity = info?.patientComplexity || {};
  const programCategory = info?.enrolledProgramService?.getPrograms() || [];
  const vitals = info?.enrolledProgramService?.getVitals()?.filter((v) => !!v);

  const isLoading = info?.isLoadingObj.healthConditionsLoading
    || info?.isLoadingObj.patientInfoLoading
    || info?.isLoadingObj.patientComplexityLoading;

  const handleOnSubmit = () => {
    updateHook.updateValue();
    onSubmit?.();
  };

  return (
    <PatientCommonCardComponent
      title={title}
      showHeaderOutside={showHeaderOutside}
      showEdit={showEdit}
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={healthConditionInfo || {}}
      formContent={(
        <LoadingOverlayComponent isLoading={isLoading} showSkeleton>
          <DiagnosisIcdCodeFormContainer
            patientId={patientId}
            initialValues={{
              diagnoses: dx,
              icdTable: healthConditions,
              complexity: patientComplexity,
            }}
            onCancel={onCancel}
            onSubmit={handleOnSubmit}
            onError={onError}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
            programCategory={programCategory}
            vitals={vitals}
          />
        </LoadingOverlayComponent>
      )}
      content={(
        <DiagnosisIcdCodeViewComponent
          initDx={dx}
          initHealthCondition={healthConditions}
          complexity={patientComplexity}
        />
      )}
    />
  );
};
