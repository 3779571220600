import { ReactNode } from 'react';
import CalendarOutlined from '@ant-design/icons/lib/icons/CalendarOutlined';
import { Button, ButtonProps, Space } from 'antd';
import classNames from 'classnames';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import { MakeSchedulesModalComponent, MakeSchedulesModalComponentProps } from './MakeSchedulesModalComponent';

export interface MakeSchedulesComponentProps extends MakeSchedulesModalComponentProps {
  btnClassName?: string;
  btnType?: ButtonProps['type'];
  btnText?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export const MakeSchedulesComponent = ({
  btnClassName = '',
  btnType = 'link',
  btnText = 'Create a Visit',
  icon = <CalendarOutlined />,
  disabled,
  visitType,
  assignees,
  shouldTriggerVisitType,
}: MakeSchedulesComponentProps) => {
  const { isOpen, open, close } = useOpen();

  return (
    <>
      <Button
        type={btnType}
        onClick={open}
        className={classNames({
          'make-schedule-button': true,
          'p0 b7 text-blue': btnType === 'link',
          [btnClassName]: !!btnClassName,
        })}
        disabled={disabled}
      >
        <Space>
          {icon}
          {btnText}
        </Space>
      </Button>
      <MakeSchedulesModalComponent
        isOpen={isOpen}
        onClose={close}
        visitType={visitType}
        assignees={assignees}
        shouldTriggerVisitType={shouldTriggerVisitType}
      />
    </>
  );
};
