import { useEffect } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { useRewriteVisitNote } from '../../../../services/RewriteWithAIServices/hooks/useRewriteVisitNote';
import { RewriteWithAIComponent } from '../../component/RewriteWithAIComponent/RewriteWithAIComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface RewriteNoteWithAIContainerProps {
  originalNote: string;
}

export const RewriteNoteWithAIContainer = ({
  originalNote,
}: RewriteNoteWithAIContainerProps) => {
  const { userId } = useLoggedInUserFromContext();
  const { info } = usePatientContext();
  const patientId = info?.id || '';

  const rewriteNoteHook = useRewriteVisitNote({});

  const handleRewriteNote = useDebounce(() => {
    if (originalNote) {
      rewriteNoteHook.send({
        params: {
          content: {
            patient_id: patientId || '',
            user_id: userId || '',
            message: originalNote,
          },
        },
      });
    }
  }, 100, []);

  useEffect(() => {
    handleRewriteNote();
  }, [originalNote]);

  return (
    <RewriteWithAIComponent
      originalContent={originalNote}
      newContent={rewriteNoteHook.data || originalNote}
      isLoading={rewriteNoteHook.isLoading}
      onRefetch={handleRewriteNote}
    />
  );
};
