import { Select, SelectProps } from 'antd';
import { find, range, sortBy } from 'lodash';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { useMemo } from 'react';
import { TimeService } from '../../../../helpers/time/timeService';
import { CalendarSourceEnum } from '../../../../uc-api-sdk';
import { InputType } from '../../../Input/types';

export interface TimeSelectComponentProps extends InputType<number> {
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  options?: number[]; // duration in minutes
  source?: CalendarSourceEnum;
  placeholder?: string;
}

export const TimeSelectComponent = ({
  value,
  onChange,
  disabled,
  minValue,
  maxValue = 21, // 9pm
  options: mainOptions,
  source,
  placeholder
}: TimeSelectComponentProps) => {
  const options = useMemo<SelectProps['options']>(() => {
    const startTime = minValue !== undefined ? minValue / 60 : 5;
    const endTime = maxValue > 24 ? 23 : maxValue;
    // startTime * 15 * 4 for number of 15 minutes in a day
    // endTime * 15 * 4 for number of 15 minutes in a day
    // 15 for every 15 minutes
    let res: Exclude<SelectProps['options'], undefined> = [];
    if (mainOptions) {
      if (minValue) {
        mainOptions.forEach((v) => {
          res.push({
            value: v,
            label: TimeService.getTimeLabel(v),
            disabled: undefined,
          });
        });
      }
    } else {
      range(startTime * 4 * 15, endTime * 15 * 4 + 15, 15).forEach((v) => {
        res.push(
          {
            value: v,
            label: TimeService.getTimeLabel(v),
            // disabled: minValue ? v <= minValue : undefined,
            disabled: undefined,
          }
        );
      });
    }
    if (value) {
      const hasValue = !!find(res, (r) => (r.value === value));
      // if the value doesn't exist in the options, add it and sort the options
      if (!hasValue) {
        res.push({
          value,
          label: TimeService.getTimeLabel(value),
          disabled: undefined,
        });
        res = sortBy(res, 'value');
      }
    }

    return res;
  }, [minValue, value, source, mainOptions]);

  const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    let trimmedInput = input;
    if (input.startsWith('0') && input.length === 1) {
      return String(option?.label).length === 7;
    }
    if (input.startsWith('0')) {
      trimmedInput = input.replace(/^0+/, '');
    }

    return String(option?.label)?.toLowerCase()
      .startsWith(trimmedInput.toLowerCase());
  };

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      showSearch
      optionFilterProp="label"
      filterOption={filterOption}
    />
  );
};
