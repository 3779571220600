import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { FC } from 'react';
import { EditMedicationDrawerComponent } from '../../../features/medication/component/EditMedicationDrawerComponent/EditMedicationDrawerComponent';
import { MedicationType } from '../../../features/medication/type/type';
import { MedicationDeleteConfirmationModal } from '../../../uiComponent/Medication/MedicationDeleteConfirmationModal';
import { MedicationHistoryDrawerComponent } from '../../../uiComponent/Medication/MedicationHistoryDrawerComponent/MedicationHistoryDrawerComponent';

export interface MedicationTableSettingsComponentProps {
  medId: string;
  record: MedicationType;
  patientId?: string;
}
export const MedicationTableSettingsComponent: FC<MedicationTableSettingsComponentProps> = ({
  medId,
  record,
  patientId,
}) => {
  const items = [
    {
      key: 1,
      label: (
        <MedicationHistoryDrawerComponent
          histories={record.histories}
          name={record.name || ''}
        />
      ),
    },
    {
      key: 2,
      label: (
        <EditMedicationDrawerComponent
          medication={record}
          patientId={patientId}
        />
      ),
    },
    {
      key: 3,
      label: (
        <MedicationDeleteConfirmationModal
          medId={medId}
        />
      ),
    },

  ];
  return (
    <Dropdown
      overlayClassName="button-in-dropdown"
      className="medicationSettings jc-e no-border"
      trigger={['click']}
      menu={{ items }}
    >
      <MoreOutlined className="float-right" />
    </Dropdown>
  );
};
