import { Modal, ModalProps } from 'antd';
import {
  compact,
  difference, forEach,
  isEmpty,
  map,
  omit
} from 'lodash';
import { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  InsuranceCardSubmitValue,
  InsuranceSubmitValue,
  PriorAuthSubmitValue,
  TempPriorAuthCase,
} from '../../../hooks/formHook/useInsuranceForm';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import {
  BenefitOrderEnum, InsuranceSubmitForm, PriorAuthCase,
  PriorAuthCaseStatusEnum
} from '../../../uc-api-sdk';
import { Insurance, MaskInsuranceInfo, MaskInsuranceInfoRequest } from './insuranceApis';
import TimezoneService from '../../../helpers/timezone/timezoneService';
import { DATE_STANDARD, REQUEST_DATE_FORMAT } from '../../../constants/timeFormat';

export const MAX_INSURANCE_COUNT = 3;

export const useInsuranceHelper = (patientInsurance?: Insurance | null) => {
  const benefitOrderIndex = useMemo(() => ({
    [BenefitOrderEnum.PRIMARY]: 0,
    [BenefitOrderEnum.SECONDARY]: 1,
    [BenefitOrderEnum.TERTIARY]: 2,
  }), []);

  const getDayjsDate = useCallback((date?: string | null) => {
    if (!date) return undefined;
    return TimezoneService.calcDateTimeDayjs(date);
  }, []);

  const getLocalDate = (date?: Dayjs | string) => (
    date ? dayjs(date).format(DATE_STANDARD) : undefined
  );

  const getDateFormat = (date?: Dayjs | string) => {
    if (!date) return undefined;
    const d = dayjs(date);
    return d.startOf('day').utc().format(REQUEST_DATE_FORMAT);
  };

  const getPriorAuthExpirationDate = (date?: string | null) => getDayjsDate(date);

  const getPriorAuthCase = (insuranceInfo?: MaskInsuranceInfo) => {
    const {
      needPriorAuth,
      priorAuthCaseList,
    } = insuranceInfo ?? {};
    if (isEmpty(priorAuthCaseList)) {
      return {};
    }
    let activeCase: PriorAuthCase | undefined;
    let historyCases = [...(priorAuthCaseList || [])];
    if (needPriorAuth) {
      [activeCase, ...historyCases] = historyCases;
    }
    return {
      activeCase,
      historyCases,
    };
  };

  const shouldPriorAuthCaseBeCancellable = useCallback(<T extends TempPriorAuthCase>(
    priorAuthCase?: T
  ) => {
    if (isEmpty(priorAuthCase)) {
      return true;
    }
    return (
      priorAuthCase?.status !== PriorAuthCaseStatusEnum.APPROVED
      || (
        typeof priorAuthCase?.expirationDays === 'number'
        && priorAuthCase?.expirationDays < 0 // already expired
      )
    );
  }, []);

  const parsePriorAuthCaseFormValues = useCallback((values?: InsuranceSubmitValue) => {
    const {
      priorAuth,
      priorAuthDeniedDate,
      priorAuthApprovalDate,
      priorAuthSubmissionDate,
      priorAuthAuthPeriod,
      priorAuthAuthorizationNumber,
      priorAuthDeniedReason,
      priorAuthCPTCodeCategory,
      priorAuthCPTCodeUnits,
      priorAuthStatus,
      priorAuthComments,
    } = values || {};

    if (!priorAuth) {
      return undefined;
    }

    let newPriorAuth: PriorAuthCase = {
      cptCodeCategory: priorAuthCPTCodeCategory,
      cptCodes: priorAuthCPTCodeUnits,
      status: priorAuthStatus,
      comment: priorAuthComments,
    };

    if (priorAuthStatus !== PriorAuthCaseStatusEnum.NEED_SUBMISSION) {
      newPriorAuth = {
        ...newPriorAuth,
        submissionDate: getDateFormat(priorAuthSubmissionDate),
      };
    }

    if (priorAuthStatus === PriorAuthCaseStatusEnum.DENIED) {
      newPriorAuth = {
        ...newPriorAuth,
        deniedDate: getDateFormat(priorAuthDeniedDate),
        deniedReason: priorAuthDeniedReason,
      };
    }

    if (
      [
        PriorAuthCaseStatusEnum.APPROVED,
        PriorAuthCaseStatusEnum.PENDING_RESULT
      ].includes(priorAuthStatus as PriorAuthCaseStatusEnum)
    ) {
      newPriorAuth = {
        ...newPriorAuth,
        authorizationNumber: priorAuthAuthorizationNumber,
        authStartDate: getDateFormat(priorAuthAuthPeriod?.[0]),
        authExpiredDate: getDateFormat(priorAuthAuthPeriod?.[1]),
        localStartDate: (
          priorAuthAuthPeriod?.[0]
            ? getLocalDate(priorAuthAuthPeriod?.[0]) : undefined
        ),
        localExpiredDate: (
          priorAuthAuthPeriod?.[1]
            ? getLocalDate(priorAuthAuthPeriod?.[1]) : undefined
        ),
      };
    }

    if (priorAuthStatus === PriorAuthCaseStatusEnum.APPROVED) {
      newPriorAuth = {
        ...newPriorAuth,
        approvalDate: getDateFormat(priorAuthApprovalDate),
      };
    }

    return newPriorAuth;
  }, []);

  const parsePriorAuthCaseToFormValues = (priorAuthCase?: TempPriorAuthCase) => {
    if (!priorAuthCase) {
      return undefined;
    }
    return {
      priorAuthStatus: priorAuthCase?.status ?? undefined,
      priorAuthSubmissionDate: getDayjsDate(priorAuthCase?.submissionDate),
      priorAuthApprovalDate: getDayjsDate(priorAuthCase?.approvalDate),
      priorAuthAuthorizationNumber: priorAuthCase?.authorizationNumber ?? undefined,
      priorAuthCPTCodeUnits: priorAuthCase?.cptCodes ?? undefined,
      priorAuthAuthPeriod: (
        priorAuthCase
          ? (
            [
              getDayjsDate(priorAuthCase.authStartDate),
              getDayjsDate(priorAuthCase.authExpiredDate)
            ]
          ) : undefined
      ),
      priorAuthDeniedDate: getDayjsDate(priorAuthCase?.deniedDate),
      priorAuthDeniedReason: priorAuthCase?.deniedReason ?? undefined,
      priorAuthComments: priorAuthCase?.comment,
      priorAuthCPTCodeCategory: priorAuthCase?.cptCodeCategory,
    } as PriorAuthSubmitValue;
  };

  const parseInsuranceToFormValues = useCallback((insurance?: Insurance | null) => {
    if (!insurance) return {};
    const orderedList = [
      undefined,
      undefined,
      undefined
    ] as (InsuranceCardSubmitValue | undefined)[];
    forEach(insurance?.insuranceInfoResponses as MaskInsuranceInfo[], (info) => {
      const index = benefitOrderIndex[info.benefitOrder as BenefitOrderEnum];
      const {
        activeCase,
        historyCases,
      } = getPriorAuthCase(info) || {};
      const parsedPriorAuthCaseValues = (
        parsePriorAuthCaseToFormValues(activeCase)
      );
      orderedList[index] = {
        ...omit(info, 'providerId'),
        insuranceProviderId: info?.providerId ?? undefined,
        priorAuth: info.needPriorAuth ?? undefined,
        ...parsedPriorAuthCaseValues,
        // readonly
        priorAuthActiveCase: activeCase,
        priorAuthHistoryCases: historyCases,
      };
    });

    return {
      insuranceList: compact(orderedList),
      // readonly
      nonCovered: insurance.nonCovered,
      note: insurance.note,
    } as InsuranceSubmitValue;
  }, []);

  const validatePriorAuthCase = useCallback((values: InsuranceSubmitValue) => {
    const primaryInsurance = values.insuranceList?.find(
      (v) => v.benefitOrder === BenefitOrderEnum.PRIMARY
    );

    if (!primaryInsurance) {
      return true;
    }

    const {
      priorAuth,
      priorAuthActiveCase,
      priorAuthApprovalDate,
      priorAuthAuthPeriod,
      priorAuthAuthorizationNumber,
      priorAuthStatus,
    } = values || {};
    if (!priorAuth) {
      // priorAuth is turned off
      return true;
    }

    // make sure all required fields are satisfied
    switch (priorAuthStatus) {
      case PriorAuthCaseStatusEnum.APPROVED:
        if (
          priorAuthActiveCase?.status === PriorAuthCaseStatusEnum.APPROVED
        ) {
          // case already exists
          return true;
        }
        if (
          !priorAuthAuthorizationNumber
          || !priorAuthApprovalDate
          || !priorAuthAuthPeriod?.[0]
          || !priorAuthAuthPeriod?.[1]
        ) {
          return false;
        }
        break;
      default:
    }
    return true;
  }, []);

  const parseFormValues = useCallback((values: InsuranceSubmitValue) => {
    const {
      insuranceList,
      isNonCovered,
      reason,
    } = values;
    // use conditional statements to avoid undefined value
    const updateValue = {} as InsuranceSubmitForm;

    if (typeof isNonCovered === 'boolean') {
      updateValue.nonCovered = {
        isNonCovered,
        reason: isNonCovered ? reason : '',
      };
    }
    updateValue.insuranceInfoRequests = map(insuranceList, ({
      insuranceProviderId,
      ...insurance
    }) => {
      let insuranceValue = { ...insurance };
      forEach(insurance, (v, k) => {
        // clean up unwanted prior-auth form fields
        if (k.startsWith('priorAuth')) {
          insuranceValue = omit(insuranceValue, k);
        }
      });
      const value = {
        ...insuranceValue,
        providerId: insuranceProviderId,
      } as MaskInsuranceInfoRequest;
      if (
        insurance.benefitOrder === BenefitOrderEnum.PRIMARY
        && insurance.provider !== undefined
        && insurance.priorAuth !== undefined
      ) {
        value.priorAuthCase = {
          needPriorAuth: !!insurance.priorAuth,
          ...parsePriorAuthCaseFormValues(insurance),
        };
      }
      return value;
    });
    return updateValue;
  }, []);

  const getNextOrderOfBenefit = useCallback((insuranceList: InsuranceCardSubmitValue[]) => {
    if (isEmpty(insuranceList)) {
      return BenefitOrderEnum.PRIMARY;
    }
    const selectedOrders = insuranceList?.map((i) => i.benefitOrder) || [];
    const missingOrders = difference(
      [
        BenefitOrderEnum.PRIMARY,
        BenefitOrderEnum.SECONDARY,
        BenefitOrderEnum.TERTIARY,
      ],
      selectedOrders,
    );
    return missingOrders[0] ?? undefined;
  }, []);

  const shouldShowPA = useCallback((benefitOrder: BenefitOrderEnum) => (
    benefitOrder === BenefitOrderEnum.PRIMARY
  ), []);

  const promptDeleteConfirmation = useCallback((
    onOk?: () => void,
    options?: ModalProps,
  ) => {
    Modal.confirm({
      icon: null,
      content: 'Are you sure you want to delete this insurance?',
      centered: true,
      closable: true,
      onOk,
      okText: 'Yes, delete',
      ...options,
    });
  }, []);

  const initialValues = useDeepCompareMemo(() => (
    parseInsuranceToFormValues(patientInsurance)
  ), [patientInsurance]);

  return {
    initialValues,
    parseInsuranceToFormValues, // data to form
    parseFormValues, // form to data
    getNextOrderOfBenefit,
    benefitOrderIndex,
    promptDeleteConfirmation,

    // For Prior Auth
    getPriorAuthExpirationDate,
    getPriorAuthCase,
    parsePriorAuthCaseFormValues, // form to data
    parsePriorAuthCaseToFormValues, // data to form
    validatePriorAuthCase,
    shouldPriorAuthCaseBeCancellable,
    shouldShowPA,
  };
};
