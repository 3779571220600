import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface VisitTypeComponentProps {
  value?: VisitTypeEnum;
  className?: string;
  type?: 'abbr' | 'full' | 'fullWithBracket';
}

export const VisitTypeComponent = ({
  value,
  type = 'abbr',
  className,
}: VisitTypeComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case VisitTypeEnum.INIT: return 'Initial Visit';
      case VisitTypeEnum.TECH_ONBOARDING: return 'Tech Onboarding';
      case VisitTypeEnum.TECH: return 'Tech Support';
      case VisitTypeEnum.FOLLOW_UP: return 'Follow-up';
      case VisitTypeEnum.ADDITIONAL: return 'Additional';
      case VisitTypeEnum.MNT: return 'MNT';
      case VisitTypeEnum.INITIAL: return 'Initial Visit';
      case VisitTypeEnum.POST_MD_FOLLOW_UP: return 'Post-MD Follow Up';
      default: return null;
    }
  };

  const getAbbreviation = () => {
    switch (value) {
      case VisitTypeEnum.INIT: return '[IA]';
      case VisitTypeEnum.TECH_ONBOARDING: return '[T/O]';
      case VisitTypeEnum.TECH: return '[T/S]';
      case VisitTypeEnum.FOLLOW_UP: return '[F/U]';
      case VisitTypeEnum.ADDITIONAL: return '[ADD]';
      case VisitTypeEnum.MNT: return '[MNT]';
      case VisitTypeEnum.INITIAL: return '[INIT]';
      case VisitTypeEnum.POST_MD_FOLLOW_UP: return '[PFU]';
      default: return null;
    }
  };

  const getFullNameWithBracket = () => (
    `[${getFullName()}]`
  );

  const getText = () => {
    switch (type) {
      case 'full': return getFullName();
      case 'fullWithBracket': return getFullNameWithBracket();
      case 'abbr':
      default:
        return getAbbreviation();
    }
  };

  return (
    <div className={className}>
      {getText()}
    </div>
  );
};
