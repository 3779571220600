import { EventTypeEnum, VitalEnumType } from '../../uc-api-sdk';

export interface MedicalAlertEventTypeEnumComponentProps {
  value?: EventTypeEnum | null;
}

export const getAlertEventValue = (value?: EventTypeEnum | null) => {
  if (value?.includes(VitalEnumType.BP)) {
    return 'BP';
  }
  if (value?.includes(VitalEnumType.BG)) {
    return 'BG';
  }
  return '';
};

export const MedicalAlertEventTypeEnumComponent = ({
  value
}: MedicalAlertEventTypeEnumComponentProps) => {
  const getText = () => getAlertEventValue(value);

  return (
    <>
      {getText()}
    </>
  );
};
