import { useState } from 'react';
import { OnboardingChecklistItemEnum, VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { TechOnboardingChecklistCardComponent } from '../../../enrollment/TechOnboardingChecklistComponent/TechOnboardingChecklistCardComponent';
import { AppCardComponent } from '../../../unifiedCareApp/component/AppCardComponent/AppCardComponent';
import { useWorklistActions } from '../../hook/useWorklistActions';
import { WorklistComponentCommonProps } from '../../types';
import { FutureFollowUpClinicEventComponent } from '../FutureFollowUpClinicEventComponent/FutureFollowUpClinicEventComponent';
import { VisitSummaryInputComponent } from '../VisitSummaryInputComponent/VisitSummaryInputComponent';
import { WorklistButtonComponent } from '../WorklistButtonComponent/WorklistButtonComponent';
import { WorklistDevicesComponent } from '../WorklistDevicesComponent/WorklistDevicesComponent';
import { WorklistVisitInfoComponent } from '../WorklistVisitInfoComponent/WorklistVisitInfoComponent';

export interface TechOnboardingWorklistComponentProps extends WorklistComponentCommonProps { }

export const TechOnboardingWorklistComponent = ({
  patientId,
  clinicEvent,
  onCheckOut,
  onClose,
  onClinicEventUpdate,
  onCompleteCharting,
  disabled,
}: TechOnboardingWorklistComponentProps) => {
  const {
    getVisitSummary,
    isVisitSummaryChanged,
    setVisitSummary,
    validateVisitSummary,
    errors,
  } = useWorklistActions(clinicEvent);
  const [
    onboardingChecklist,
    setOnboardingChecklist,
  ] = useState<OnboardingChecklistItemEnum[]>(clinicEvent.onboardingChecklist);

  const getClinicEventValues = () => ({
    visitSummary: getVisitSummary(),
    onboardingChecklist,
  });

  const handleCheckOut = () => {
    onCheckOut?.(getClinicEventValues());
  };

  const handleCompleteCharting = () => {
    if (!validateVisitSummary()) return;
    onCompleteCharting?.(getClinicEventValues());
  };

  const handleClose = () => {
    if (
      isVisitSummaryChanged
      || (
        onboardingChecklist !== undefined
        && onboardingChecklist !== clinicEvent.onboardingChecklist
      )
    ) {
      onClinicEventUpdate?.(getClinicEventValues());
    }
    onClose?.();
  };

  return (
    <div className="flex fr-reverse">
      <FixedComponent className="worklist-navigation">
        <FixedComponent.Child isFixed>
          <WorklistVisitInfoComponent
            visitType={clinicEvent.visitType as VisitTypeEnum}
            visitMethod={clinicEvent.visitMethod as VisitMethodEnum}
            description={clinicEvent.description || ''}
            startTime={clinicEvent.checkInTime || ''}
            endTime={clinicEvent.checkOutTime || ''}
            onClose={handleClose}
          />
        </FixedComponent.Child>
        <FixedComponent.Child className="worklist-drawer-content">
          <AppCardComponent
            sendDownloadAppMsgProps={{
              patientId
            }}
            showAppVersion={false}
          />
          <TechOnboardingChecklistCardComponent
            value={onboardingChecklist}
            onChange={setOnboardingChecklist}
            disabled={disabled}
          />
          <FutureFollowUpClinicEventComponent />
          <VisitSummaryInputComponent
            defaultValue={getVisitSummary() ?? undefined}
            onChange={setVisitSummary}
            showRequiredMark={errors.VISIT_SUMMARY}
          />
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <WorklistButtonComponent
            clinicEvent={clinicEvent}
            onCheckout={handleCheckOut}
            onCompleteCharting={handleCompleteCharting}
            onClose={handleClose}
          />
        </FixedComponent.Child>
      </FixedComponent>
      <div className="worklist-section">
        <WorklistDevicesComponent patientId={patientId} isTech />
      </div>
    </div>
  );
};
