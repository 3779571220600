import { ControlLevelService } from '../../../../services/ControlLevelService';
import { PatientProtocolEnum } from '../../../../uc-api-sdk';
import { careProtocols } from '../../../enrollment/component/EnrollmentCareProtocolComponent.tsx/constant';

export interface CareProtocolComponentProps {
  controlService?: ControlLevelService;
  recommendProtocol?: PatientProtocolEnum;
}

export const CareProtocolComponent = ({
  controlService,
  recommendProtocol
}: CareProtocolComponentProps) => {
  const careProtocol = controlService?.getControlLevelInfo()?.protocol;
  const getCareProtocolName = (
    protocol: PatientProtocolEnum
  ) => careProtocols[protocol as keyof typeof careProtocols]?.title || 'Default Care Protocol';

  if (!careProtocol) {
    return null;
  }

  return (
    <span>
      {getCareProtocolName(recommendProtocol ?? careProtocol)}
    </span>
  );
};
