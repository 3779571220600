import { Card, Space } from 'antd';
import { SendDownloadAppMsgBtnContainer, SendDownloadAppMsgBtnContainerProps } from '../../container/SendDownloadAppMsgBtnContainer/SendDownloadAppMsgBtnContainer';
import { AppVersionContainer } from '../../container/AppVersionContainer/AppVersionContainer';
import { UnifiedCareAppIconComponent } from '../UnifiedCareAppIconComponent/UnifiedCareAppIconComponent';

export interface AppCardComponentProps {
  sendDownloadAppMsgProps: SendDownloadAppMsgBtnContainerProps;
  showAppVersion?: boolean;
}

export const AppCardComponent = ({
  sendDownloadAppMsgProps,
  showAppVersion = true,
}: AppCardComponentProps) => (
  <Card>
    <Space direction="vertical" size="large">
      <div>Download & install App</div>
      <Space size="large">
        <UnifiedCareAppIconComponent />
        <Space direction="vertical">
          <div>Unified Care App</div>
          { showAppVersion && <AppVersionContainer /> }
        </Space>
      </Space>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SendDownloadAppMsgBtnContainer {...sendDownloadAppMsgProps} />
    </Space>
  </Card>
);
