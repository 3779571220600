import { useCallback, useMemo } from 'react';
import { intersection, isEmpty } from 'lodash';
import EnvConfig from '../../configs/envConfig/envConfig';

export type LogCategoryName =
  'calendar'
  | 'visit';

export const useLog = (categoryName: LogCategoryName | LogCategoryName[]) => {
  const categories = useMemo(() => (
    Array.isArray(categoryName) ? categoryName : [categoryName]
  ), [categoryName]);

  const showLog = useMemo(() => (
    !isEmpty(intersection(EnvConfig.logCategories, categories))
  ), [categories]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const log = useCallback((...args: any[]) => {
    if (showLog) {
      let log: string[] = [
        `%c ${categoryName}:`,
        'background: #222; color: #bada55',
        ...args,
      ];

      if (EnvConfig.logTrace) {
        log = log.concat([
          '\n',
          // for getting the trace of the log
          new Error().stack?.replace('Error', ''),
        ] as string[]);
      }

      console.log(...log);
    }
  }, [showLog, categoryName]);

  return {
    log,
  };
};
