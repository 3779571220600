import { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Button, Modal, Space } from 'antd';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { InputType } from '../../../Input/types';
import { VisitTypeSelectComponent, VisitTypeSelectComponentProps } from './VisitTypeSelectComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';

export interface VisitTypeSelectCalendarValue {
  shouldJump: boolean;
  value?: VisitTypeEnum;
  manuallyTriggered?: boolean; // to simulate onChange for visitType
}

export interface VisitTypeSelectCalendarComponentProps
  extends Omit<InputType<VisitTypeSelectCalendarValue>, 'value'> {
  value?: VisitTypeEnum | VisitTypeSelectCalendarValue;
  startDate?: Dayjs;
  endDate?: Dayjs;
  jumpEnabled?: boolean;
  initialType?: VisitTypeEnum;
}

export const VisitTypeSelectCalendarComponent = ({
  disabled,
  value,
  onChange,
  startDate,
  endDate,
  jumpEnabled = false,
  initialType,
}: VisitTypeSelectCalendarComponentProps) => {
  const { isOpen, open, close } = useOpen();
  const [tempValue, setTempValue] = useState<VisitTypeEnum>();
  const { getRecommendedJumpDate } = useCreateVisitForm();

  const handleModalJump = () => {
    onChange?.({
      value: tempValue,
      shouldJump: true,
    });
    close();
  };

  const handleModalDontJump = () => {
    onChange?.({
      value: tempValue,
      shouldJump: false,
    });
    close();
  };

  const handleChange: VisitTypeSelectComponentProps['onChange'] = (v) => {
    if (
      jumpEnabled
      && v === VisitTypeEnum.FOLLOW_UP
      && startDate
    ) {
      const recommendedDate = getRecommendedJumpDate(startDate);
      if (recommendedDate) {
        open();
        setTempValue(v);
        return;
      }
    }
    onChange?.({
      value: v,
      shouldJump: jumpEnabled && true,
    });
  };

  useEffect(() => {
    if (typeof value === 'string') {
      onChange?.({
        value,
        shouldJump: false,
      });
    }
  }, [value]);

  useEffect(() => {
    if (
      (value as VisitTypeSelectCalendarValue)?.manuallyTriggered
      && startDate
      && endDate
    ) {
      handleChange((value as VisitTypeSelectCalendarValue).value);
    }
  }, [
    (value as VisitTypeSelectCalendarValue)?.manuallyTriggered,
    startDate,
    endDate
  ]);

  return (
    <>
      <VisitTypeSelectComponent
        disabled={disabled}
        onChange={handleChange}
        value={typeof value === 'string' ? value : value?.value}
        initialType={initialType}
      />
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        width={324}
        centered
      >
        <div className="mb20">
          The next follow-up visit is due between
          {' '}
          <DisplayDateComponent value={startDate} format="USA_DATE" />
          {' '}
          -
          {' '}
          <DisplayDateComponent value={endDate} format="USA_DATE" />
          {' '}
          for this patient. Auto-jump to the
          recommended date for scheduling?
        </div>
        <Space size={30}>
          <Button className="p0" type="link" onClick={handleModalJump}>
            <strong>Jump</strong>
          </Button>
          <Button className="p0" type="link" onClick={handleModalDontJump}>
            <strong>Don't jump</strong>
          </Button>
        </Space>
      </Modal>
    </>
  );
};
