import { Button, Form, Input } from 'antd';
import { FormType } from '../../../Input/types';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { Title } from '../../../../uiComponent/Title/Title';
import { InsurancePlanFormItemComponent } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent/InsurancePlanFormItemComponent';

import './PriorAuthStartNewFormComponent.scss';

interface FormValues {
  provider?: string;
  insuranceProviderId?: string;
  policyNumber?: string;
  insuranceId?: string;
}

export interface PriorAuthStartNewFormComponentProps
  extends FormType<FormValues> {
    disabled?: boolean;
  }

export const PriorAuthStartNewFormComponent = ({
  disabled,
  initialValues,
  onSubmit,
  onCancel
}: PriorAuthStartNewFormComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <Form
      form={insuranceForm.form}
      initialValues={initialValues}
      onFinish={insuranceForm.handleSubmit(onSubmit)}
      layout="vertical"
      className="prior-auth-start-new-form"
      disabled={disabled}
    >
      <Title className="mb30">
        New prior-authorization
      </Title>
      <div>
        Please verify the patient's Insurance and  Policy# before proceeding:
      </div>
      <div className="prior-auth-start-new-form-inputs mt20">
        <InsurancePlanFormItemComponent
          span={24}
        />
        <FormItem info={insuranceForm.getInfo('policyNumber')}>
          <Input />
        </FormItem>
      </div>
      <div className="flex fd-c jc-c ai-c mt100">
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          block
        >
          Start New Prior-auth
        </Button>
        <LinkButton
          onClick={onCancel}
          underlineOnHover
        >
          I want to do it later
        </LinkButton>
      </div>
    </Form>
  );
};
