import {
  createContext,
  ReactNode,
  useContext,
  useMemo
} from 'react';
import { useUpdateListener } from '../UpdateContext/UpdateContext';

export interface CareNoteContextValue {
  refresh: () => void;
}

const CareNoteContext = createContext<CareNoteContextValue | undefined>(undefined);

export interface CareNoteContextProviderProps extends CareNoteContextValue {
  children: ReactNode,
}

export const CareNoteContextProvider = ({
  children,
  refresh,
}: CareNoteContextProviderProps) => {
  useUpdateListener('CARE_NOTE_CREATED', refresh);
  useUpdateListener('MTPR_FINISHED', refresh);
  useUpdateListener('TICKET_RESOLVED', refresh);
  useUpdateListener('ALERT_RESOLVED', refresh);
  useUpdateListener('ALERT_COMMENTED', refresh);
  useUpdateListener('ALERT_SNOOZE_OR_UN_SNOOZE', refresh);
  useUpdateListener('PAUSE_SERVICE_UPDATED', refresh);
  useUpdateListener('UNABLE_TO_ENGAGE_UPDATED', refresh);
  useUpdateListener('CUSTOMIZED_A1C_GOAL_UPDATED', refresh);

  const contextValue = useMemo(() => ({
    refresh,
  }), [refresh]);

  return (
    <CareNoteContext.Provider
      value={contextValue}
    >
      {children}
    </CareNoteContext.Provider>
  );
};

export const useCareNoteContext = () => {
  const context = useContext(CareNoteContext);
  return (context || {}) as CareNoteContextValue;
};
