import { IconProps } from '../icons/Icon';

// IMPORTANT: src is relative path from src/assets
const makeSVGIconInfo = (
  src: string,
  alt = '',
  size = undefined as IconProps['size'],
) => ({ src, alt, size });

export const MENU_ICON = makeSVGIconInfo(
  '/icons/menu.svg',
  'menu Icon',
);

export const DUMP_REPORT_ICON = makeSVGIconInfo(
  '/icons/dump-report-icon.svg',
  'dump report icon',
);

export const DOWNLOAD_ICON = makeSVGIconInfo(
  '/icons/download-icon.svg',
  'download icon',
);

export const DUMP_REPORT_SMALL_ICON = makeSVGIconInfo(
  '/icons/dump-report-small-icon.svg',
  'dump report small icon',
);

export const UC_MANAGEMENT_ICON = makeSVGIconInfo(
  '/icons/uc-management.svg',
  'uc management icon',
);

export const COPILOT_CLOSE = makeSVGIconInfo(
  '/icons/copilot-close.svg',
  'copilot-close',
);

export const CLINICAL_ORG_ICON = makeSVGIconInfo(
  '/icons/clinical-org.svg',
  'clinical org icon',
);

export const CHEVRON_LEFT_ICON = makeSVGIconInfo(
  '/icons/chevron-left.svg',
  'chevron left icon',
);

export const DROPDOWN_ICON = makeSVGIconInfo(
  '/icons/dropdown-icon.svg',
  'down',
);

export const BILLING_DEPT_ICON = makeSVGIconInfo(
  '/icons/billing-dept.svg',
  'billing department',
);

export const CALL_CENTER_DEPT_ICON = makeSVGIconInfo(
  '/icons/call-center-dept.svg',
  'call-center department',
);

export const CARE_CENTER_ICON = makeSVGIconInfo(
  '/icons/care-center.svg',
  'care center',
);

export const CARE_DEPT_ICON = makeSVGIconInfo(
  '/icons/care-dept.svg',
  'care department',
);

export const CARE_GROUP_ICON = makeSVGIconInfo(
  '/icons/care-group.svg',
  'care group',
);

export const OPTION_DOTS_ICON = makeSVGIconInfo(
  '/icons/option-dots.svg',
  'option dots',
);

export const PEN_ICON = makeSVGIconInfo(
  '/icons/pen.svg',
  'pen',
);

export const TRASH_ICON = makeSVGIconInfo(
  '/icons/trash.svg',
  'trash',
);

export const PHONE_ICON = makeSVGIconInfo(
  '/icons/phone.svg',
  'phone',
);

export const WORLD_ICON = makeSVGIconInfo(
  '/icons/world.svg',
  'world',
);

export const CALENDAR_ICON = makeSVGIconInfo(
  '/icons/calendar.svg',
  'calendar',
);

export const CLOCK_ICON = makeSVGIconInfo(
  '/icons/clock.svg',
  'clock',
);

export const STAFF_ICON = makeSVGIconInfo(
  '/icons/staff.svg',
  'staff',
);

export const DOCUMENT_ICON = makeSVGIconInfo(
  '/icons/document.svg',
  'document',
);

export const LEFT_ARROW_ICON = makeSVGIconInfo(
  '/icons/left-arrow-square.svg',
  'left arrow',
);

export const RIGHT_ARROW_ICON = makeSVGIconInfo(
  '/icons/right-arrow.svg',
  'right arrow',
);

export const PREVIOUS_ICON = makeSVGIconInfo(
  '/icons/previous.svg',
  'previous',
);

export const NEXT_ICON = makeSVGIconInfo(
  '/icons/next.svg',
  'next',
);

export const SESSION_TIMEOUT_ICON = makeSVGIconInfo(
  '/icons/session-timeout.svg',
  'session timeout',
);

export const AVATAR_ICON = makeSVGIconInfo(
  '/icons/avatar.svg',
  'avatar',
);

export const SECURITY_ICON = makeSVGIconInfo(
  '/icons/security.svg',
  'security',
);

export const FOUR_DIAMONDS = makeSVGIconInfo(
  '/icons/four-diamonds.svg',
  'four-diamonds',
);

export const SORT_DESC_DEFAULT_ICON = makeSVGIconInfo(
  '/icons/sort-descending-default.svg',
  'sort-descending-default',
);

export const SORT_DESC_ICON = makeSVGIconInfo(
  '/icons/sort-descending.svg',
  'sort-descending',
);

export const SORT_ASCE_ICON = makeSVGIconInfo(
  '/icons/sort-ascending.svg',
  'sort-ascending',
);

export const SPIN_ICON = makeSVGIconInfo(
  '/icons/spin.svg',
  'spin',
);

export const BEDTIME_ICON = makeSVGIconInfo(
  '/icons/bedtime-icon.svg',
  'bedtime-icon',
);

export const OVERNIGHT_ICON = makeSVGIconInfo(
  '/icons/overnight-icon.svg',
  'overnight-icon',
);

export const EXERCISED_ICON = makeSVGIconInfo(
  '/icons/exercised-icon.svg',
  'exercised-icon',
);

export const HAD_INSULIN_ICON = makeSVGIconInfo(
  '/icons/took-insulin-icon.svg',
  'took-insulin-icon',
);

export const HAD_MEDS_ICON = makeSVGIconInfo(
  '/icons/took-meds-icon.svg',
  'took-meds-icon',
);

export const ARRHYTHMIA_ICON = makeSVGIconInfo(
  '/icons/arrhythmia-hr-icon-white.svg',
  'arrhythmia-hr-icon-white',
);

export const HEADER_CALENDAR_ICON = makeSVGIconInfo(
  '/icons/header-calendar.svg',
  'header-calendar',
);

export const HEADER_ALERT_ICON = makeSVGIconInfo(
  '/icons/header-alert-icon.svg',
  'header-alert-icon',
);

export const HEADER_PHONE_ICON = makeSVGIconInfo(
  '/icons/header-phone-icon.svg',
  'header-phone-icon',
);

export const HEADER_MSG_ICON = makeSVGIconInfo(
  '/icons/header-msg-icon.svg',
  'header-msg-icon',
);

export const HOME_ICON = makeSVGIconInfo(
  '/icons/home-icon.svg',
  'home-icon',
);

export const ROUTINE_CARE_ICON = makeSVGIconInfo(
  '/icons/routine-care-icon.svg',
  'routine-care-icon',
);
export const ENROLLMENT_ICON = makeSVGIconInfo(
  '/icons/enrollment-icon.svg',
  'enrollment-icon',
);

export const PATIENTS_ICON = makeSVGIconInfo(
  '/icons/patients-icon.svg',
  'patients-icon',
);

export const INSIGHTS_ICON = makeSVGIconInfo(
  '/icons/insight-icon.svg',
  'insight-icon',
);

export const LEFT_WHITE_CHEVRON_ICON = makeSVGIconInfo(
  '/icons/left-white-chevron.svg',
  'left-white-chevron',
);

export const PREVIEW_OPEN_ICON = makeSVGIconInfo(
  '/icons/preview-open-icon.svg',
  'preview-open',
);

export const PREVIEW_CLOSE_ICON = makeSVGIconInfo(
  '/icons/preview-close-icon.svg',
  'preview-close',
);

export const MAXIMIZE_WINDOW_ICON = makeSVGIconInfo(
  '/icons/maximize-window.svg',
  'preview-close',
);

export const MINIMIZE_WINDOW_ICON = makeSVGIconInfo(
  '/icons/minimize-window.svg',
  'preview-close',
);

export const STAR_ICON = makeSVGIconInfo(
  '/icons/star-icon.svg',
  'star-icon',
);

export const INELIGIBLE_TO_ENROLL_PATIENT_ALERT_ICON = makeSVGIconInfo(
  '/icons/ineligible-to-enroll-patient-alert-icon.svg',
  'ineligible-to-enroll-patient-alert-icon',
);

export const FEATURE_COMING_SOON_ALERT_ICON = makeSVGIconInfo(
  '/icons/feature-coming-soon-alert-icon.svg',
  'feature-coming-soon-alert-icon',
);

export const VITALS_BG_ICON = makeSVGIconInfo(
  '/icons/vitals-bg-icon.svg',
  'vitals-bg-icon',
);

export const VITALS_BP_ICON = makeSVGIconInfo(
  '/icons/vitals-bp-icon.svg',
  'vitals-bp-icon',
);

export const VITALS_PO_ICON = makeSVGIconInfo(
  '/icons/vitals-po-icon.svg',
  'vitals-po-icon',
);

export const VITALS_TM_ICON = makeSVGIconInfo(
  '/icons/vitals-tm-icon.svg',
  'vitals-tm-icon',
);

export const VITALS_HS_ICON = makeSVGIconInfo(
  '/icons/vitals-hs-icon.svg',
  'vitals-hs-icon',
);

export const VIDEO_PLAY_BUTTON_ICON = makeSVGIconInfo(
  '/icons/video-play-button.svg',
  'video-play-button',
);

export const MESSAGE_GRAY_ICON = makeSVGIconInfo(
  '/icons/message-gray.svg',
  'message-gray',
);

export const MESSAGE_ARTICLE_ICON = makeSVGIconInfo(
  '/icons/article-attachment.svg',
  'attachment-icon',
);

export const MESSAGE_MENU_ICON = makeSVGIconInfo(
  '/icons/message-icon.svg',
  'message-icon',
);

export const TICKET_MENU_ICON = makeSVGIconInfo(
  '/icons/ticket.svg',
  'ticket-menu-icon',
);

export const MESSAGE_SYSTEM_AVATAR = makeSVGIconInfo(
  '/icons/system-msg-avatar.svg',
  'system-message-avatar',
);

export const VIDEO_VISIT_ICON = makeSVGIconInfo(
  '/icons/video-visit-icon.svg',
  'video-visit-icon',
);

export const IN_CLINIC_VISIT_ICON = makeSVGIconInfo(
  '/icons/in-clinic-visit-icon.svg',
  'in-clinic-visit-icon',
);

export const PHONE_VISIT_ICON = makeSVGIconInfo(
  '/icons/phone-visit-icon.svg',
  'phone-visit-icon',
);

export const VECTOR_TROPHY_ICON = makeSVGIconInfo(
  '/icons/vector-trophy-icon.svg',
  'vector-trophy-icon',
);

export const UTILITY_SCREENING_ICON = makeSVGIconInfo(
  '/icons/utility-screening.svg',
  'Screening',
);

export const UTILITY_TRANSCRIBING_ICON = makeSVGIconInfo(
  '/icons/utility-transcribing.svg',
  'Screening',
);

export const FILLED_USER_ICON = makeSVGIconInfo(
  '/icons/filled-user-icon.svg',
  'filled-user-icon',
);

export const FILE_SEARCH_ICON = makeSVGIconInfo(
  '/icons/file-search-icon.svg',
  'file-search-icon',
);

export const LOCK_ICON = makeSVGIconInfo(
  '/icons/lock-icon.svg',
  'lock-icon',
);

export const PATIENT_INFO_HIDE_ICON = makeSVGIconInfo(
  '/icons/patient-info-hide.svg',
  'patient-info-hide',
);

export const CALL_ICON = makeSVGIconInfo(
  '/icons/call.svg',
  'phone-call',
);

export const CHAT_ICON = makeSVGIconInfo(
  '/icons/chat.svg',
  'chat',
);

export const MENU_DOT_ICON = makeSVGIconInfo(
  '/icons/menu-dot.svg',
  'menu-dot',
);

export const DOWN_ARROW_ICON = makeSVGIconInfo(
  '/icons/down-arrow.svg',
  'menu-dot',
);

export const UP_ARROW_ICON = makeSVGIconInfo(
  '/icons/up-arrow.svg',
  'menu-dot',
);

export const LOCATION_ICON = makeSVGIconInfo(
  '/icons/location-icon.svg',
  'location',
);

export const CLOSE_ICON = makeSVGIconInfo(
  '/icons/close-icon.svg',
  'system-message-avatar',
);

export const EDIT_ICON = makeSVGIconInfo(
  '/icons/edit-icon.svg',
  'edit',
);

export const WRITE_ICON = makeSVGIconInfo(
  '/icons/write-icon.svg',
  'write',
);

export const TRASH_CAN_ICON = makeSVGIconInfo(
  '/icons/trash-can-icon.svg',
  'TRASH_CAN_ICON',
);

export const BLOOD_DROP_2TONE_ICON = makeSVGIconInfo(
  '/icons/blood-drop-2tone-icon.svg',
  'blood-drop',
);

export const HEART_2TONE_ICON = makeSVGIconInfo(
  '/icons/heart-2tone-icon.svg',
  'heart',
);

export const TWO_WEEK_CALENDAR_ICON = makeSVGIconInfo(
  '/icons/two-week-calendar-icon.svg',
  'two-week-calendar',
);

export const POPOUT_ICON = makeSVGIconInfo(
  '/icons/popout-icon.svg',
  'popout',
);

export const CARE_FOLDER_ICON = makeSVGIconInfo(
  '/icons/care-folder-icon.svg',
  'folder-icon',
);

export const ENROLL_ICON = makeSVGIconInfo(
  '/icons/enroll-icon.svg',
  'enrollment-icon',
);

export const CHECK_CIRCLE_ICON = makeSVGIconInfo(
  '/icons/check-circle-icon.svg',
  'checked-icon',
);

export const TRANSCRIBING_ICON = makeSVGIconInfo(
  '/icons/transcribing-icon.svg',
  'transcribing-icon',
);

export const SCREENING_ICON = makeSVGIconInfo(
  '/icons/screening-icon.svg',
  'screening-icon',
);

export const FIREWORKS_ICON = makeSVGIconInfo(
  '/icons/fireworks.svg',
  'fireworks-icon',
);

export const UTILITIES_MENU_ICON = makeSVGIconInfo(
  '/icons/utilities-menu-icon.svg',
  'transcribe-menu-item',
);

export const HISTORY_ICON = makeSVGIconInfo(
  '/icons/history-icon.svg',
  'history',
);

export const START_ENROLLMENT_ICON = makeSVGIconInfo(
  '/icons/start-enrollment.svg',
  'start-enrollment',
);

export const REFER_ICON = makeSVGIconInfo(
  '/icons/refer-icon.svg',
  'refer-icon',
);

export const CALL_UNENROLLED = makeSVGIconInfo(
  '/icons/call-unenrolled.svg',
  'call-unenrolled',
);

export const SETTINGS_ICON = makeSVGIconInfo(
  '/icons/settings.svg',
  'settings',
);

export const EXIT_ICON = makeSVGIconInfo(
  '/icons/exit-icon.svg',
  'exit',
);

export const UC_MOBILE_APP_ICON = makeSVGIconInfo(
  '/icons/unifiedcare-mobile-app-icon.svg',
  'unifiedcare-mobile-app-icon',
);

export const EMPTY_IMG = makeSVGIconInfo(
  '/icons/empty-img.svg',
  'empty-img',
);

export const PLUS_ICON = makeSVGIconInfo(
  '/icons/plus-icon.svg',
  'plus-icon',
);

export const BLUE_STAR_ICON = makeSVGIconInfo(
  '/icons/blue-star-icon.svg',
  'blue-star-icon',
);

export const MAGIC_STICK = makeSVGIconInfo(
  '/icons/magic-stick.svg',
  'magic-stick',
);

export const MANUAL_INPUT = makeSVGIconInfo(
  '/icons/manualInput.svg',
  'manual-input',
);

export const FEEDBACK_ICON = makeSVGIconInfo(
  '/icons/feedback-icon.svg',
  'feedback-icon',
);

export const TROUBLESHOOTING_ICON = makeSVGIconInfo(
  '/icons/troubleshooting.svg',
  'troubleshooting',
);

export const RED_INFO_ICON = makeSVGIconInfo(
  '/icons/red_info_icon.svg',
  'red_info_icon'
);

export const ZOOM_ICON = makeSVGIconInfo(
  '/icons/zoom-icon.svg',
  'Zoom'
);

export const BG_NO_CIRCLE = makeSVGIconInfo(
  '/icons/BG-no-circle.svg',
  'blood glucose'
);

export const BP_NO_CIRCLE = makeSVGIconInfo(
  '/icons/BP-no-circle.svg',
  'blood pressure'
);

export const LIPID_MANAGEMENT_ICON = makeSVGIconInfo(
  '/icons/lipid-management-icon.svg',
  'lipid management'
);

export const CANCER_ICON = makeSVGIconInfo(
  '/icons/cancer-icon.svg',
  'cancer'
);

export const WEIGHT_NO_CIRCLE = makeSVGIconInfo(
  '/icons/weight-no-circle.svg',
  'weight'
);

export const COPD_ICON = makeSVGIconInfo(
  '/icons/copd-icon.svg',
  'COPD'
);

export const CHF_ICON = makeSVGIconInfo(
  '/icons/chf-icon.svg',
  'CHF'
);

export const CKD_ICON = makeSVGIconInfo(
  '/icons/ckd-icon.svg',
  'CKD'
);

export const COVID_ICON = makeSVGIconInfo(
  '/icons/covid-icon.svg',
  'COVID'
);

export const NOTE_ICON = makeSVGIconInfo(
  '/icons/note-icon.svg',
  'note'
);

export const MOVABLE_ICON = makeSVGIconInfo(
  '/icons/movable-icon.svg',
  'movable'
);

export const CARE_OPERATION_HIERARCHY_ICON = makeSVGIconInfo(
  '/icons/care-operation-hierarchy.svg',
  'care operation hierarchy'
);

export const MEDICAL_ORGANIZATION_ICON = makeSVGIconInfo(
  '/icons/medical-organization.svg',
  'medical organization'
);

export const MEDICAL_ORGANIZATION_ICON_HOME = makeSVGIconInfo(
  '/icons/medical-org-home-page.svg',
  'medical organization home'
);

export const USER_HOME_ICON = makeSVGIconInfo(
  '/icons/user-admin-home.svg',
  'user home'
);

export const USER_ICON = makeSVGIconInfo(
  '/icons/user-icon.svg',
  'user'
);

export const CARE_UNIT_ROOT_ICON_AP2 = makeSVGIconInfo(
  '/icons/care-unit-root-icon.svg',
  'care unit root'
);

export const CARE_CENTER_ICON_AP2 = makeSVGIconInfo(
  '/icons/care-center-icon.svg',
  'care center'
);

export const CARE_DIVISION_ICON_AP2 = makeSVGIconInfo(
  '/icons/care-division-icon.svg',
  'care division'
);

export const CARE_GROUP_ICON_AP2 = makeSVGIconInfo(
  '/icons/care-group-icon.svg',
  'care group'
);

export const ORDER_BOX_ICON = makeSVGIconInfo(
  '/icons/order-box.svg',
  'order-box'
);

export const ERROR_ICON = makeSVGIconInfo(
  '/icons/error-icon.svg',
  'error-icon'
);

export const APP_CRASH_ICON = makeSVGIconInfo(
  '/icons/app-crash-icon.svg',
  'app-crash-icon'
);

export const NO_DATA_ICON = makeSVGIconInfo(
  '/icons/no-data.svg',
  'no-data'
);

export const NO_DATA_NO_TEXT_ICON = makeSVGIconInfo(
  '/icons/no-data-no-text.png',
  'no-data',
  { width: 68, height: 52 }
);

export const RESTRICTED_ACCESS_ICON = makeSVGIconInfo(
  '/icons/restricted-access-icon.svg',
  'restricted-access'
);

export const CLIPBOARD_PEN_ICON = makeSVGIconInfo(
  '/icons/clipboard-pen-icon.svg',
  'clipboard-pen'
);

export const NOTIFICATION_BELL_ICON = makeSVGIconInfo(
  '/icons/notification-bell-icon.svg',
  'notification-bell'
);

export const BILLABLE_TIME_REVIEW_ICON = makeSVGIconInfo(
  '/icons/billabletime-icon.svg',
  'billable-time-review'
);

export const MEDICAL_ALERT_DASHBOARD_ICON = makeSVGIconInfo(
  '/icons/medical-alert-dashboard.svg',
  'medical-alert-dashboard'
);

export const SIDE_BAR_ALERTS_ICON = makeSVGIconInfo(
  '/icons/side-bar-alerts.svg',
  'side-bar-alerts'
);

export const COPY_ICON = makeSVGIconInfo(
  '/icons/copy-icon.svg',
  'copy-icon'
);

export const VISITS_AND_EVENTS_TAB_ICON = makeSVGIconInfo(
  '/icons/visits-and-events-icon.svg',
  'visits-and-events'
);

export const NOTIFY_PATIENTS_ICON = makeSVGIconInfo(
  '/icons/notify-patients-icon.svg',
  'notify-patients'
);

export const USER_CHECK_ICON = makeSVGIconInfo(
  '/icons/user-check-icon.svg',
  'user-check-icon'
);

export const SWITCH_ICON = makeSVGIconInfo(
  '/icons/switch-icon.svg',
  'switch-icon'
);

export const COLLECT_DEVICES_ICON = makeSVGIconInfo(
  '/icons/collect-devices-icon.svg',
  'collect-devices-icon'
);

export const TERMINATE_ICON = makeSVGIconInfo(
  '/icons/terminate-icon.svg',
  'terminate-icon'
);

export const BATCH_ASSIGNMENT_ICON = makeSVGIconInfo(
  '/icons/batch-assignment.svg',
  'batch-assignment'
);

export const BATCH_MESSAGING_ICON = makeSVGIconInfo(
  '/icons/batch-messaging.svg',
  'batch-messaging'
);

export const NOTIFIED_CALENDAR_ICON = makeSVGIconInfo(
  '/icons/notified-calendar.svg',
  'notified-calendar'
);

export const SUCCESS_SHIELD_ICON = makeSVGIconInfo(
  '/icons/success-shield.svg',
  'success-shield'
);

export const TRANSLATE_ICON = makeSVGIconInfo(
  '/icons/translate-icon.svg',
  'translate-icon'
);

export const SHIELD_ICON = makeSVGIconInfo(
  '/icons/shield.svg',
  'shield-icon'
);

export const BATCH_UPLOAD_ICON = makeSVGIconInfo(
  '/icons/batch-upload-icon.svg',
  'batch-upload-icon'
);

export const FILE_ICON = makeSVGIconInfo(
  '/icons/file-icon.svg',
  'file-icon'
);

export const CLINIC_BILLING_ICON = makeSVGIconInfo(
  '/icons/clinic-billing-icon.svg',
  'clinic-billing-icon'
);

export const PAYMENT_INVOICE_ICON = makeSVGIconInfo(
  '/icons/payment-invoice-icon.svg',
  'payment-invoice-icon'
);

export const REIMBURSEMENT_ICON = makeSVGIconInfo(
  '/icons/reimbursement-icon.svg',
  'reimbursement-icon'
);

export const BILLING_AND_REPORT_ICON = makeSVGIconInfo(
  '/icons/billing-and-report-icon.svg',
  'billing-and-report-icon'
);

export const PATIENT_DELETED_ICON = makeSVGIconInfo(
  '/icons/patient-deleted-icon.svg',
  'patient-deleted-icon'
);

export const DOUBLE_ARROW_DOWN = makeSVGIconInfo(
  '/icons/double-arrow-down.svg',
  'double-arrow-down'
);

export const RECENT_ICON = makeSVGIconInfo(
  '/icons/recent-icon.svg',
  'recent-icon'
);

export const PIN_OUTLINE_ICON = makeSVGIconInfo(
  '/icons/pin-outline-icon.svg',
  'pin-outline-icon'
);

export const PIN_FILL_ICON = makeSVGIconInfo(
  '/icons/pin-fill-icon.svg',
  'pin-fill-icon'
);

export const GAUGE_ICON = makeSVGIconInfo(
  '/icons/gauge-icon.svg',
  'gauge'
);

export const BILLING_SQUARE_ICON = makeSVGIconInfo(
  '/icons/billing-square-icon.svg',
  'billing-square'
);

export const PDF_ICON = makeSVGIconInfo(
  '/icons/pdf-icon.svg',
  'pdf-icon'
);

export const FOLDER_ICON = makeSVGIconInfo(
  '/icons/folder-icon.svg',
  'folder-icon'
);

export const EXCEL_SHEET_ICON = makeSVGIconInfo(
  '/icons/excel-sheet-icon.svg',
  'excel-sheet-icon'
);

export const TIMER_ICON = makeSVGIconInfo(
  '/icons/timer-icon.svg',
  'timer'
);

export const TWINKLE_STAR = makeSVGIconInfo(
  '/icons/twinkle-star-icon.svg',
  'twinkle-star-icon'
);

export const NOTE_SEARCH_ICON = makeSVGIconInfo(
  '/icons/note-search-icon.svg',
  'note-search-icon',
);

export const REWRITE_WITH_AI_ICON = makeSVGIconInfo(
  '/icons/rewrite-with-ai.svg',
  'rewrite-with-ai'
);

export const CARE_PROTOCOL_BACKGROUND_ICON = makeSVGIconInfo(
  '/icons/care-protocol-background.svg',
  'care-protocol-background'
);

export const MESSAGE_ICON_2 = makeSVGIconInfo(
  '/icons/message-icon-2.svg',
  'message-icon',
);

export const MED_BP_ICON = makeSVGIconInfo(
  '/icons/med-bp.svg',
  'BP'
);

export const MED_BG_ICON = makeSVGIconInfo(
  '/icons/med-bg.svg',
  'BG'
);
