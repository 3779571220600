import { VisitTypeEnumComponent } from '../../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { ClinicEvent } from '../../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface PrintVisitSummaryComponentProps {
  visit?: ClinicEvent | null
}

export const PrintVisitSummaryComponent = ({
  visit,
}: PrintVisitSummaryComponentProps) => {
  if (!visit || !visit.visitType || !visit.visitSummary) {
    return null;
  }
  return (
    <div className="mt5 mb30">
      <TextComponent className="pop-text">
        Visit Summary -
        {' '}
        <VisitTypeEnumComponent value={visit?.visitType} />
        {' '}
        <DisplayDateComponent value={visit?.startTime} format="USA_DATE" />
      </TextComponent>
      <div>
        <DisplayOrEmptyComponent value={visit?.visitSummary} />
      </div>
    </div>
  );
};
