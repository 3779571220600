import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

export interface MedicationListNoteComponentProps {
  value?: string;
  onChange?: TextAreaProps['onChange'];
}

export const MedicationListNoteComponent = ({
  value,
  onChange
}: MedicationListNoteComponentProps) => (
  <Input.TextArea
    value={value}
    onChange={onChange}
    allowClear
    autoSize={{ minRows: 2 }}
    className="medication-list-note"
  />
);
