import { Space } from 'antd';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { LabelValueComponent } from '../../../../uiComponent/LabelValueComponent/LabelValueComponent';
import { OutstandingItemsTagComponent } from '../../../outstanding/component/OutstandingItemsTagComponent/OutstandingItemsTagComponent';
import { LastFollowupVisitContainer } from '../../../visit/container/LastFollowupVisitContainer/LastFollowupVisitContainer';
import './WorklistPatientInfoComponent.scss';
import { CareProtocolComponent } from '../../../patient/component/CareProtocolComponent/CareProtocolComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { AccountHelper } from '../../../../helpers/account';
import { ControlLevelEnumComponent } from '../../../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';
import { useICDCodeRecommendationContext } from '../../../../contexts/ICDCodeRecommendationContext/ICDCodeRecommendationContext';

export interface WorklistPatientInfoComponentProps { }

export const WorklistPatientInfoComponent = () => {
  const {
    info
  } = usePatientContext();
  const {
    outstandingService,
    controlLevelService,
    isLoading,
  } = info || {};
  const { recommendProtocol } = useICDCodeRecommendationContext();

  const totalOutstandingCount = outstandingService?.getTotalCount() || 0;
  const visitCount = outstandingService?.outstandingItemCount?.visitCount || 0;

  const shouldShowOutstandingTag = (
    // has outstanding items
    totalOutstandingCount > 0
    // has outstanding item other than visit
    && totalOutstandingCount > visitCount
  );

  const renderInfo = (info?: PatientInfo) => (
    <Space direction="vertical" className="w100 pb10 worklist-patient-info">
      <div className="flex ai-c">
        <TextComponent size="x-large" bold>
          {AccountHelper.getFullName(info?.patientInfo?.profile)}
        </TextComponent>
        <div className="ml10">
          <ControlLevelEnumComponent
            value={(
              controlLevelService?.getControlLevelInfo()?.controlLevel?.controlLevel
              ?? undefined
            )}
          />
        </div>
      </div>
      {
        shouldShowOutstandingTag
        && (
          <div className="flex jc-sb ai-c">
            <OutstandingItemsTagComponent />
          </div>
        )
      }
      <LabelValueComponent
        label="Clinic:"
        value={(
          <ClinicNameDisplayComponent
            clinic={info?.patientInfo?.clinic || undefined}
          />
        )}
      />
      <LabelValueComponent
        label="Status:"
        value={(
          <>
            <span>
              {info?.enrolledProgramService.isEnrolled() ? 'Enrolled' : 'Unenrolled'}
            </span>
            {' - '}
            <CareProtocolComponent
              controlService={info?.controlLevelService}
              recommendProtocol={recommendProtocol}
            />
            {
              info?.enrolledProgramService.getPrograms().length
                ? (
                  <span>
                    (
                    <ProgramCategoryEnumListComponent
                      value={info?.enrolledProgramService.getPrograms()}
                      type="short"
                    />
                    )
                  </span>
                ) : null
            }
          </>
        )}
      />
      <Space wrap>
        <LabelValueComponent
          label="Vitals:"
          value={(
            <VitalEnumTypeListComponent
              value={info?.enrolledProgramService.getVitals()}
              type="short"
              splitter=", "
            />
          )}
        />
      </Space>
      <LabelValueComponent
        label="Last Follow-up Visit:"
        value={(<LastFollowupVisitContainer patientId={info?.patientInfo?.id} />)}
      />
    </Space>
  );

  return (
    <div className="worklist-patient-info-component">
      <LoadingOverlayComponent isLoading={isLoading}>
        {renderInfo(info)}
      </LoadingOverlayComponent>
    </div>
  );
};
