import { BehaviorChangeEnum } from '../../../../../uc-api-sdk';
import { PatientBehaviorChangeEnumComponent } from '../../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeEnumComponent/PatientBehaviorChangeEnumComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface PrintCarePlanBehaviorChangeComponentProps {
  behaviorChange?: BehaviorChangeEnum;
}

export const PrintCarePlanBehaviorChangeComponent = ({
  behaviorChange,
}: PrintCarePlanBehaviorChangeComponentProps) => (
  <>
    <div className="page-break" />
    <TextComponent className="pop-text">
      Stage of Behavior Change
    </TextComponent>
    <PatientBehaviorChangeEnumComponent value={behaviorChange} />
  </>
);
