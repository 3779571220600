import { Button } from 'antd';
import dayjs from 'dayjs';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { CalendarDrawerComponent } from '../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export interface StartNowUserOverlapNoticeComponentProps {
  startNowOverlapInterval: number;
  onGetStartedClick?: () => void;
  onCheckMyCalendarClick?: () => void;
  onCloseCalendar?: () => void;
}

export const StartNowUserOverlapNoticeComponent = ({
  startNowOverlapInterval,
  onGetStartedClick,
  onCheckMyCalendarClick,
  onCloseCalendar,
}: StartNowUserOverlapNoticeComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();
  const { userId = '' } = useLoggedInUserFromContext();

  const handleGetStartedClick = () => {
    onGetStartedClick?.();
  };

  const handleCheckMyCalendarClick = () => {
    onCheckMyCalendarClick?.();
    open();
  };

  const handleOnCloseCalendar = () => {
    close();
    onCloseCalendar?.();
  };

  return (
    <>
      <div>
        <TextComponent size="large" bold>
          We noticed your time is occupied in the next
          {' '}
          {startNowOverlapInterval}
          {' '}
          minutes.
        </TextComponent>
        <TextComponent className="my10">
          Are you sure to start the visit now?
        </TextComponent>
        <div className="flex mt30 mb10 jc-e">
          <Button
            type="primary"
            onClick={handleGetStartedClick}
          >
            Get Me Started
          </Button>
          <Button
            onClick={handleCheckMyCalendarClick}
          >
            Check My Calendar
          </Button>
        </div>
      </div>
      <CalendarDrawerComponent
        isOpen={isOpen}
        onClose={handleOnCloseCalendar}
        visitInitialValue={undefined}
        calendarInitialValue={{
          date: dayjs().toISOString(),
          participants: [userId],
        }}
      />
    </>
  );
};
