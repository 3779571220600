import { RoleTypeEnumComponent } from '../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { AccountHelper } from '../../../../helpers/account';
import { VisitParticipant } from '../../../../uc-api-sdk';

export interface CalendarVisitParticipantComponentProps {
  value: VisitParticipant;
}

export const CalendarVisitParticipantComponent = ({
  value,
}: CalendarVisitParticipantComponentProps) => (
  <>
    <span>
      {
        AccountHelper.getFullName(value.participantInfo)
        || value.participantInfo?.email
      }
    </span>
    {value.roleInVisit && (
      <span>
        {' ('}
        <RoleTypeEnumComponent value={value.roleInVisit} />
        )
      </span>
    )}
  </>
);
