import { Spin } from 'antd';
import { PortalPlatform } from '../../../../../contexts/loggedInUserContext/types';
import { Icons } from '../../../../../icons/Icons';
import { ClickableDiv } from '../../../../../uiComponent/ClickableDiv/ClickableDiv';
import { LogoutComponent } from '../../../../../uiComponent/LogoutComponent/LogoutComponent';
import { OnSiteInfoComponent } from '../../../../../uiComponent/OnSiteInfoComponent/OnSiteInfoComponent';
import { LoggedInMiniUserComponent } from '../LoggedInMiniUserComponent/LoggedInMiniUserComponent';
import { LoggedInPlatformComponent } from '../LoggedInPlatformComponent/LoggedInPlatformComponent';
import { LoggedInUserSettingsComponent } from '../LoggedInUserSettingsComponent/LoggedInUserSettingsComponent';

export const userInfoMenuItem = {
  label: <LoggedInMiniUserComponent />,
  key: 'userInfo',
};

export const platformCarePortalMenuItem = {
  label: (
    <LoggedInPlatformComponent
      redirectTo={PortalPlatform.CarePortal}
    >
      Care Portal
    </LoggedInPlatformComponent>
  ),
  key: 'carePortal',
};

export const platformAdminPortalMenuItem = {
  label: (
    <LoggedInPlatformComponent
      redirectTo={PortalPlatform.AdminPortal}
    >
      Admin Portal
    </LoggedInPlatformComponent>
  ),
  key: 'adminPortal',
};

export const platformBillerPortalMenuItem = {
  label: (
    <LoggedInPlatformComponent
      redirectTo={PortalPlatform.BillerPortal}
    >
      Biller Portal
    </LoggedInPlatformComponent>
  ),
  key: 'billerPortal',
};

export const onSiteInfoMenuItem = {
  label: <OnSiteInfoComponent className="flex gap2 ai-c" editable={false} onClick={undefined} />,
  key: 'onSiteInfo',
};

export const settingsMenuItem = {
  label: <LoggedInUserSettingsComponent />,
  key: 'settings',
};

export const logoutMenuItem = {
  label: (
    <LogoutComponent>
      {
        ({ onClick, isLoggingOut }) => (
          <ClickableDiv
            className="flex gap2"
            onClick={onClick}
          >
            <Icons.Exit hexColor="#000" />
            Log out
            {isLoggingOut && (<Spin />)}
          </ClickableDiv>
        )
      }
    </LogoutComponent>
  ),
  key: 'logout',
};
