import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicationListReviewSelectComponent } from '../MedicationListComponent/MedicationListReviewSelectComponent';
import { MedicationListNoteComponent } from '../MedicationListComponent/MedicationListNoteComponent';
import { UpdatedByAt } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { MedicationSelectLabelInfoComponent } from './MedicationSelectLabelInfoComponent';
import { MedicationReminderFormItemComponent } from './MedicationReminderFormItemComponent';

export interface MedicationComplianceFormItemOptions {
  medicationReviewHidden?: boolean;
  medicationReviewRequired?: boolean;
  medicationReminderRequired?: boolean;
}
export interface MedicationComplianceFormItemsComponentProps
  extends MedicationComplianceFormItemOptions {
  medicationComplianceLastInfo?: UpdatedByAt;
  medicationReminderLastInfo?: UpdatedByAt;
}

export const MedicationComplianceFormItemsComponent = ({
  medicationReviewHidden,
  medicationComplianceLastInfo,
  medicationReminderLastInfo,
  medicationReviewRequired = true,
  medicationReminderRequired,
}: MedicationComplianceFormItemsComponentProps) => {
  const hook = useMedicationContainerForm();

  return (
    <>
      <FormItem
        className="required-field"
        name={hook.getName('medicationReview')}
        label={(
          <MedicationSelectLabelInfoComponent
            label={hook.getLabel('medicationReview')}
            lastInfo={medicationComplianceLastInfo}
          />
        )}
        labelCol={{ span: 24 }}
        rules={[{ required: medicationReviewRequired, message: <MissingErrorComponent /> }]}
        hideRequiredMark
        hidden={medicationReviewHidden}
      >
        <MedicationListReviewSelectComponent />
      </FormItem>

      <FormItem
        info={hook.getInfo('note')}
        labelCol={{ span: 24 }}
      >
        <MedicationListNoteComponent />
      </FormItem>

      <MedicationReminderFormItemComponent
        required={medicationReminderRequired}
        medicationReminderLastInfo={medicationReminderLastInfo}
      />
    </>
  );
};
