import {
  Button,
  Col, Form, Row, Switch
} from 'antd';
import './AppNotificationComponent.scss';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAppNotificationForm } from './useAppNotificationForm';
import { ArticleFrequencySelectComponent } from './ArticleFrequencySelectComponent';
import { AppFunctions } from '../../../../uc-api-sdk';

export interface AppNotificationFormComponentProps {
  initialValues?: AppFunctions,
  isLoading?: boolean;
  onSubmit?: (value: AppFunctions) => void;
  onCancel?: () => void;
}

export const AppNotificationFormComponent = ({
  initialValues,
  isLoading,
  onSubmit,
  onCancel,
}: AppNotificationFormComponentProps) => {
  const appNotificationFormInfo = useAppNotificationForm();
  const { form } = appNotificationFormInfo;

  const onFinishPreCheck = (value: AppFunctions) => {
    onSubmit?.(value);
  };

  return (
    <Form
      className="AppNotificationComponent"
      initialValues={initialValues}
      onFinish={appNotificationFormInfo.handleSubmitAndReset(onFinishPreCheck)}
      disabled={appNotificationFormInfo.formDisabled || isLoading}
      layout="vertical"
      form={form}
    >
      <Row className="mb12">
        <Col span={6}>
          <FormItem
            className="AppNotificationComponent__selectWindow"
            info={appNotificationFormInfo.getInfo('articleFrequency')}
          >
            <ArticleFrequencySelectComponent />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={appNotificationFormInfo.getInfo('measurementReminder')}
            valuePropName="checked"
          >
            <Switch />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={appNotificationFormInfo.getInfo('medicationReminder')}
            valuePropName="checked"
          >
            <Switch />
          </FormItem>
        </Col>
      </Row>
      <Button
        className="mr10"
        type="primary"
        htmlType="submit"
        loading={isLoading}
      >
        Save
      </Button>
      <Button
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Form>
  );
};
