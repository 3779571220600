import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { BehaviorChangeContainer } from '../../../intervention/container/BehaviorChangeContainer/BehaviorChangeContainer';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useNestedFormContext } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';

export interface WorklistBehaviorChangeComponentProps extends NestedFormComponentChildProps {
  patientInfo?: PatientInfo;
}

export const WorklistBehaviorChangeComponent = ({
  patientInfo,
  onSubmit,
  onError,
  onValuesChange,
}: WorklistBehaviorChangeComponentProps) => {
  const { clinicEvent } = useWorklistContext();
  const { form: nestedForm, disabled } = useNestedFormContext() || {};

  return (
    <BehaviorChangeContainer
      form={nestedForm}
      disabled={disabled}
      visitId={clinicEvent?.id}
      info={(patientInfo || {}) as PatientInfo}
      showEdit={false}
      isEditing
      showButtons={false}
      onSubmit={onSubmit}
      onError={onError}
      onValuesChange={onValuesChange}
    />
  );
};
