import { Button, Modal, Image } from 'antd';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { useDoNotShowAgain } from '../../../patient/hook/useDoNotShowAlertAgain';
import PaymentInvoicePopup from '../../../../assets/images/paymentInvoicePopup.png';
import { RoleTypeEnum } from '../../../../uc-api-sdk';
import './IntroducingNewPaymentPopupModalComponent.scss';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';

export interface IntroducingNewPaymentPopupModalComponentProps {}

export const IntroducingNewPaymentPopupModalComponent = () => {
  const { userId, doesUserRoleIncludes } = useLoggedInUserFromContext();
  const { goToCarePortalClinicBilling } = useChangePage();
  const [isIntroducedPaymentInvoiceFeatures, setIsIntroducedPaymentInvoiceFeatures] = useDoNotShowAgain('isIntroducedPaymentInvoiceFeatures', userId);

  const renderImage = () => (
    <Image className="render-image" src={PaymentInvoicePopup} preview={false} width={800} height={343} />
  );

  const onClickExplore = () => {
    setIsIntroducedPaymentInvoiceFeatures(true);
    goToCarePortalClinicBilling();
  };

  return (
    <Modal
      mask
      onCancel={() => setIsIntroducedPaymentInvoiceFeatures(true)}
      width={800}
      title={renderImage()}
      maskClosable={false}
      open={
        !isIntroducedPaymentInvoiceFeatures
        && doesUserRoleIncludes([RoleTypeEnum.CLINICAL_MANAGER])
      }
      centered
      className="introducing-new-payment-popup"
      footer={null}
    >
      <div className="flex jc-sb gap3">
        <div className="render-title ta-l fs20 b7">
          Introducing New Payment & Invoice Features
        </div>
        <div className="ta-l">
          We've simplified the process for viewing and paying your invoices.
          For future invoices, you'll be able to:
          <b>view invoice details</b>
          ,
          <b>download invoices</b>
          ,
          <b>track payment status</b>
          , and make payments directly through the portal.
        </div>
      </div>
      <div className="render-get-started flex jc-e">
        <Button onClick={onClickExplore}>
          Explore Payment & Invoice
        </Button>
      </div>
    </Modal>
  );
};
