import { WorklistMedicationComponent } from '../WorklistMedicationComponent/WorklistMedicationComponent';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';

export interface SimplifiedWorklistMedicationComponentProps {
  patientId: string;
  itemNumber?: number;
}

export const SimplifiedWorklistMedicationComponent = ({
  patientId,
  itemNumber = -1,
}: SimplifiedWorklistMedicationComponentProps) => (
  <div className="simplified-worklist-section">
    <WorklistSectionHeaderComponent
      number={itemNumber}
      title="Medication Reconciliation & Reminder"
    />

    <WorklistMedicationComponent
      patientId={patientId}
      title=""
      showHeaderOutside={false}
    />
  </div>
);
