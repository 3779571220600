import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import './MedicationNameRendererComponent.scss';
import TooltipComponent from '../../../uiComponent/TooltipComponent/TooltipComponent';

export interface MedNameProps {
  name: string;
  otherMD: boolean;
}

export const MedicationNameRendererComponent = ({ name, otherMD }: MedNameProps) => (
  <Space size={7} className="medication-name-renderer-wrapper">
    <TooltipComponent type={null} title={name}>
      <div className="medication-name-renderer">{name}</div>
    </TooltipComponent>
    {otherMD && (
      <Tooltip title="This medication is not prescribed by this patient's primary provider.">
        <ExclamationCircleOutlined />
      </Tooltip>
    )}
  </Space>
);
