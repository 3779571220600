import { InputNumber, InputNumberProps } from 'antd';
import React from 'react';
import './NumberInputWithSuffixComponent.scss';

export interface NumberInputWithSuffixComponentProps extends InputNumberProps {
  showUnit?: boolean,
  unit?: React.ReactNode,
  label?: React.ReactNode,
}

export const NumberInputWithSuffixComponent = ({
  showUnit,
  unit,
  ...props
}: NumberInputWithSuffixComponentProps) => (
  <InputNumber
    className="number-input-with-suffix"
    onKeyDown={(e) => {
      // enable cursor movement when on EditContainer
      e.stopPropagation();
    }}
    addonAfter={showUnit
      ? <div className="lab-result-unit">{unit}</div>
      : ''}
    placeholder={showUnit ? undefined : 'Value'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
