import { FC } from 'react';
import { Popover } from 'antd';
import { ControlLevelEnum, PatientControlLevel } from '../../../../uc-api-sdk';
import { ControlLevelCardComponent } from '../../../patient/component/ControlLevelCardComponent/ControlLevelCardComponent';
import { ControlLevelEnumComponent } from '../../../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';

export interface ControlLevelInfoProps {
  patientId: string;
  cardInfo?: PatientControlLevel;
}

export const ControlLevelInfoComponent: FC<ControlLevelInfoProps> = ({
  patientId,
  cardInfo,
}) => (
  <Popover
    trigger="click"
    placement="bottomLeft"
    showArrow={false}
    content={(
      <ControlLevelCardComponent
        patientId={patientId}
        cardInfo={cardInfo}
      />
    )}
    destroyTooltipOnHide
  >
    <div className="pointer">
      <ControlLevelEnumComponent
        value={cardInfo?.controlLevel?.controlLevel as ControlLevelEnum | undefined}
      />
    </div>
  </Popover>
);
