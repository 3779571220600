import {
  keyBy, lowerCase, map, startCase
} from 'lodash';
import { useMemo } from 'react';
import { ConditionEnum, useTemplatesGetClinicGoalTemplate } from '../../../../uc-api-sdk';
import { ClinicalGoalCardFormComponent, ClinicalGoalCardFormComponentProps } from '../../component/ClinicalGoalComponent/ClinicalGoalFormComponent/ClinicalGoalCardFormComponent';
import { ConditionEumComponent } from '../../../../enumComponent/ConditionEumComponent/ConditionEumComponent';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

interface ClinicalGoalTemplateFetchContainerProps extends Omit<ClinicalGoalCardFormComponentProps, 'templates'> {
  onSubmit: ClinicalGoalCardFormComponentProps['onSubmit'];
  onCancel: ClinicalGoalCardFormComponentProps['onCancel'];
  isLoading: boolean;
  disabled?: boolean;
  requireClinicalGoal?: boolean;
}

export const ClinicalGoalTemplateFetchContainer = ({
  onSubmit,
  onCancel,
  isLoading,
  initialValues,
  onValuesChange,
  formButtons,
  disabled,
  requireClinicalGoal,
}: ClinicalGoalTemplateFetchContainerProps) => {
  const fetchHook = useTemplatesGetClinicGoalTemplate({});
  const templates = keyBy(fetchHook.data?.data, 'condition') || {};

  const defaultConditions = useMemo(() => {
    const result = map(templates, (item) => ({
      value: item.condition,
      label: (<ConditionEumComponent value={item.condition as ConditionEnum} />),
      searchableLabel: startCase(lowerCase(item.condition as ConditionEnum)),
      disabled: false
    }));
    return result;
  }, [templates]);

  return (
    <FetchComponent
      info={fetchHook}
    >
      {
        (value) => {
          if (value?.rawValue?.data === undefined) {
            return <LoadingOverlayComponent isLoading />;
          }
          return (
            <ClinicalGoalCardFormComponent
              defaultConditions={defaultConditions}
              onSubmit={onSubmit}
              onCancel={onCancel}
              initialValues={initialValues}
              isLoading={isLoading}
              templates={templates}
              onValuesChange={onValuesChange}
              formButtons={formButtons}
              disabled={disabled}
              requireClinicalGoal={requireClinicalGoal}
            />
          );
        }
      }
    </FetchComponent>
  );
};
