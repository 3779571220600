import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { WorklistSectionHeaderComponent } from '../WorklistSectionHeaderComponent/WorklistSectionHeaderComponent';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { WorklistMedReminderConsentFormContainer } from '../../container/WorklistMedReminderConsentFormContainer/WorklistMedReminderConsentFormContainer';

export interface SimplifiedWorklistMedReminderConsentComponentProps
  extends NestedFormComponentChildProps {
  patientId: string;
  itemNumber?: number;
}

export const SimplifiedWorklistMedReminderConsentComponent = ({
  patientId,
  itemNumber = -1,
}: SimplifiedWorklistMedReminderConsentComponentProps) => (
  <div className="simplified-worklist-section">
    <WorklistSectionHeaderComponent
      number={itemNumber}
      title="Obtain Patient Consent for Medication Reminders"
    />

    <NestedFormComponent>
      <WorklistMedReminderConsentFormContainer
        patientId={patientId}
      />
    </NestedFormComponent>
  </div>
);
