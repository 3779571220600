import { MedicalAlertCategoryEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface MedicalAlertCategoryEnumComponentProps {
  value: MedicalAlertCategoryEnum | undefined | null;
}

export const MedicalAlertCategoryEnumComponent = ({
  value
}: MedicalAlertCategoryEnumComponentProps) => {
  const getText = (): React.ReactNode => {
    switch (value) {
      case MedicalAlertCategoryEnum.CLINICAL:
        return 'Clinical alert';
      case MedicalAlertCategoryEnum.PROVIDER:
        return 'Provider alert';
      default:
        return <DisplayOrEmptyComponent />;
    }
  };

  return <>{getText()}</>;
};
