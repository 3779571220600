import { CarePlanUpdateFromEnum } from '../../uc-api-sdk';

export interface CarePlanUpdateFromEnumComponentProps {
  value?: CarePlanUpdateFromEnum | null
}

export const CarePlanUpdateFromEnumComponent = ({
  value,
}: CarePlanUpdateFromEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case CarePlanUpdateFromEnum.FOLLOW_UP:
        return 'Follow-up Visit';
      case CarePlanUpdateFromEnum.ADDITIONAL:
        return 'Additional Visit';
      case CarePlanUpdateFromEnum.INIT_ASSESSMENT:
        return 'Initial Visit';
      case CarePlanUpdateFromEnum.INITIAL:
        return 'Initial Visit';
      case CarePlanUpdateFromEnum.POST_MD_FOLLOW_UP:
        return 'Post-MD Follow-up Visit';
      case CarePlanUpdateFromEnum.PROFILE:
        return 'Profile';
      case CarePlanUpdateFromEnum.OTHER:
        return 'Other Visit';
      default:
        return '';
    }
  };
  return (<span>{getText()}</span>);
};
