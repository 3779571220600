import { Dayjs } from 'dayjs';
import { MTPRConditionTitleWithIconComponent, MTPRConditionTitleWithIconComponentProps } from '../MTPRConditionTitleWithIconComponent/MTPRConditionTitleWithIconComponent';
import { LastMeasurementComponent } from '../../../vitals/component/LastMeasurementComponent/LastMeasurementComponent';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface MTPRMeasurementSummaryTitleComponentProps
  extends MTPRConditionTitleWithIconComponentProps {
  lastMeasurementDate?: Dayjs | string | number | null;
}

export const MTPRMeasurementSummaryTitleComponent = ({
  condition,
  customCategory,
  lastMeasurementDate,
}: MTPRMeasurementSummaryTitleComponentProps) => (
  <div className="flex gap3 ai-c mb10">
    <MTPRConditionTitleWithIconComponent
      condition={condition}
      customCategory={customCategory}
    />
    <div className="fs12">
      {lastMeasurementDate
        && (
          <LastMeasurementComponent
            value={TimezoneService.calcDateTimeDayjs(lastMeasurementDate)}
          />
        )}
    </div>
  </div>
);
