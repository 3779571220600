import { Drawer } from 'antd';
import { PhysicianReportContainer } from '../container/PhysicianReportContainer';
import { LargeDrawerWidth } from '../../../../constants/drawer';

export interface PhysicianReportDrawerComponentProps {
  isPreFollowUpSummary?: boolean;
  patientId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PhysicianReportDrawerComponent = ({
  isPreFollowUpSummary,
  patientId,
  isOpen,
  onClose,
}: PhysicianReportDrawerComponentProps) => (
  <Drawer
    open={isOpen}
    closable
    onClose={onClose}
    width={LargeDrawerWidth}
    destroyOnClose
  >
    <PhysicianReportContainer patientId={patientId} isPreFollowUpSummary={isPreFollowUpSummary} />
  </Drawer>
);
