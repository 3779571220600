import dayjs from 'dayjs';
import { Timeline } from 'antd';
import { useCallback } from 'react';
import useBoolean from '../../../hooks/useBoolean/useBoolean';
import { FrequencyDisplayType, MedicationHistoryItem } from '../../../uc-api-sdk';
import { DisplayDateComponent } from '../../DisplayComponent/DisplayDateComponent';
import { DefaultExtendableDrawers } from '../../ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../ExtendableDrawerComponent/ExtendableDrawerComponent';
import './MedicationHistoryDrawerComponent.scss';
import { ExtendableDrawerButtonComponent } from '../../ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';
import { USA_DATE } from '../../../constants/timeFormat';
import { getDosingFrequencyText } from '../../../enumComponent/DosageFrequencyEnumComponent/DosageFrequencyEnumComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.viewMedication;

export interface MedicationHistoryDrawerComponentProps {
  name: string | undefined,
  histories?: MedicationHistoryItem[] | null;
}

export const MedicationHistoryDrawerComponent = ({
  name,
  histories = [],
}: MedicationHistoryDrawerComponentProps) => {
  const {
    value: open,
    toggle: toggleOpen,
  } = useBoolean(false);

  const renderContent = useCallback((
    historyItem: MedicationHistoryItem,
  ) => {
    const {
      field,
      originalValue,
      newValue: historyNewValue = '',
    } = historyItem || {};
    let changedField = field;
    let oldValue = originalValue;
    let newValue = historyNewValue;
    if (!field) return 'Unknown';
    if (field === 'reminders') {
      return 'Medication reminder changed.';
    }
    if (field === 'frequency') {
      changedField = 'Dosing frequency';
      oldValue = getDosingFrequencyText('long', oldValue as FrequencyDisplayType);
      newValue = getDosingFrequencyText('long', newValue as FrequencyDisplayType);
    }
    if (['startDate', 'endDate'].includes(field)) {
      oldValue = oldValue ? dayjs(oldValue).format(USA_DATE) : '--';
      newValue = newValue ? dayjs(newValue).format(USA_DATE) : '--';
    }
    return `Changed the ${changedField} from ${oldValue || '--'} to ${newValue || '--'}`;
  }, []);

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText="History"
        buttonProps={{
          type: 'text',
        }}
        onClick={toggleOpen}
      />
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        className="historyDrawerComponent"
        footer={null}
        placement="right"
        width={520}
        open={open}
        onClose={toggleOpen}
      >
        <div className="fs2 mb20 b7">{name}</div>
        <Timeline mode="left">
          {histories?.map((h, index) => (
            <Timeline.Item className={index === 0 ? 'blue' : 'grey'} key={h.modifiedAt?.toString()}>
              {index === 0 && <div>Latest update</div>}
              <div className={`historyTime mb5 ${index === 0 ? 'b6' : ''}`}>
                <DisplayDateComponent
                  value={h?.modifiedAt}
                  format="MEDICATION_HISTORY_DATE_FORMAT"
                />
              </div>
              <div>
                {
                  histories.length === 1 || index === histories.length - 1
                    ? h?.newValue
                    : renderContent(h)
                }
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </ExtendableDrawerComponent>
    </>
  );
};
