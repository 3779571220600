import { Col, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useEffect } from 'react';
import { MedicationDosageInputComponent } from '../../../../uiComponent/Medication/MedicationDosageInput';
import { FrequencyDisplayType } from '../../../../uc-api-sdk';
import './MedicationDosageListFormItemComponent.scss';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';

export interface MedicationDosageListFormItemComponentProps {
  frequency?: FrequencyDisplayType;
  isSameDosage?: boolean;
}

export const MedicationDosageListFormItemComponent = ({
  frequency,
  isSameDosage,
}: MedicationDosageListFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const medicationDosageForm = useMedicationForm({ form });

  const getDosageFields = (frequency?: FrequencyDisplayType, isSameDosage?: boolean) => {
    if (isSameDosage) return Array(1).fill(undefined);
    switch (frequency) {
      case FrequencyDisplayType.BID:
        return Array(2).fill(undefined);
      case FrequencyDisplayType.TID:
      case FrequencyDisplayType.Q8H:
        return Array(3).fill(undefined);
      case FrequencyDisplayType.QID:
      case FrequencyDisplayType.Q6H:
        return Array(4).fill(undefined);
      default:
        return [];
    }
  };

  useEffect(() => {
    const dosage = (
      medicationDosageForm.form.getFieldValue(
        medicationDosageForm.getMainFormItemName()
      )
    );
    const dosageFields = getDosageFields(frequency, isSameDosage);
    if (dosage?.length && dosage?.length === dosageFields.length) return;
    medicationDosageForm.form.setFieldValue(
      medicationDosageForm.getMainFormItemName(),
      dosageFields
    );
  }, [frequency, isSameDosage]);

  return (
    <Form.List
      name={medicationDosageForm.getMainFormItemName()}
    >
      {(fields) => (
        fields.map((field, idx) => (
          <Col span={12} key={`Dose${idx + 1}`} className="medication-dosage__item">
            <FormItem
              name={field.name}
              label={isSameDosage ? 'Dose' : `Dose ${idx + 1}`}
              required
              rules={[{ required: true, message: 'Missing Dose!' }]}
              className="required-field"
            >
              <MedicationDosageInputComponent />
            </FormItem>
          </Col>
        ))
      )}
    </Form.List>
  );
};
