import { Form } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { CPTCodeUnitsComponent } from '../CPTCodeComponent/CPTCodeUnitsComponent/CPTCodeUnitsComponent';
import { CPTCodeCategorySelectComponent, CPTCodeCategorySelectComponentProps } from '../CPTCodeComponent/CPTCodeCategorySelectComponent/CPTCodeCategorySelectComponent';
import { defaultCPTCodesByCategory } from '../CPTCodeComponent/constant';

import './CPTCodeFormItemComponent.scss';

export interface CPTCodeFormItemComponentProps {
  fieldName?: number;
}

export const CPTCodeFormItemComponent = ({
  fieldName,
}: CPTCodeFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  const handleOnChangeCategory: CPTCodeCategorySelectComponentProps['onChange'] = (
    value,
  ) => {
    if (!value) return;
    const cptCodeUnitsByCategory = defaultCPTCodesByCategory[value];
    insuranceForm.form.setFieldValue(
      insuranceForm.makeObjectNamePath('priorAuthCPTCodeUnits', fieldName),
      cptCodeUnitsByCategory,
    );
  };

  return (
    <div className="ant-form-item-combined-rows">
      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthCPTCodeCategory', fieldName)}
        label={insuranceForm.getLabel('priorAuthCPTCodeCategory')}
      >
        <CPTCodeCategorySelectComponent
          onChange={handleOnChangeCategory}
        />
      </FormItem>
      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthCPTCodeUnits', fieldName)}
        colon={false}
        label=" "
        className="prior-auth-cpt-code-units"
      >
        <CPTCodeUnitsComponent />
      </FormItem>
    </div>
  );
};
