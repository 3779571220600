import { ReactNode } from 'react';
import { Card, Divider } from 'antd';
import { MedicationReminderListFormItemComponent } from '../MedicationReminderListFormItemComponent/MedicationReminderListFormItemComponent';
import { MedicationReminderSwitchFormItemComponent } from '../MedicationReminderSwitchFormItemComponent/MedicationReminderSwitchFormItemComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

import './MedicationReminderCardFormItemComponent.scss';

export interface MedicationReminderCardFormItemComponentProps {
  title?: ReactNode;
}

export const MedicationReminderCardFormItemComponent = ({
  title = (
    <TextComponent size="small" color="gray2">
      Medication Reminder
    </TextComponent>
  ),
}: MedicationReminderCardFormItemComponentProps) => {
  const formHook = useMedicationForm();
  return (
    <FormItem
      noStyle
      shouldUpdate={formHook.shouldUpdate(['frequency', 'enableReminder'])}
    >
      {
        ({ getFieldValue }) => {
          const frequency = formHook.getValue('frequency', getFieldValue);
          const enableReminder = formHook.getValue('enableReminder', getFieldValue);
          return (
            <>
              {title}
              <Card className="medication-reminder__card default-br">
                <MedicationReminderSwitchFormItemComponent />
                {
                  enableReminder && (
                    <>
                      <Divider />
                      <MedicationReminderListFormItemComponent
                        frequency={frequency}
                      />
                    </>
                  )
                }
              </Card>
            </>
          );
        }
      }
    </FormItem>
  );
};
